<div
  class="notification-box"
  [ngClass]="{
    'info-notiffication': type == 'info',
    'success-notiffication': type == 'success',
    'error-notiffication': type == 'error',
    'neutral-notiffication': type == 'neutral',
    'warning-notiffication': type == 'warning',
    'hide-notification': !visible
  }"
>
  <div class="d-flex flex-row">
    <div style="width: 24px">
      <i
        class="icon"
        [ngClass]="{ 'absui-icon--info-notification': type }"
        style="display: inline-block"
      ></i>
    </div>
    <div style="width: calc(100vw - 94px)">
      <p class="text-notification">{{ message }}</p>
    </div>
    <div style="width: 70px">
      <i
        class="icon absui-icon--close close-btn"
        (click)="closeNotification()"
      ></i>
    </div>
  </div>
</div>
