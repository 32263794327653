import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { EventService } from './event.service';

@Injectable()
export class isLocalhost implements CanActivate {
  constructor(private event: EventService) {}

  canActivate() {
    return (
      this.event.returnEnvironmentInfo() === 'localhost' ||
      this.event.returnEnvironmentInfo() === 'dev' ||
      this.event.returnEnvironmentInfo() === 'qa'
    );
  }
}
