import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChatUtilsService } from './chat-utils.service';
import { catchError, delay, Observable, of, retry } from 'rxjs';
import { IMessage } from '../interfaces/message';
import { EventService } from 'src/app/event.service';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  constructor(
    private httpClient: HttpClient,
    private utils: ChatUtilsService,
    private event: EventService,
    private chatService: ChatService
  ) {}

  get(url: string, token: string): Observable<IMessage> {
    /* for debugging */
    if (url.includes('/test')) {
      return this.simulateFetch();
    }

    /* debugowanie na środowiskach deweloperskich */
    const env = this.event.returnEnvironmentInfo();
    if (env === 'localhost' || env === 'dev' || env === 'qa') {
      if (url.includes('/test')) {
        return this.simulateFetch();
      }
    }
    
    if (this.chatService.isDebugMode()) return this.simulateFetch();

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        accept: '*/*',
      },
    };

    try {
      this.checkConnection(url, token);

      return this.httpClient.get(url, options) as Observable<IMessage>;
    } catch (e: any) {
      return this.errorHandler(e);
    }
  }

  simulateFetch = (): Observable<IMessage> =>
    of(<IMessage>{
      text: 'Jasne o które zlecenie chodzi?',
      time: this.utils.getTime(),
      type: 'incoming',
      buttons: [
        { text: 'Serwisowe', value: 'button1' },
        { text: 'Produkcyjne', value: 'button2' },
      ],
    });

  post(message: IMessage, url: string, token: string): Observable<IMessage> {
    const body = JSON.stringify(message);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        accept: '*/*',
      },
    };

    /* debugowanie na środowiskach deweloperskich */
    const env = this.event.returnEnvironmentInfo();
    if (env === 'localhost' || env === 'dev' || env === 'qa') {
      if (url.includes('/test') || message.text.includes('test')) {
        return this.simulateSend();
      }
    }

    if (this.chatService.isDebugMode()) return this.simulateSend();

    try {
      this.checkConnection(url, token);
      return this.httpClient
        .post<any>(url, body, options)
        .pipe(retry(0), catchError(this.errorHandler)) as Observable<IMessage>;
    } catch (e: any) {
      let msg = e.message;
      if (e.message.includes('Failed to fetch')) {
        msg =
          '<b>Nie można połączyć się z serwerem.</b> <br/>Sprawdź połączenie internetowe.<script>alert("XSS")</script>';
      }
      const errorMessage: IMessage = <IMessage>{
        text: msg,
        time: this.utils.getTime(),
        type: 'incoming',
      };
      return of(errorMessage);
    }
  }

  simulateSend = (): Observable<IMessage> => {
    return of(<IMessage>{
      text: 'Jasne o które zlecenie chodzi?',
      time: this.utils.getTime(),
      type: 'incoming',
      buttons: [
        { text: 'Serwisowe', value: 'button1' },
        { text: 'Produkcyjne', value: 'button2' },
      ],
    }).pipe(delay(1000));
  };

  checkConnection(url: string, token: string) {
    if (!url || this.utils.verifyUrl(url) === false) {
      throw new Error(
        '<b>Błąd konfiguracji połączenia z API.</b> <br/> Niepoprawny adres URL.'
      );
    }

    if (!token) {
      throw new Error(
        '<b>Błąd konfiguracji połączenia z API.</b> <br/> Brak tokena autoryzacyjnego.'
      );
    }
  }

  errorHandler = (e: any): Observable<IMessage> => {
    let msg = e.message;
    if (e.message.includes('Failed to fetch')) {
      msg =
        '<b>Nie można połączyć się z serwerem.</b> <br/>Sprawdź połączenie internetowe.';
      return of(<IMessage>{
        text: msg,
        time: this.utils.getTime(),
        type: 'incoming',
      });
    }
    const errorMessage: IMessage = <IMessage>{
      text: '',
      time: this.utils.getTime(),
      type: 'incoming',
      error: e,
    };
    return of(errorMessage);
  };
}
