[
  {
    "id": "1",
    "idPatch": "0",
    "name": "menu.apps",
    "icon": "icon icon-absui-icon--location",
    "url": null,
    "items": [],
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "2",
    "name": "menu.start",
    "idPatch": "1",
    "icon": "icon absui-icon--home-3",
    "url": "auctions/dashboard",
    "items": [],
    "patch": "",
    "submenu": [
      {
        "id": "21",
        "idPatch": "2",
        "name": "menu.auctions.news",
        "url": "auctions/dashboard",
        "code": null,
        "patch": "menu.start",
        "visible": true,
        "signature": "P",
        "classCss": "redHeader"
      },
      {
        "id": "22",
        "idPatch": "2",
        "name": "menu.avp",
        "url": "auctions/news",
        "code": null,
        "patch": "menu.start",
        "visible": true,
        "signature": "A",
        "classCss": "blueGreyHeader"
      }
    ],
    "tooltip": false,
    "code": null,
    "visible": true
  },
  {
    "id": "4",
    "name": "menu.auctions.transactions",
    "idPatch": "3",
    "icon": "icon absui-icon--table-coins",
    "url": "auctions/transactions",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "5",
    "name": "menu.auctions.auctions",
    "idPatch": "4",
    "icon": "icon absui-icon--list-money",
    "url": "auctions/auctions",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "6",
    "name": "menu.auctions.packages",
    "idPatch": "5",
    "icon": "icon absui-icon--package-front",
    "url": "auctions/packages",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "7",
    "name": "menu.articles",
    "idPatch": "6",
    "icon": "icon absui-icon--trolley",
    "url": "auctions/goods",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "8",
    "name": "menu.contractors",
    "idPatch": "7",
    "icon": "icon absui-icon--user-group",
    "url": "auctions/clients",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "9",
    "name": "menu.auctions.mail",
    "idPatch": "8",
    "icon": "icon absui-icon--mail-back-menu",
    "url": "auctions/mail",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "11",
    "name": "Konfiguracja",
    "idPatch": "10",
    "icon": "icon absui-icon--settings-user",
    "url": "auctions/conf",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "12",
    "name": "Użytkownicy",
    "idPatch": "11",
    "icon": "icon absui-icon--list-user",
    "url": "auctions/accounts",
    "items": [],
    "patch": "",
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  }
]
