import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DxButtonModule, DxTooltipModule } from 'devextreme-angular';
import { EventService } from 'src/app/event.service';
import { PrintService } from 'src/app/print.service';

@Component({
    selector: 'app-single-queue-task',
    templateUrl: './single-queue-task.component.html',
    styleUrls: ['./single-queue-task.component.scss'],
    imports: [CommonModule, DxButtonModule, DxTooltipModule, TranslateModule]
})
export class SingleQueueTaskComponent {
  @Input() item: any;
  constructor(private cd: ChangeDetectorRef, private print: PrintService, public event: EventService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.item?.currentValue) {
      /* capitalize first letter of status */
      const status = this.item.StatusDescription;
      let str = status[0].toUpperCase() + status.slice(1);
      this.item.StatusDescription = str;
      this.cd.detectChanges();
    }
  }

  retryTask(task) {
    if (task.Type == 'PrintBill') {
      this.print.newTask.next({ event: 'retryPrint', data: task });
    }
  }

  cancelTask(task) {
    if (task.Type == 'PrintBill') {
      this.print.newTask.next({ event: 'cancelPrint', data: task });
    }
  }
}
