<app-license-box-info #licenseBox></app-license-box-info>

@if(runenviroment === 'OnPremises'){
<div class="on-premis-box">
  <p>
    Pracujesz w wersji developerskiej OnPremises. Wersja nie do użytku
    komercjnego.
  </p>
</div>
}

<header *ngIf="event.deviceType == 'desktop'; else mobileView">
  <dx-toolbar [items]="toolbarContent" (click)="onToolbarClick()"></dx-toolbar>
  <div
    class="title-menu text-center"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <img
      src="../../../assets/img/logo_anywhere.svg"
      style="position: absolute; left: 92px; width: 103px"
    />
    <div
      class="btn-header-panel left-btn-header-panel"
      style="display: inline-block; position: relative; top: 0px; left: 82px"
    >
      <div *ngIf="dropdownVisible.search" class="box-search">
        <dx-text-box
          class="search-box dashboard-step-2"
          placeholder="{{ 'search' | translate }}..."
          (keyup.enter)="searchRes()"
          (click)="onClickSearchInput($event)"
          [(value)]="searchValue"
          [ngClass]="{ 'dx-state-focused-active': isSearchResult }"
          valueChangeEvent="input"
          (onValueChanged)="onValueChanged($event)"
          (onKeyDown)="onKeyDown($event)"
          [showClearButton]="false"
          [buttons]="searchBoxButtons"
        >
        </dx-text-box>

        <i
          class="icon icon-search search-hover"
          [ngClass]="{
            'btn-search-icon': !isSearchResult,
            'btn-search-icon-active': isSearchResult
          }"
          (click)="isSearchResult = true; searchRes()"
          tabIndex="-1"
        ></i>
      </div>
    </div>
    <!-- <p class="p-url-header">{{ pageRouter | translate}}</p> -->

    <!-- <wapro-logo
      fillColorPrimary="neutral-100"
      fillColorSecondary="neutral-100"
      type="anywhere"
      [width]="106"
      [height]="14"
    ></wapro-logo> -->

    <div class="btn-header-panel right-btn-header-panel dashboard-step-3">
      <dx-button
        *ngIf="dropdownVisible.company"
        class="user-panel-header"
        style="height: 32px"
        #companychose
        type="default"
        stylingMode="text"
        id="companychose"
        [ngClass]="{
          'user-panel-header-active': chooseCompany,
          'user-panel-header': !chooseCompany
        }"
        (click)="onChooseCompany($event)"
      >
        <p>
          {{
            event.footerInfo?.CompanyName?.length >= 30
              ? (event?.footerInfo?.CompanyName | slice : 0 : 30) + " ..."
              : event?.footerInfo?.CompanyName
          }}
        </p>
        <i
          class="icon"
          [ngClass]="{
            'absui-icon--arrow-drop-down-box': !chooseCompany,
            'dx-icon-spinup': chooseCompany
          }"
          tabIndex="-1"
          style="position: relative; font-size: 18px !important"
        ></i>
      </dx-button>

      <dx-button
        *ngIf="
          dropdownVisible.warehouse &&
          event?.footerInfo?.VariantName !== 'ANY FAKTUROWANIE' &&
          event?.footerInfo?.VariantName !== 'FAKTUROWANIE' &&
          event?.footerInfo?.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          event?.footerInfo?.VariantName !== 'FAKTUROWANIE PRO'
        "
        class="user-panel-header"
        id="warehouse-change-button"
        type="default"
        stylingMode="text"
        [ngClass]="{
          'user-panel-header-active': chooseWarehouse,
          'user-panel-header': !chooseWarehouse
        }"
        (click)="onChooseWarehouse($event)"
        [disabled]="event?.footerInfo?.VariantName === 'START'"
      >
        <div
          [ngStyle]="{ 'background-color': currentColorWarehouse }"
          class="box-color-warehouse"
          style="margin-left: 10px; margin-right: 10px"
        ></div>
        <p>
          {{
            !isOnlySymbol
              ? event.footerInfo?.AppWarehouseName?.length >= 30
                ? (event.footerInfo?.AppWarehouseName | slice : 0 : 30) + " ..."
                : event.footerInfo?.AppWarehouseName
              : event.footerInfo?.AppWarehouseSymbol
          }}
        </p>
        <i
          class="icon"
          [ngClass]="{
            'absui-icon--arrow-drop-down-box': !chooseWarehouse,
            'dx-icon-spinup': chooseWarehouse
          }"
          tabIndex="-1"
          style="position: relative; font-size: 18px !important"
        ></i>
      </dx-button>

      <!-- kasa -->
      <dx-button
        *ngIf="
          dropdownVisible.cash &&
          event?.footerInfo?.VariantName !== 'ANY FAKTUROWANIE' &&
          event?.footerInfo?.VariantName !== 'FAKTUROWANIE' &&
          event?.footerInfo?.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          event?.footerInfo?.VariantName !== 'FAKTUROWANIE PRO'
        "
        class="user-panel-header"
        style="height: 32px"
        #chooseCashBox
        type="default"
        stylingMode="text"
        id="chooseCash"
        [ngClass]="{
          'user-panel-header-active': chooseCash,
          'user-panel-header': !chooseCash
        }"
        (click)="onChooseCash($event)"
      >
        <p>
          {{
            !isOnlySymbol
              ? event.footerInfo?.AppCashName?.length >= 30
                ? (event.footerInfo?.AppCashName | slice : 0 : 30) + " ..."
                : event.footerInfo?.AppCashName
              : event.footerInfo?.AppCashSymbol
          }}
        </p>
        <i
          class="icon"
          [ngClass]="{
            'absui-icon--arrow-drop-down-box': !chooseCash,
            'dx-icon-spinup': chooseCash
          }"
          tabIndex="-1"
          style="position: relative; font-size: 18px !important"
        ></i>
      </dx-button>
      <!--  -->
      <dx-button
        *ngIf="isAuctions() && currentAuctionsAccount()"
        class="user-panel-header"
        style="height: 32px"
        type="default"
        stylingMode="text"
        id="aucUser"
        (onClick)="goToUsers()"
      >
        <div
          [ngClass]="{
            'circle-red': currentAuctionsAccount()?.SystemId == 1,
            'circle-yellow': currentAuctionsAccount()?.SystemId == 2
          }"
        ></div>
        <p>
          {{
            currentAuctionsAccount()?.AccountName?.length >= 30
              ? (currentAuctionsAccount()?.AccountName | slice : 0 : 30) +
                " ..."
              : currentAuctionsAccount()?.AccountName
          }}
        </p>
      </dx-button>

      <dx-button
        *ngIf="dropdownVisible.user"
        (click)="onUserPanelClick($event)"
        id="userPanelHeader"
        stylingMode="text"
        [ngClass]="{
          'user-panel-header-active': isHideUserPanel,
          'user-panel-header': !isHideUserPanel
        }"
      >
        <i
          class="icon absui-icon--account-circle"
          style="position: relative"
          tabIndex="-1"
        ></i>
      </dx-button>

      <dx-button
        *ngIf="dropdownVisible.help"
        (click)="onNotificationsClick($event)"
        id="notifications"
        stylingMode="text"
        [ngClass]="{
          'btn-header-active': isShowQueue,
          'btn-header': !isShowQueue,
          showDotQueueTask: showDotQueueTask
        }"
      >
        <i
          class="icon absui-icon--notifications"
          tabIndex="-1"
          style="top: -2px; position: relative"
        ></i>
        <span class="showDotQueueTask" *ngIf="showDotQueueTask"></span>
      </dx-button>
    </div>

    <!-- <div style="width: 64px; height: 32px; background-color: #333333; position: absolute; right: 0; top: -7px; padding: 7px;">
            <i class="icon absui-icon--account-circle" 
                style="cursor: pointer; margin-right: 7px;"
                (click)="showUserPanel()"></i>

            <i class="icon icon-sign-out"  title="{{'menu.log-out' | translate}}"
                style="cursor: pointer;" (click)="logOut()"></i>
        </div> -->
  </div>
  <dx-tooltip
    target="#companychose"
    position="bottom"
    [hideEvent]="{ name: 'mouseleave' }"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
    cssClass="tooltip"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ event.footerInfo?.CompanyName || "Firma" }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    target="#warehouse-change-button"
    position="bottom"
    [hideEvent]="{ name: 'mouseleave' }"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
    cssClass="tooltip"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ event.footerInfo?.AppWarehouseSymbol }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    target="#helpPanelHeader"
    position="bottom"
    [hideEvent]="{ name: 'mouseleave' }"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
    cssClass="tooltip"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ "menu.navigationPanelUser.help" | translate }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    target="#userPanelHeader"
    position="bottom"
    [hideEvent]="{ name: 'mouseleave' }"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
    cssClass="tooltip"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ "settings" | translate }} <br />{{ currentUserName }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    target="#notifications"
    position="bottom"
    [hideEvent]="{ name: 'mouseleave' }"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
    cssClass="tooltip"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ "menu.navigationPanelUser.notifications" | translate }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    target="#help"
    position="bottom"
    [hideEvent]="{ name: 'mouseleave' }"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
    cssClass="tooltip"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ "menu.navigationPanelUser.help" | translate }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    target="#logout"
    position="bottom"
    [hideOnOutsideClick]="false"
    [hideEvent]="{ name: 'mouseleave' }"
    [showEvent]="event.tooltipShowEvent"
    cssClass="tooltip"
    [wrapperAttr]="{ class: 'logout-tooltip' }"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ "menu.log-out" | translate }}
    </div>
  </dx-tooltip>

  <dx-drawer
    [openedStateMode]="selectedOpenMode"
    [position]="selectedPosition"
    [revealMode]="selectedRevealMode"
    template="template"
    [(opened)]="isDrawerOpen"
    height="auto"
    (openedChange)="openedChange()"
    [animationEnabled]="true"
    tabIndex="-1"
  >
    <div
      *dxTemplate="let data of 'template'"
      class="bg-menu"
      style="width: 82px"
    >
      <dx-scroll-view
        width="100%"
        height="calc(100vh - 32px)"
        direction="vertical"
        showScrollbar="onHover"
        class="dashboard-step-1"
      >
        <dx-menu
          data-cy="main-menu-btn"
          #menu
          [dataSource]="navigation"
          displayExpr="name"
          [focusStateEnabled]="true"
          [showFirstSubmenuMode]="showFirstSubmenuModes"
          orientation="vertical"
          submenuDirection="rightOrBottom"
          [hideSubmenuOnMouseLeave]="true"
          itemTemplate="items"
          (onItemClick)="onItemClick($event)"
          cssClass="main-menu"
          style="padding-left: 6px; padding-bottom: 75px"
          tabindex="1"
        >
          <div *dxTemplate="let item of 'items'; let i = index">
            <ng-container
              *ngIf="item.name !== 'menu.start'; else standardRouter"
            >
              <!-- szybkie kroki -->
              <div
                class="dx-item-content dx-menu-item-content"
                [id]="'id-' + item.id"
                *ngIf="item?.type === 'listHeader' && item.url != 'help'"
                (mouseenter)="toggleDefault()"
                (mouseleave)="toggleHide()"
                routerLinkActive="active"
                (click)="changeRouter(item)"
                (keydown.enter)="changeRouter(item)"
                [ngClass]="{
                  'dx-submenu-top': item.id > 20,
                  fastSteps: item?.type === 'listHeader'
                }"
                [routerLink]="['/' + item.url]"
              >
                <div
                  [ngClass]="{
                    'text-left': item.id > 20,
                    'text-center': item.id < 21
                  }"
                >
                  <i
                    class="dx-icon icon {{ item.icon }}"
                    style="display: block; margin-left: 20px"
                  ></i>
                  <span
                    class="dx-menu-item-text-span"
                    [ngClass]="{
                      'dx-submenu-top-span': item.id > 20,
                      fastSteps: item?.type === 'listHeader'
                    }"
                    >{{ item.name | translate }}</span
                  >
                </div>
              </div>
              <!--  -->

              <div
                *ngIf="item?.type === 'popup'"
                [id]="'id-' + item.id"
                class="dx-item-content dx-menu-item-content"
                (mouseenter)="toggleDefault()"
                (mouseleave)="toggleHide()"
                (click)="runPopup(item)"
                (keydown.enter)="runPopup(item)"
                [ngClass]="{
                  'dx-submenu-top': item.id > 20
                }"
              >
                <div tabindex="-1" class="a-link">
                  <div
                    [ngClass]="{
                      'text-left': item.id > 20,
                      'text-center': item.id < 21
                    }"
                  >
                    <!-- <i
                        class="icon absui-icon--arrow-drop-right submenu-icon-show"
                        style="position: absolute; top: 2px; right: 0px"
                        *ngIf="item.id < 10 && item.items.length > 0"
                      ></i> -->
                    <i
                      class="dx-icon icon {{ item.icon }}"
                      style="display: block; margin-left: 20px"
                    ></i>
                    <span
                      class="dx-menu-item-text-span"
                      [ngClass]="{
                        'dx-submenu-top-span': item.id > 20
                      }"
                    >
                      {{ item.name | translate }}
                      <p class="p-shorcut" *ngIf="item.shortcut">
                        {{ item.shortcut }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="dx-item-content dx-menu-item-content"
                [id]="'id-' + item.id"
                *ngIf="item.url && item.url != 'help' && item.url != 'jpk/help'"
                (mouseenter)="toggleDefault()"
                (mouseleave)="toggleHide()"
                routerLinkActive="active"
                (click)="changeRouter(item)"
                (keydown.enter)="changeRouter(item)"
                [ngClass]="{
                  'dx-submenu-top': item.id > 20
                }"
                [routerLink]="['/' + item.url]"
              >
                <a [routerLink]="['/' + item.url]" tabindex="-1" class="a-link">
                  <div
                    [ngClass]="{
                      'text-left': item.id > 20,
                      'text-center': item.id < 21
                    }"
                  >
                    <!-- <i
                      class="icon absui-icon--arrow-drop-right submenu-icon-show"
                      style="position: absolute; top: 2px; right: 0px"
                      *ngIf="item.id < 10 && item.items.length > 0"
                    ></i> -->
                    <i
                      class="dx-icon icon {{ item.icon }}"
                      style="display: block; margin-left: 20px"
                    ></i>
                    <span
                      class="dx-menu-item-text-span"
                      [ngClass]="{
                        'dx-submenu-top-span': item.id > 20
                      }"
                    >
                      {{ item.name | translate }}
                      <p class="p-shorcut" *ngIf="item.shortcut">
                        {{ item.shortcut }}
                      </p>
                    </span>
                  </div>
                </a>
              </div>

              <div
                class="dx-item-content dx-menu-item-content"
                [id]="'id-' + item.id"
                *ngIf="item.url == 'jpk/help'"
                (mouseenter)="toggleDefault()"
                (mouseleave)="toggleHide()"
                routerLinkActive="active"
                (click)="changeRouter(item)"
                (keydown.enter)="changeRouter(item)"
                [ngClass]="{
                  'dx-submenu-top': item.id > 20
                }"
              >
                <div
                  [ngClass]="{
                    'text-left': item.id > 20,
                    'text-center': item.id < 21
                  }"
                >
                  <i
                    class="dx-icon icon {{ item.icon }}"
                    style="display: block; margin-left: 20px"
                  ></i>
                  <span
                    class="dx-menu-item-text-span"
                    [ngClass]="{
                      'dx-submenu-top-span': item.id > 20
                    }"
                  >
                    {{ item.name | translate }}
                    <p class="p-shorcut" *ngIf="item.shortcut">
                      {{ item.shortcut }}
                    </p>
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-template #standardRouter>
              <div
                *ngIf="item?.type === 'popup'"
                [id]="'id-' + item.id"
                class="dx-item-content dx-menu-item-content"
                (mouseenter)="toggleDefault()"
                (mouseleave)="toggleHide()"
                (click)="runPopup(item)"
                (keydown.enter)="runPopup(item)"
                [ngClass]="{
                  'dx-submenu-top': item.id > 20
                }"
              >
                <div tabindex="-1" class="a-link">
                  <div
                    [ngClass]="{
                      'text-left': item.id > 20,
                      'text-center': item.id < 21
                    }"
                  >
                    <!-- <i
                        class="icon absui-icon--arrow-drop-right submenu-icon-show"
                        style="position: absolute; top: 2px; right: 0px"
                        *ngIf="item.id < 10 && item.items.length > 0"
                      ></i> -->
                    <i
                      class="dx-icon icon {{ item.icon }}"
                      style="display: block; margin-left: 20px"
                    ></i>
                    <span
                      class="dx-menu-item-text-span"
                      [ngClass]="{
                        'dx-submenu-top-span': item.id > 20
                      }"
                    >
                      {{ item.name | translate }}
                      <p class="p-shorcut" *ngIf="item.shortcut">
                        {{ item.shortcut }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="dx-item-content dx-menu-item-content"
                [id]="'id-' + item.id"
                *ngIf="
                  (item.url || item?.type === 'listHeader') &&
                  item.url != 'help'
                "
                (mouseenter)="toggleDefault()"
                (mouseleave)="toggleHide()"
                routerLinkActive="active"
                [routerLink]="['/' + item.url]"
              >
                <div class="text-center">
                  <i class="dx-icon icon {{ item.icon }}"></i><br />
                  <span class="dx-menu-item-text-span">{{
                    item.name | translate
                  }}</span>
                </div>
              </div>
            </ng-template>

            <!-- [routerLink]="['/'+item.url]" -->

            <div
              class="dx-item-content dx-menu-item-content"
              [id]="'id-' + item.id"
              *ngIf="item.url && item.url == 'help'"
              (mouseenter)="toggleDefault()"
              (mouseleave)="toggleHide()"
              (keydown.enter)="keyEnter(item)"
              [ngClass]="{
                'apps-css': item.id == 1,
                active: item.id == 1 && widthPanelApps
              }"
            >
              <div class="text-center">
                <a
                  href="https://wapro.pl/dokumentacja-erp/mag/webframe.htm"
                  target="_blank"
                  style="text-decoration-line: none"
                >
                  <i
                    class="dx-icon icon {{ item.icon }}"
                    style="color: #333 !important"
                  ></i
                  ><br />
                  <span
                    class="dx-menu-item-text-span"
                    style="
                      color: #333 !important;
                      font-family: Roboto, sans-serif;
                      font-weight: 500;
                      font-size: 0.625rem;
                      line-height: 12px;
                    "
                    >{{ item.name | translate }}</span
                  >
                </a>
              </div>
            </div>

            <div [ngClass]="{ 'dx-menu-items-container': item.id > 20 }">
              <div
                class="dx-item-content dx-menu-item-content text-center"
                [id]="'id-' + item.id"
                *ngIf="!item.url && !item?.type"
                (mouseenter)="toggleDefault(); hideAppPanel(item.id)"
                (mouseleave)="toggleHide(); hideAppPanel(item.id)"
                (keydown.enter)="keyEnter(item)"
                [ngClass]="{
                  'apps-css': item.id == 1,
                  'dx-submenu-top': item.id > 20
                }"
              >
                <div
                  [ngClass]="{
                    'text-left': item.id > 20,
                    'text-center': item.id < 21
                  }"
                >
                  <i
                    class="dx-icon icon {{ item.icon }}"
                    style="margin-top: -2px; display: block; margin-left: 20px"
                    *ngIf="item.id == 1"
                  ></i>
                  <span
                    class="dx-menu-item-text-span"
                    [ngClass]="{ 'dx-submenu-top-span-sub': item.id > 20 }"
                    >{{ item.name | translate }}</span
                  >
                  <i
                    class="icon absui-icon--arrow-drop-right-user-panel"
                    *ngIf="item.id > 20"
                    style="position: absolute; top: 1px; right: 15px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </dx-menu>

        <app-version-selected *ngIf="isDemo"> </app-version-selected>
        <app-wapro-app-select
          *ngIf="!isDemo"
          (onChoosed)="goApp($event)"
        ></app-wapro-app-select>
      </dx-scroll-view>
    </div>
    <div
      id="content"
      [ngClass]="{ 'hide-scroll': route.url === '/start/dashboard' }"
      (click)="clickContent()"
      style="
        height: calc(100vh - 32px);
        width: calc(100%);
        max-width: 102%;
        overflow-y: hidden;
        overflow-x: hidden !important;
      "
    >
      <div>
        <app-top-menu
          *ngIf="isTopMenuVisible()"
          [items]="submenu"
          [code]="code"
        ></app-top-menu>
        <router-outlet></router-outlet>
      </div>
    </div>
  </dx-drawer>
</header>

<div
  class="user-info-panel"
  *ngIf="isHideUserPanel"
  [ngStyle]="{ 'right.px': dropdownMarginsR.user }"
  [ngClass]="{ positinTop: licenseBox.isHideLicenseInfo() }"
>
  <div class="row" style="width: 100%; padding: 6px; margin-left: 0px">
    <div class="col-md-12 text-center box-user">
      <div class="circle_user">
        <p>{{ currentUserName | slice : 0 : 2 }}</p>
      </div>
      <p class="p_user_name">{{ currentUserName }}</p>
    </div>
  </div>

  <div
    class="row"
    style="width: 100%; margin-left: 0px; padding: 6px; padding-top: 0px"
  >
    <div
      class="col-md-12"
      style="padding: 14px 0px; padding-top: 0px; padding-bottom: 6px"
    >
      <dx-menu
        #menu
        [dataSource]="navigationPanelUser()"
        displayExpr="name"
        [tabIndex]="-1"
        [showFirstSubmenuMode]="showFirstSubmenuModes"
        orientation="vertical"
        submenuDirection="auto"
        [hideSubmenuOnMouseLeave]="false"
        cssClass="menu-panel-user border-bottom menu-select-list-icons"
        style="
          position: relative;
          margin-left: 0px;
          width: 286px;
          margin-bottom: -5px;
        "
        (onItemClick)="itemClick($event)"
        itemTemplate="menuItem"
      >
        <div
          *dxTemplate="let item of 'menuItem'"
          class="top-menu-item text-left"
          routerLinkActive="active"
          style="width: 100%"
        >
          <div
            class="dx-item-content dx-menu-item-content"
            [ngClass]="{
              'lang-selection':
                item.role === 'lang-selection' ||
                item.role === 'theme-selection',
              'lang-selected': item.active,
            }"
            style="
              width: 100% !important;
              display: flex;
              justify-content: left;
              align-items: center;
            "
          >
            <i
              class="dx-icon"
              [class]="item.icon"
            ></i>
            <span
              class="dx-menu-item-text"
              [style.color]="
                item.active ? 'var(--text-brand)' : 'var(--text-gray)'
              "
              >{{ item.name | translate }}</span
            >
            <i
              class="icon absui-icon--arrow-drop-right-user-panel"
              *ngIf="item.id == 3 || item.id == 7"
              style="
                margin-left: auto;
                margin-right: 15px;
                color: var(--text-gray);
                margin-top: -8px;
              "
            ></i>

            <ng-container *ngIf="item.id == 4">
              <span
                class="switch-desc"
                style="margin-right: 6px; margin-left: auto"
                >Auto</span
              >
              <dx-switch
                [(value)]="isDefaultOnboarding"
                (onValueChanged)="onDefaultOnboardingChanged($event)"
                id="defaultOnBoarding"
                style="margin-right: 0px; margin-top: 0px"
              >
              </dx-switch>
              <dx-tooltip
                target="#defaultOnBoarding"
                position="bottom"
                [hideEvent]="{ name: 'mouseleave' }"
                [hideOnOutsideClick]="false"
                [showEvent]="event.tooltipShowEvent"
                cssClass="tooltip"
              >
                <div *dxTemplate="let data = data; of: 'content'">Auto</div>
              </dx-tooltip>
            </ng-container>
          </div>
        </div>
      </dx-menu>

      <dx-menu
        #menu
        [dataSource]="helpPanel()"
        displayExpr="name"
        [tabIndex]="-1"
        [showFirstSubmenuMode]="showFirstSubmenuModes"
        orientation="vertical"
        submenuDirection="auto"
        [hideSubmenuOnMouseLeave]="false"
        cssClass="menu-panel-user menu-select-list-icons"
        style="
          position: relative;
          margin-left: 0px;
          margin-bottom: -5px;
          margin-top: 5px;
        "
        (onItemClick)="itemClick($event)"
        itemTemplate="menuItem"
      >
        <div
          *dxTemplate="let item of 'menuItem'"
          class="top-menu-item text-left"
          routerLinkActive="active"
          style="width: 100%"
          [ngClass]="{
            'border-top': item.name === 'menu.log-out'
          }"
        >
          <div
            class="dx-item-content dx-menu-item-content"
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <i
              class="dx-icon"
              [class]="item.icon"
            ></i>
            <span class="dx-menu-item-text">{{ item.name | translate }}</span>
            <i
              class="icon absui-icon--arrow-drop-right-user-panel"
              *ngIf="item.id == 3"
              style="position: absolute; right: 20px"
            ></i>
          </div>
        </div>
      </dx-menu>
    </div>
  </div>
</div>

<div
  class="user-info-panel"
  *ngIf="isHelpVisible"
  style="min-width: 280px"
  [ngStyle]="{ 'right.px': dropdownMarginsR.help }"
>
  <div class="row">
    <div class="col-md-12">
      <dx-menu
        #menu
        [dataSource]="helpPanel()"
        displayExpr="name"
        [tabIndex]="-1"
        [showFirstSubmenuMode]="showFirstSubmenuModes"
        orientation="vertical"
        submenuDirection="auto"
        [hideSubmenuOnMouseLeave]="false"
        cssClass="menu-panel-user"
        style="position: relative; margin-left: 0px; margin-bottom: -5px"
        (onItemClick)="itemClick($event)"
        itemTemplate="menuItem"
      >
        <div
          *dxTemplate="let item of 'menuItem'"
          class="top-menu-item text-left"
          routerLinkActive="active"
        >
          <div class="dx-item-content dx-menu-item-content">
            <i
              class="dx-icon"
              [class]="item.icon"
              style="
                position: relative;
                top: -3px;
                width: 18px;
                height: 18px;
                margin-right: -15px;
              "
            ></i>
            <span class="dx-menu-item-text">{{ item.name | translate }}</span>
            <i
              class="icon absui-icon--arrow-drop-right-user-panel"
              *ngIf="item.id == 3"
              style="position: absolute; right: 20px"
            ></i>
            <ng-container *ngIf="item.id == 4">
              <span class="switch-desc" style="margin-right: 6px">Auto</span>
              <dx-switch
                [(value)]="isDefaultOnboarding"
                (onValueChanged)="onDefaultOnboardingChanged($event)"
                id="defaultOnBoarding"
              >
              </dx-switch>
              <dx-tooltip
                target="#defaultOnBoarding"
                position="bottom"
                [hideEvent]="{ name: 'mouseleave' }"
                [hideOnOutsideClick]="false"
                [showEvent]="event.tooltipShowEvent"
                cssClass="tooltip"
              >
                <div *dxTemplate="let data = data; of: 'content'">Auto</div>
              </dx-tooltip>
            </ng-container>
          </div>
        </div>
      </dx-menu>
    </div>
  </div>
</div>

<div
  class="apps-panel text-center"
  [ngClass]="{
    'apps-panel': !widthPanelApps,
    'apps-panel-open': widthPanelApps
  }"
  (mouseenter)="showPanelApp()"
  (mouseleave)="hidePanelApp()"
>
  @for (app of apps; track app.shortcut) {
  <div class="apps-box" (click)="goApp(app.app)">
    <div class="hexagon-red">
      <p>{{ app.shortcut }}</p>
    </div>
    <p class="name-apps">{{ app.master }}<br />{{ app.detail }}</p>
  </div>
  }
</div>

<dx-popup
  [width]="600"
  [height]="759"
  [showTitle]="true"
  maxWidth="100%"
  maxHeight="90%"
  title="{{ 'menu.navigationPanelUser.applicationInformation' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="isAboutVersion"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [wrapperAttr]="{ class: 'about-program' }"
>
  <div *dxTemplate="let data of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="normal"
        stylingMode="text"
        icon="icon absui-icon--close"
        [id]="'cancel' + unicalGuid"
        style="z-index: 1000"
        (onClick)="closeAppInfo()"
      ></dx-button>
    </div>
    <div class="content-about-program">
      <wapro-logo
        type="anywhere"
        class="wapro-anywhere-mobile"
        [width]="303"
        [height]="40"
        style="padding-top: 4px; padding-bottom: 21px; display: block"
      ></wapro-logo>
      <p class="p_copyright">Copyright © Asseco Business Solutions S.A.</p>
      <p class="allRightsReserved">{{ "allRightsReserved" | translate }}</p>
      <p class="p_copyright">{{ "nameAndLogo" | translate }}</p>

      <wapro-logo
        type="waproPl"
        [width]="58"
        [height]="14"
        style="padding-top: 20px; padding-bottom: 21px; display: block"
      ></wapro-logo>

      <div class="spacer"></div>

      <wapro-logo
        type="mg"
        [width]="193"
        [height]="32"
        style="padding-bottom: 10px; display: block"
      ></wapro-logo>

      <p class="section-header">
        {{ "menu.navigationPanelUser.applicationInformation" | translate }}
      </p>
      <div class="spacer"></div>
      <div class="app-details-section">
        <div class="app-details-row" *ngFor="let row of appDetails.ver">
          <div class="app-details-header">
            {{ row.header.content | translate }}
          </div>
          <div class="app-details-body">
            {{
              row.body.translate
                ? (row.body.content | translate)
                : row.body.content
            }}
          </div>
        </div>
      </div>

      <p class="section-header">{{ "workers.user" | translate }}</p>
      <div class="spacer"></div>
      <div class="app-details-section">
        <div class="app-details-row" *ngFor="let row of appDetails.user">
          <div class="app-details-header">
            {{ row.header.content | translate }}
          </div>
          <div class="app-details-body">
            {{
              row.body.translate
                ? (row.body.content | translate)
                : row.body.content
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<app-confirm-dialog
  kindDialog="information"
  btnConfig="close"
  confirmText="session.end"
  [paramsMsg]="paramsMsg"
  [isVisible]="isVisiblePopUpSessionEnd"
  (onClosing)="isVisiblePopUpSessionEnd = false; cd.detectChanges()"
>
</app-confirm-dialog>

<dx-popup
  [width]="350"
  [height]="500"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="shearchInMenu"
  (visibleChange)="shearchInMenu = $event"
>
  <div *dxTemplate="let data of 'content'">
    <dx-list
      [dataSource]="navigationSearch"
      [height]="400"
      [searchEnabled]="true"
      searchExpr="name"
      (onItemClick)="onItemClickMenuSearch($event)"
    >
      <div *dxTemplate="let item of 'item'">
        <div class="product">
          <div>{{ item.name | translate }}</div>
        </div>
      </div>
    </dx-list>
  </div>
</dx-popup>

<dx-popup
  width="100%"
  height="100%"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="isSearchResult"
  *ngIf="event.deviceType == 'mobile'; else searchResultPanelTemplate"
>
  <ng-container
    *dxTemplate="let data of 'content'"
    [ngTemplateOutlet]="searchResultPanelTemplate"
  >
  </ng-container>
</dx-popup>

<ng-template #searchResultPanelTemplate>
  <div class="search-result-panel" *ngIf="isSearchResult">
    <header
      *ngIf="event.deviceType == 'mobile'"
      style="margin-bottom: 16px"
      class="mobile-header"
    >
      <div class="header-search-mobile">
        <p class="p-title-search-mobile">{{ "systemSearch" | translate }}</p>
        <i
          class="icon absui-icon--close"
          style="position: absolute; top: 1px; right: 4px"
          (click)="isSearchResult = false"
        ></i>
      </div>

      <div class="mobile-search-textbox-wrapper">
        <dx-text-box
          class="search-mobile-tex-box"
          placeholder="{{ 'search' | translate }}..."
          (keyup)="keyUpSearch($event)"
          [(value)]="searchValue"
        >
        </dx-text-box>
        <dx-button
          class="mobile-search-textbox-button"
          (click)="isSearchResult = true; searchRes()"
          [tabIndex]="-1"
        >
          <i class="icon icon-search icon-search-mobile"></i>
        </dx-button>
      </div>
      <!-- <i class="icon icon-search icon-search-mobile" (click)="isSearchResult = true; searchRes()"
      tabIndex="-1"></i> -->
    </header>
    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      showScrollbar="onHover"
      style="max-height: calc(100% - 92px) !important"
      class="scroll-height"
    >
      <div [hidden]="!isSearchMenuResultVisible">
        <i class="icon absui-icon--list-view"></i>
        <p class="p-menu-header">Menu</p>
        <div class="line"></div>

        <dx-list
          [dataSource]="navigationSearchData"
          searchExpr="name"
          class="menu-search-list"
          (onItemClick)="onItemClickMenuSearch($event)"
          #listMenuSearch
          (onFocusIn)="onFocus = true"
          [height]="'auto'"
          [ngStyle]="{
            'margin-bottom': countData > 0 || isSearchLoading() ? '20px' : '0px'
          }"
          nextButtonText="{{ 'showMore' | translate }}"
          (onPageLoading)="onPageLoading()"
          [tabIndex]="-1"
        >
          <div *dxTemplate="let item of 'item'">
            <div class="product">
              <p class="header-menu-name">{{ item.name | translate }}</p>
              <p *ngIf="item.patch != ''" class="patch-menu">
                {{ item.patch | translate }}
              </p>
              <p
                *ngIf="item.patch2"
                class="patch-menu"
                style="margin-left: 3px"
              >
                \ {{ item.patch2 | translate }}
              </p>
              <p
                *ngIf="item.patch3"
                class="patch-menu"
                style="margin-left: 3px"
              >
                \ {{ item.patch3 | translate }}
              </p>
            </div>
          </div>
        </dx-list>
      </div>

      <div *ngIf="countData > 0 && !isSearchLoading()">
        <i class="icon absui-icon--enterprise"></i>
        <p class="p-menu-header">{{ "data" | translate }}</p>
        <div class="line"></div>

        <dx-list
          [dataSource]="resultSearchData"
          [height]="'auto'"
          class="menu-search-list"
          nextButtonText="{{ 'showMore' | translate }}"
          (onPageLoading)="onPageLoadingData()"
          (onItemClick)="onItemClickDateSearch($event)"
        >
          <div *dxTemplate="let item of 'item'" tabIndex="-1">
            <div class="product">
              <p class="header-menu-name">{{ item.Description }}</p>
              <p class="patch-menu">{{ item.ContextDescription }}</p>
            </div>
          </div>
        </dx-list>
      </div>

      <div *ngIf="isSearchLoading() && searchValue.length >= 3">
        <div class="search-result-panel-skeleton">
          <div class="search-result-panel-skeleton-header">
            <i class="icon absui-icon--enterprise"></i>
            <p class="p-menu-header">{{ "data" | translate }}</p>
            <div class="line"></div>
          </div>
          <div
            class="search-result-panel-skeleton-item"
            style="width: 60%"
          ></div>
          <div
            class="search-result-panel-skeleton-item"
            style="width: 75%"
          ></div>
          <div
            class="search-result-panel-skeleton-item"
            style="width: 40%"
          ></div>
          <div
            class="search-result-panel-skeleton-item"
            style="width: 80%"
          ></div>
          <div
            class="search-result-panel-skeleton-item"
            style="width: 50%"
          ></div>
        </div>
      </div>

      <div
        *ngIf="
          countData == 0 && !isSearchMenuResultVisible && !isSearchLoading()
        "
        class="text-center"
      >
        <img src="../../../assets/img/ico_no_data.svg" loading="lazy" />
        <p class="p-no-found">Niczego nie znaleziono</p>
      </div>
    </dx-scroll-view>
  </div>
</ng-template>

<!-- panel with available warehouses list -->
<div
  class="warehouse-choice"
  *ngIf="chooseWarehouse && event.deviceType == 'desktop'"
  [ngStyle]="{ 'right.px': dropdownMarginsR.warehouse }"
  [ngClass]="{ positinTop: licenseBox.isHideLicenseInfo() }"
>
  <dx-data-grid
    [dataSource]="warehouses"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="'auto'"
    style="margin-left: -5px"
    [ngClass]="{ dropDownBoxClassGrid: chooseWarehouse }"
    [columnResizingMode]="'widget'"
    [remoteOperations]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [id]="'grid' + unicalGuid"
    [focusedRowEnabled]="true"
    (onRowClick)="onRowClickWarehouse($event)"
    [hoverStateEnabled]="true"
    [(focusedRowIndex)]="focusedWarehouseIndex"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxi-column
      [width]="45"
      cellTemplate="editTemplate"
      [allowSorting]="false"
      alignment="center"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
      <dx-button
        class="btn-dx-top-menu-folder-grid"
        icon="icon absui-icon--mode-edit hover-edit-icon"
        [id]="'btn-drop-edit' + unicalGuid"
        [disabled]="event.readOnly || !perABD.editBtn"
        [routerLink]="[
          '/warehouse/warehouse-select/',
          data.data.WarehouseId,
          'edit'
        ]"
        (click)="chooseWarehouse = false"
      >
      </dx-button>
    </div>

    <dxi-column
      caption="K"
      [allowSorting]="false"
      width="35"
      dataField="Color"
      cellTemplate="colorTemplate"
      alignment="center"
    >
    </dxi-column>
    <div *dxTemplate="let data of 'colorTemplate'" tabindex="-1">
      <div
        class="box-color-warehouse"
        [ngStyle]="{ 'background-color': data.data.Color }"
      ></div>
    </div>

    <dxi-column
      caption="{{ 'warehouse.symbol' | translate }}"
      [allowSorting]="false"
      width="70"
      dataField="WarehouseSymbol"
    >
    </dxi-column>

    <dxi-column
      caption="{{ 'warehouse.grid.nameMobile' | translate }}"
      [allowSorting]="false"
      dataField="WarehouseName"
    >
    </dxi-column>
  </dx-data-grid>

  <!-- <div
    class="bottom-drop-box d-flex align-items-center"
    style="padding-left: 14px; width: calc(100% + 16px)"
  >
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      [disabled]="event.readOnly || !perABD.addBtn"
      [routerLink]="['/warehouse/warehouse-select/add']"
      [id]="'btn-drop-add' + unicalGuid"
      (click)="chooseWarehouse = false"
    >
    </dx-button>
  </div> -->
</div>

<!-- panel with available cash -->
<div
  class="company-chose d-flex flex-column"
  style="justify-content: space-between"
  *ngIf="chooseCash && event.deviceType == 'desktop'"
  [ngStyle]="{ 'right.px': dropdownMarginsR.cash }"
  [ngClass]="{ positinTop: licenseBox.isHideLicenseInfo() }"
>
  <dx-data-grid
    [dataSource]="cashList"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="'auto'"
    style="margin-left: -5px"
    [ngClass]="{ dropDownBoxClassGrid: chooseCash }"
    [columnResizingMode]="'widget'"
    [remoteOperations]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [id]="'grid' + unicalGuid"
    [focusedRowEnabled]="true"
    (onRowClick)="onChoosedCash($event)"
    [hoverStateEnabled]="true"
    [(focusedRowKey)]="focusedCashId"
    keyExpr="CashId"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxi-column
      [width]="45"
      cellTemplate="editTemplate"
      [allowSorting]="false"
      alignment="center"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
      <dx-button
        class="btn-dx-top-menu-folder-grid"
        icon="icon absui-icon--mode-edit hover-edit-icon"
        [id]="'btn-drop-edit' + unicalGuid"
        [disabled]="event.readOnly || !cashPerABD.editBtn"
        [routerLink]="[
          '/trade-operations/change-cash-box/',
          data.data.CashId,
          'edit'
        ]"
        (click)="chooseCash = false"
      >
      </dx-button>
    </div>

    <dxi-column
      caption="D"
      [allowSorting]="false"
      width="35"
      dataField="Symbol"
      cellTemplate="colorTemplate"
      alignment="center"
    >
    </dxi-column>
    <div *dxTemplate="let data of 'colorTemplate'" tabindex="-1">
      <i
        *ngIf="event.footerInfo.AppCashId === data.data.CashId"
        class="icon absui-icon--status-ok-green"
      ></i>
    </div>

    <dxi-column
      caption="{{ 'companies.grid.columns.name' | translate }}"
      [allowSorting]="false"
      dataField="Name"
    >
    </dxi-column>
  </dx-data-grid>

  <!-- <div class="company-chose-footer d-flex align-items-center">
    <dx-button
      class="btn-dx-top-menu-folder"
      stylingMode="text"
      type="default"
      icon="icon absui-icon--add-circle"
      [routerLink]="['/trade-operations/change-cash-box/add']"
      (click)="chooseCash = false"
      [disabled]="!cashPerABD.addBtn"
    >
    </dx-button>
  </div> -->
</div>

<!-- panel with available companies -->
<div
  class="company-chose d-flex flex-column"
  style="justify-content: space-between"
  *ngIf="chooseCompany && event.deviceType == 'desktop'"
  [ngStyle]="{ 'right.px': dropdownMarginsR.company }"
  [ngClass]="{ positinTop: licenseBox.isHideLicenseInfo() }"
>
  <dx-data-grid
    [dataSource]="companies"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="'auto'"
    style="margin-left: -5px"
    [ngClass]="{ dropDownBoxClassGrid: chooseCompany }"
    [columnResizingMode]="'widget'"
    [remoteOperations]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [id]="'grid' + unicalGuid"
    [focusedRowEnabled]="true"
    (onRowClick)="onChoosedCompany($event)"
    [hoverStateEnabled]="true"
    [(focusedRowKey)]="choosedCompanyId"
    keyExpr="CompanyId"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxi-column
      [width]="45"
      cellTemplate="editTemplate"
      [allowSorting]="false"
      alignment="center"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
      <dx-button
        class="btn-dx-top-menu-folder-grid"
        icon="icon absui-icon--mode-edit hover-edit-icon"
        [id]="'btn-drop-edit' + unicalGuid"
        [disabled]="event.readOnly || !companyPerABD.editBtn"
        [routerLink]="['/administrator/companies', data.data.CompanyId, 'edit']"
        (click)="chooseCompany = false"
      >
      </dx-button>
    </div>

    <dxi-column
      caption="{{ 'companies.grid.columns.name' | translate }}"
      [allowSorting]="false"
      dataField="CompanyName"
    >
    </dxi-column>
  </dx-data-grid>
  <div
    class="company-chose-footer d-flex align-items-center"
    *ngIf="isShowChangeDBConpanies"
  >
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--big-data-compute"
      [id]="'btn-drop-database' + unicalGuid"
      [disabled]="event.readOnly"
      (onClick)="selectDatabase()"
    >
    </dx-button>

    <dx-tooltip
      [target]="'#btn-drop-database' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "changeCompanyAndDatabase" | translate }}
      </div>
    </dx-tooltip>
  </div>
</div>

<ng-container *ngIf="event.deviceType !== 'desktop'">
  <dx-popup
    [(visible)]="chooseWarehouse"
    [showTitle]="true"
    [dragEnabled]="false"
    [showCloseButton]="false"
    (onShown)="warehousePopupShown(warehouseListMobile)"
    [classList]="'popup-full'"
    titleTemplate="title"
    width="100vw"
    fullscreen="true"
    height="calc(100% - 40px)"
    [hideOnOutsideClick]="true"
    position="bottom"
    [animation]="null"
  >
    <div *dxTemplate="let data of 'title'">
      <div class="popup-header">
        <p class="popup-title">
          {{ "production-documents.warehouse" | translate }}
        </p>
        <dx-button
          class="close"
          (onClick)="chooseWarehouse = false"
          icon="icon absui-icon--close"
          type="default"
          stylingMode="text"
        >
        </dx-button>
      </div>
    </div>
    <dx-list
      [dataSource]="warehouses"
      selectionMode="single"
      itemTemplate="listItem"
      (onItemClick)="onWarehouseChange($event); chooseWarehouse = false"
      #warehouseListMobile
    >
      <div
        *dxTemplate="let item of 'listItem'"
        class="list-item warehouse-item"
      >
        <div class="item-content">
          <b>{{ item.WarehouseName }}</b
          ><br />
          Symbol: <b>{{ item.WarehouseSymbol }}</b>
        </div>
        <!-- <dx-button
        icon="icon dx-icon-more-horizontal" type="default" stylingMode="text"></dx-button> -->
      </div>
    </dx-list>
  </dx-popup>

  <dx-popup
    [(visible)]="chooseCompany"
    [showTitle]="true"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [classList]="'popup-full'"
    titleTemplate="title"
    width="100vw"
    fullscreen="true"
    height="calc(100% - 40px)"
    [hideOnOutsideClick]="true"
    position="bottom"
    (onShown)="companyPopupShown(companyListMobile)"
    [animation]="null"
  >
    <div *dxTemplate="let data of 'title'">
      <div class="popup-header">
        <p class="popup-title">{{ "constractors.company" | translate }}</p>
        <dx-button
          (onClick)="chooseCompany = false"
          icon="icon absui-icon--close"
          class="close"
          type="default"
          stylingMode="text"
        >
        </dx-button>
      </div>
    </div>
    <dx-list
      [dataSource]="companies"
      selectionMode="single"
      itemTemplate="listItem"
      (onItemClick)="onChoosedCompany($event)"
      #companyListMobile
    >
      <div *dxTemplate="let item of 'listItem'" class="list-item company-item">
        <div class="item-content">
          <b>{{ item.CompanyName }}</b
          ><br />
          {{ "workers.attachment" | translate }}: <b>{{ companyAttachment }}</b>
        </div>
        <!-- <dx-button
          icon="icon dx-icon-more-horizontal"
          type="default"
          stylingMode="text"
        ></dx-button> -->
      </div>
    </dx-list>
  </dx-popup>
</ng-container>

<!-- @defer when(isNestRunning){ -->
<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  (nestFinished)="onNestFinished()"
>
</app-nest>
<!-- } -->

<app-table-user-extension
  tableGroupCode="Firma"
  (onClosing)="isExternalTableVisible = false"
  [isVisible]="isExternalTableVisible"
  [isPopupMode]="true"
  [isMenu]="true"
></app-table-user-extension>

<app-queue-tasks
  [isShowQueue]="isShowQueue"
  (onClosed)="hideSidePanel()"
></app-queue-tasks>

<ng-template #mobileView>
  <header
    class="top-menu-header-mobile"
    #header
    style="margin-bottom: 45px; position: sticky !important; z-index: 12"
  >
    <div
      class="top-header-mobile"
      style="
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 400;
      "
    >
      <dx-button
        id="company-change-mobile"
        (click)="
          chooseCompany = !chooseCompany;
          isMenuMobile = false;
          chooseWarehouse = false;
          getCompanies()
        "
        type="default"
        stylingMode="text"
        template="companyTemplate"
      >
        <div *dxTemplate="let data of 'companyTemplate'">
          <div class="d-flex">
            <span class="company-change-button-mobile">
              {{
                event.footerInfo?.CompanyName?.length > 20
                  ? (event.footerInfo?.CompanyName | slice : 0 : 20) + " ..."
                  : event.footerInfo?.CompanyName
              }}
            </span>
            <i
              class="icon"
              [ngClass]="{
                'absui-icon--arrow-drop-down-box': !chooseCompany,
                'dx-icon-spinup': chooseCompany
              }"
              tabIndex="-1"
            ></i>
          </div>
        </div>
      </dx-button>
      <dx-button
        id="warehouse-change-button-mobile"
        class="d-flex text-light"
        type="default"
        stylingMode="text"
        (click)="
          chooseWarehouse = !chooseWarehouse;
          isMenuMobile = false;
          chooseCompany = false
        "
        *ngIf="
          event?.footerInfo?.VariantName !== 'ANY FAKTUROWANIE' &&
          event?.footerInfo?.VariantName !== 'FAKTUROWANIE' &&
          event?.footerInfo?.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          event?.footerInfo?.VariantName !== 'FAKTUROWANIE PRO'
        "
        [disabled]="event?.footerInfo?.VariantName === 'START'"
        template="warehouseTemplate"
      >
        <div *dxTemplate="let data of 'warehouseTemplate'">
          <div class="d-flex">
            <div
              [ngStyle]="{ 'background-color': currentColorWarehouse }"
              class="box-color-warehouse"
              style="margin-left: 7px; margin-right: 7px"
            ></div>
            <span class="company-change-button-mobile">
              {{ event.footerInfo?.AppWarehouseSymbol }}
            </span>
            <i
              class="icon"
              [ngClass]="{
                'absui-icon--arrow-drop-down-box': !chooseWarehouse,
                'dx-icon-spinup': chooseWarehouse
              }"
              tabIndex="-1"
            ></i>
          </div>
        </div>
      </dx-button>
    </div>

    <nav class="nav-mobile">
      <div
        class="navbar-toggler-mobile"
        (click)="isMenuMobile = !isMenuMobile; isSubmenuItemClick = false"
        [ngClass]="{
          'navbar-toggler-mobile': !isMenuMobile,
          'navbar-toggler-mobile-active': isMenuMobile
        }"
      >
        <i class="dx-icon dx-icon-menu"></i>
      </div>

      <img
        src="../../../assets/img/logo_anywhere.svg"
        style="width: 130px; margin-left: 10px"
      />

      <!-- <wapro-logo
        fillColorPrimary="neutral-100"
        fillColorSecondary="neutral-100"
        type="anywhere"
        [width]="120"
        [height]="16"
        style="padding-left: 10px"
      ></wapro-logo> -->

      <div
        class="mobile-user-icon"
        (click)="
          isMenuMobile = false; isMobileSettings = false; isSearchResult = true
        "
        style="right: 40px"
      >
        <i
          class="icon icon-search"
          style="padding-top: 8px; position: relative"
          tabIndex="-1"
        ></i>
      </div>

      <div
        class="mobile-user-icon"
        (click)="
          isMenuMobile = false; isMobileSettings = true; isSearchResult = false
        "
      >
        <i
          class="icon absui-icon--account-circle"
          style="padding-top: 8px; position: relative"
          tabIndex="-1"
        ></i>
      </div>

      <dx-popup
        width="100%"
        height="calc(100% - 75px)"
        position="bottom"
        [showCloseButton]="false"
        [animation]="{
          show: { type: 'slide', direction: 'left' },
          hide: { type: 'slide', direction: 'left' }
        }"
        [showTitle]="false"
        [visible]="isMenuMobile"
        [shading]="false"
        [wrapperAttr]="{ class: 'menu-mobile' }"
      >
        <div *dxTemplate="let content of 'content'">
          <dx-menu
            [dataSource]="navigation"
            displayExpr="name"
            [focusStateEnabled]="true"
            *ngIf="!isSubmenuItemClick"
            [showFirstSubmenuMode]="showFirstSubmenuModes"
            orientation="vertical"
            submenuDirection="auto"
            [hideSubmenuOnMouseLeave]="true"
            itemTemplate="items"
            (onItemClick)="onItemClickMobile($event)"
            tabindex="1"
            cssClass="mobile-main-menu"
          >
            <div *dxTemplate="let item of 'items'; let i = index">
              <div>
                <div
                  class="item-menu-mobile"
                  routerLinkActive="active"
                  [class.active]="
                    route.isActive(item.url, false) && item.id != 1
                  "
                  [routerLinkActiveOptions]="{ exact: true }"
                  (touchstart)="touchStart(item)"
                  (touchend)="touchEnd()"
                  [ngClass]="{ 'tap-menu-mobile': isTap == item.id }"
                >
                  <i class="dx-icon icon {{ item.icon }}"></i>
                  <span class="dx-menu-item-text-span-mobile">{{
                    item.name | translate
                  }}</span>
                  <i
                    class="icon absui-icon--arrow-drop-right-user-panel"
                    *ngIf="item.items && item.items.length >= 1"
                    style="
                      position: relative;
                      left: 0px;
                      top: -2px;
                      margin-right: 10px;
                      float: right;
                      font-size: 20px !important;
                    "
                  ></i>
                </div>
              </div>
            </div>
          </dx-menu>
          <app-version-selected
            *ngIf="!isSubmenuItemClick && isDemo"
          ></app-version-selected>

          <app-wapro-app-select
            *ngIf="
              !isSubmenuItemClick &&
              !isDemo &&
              event.returnEnvironmentInfo() !== 'prod'
            "
            (onChoosed)="
              goApp($event); isMenuMobile = false; cd.detectChanges()
            "
          ></app-wapro-app-select>

          <div *ngIf="isSubmenuItemClick">
            <app-mobile-main-submenu
              [items]="itemChosed"
              (onClosingSubmenu)="
                isSubmenuItemClick = false; cd.detectChanges()
              "
              (onCloseMenu)="isMenuMobile = false; cd.detectChanges()"
              [parentLevel]="itemChosed"
            >
            </app-mobile-main-submenu>
          </div>
        </div>
      </dx-popup>
    </nav>
  </header>

  <div id="content">
    <div>
      <app-top-menu
        *ngIf="isTopMenuVisible()"
        [items]="submenu"
        [code]="code"
      ></app-top-menu>
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-mobile-list
    [dataSource]="navigationPanelUserMobile()"
    [visible]="isMobileSettings"
    (onClosed)="isMobileSettings = false"
    (onItemClick)="itemClick($event)"
    [title]="currentUserName"
    [titleIcon]="'absui-icon--account-circle'"
  ></app-mobile-list>
  <div
    *ngIf="event.deviceType === 'mobile'"
    class="bottomNavigationBar"
    #bottomNavigationBar
  >
    <dx-button
      class="btn-dx-top-menu-folder btn-dynamic-state"
      icon="icon absui-icon--arrow-downward"
      (onClick)="scroll('down')"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu-folder btn-dynamic-state"
      icon="icon absui-icon--arrow-upward"
      (onClick)="scroll('up')"
    >
    </dx-button>
  </div>
</ng-template>

<app-notification
  type="info"
  [visible]="isShowNotification"
  [message]="notificationText"
  (onClose)="noCloseNotification()"
>
</app-notification>

<app-confirm-dialog
  *ngIf="isChangeVariantVisible()"
  [isVisible]="true"
  kindDialog="information"
  btnConfig="dynamicContent"
  [dynamicBtns]="dynamicBtnConfig"
  [confirmText]="confirmText"
  [confirmHeader]="confirmHeader"
  (onDynamicBtnClicked)="onDynamicBtnClicked($event)"
  width="546"
>
</app-confirm-dialog>
