import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  KeyboardShortcutsModule,
  ShortcutEventOutput,
  ShortcutInput,
} from 'ng-keyboard-shortcuts';
import { EventService } from '../../event.service';
import {
  DxButtonModule,
  DxDropDownButtonModule,
  DxMenuModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { SubmenuShowMode } from 'devextreme/common';
import { ChatEventService } from 'src/app/wapro-assistant/services/chat-event.service';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss'],
    inputs: ['items', 'code'],
    imports: [
        DxScrollViewModule,
        DxMenuModule,
        DxTooltipModule,
        DxDropDownButtonModule,
        DxButtonModule,
        DxSelectBoxModule,
        KeyboardShortcutsModule,
        TranslateModule,
        CommonModule,
        RouterModule,
        DxPopupModule,
    ]
})
export class TopMenuComponent implements OnInit, OnChanges {
  @ViewChild('scrollMobile') scrollMobile;
  code;
  submenu: any[] = [
    {
      id: '21',
      idPatch: '0',
      name: 'Pulpit',
      url: '/start/dashboard',
      items: [],
      code: null,
      visible: true,
    },
    {
      id: '22',
      idPatch: '1',
      name: 'AVP',
      url: '/start/avp',
      items: [],
      code: null,
      visible: true,
    },
  ];

  isShowTabs: boolean = false;
  items;
  permissions;
  defaultConfiguration;
  selectedRevealMode: string = 'slide';
  showFirstSubmenuModes = {
    name: 'onHover' as SubmenuShowMode,
    delay: { show: 200, hide: 200 },
  };

  contextMenu;
  unicalGuid;
  isActive: boolean = false;
  pathUrl = '';
  shortcuts: ShortcutInput[] = [];
  widthScrollWiew = 'calc(100% - 80px)';
  showScrollBar = 'onHover';

  perSale: boolean = false;
  perPurchase: boolean = false;
  perBil: boolean = false;
  perRem: boolean = false;
  application: string = '';
  isTopMenu: boolean = true;

  constructor(
    public cd: ChangeDetectorRef,
    public event: EventService,
    public chatEvent: ChatEventService,
    private translate: TranslateService,
    public route: Router
  ) {
    this.perSale = this.event.checkPermissions('Sprzedaz');
    this.perPurchase = this.event.checkPermissions('Zakup');

    this.perBil = this.event.checkPermissions('Bil');
    this.perRem = this.event.checkPermissions('Rem');
    const strConf = localStorage.getItem('defaultMenuConfiguration');
    this.defaultConfiguration = strConf ? JSON.parse(strConf) : {};
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('menu.renament')
      .subscribe((text) => (this.exportListWarehouse[0].text = text));
    this.translate
      .get('menu.openingBalance')
      .subscribe((text) => (this.exportListWarehouse[1].text = text));

    this.translate.get('menu.sale').subscribe((text) => {
      this.exportList[0].text = text;
    });

    this.translate.get('menu.purchase').subscribe((text) => {
      this.exportList[1].text = text;
    });

    this.translate.get('menu.exportFakir').subscribe((text) => {
      this.exportListPopup[0].text = text;
    });

    route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        setTimeout(() => {
          this.submenu.forEach((field) => {
            if (
              (field.code == 'Detal' ||
                field.url == 'crm/crm-kanban' ||
                field.code == 'Intrastat' ||
                field.code == 'Sad' ||
                field.code == 'Bil' ||
                field.code == 'Przeceny' ||
                field?.type == 'popup' ||
                field?.type == 'listHeader' ||
                field.url == 'crm/service-orders-kanban' ||
                field.url == 'other/bussines-link') &&
              this.event.deviceType == 'mobile'
            ) {
              field.visible = false;
            }
          });
        }, 0);
      }
    });
  }

  signatureUrl: string = '';

  setDefaultTimeout = false;

  setDefault(item) {
    this.setDefaultTimeout = true;
    this.defaultConfiguration[this.code] = item.url;
    this.submenuSelect.forEach((el) => (el.active = false));
    item.active = true;
    localStorage.setItem(
      'defaultMenuConfiguration',
      JSON.stringify(this.defaultConfiguration)
    );
    setTimeout(() => {
      this.setDefaultTimeout = false;
    }, 100);
  }

  ngOnInit(): void {
    if (this.event.deviceType != 'desktop') {
      this.widthScrollWiew = 'calc(100% - 20px)';
      this.showScrollBar = 'never';
    }

    this.getPermissions();
    document.addEventListener('click', (e) => {
      if ((e.target as any)?.className !== 'dx-icon dx-icon-more-horizontal') {
        this.isOpened = false;
      }
    });

    this.submenu.forEach((field) => (field.patchMain = false));
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        /* metoda .substr() jest przestarzała */
        // this.pathUrl = this.route.url.substr(1, this.route.url.length);
        this.pathUrl = this.route.url.substring(1, this.route.url.length);

        setTimeout(() => {
          this.submenu.forEach((field) => (field.patchMain = false));
          this.checkPath(this.submenu);
          try {
            this.submenu[this.indexPath].patchMain = true;
          } catch {}
        }, 1000);
      }
    });
  }

  indexPath;
  checkPath = (item) => {
    item.forEach((field) => {
      if (this.pathUrl.indexOf('?')) {
        this.pathUrl = this.pathUrl.split('?')[0];
      }
      /* dashboard ma path z '/' na początku i ten warunek powoduje błąd w konsoli */
      // if (this.pathUrl == 'start/dashboard') this.pathUrl = '/' + this.pathUrl;

      if (this.pathUrl.indexOf('trade-operations/trader-documents/') !== -1) {
        this.pathUrl = 'trade-operations/trader-documents';
      }

      if (field.url == this.pathUrl) {
        this.indexPath = field.idPatch;
      }
      if (field.items) {
        this.checkPath(field.items);
      }
    });

    if (this.event.deviceType == 'mobile') {
      let tabListMenu = document.getElementsByClassName(
        'dx-menu-item-wrapper'
      ) as HTMLCollectionOf<HTMLElement>;
      setTimeout(() => {
        this.scrollMobile?.instance.scrollTo({
          left: tabListMenu[this.indexPath].offsetLeft,
          top: 0,
        });
      }, 0);
    }
  };

  getPermissionsToMenu = () => {
    let permissions = this.event.decryptString(
      localStorage.getItem('appPermissions')
    );
    try {
      this.submenu.forEach((menu) => {
        let index = permissions.find((field) => field.Code == menu.code);
        if (index) {
          menu.visible = true;
        }
      });
    } catch {}
    // permissions.forEach(menu=>{

    //   // let men = this.navigation.find(field=>field.code == menu.Code)
    //   // if(men) men.visible = true;
    // })
  };

  submenuSelect = [];

  ngOnChanges() {
    this.application = localStorage.getItem('activeApplicationWapro');
    this.isTopMenu = this.application === 'jpk' ? false : true;

    if (this.items && this.items.submenu) {
      let index = this.items.submenu.findIndex(
        (field) => field.name === 'menu.sale'
      );

      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }

      index = this.items.submenu.findIndex(
        (field) => field.name === 'form-financial-document.operations'
      );
      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }

      index = this.items.submenu.findIndex(
        (field) => field.name === 'constractors.fastSteps'
      );
      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }

      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }
      index = this.items.submenu.findIndex(
        (field) => field.name === 'menu.purchase'
      );
      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }

      index = this.items.submenu.findIndex(
        (field) => field.name === 'menu.openingBalance'
      );
      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }

      index = this.items.submenu.findIndex(
        (field) => field.name === 'menu.renament'
      );
      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }

      index = this.items.submenu.findIndex(
        (field) => field.name === 'menu.exportFakir'
      );
      if (index != -1) {
        this.items.submenu.splice(index, 1);
      }

      if (this.items.submenu.length > 0) {
        this.submenu = this.items.submenu;
        //this.getPermissionsToMenu()
      } else {
        this.submenu = [
          {
            id: '1',
            name: 'Start',
            url: '/start/dashboard',
            items: [],
          },
          {
            id: '2',
            name: 'Firmy',
            url: '/administrator/companies',
            items: [],
          },
        ];
      }
      if (this.defaultConfiguration && this.defaultConfiguration[this.code]) {
        const item = this.submenu.find(
          (el) => el.url === this.defaultConfiguration[this.code]
        );
        if (item) {
          item.active = true;
        }
      }
      this.submenuSelect = this.submenu.filter(
        (el) => !el.items || el.items.length == 0
      );
      this.submenuSelect.forEach((el) => {
        el.tooltip = el.url ? el.url.replace('/', '-') : '';
        while (el.tooltip.indexOf('/') > -1) {
          el.tooltip = el.tooltip.replace('/', '-');
        }
      });
    }

    this.cd.detectChanges();
  }

  isOpened: boolean = false;

  clck(item) {
    if (this.setDefaultTimeout) {
      return;
    }

    if (this.route.url === '/start') {
      const strConf = localStorage.getItem('defaultMenuConfiguration');
      const defaultConfiguration = strConf ? JSON.parse(strConf) : {};
      if (defaultConfiguration[item.itemData.code]) {
        localStorage.setItem(
          'lastNavigateWapro_' + this.application,
          defaultConfiguration[item.itemData.code]
        );
        this.route.navigateByUrl(defaultConfiguration[item.itemData.code]);
      } else {
        let index = 0,
          found = false;
        if (item.itemData.submenu) {
          while (!found || index > item.itemData.submenu.length) {
            const foundItem = this.permissions.find(
              (field) => field.Code == item.itemData.submenu[index].code
            );
            if (foundItem) {
              found = true;
            } else {
              index++;
            }
          }
        }

        if (found) {
          localStorage.setItem(
            'lastNavigateWapro_' + this.application,
            item.itemData.submenu[index].url
          );
          this.route.navigateByUrl(item.itemData.submenu[index].url);
        } else {
          localStorage.setItem(
            'lastNavigateWapro_' + this.application,
            item.itemData.url
          );
          this.route.navigateByUrl(item.itemData.url);
        }
      }
    } else if (item.itemData.url) {
      localStorage.setItem(
        'lastNavigateWapro_' + this.application,
        item.itemData.url
      );
      this.route.navigateByUrl(item.itemData.url);
    }
  }

  getPermissions = () => {
    setTimeout(() => {
      this.permissions = this.event.decryptString(
        localStorage.getItem('appPermissions')
      );
    }, 800);
  };

  openWindow(link) {
    if (this.event.openArrayDocument.length > 0) {
      window.open(link, '_blank');
      return;
    }

    this.route.navigateByUrl(link);
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'shift + m',
        label: 'menu.warehouse',
        description: 'menu.warehouseDocuments',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/warehouse/warehouse-documents');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + w',
        label: 'menu.warehouse',
        description: 'menu.orders',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/warehouse/orders');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + p',
        label: 'menu.warehouse',
        description: 'menu.productionDocuments',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/warehouse/production-documents');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + g',
        label: 'menu.warehouse',
        description: 'menu.warehouseSelection',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/warehouse/warehouse-select');
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + alt + r',
        label: 'menu.warehouse',
        description: 'menu.renament',
        command: (e: ShortcutEventOutput) => {
          if (e.event.ctrlKey && e.event.altKey) {
            this.route.navigateByUrl('warehouse/inventory');
          }
        },
        preventDefault: true,
      },

      {
        key: 'ctrl + alt + o',
        label: 'menu.warehouse',
        description: 'menu.openingBalance',
        command: (e: ShortcutEventOutput) => {
          if (e.event.ctrlKey && e.event.altKey) {
            this.route.navigateByUrl('warehouse/opening-balance');
          }
        },
        preventDefault: true,
      },
      // mag end
      {
        key: 'ctrl + alt + s',
        label: 'menu.comercial-operation',
        description: 'dashboard.sell',
        command: (e: ShortcutEventOutput) => {
          if (e.event.ctrlKey && e.event.altKey) {
            this.route.navigateByUrl('/trade-operations/sales');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + d',
        label: 'menu.comercial-operation',
        description: 'menu.retailSales',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/trade-operations/retail-sales');
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + alt + z',
        label: 'menu.comercial-operation',
        description: 'menu.purchase',
        command: (e: ShortcutEventOutput) => {
          if (e.event.ctrlKey && e.event.altKey) {
            this.route.navigateByUrl('/trade-operations/buy');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + h',
        label: 'menu.comercial-operation',
        description: 'menu.commercialDocuments',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/trade-operations/trader-documents');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + r',
        label: 'menu.comercial-operation',
        description: 'menu.settlements',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/trade-operations/settlements');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f',
        label: 'menu.comercial-operation',
        description: 'menu.financiaDocuments',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/trade-operations/finances-documents');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + i',
        label: 'CRM',
        description: 'menu.serviceOrders',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/crm/service-orders');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + o',
        label: 'CRM',
        description: 'menu.offers',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/crm/offers');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + a',
        label: 'menu.library',
        description: 'menu.articles',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/library/articles');
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + k',
        label: 'menu.library',
        description: 'menu.contractors',
        command: (e: ShortcutEventOutput) => {
          if (this.isOnlyShift(e.event)) {
            this.openWindow('/library/customers');
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        label: '',
        description: '',
        command: (e) => {
          if (!e.event.shiftKey) {
            //blokada otwarcia okienka z wyborem pliku
          }
        },
        preventDefault: true,
      },
      {
        key: 'F11',
        label: '',
        description: '',
        command: () => {
          /* blokada domyślnego skrótu do trybu pełnoekranowego */
        },
        preventDefault: true,
      },
      {
        key: 'alt + F11',
        label: '',
        description: '',
        command: () => {
          this.event.toggleFullscreen();
        },
        preventDefault: true,
      }
    );

    this.shortcuts.forEach((el) => {
      if (el?.label) {
        this.translate.get(el.label).subscribe((text) => (el.label = text));
      }
      if (el?.description) {
        this.translate
          .get(el.description)
          .subscribe((text) => (el.description = text));
      }
    });
  }

  ngOnDestroy() {}

  isOnlyShift(e: KeyboardEvent): boolean {
    return !e.ctrlKey && !e.altKey;
  }

  exportList = [
    { id: 0, text: 'Sprzedaż', disabled: false },
    { id: 1, text: 'Zakup', disabled: false },
  ];

  exportListWarehouse = [
    { id: 2, text: 'Remanent', disabled: false },
    { id: 3, text: 'Bilans otwarcia', disabled: false },
  ];

  exportListPopup = [{ id: 4, text: 'Eksport do systemu FK', disabled: false }];

  onItemClickExport(e) {
    if (e.itemData.id == 0) {
      this.route.navigateByUrl('/trade-operations/sales');
      return;
    }
    if (e.itemData.id == 1) {
      this.route.navigateByUrl('/trade-operations/buy');
      return;
    }

    if (e.itemData.id == 2 && this.perRem) {
      this.route.navigateByUrl('/warehouse/inventory');
      return;
    }

    if (e.itemData.id == 3 && this.perBil) {
      this.route.navigateByUrl('/warehouse/opening-balance');
      return;
    }

    if (e.itemData.id == 4) {
      this.event.isExportFKVisible.set(true);
      return;
    }
  }

  isTouch: any = null;
  toggleAssistant() {
    const isOpen = this.chatEvent.isAssistantOpen();
    this.chatEvent.isAssistantOpen.set(!isOpen);
  }
}
