<div
  class="version-content"
  *ngIf="event.deviceType == 'desktop'; else mobileView"
>
  <div class="separator-box"></div>
  <div>
    <dx-menu
      [dataSource]="appList"
      displayExpr="name"
      [focusStateEnabled]="true"
      orientation="vertical"
      submenuDirection="rightOrBottom"
      [hideSubmenuOnMouseLeave]="true"
      itemTemplate="items"
      [showFirstSubmenuMode]="showSubmenuModes[0]"
      cssClass="main-menu version-menu"
      tabindex="1"
      (onItemClick)="onItemClick($event)"
    >
      <div
        *dxTemplate="let item of 'items'; let i = index"
        routerLinkActive="active"
        [class.active]="item.name === event?.footerInfo?.VariantName"
        [ngClass]="{ 'no-hover-class': item.header }"
      >
        <div
          *ngIf="item.node === 0"
          class="box-current-variant"
          style="height: 64px"
        >
          <div class="hexagon-red">
            <p>{{ item.shortcut }}</p>
          </div>
        </div>

        <div *ngIf="item.visible == true" class="box-current-variant">
          <p
            class="active-version"
            *ngIf="item.header"
            style="height: auto; cursor: default; margin-bottom: 6px"
          >
            {{ item.name | translate }}
          </p>

          <div class="d-flex flex-row" *ngIf="!item.header">
            <div style="width: 54px">
              <div class="hexagon-red" style="margin-top: 4px">
                <p>{{ item.shortcut }}</p>
              </div>
            </div>
            <div>
              <p
                [ngClass]="{
                  'app-name-active': item.active,
                  'app-name': !item.active,
                  'show-version': item.shortcut === 'mg'
                }"
              >
                {{ item.name }}
              </p>
              <p
                *ngIf="
                  (item.active && item.shortcut === 'mg') ||
                  item.shortcut === 'mg'
                "
                class="version-description"
              >
                v. {{ event?.footerInfo?.AppVersion }} |
                {{ "permissions.variant" | translate }}
                {{ event?.footerInfo?.VariantName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </dx-menu>
  </div>
</div>

<ng-template #mobileView>
  <div class="separator-box" style="width: 100%"></div>

  <dx-menu
    [dataSource]="appList"
    displayExpr="name"
    [focusStateEnabled]="true"
    *ngIf="!isSubmenuItemClick"
    orientation="vertical"
    submenuDirection="auto"
    [hideSubmenuOnMouseLeave]="true"
    itemTemplate="items"
    (onItemClick)="onItemClickMobile($event)"
    tabindex="1"
    cssClass="mobile-main-menu"
  >
    <div *dxTemplate="let item of 'items'; let i = index">
      <div>
        <div
          class="item-menu-mobile"
          (touchstart)="touchStart(item)"
          (touchend)="touchEnd()"
          [ngClass]="{ 'tap-menu-mobile': isTap == item.node }"
        >
          <div class="d-flex flex-row" *ngIf="!item.header">
            <div style="width: 33px; margin-left: -12px">
              <div
                class="hexagon-red"
                style="margin-top: 0px; width: 20px; height: 20px"
              >
                <p style="font-size: 8px; bottom: -13px">
                  {{ item.shortcut[0] }}
                </p>
              </div>
            </div>
            <div>
              <span class="dx-menu-item-text-span-mobile">
                {{ "menu.apps" | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dx-menu>

  <div *ngIf="isSubmenuItemClick">
    <ng-container *ngTemplateOutlet="mobileSubmenu"></ng-container>
  </div>
</ng-template>

<ng-template #mobileSubmenu>
  <div class="mobile-submenu-variant">
    <div class="header-submenu-main-menu-mobile" style="padding-top: 10px">
      <div class="d-flex flex-row">
        <div>
          <div class="btn-header-submenu">
            <i
              class="icon absui-icon--arrow-back-mobile-menu"
              (click)="backToParent()"
            ></i>
          </div>
        </div>
        <div style="padding-top: 7px; width: calc(100vw - 80px)">
          <p class="title-menu-parent">{{ "menu.apps" | translate }}</p>
        </div>
        <div>
          <div class="btn-header-submenu">
            <!-- <i class="icon absui-icon--close" (click)="onCloseSubmenu()"></i> -->
          </div>
        </div>
      </div>
    </div>

    <div class="content-main-submenu-mobile">
      <div *ngFor="let item of itemChosed?.items">
        <div
          routerLinkActive="active"
          (click)="onItemClickMobile(item)"
          class="mobile-main-submenu"
          (touchstart)="touchStart(item)"
          (touchend)="touchEnd()"
          [ngClass]="{
            'tap-submenu': isTap == item.node && !item.header
          }"
          *ngIf="item.visible == true"
        >
          <div class="text-center">
            <div
              class="d-flex flex-row"
              *ngIf="item.header"
              style="height: 36px; margin-bottom: -10px"
            >
              <p
                class="active-version-mobile"
                *ngIf="item.header"
                style="
                  height: auto;
                  cursor: default;
                  margin-bottom: 6px;
                  width: 100%;
                "
              >
                {{ item.name | translate }}
              </p>
            </div>
            <div class="d-flex flex-row" *ngIf="!item.header">
              <div
                style="
                  width: 45px;
                  margin-left: -12px;
                  position: relative;
                  top: 5px;
                "
              >
                <div
                  class="hexagon-red"
                  style="margin-top: 0px; width: 32px; height: 32px"
                >
                  <p style="font-size: 8px">
                    {{ item.shortcut }}
                  </p>
                </div>
              </div>
              <div style="padding-left: 12px">
                <p
                  class="dx-menu-item-text-span-mobile"
                  style="
                    margin-bottom: 0px;
                    margin-left: 0px;
                    margin-top: 10px;
                    display: block;
                  "
                  [ngClass]="{
                    'app-name-active-mobile': item.active,
                    'show-version-mobile': item.shortcut === 'mg'
                  }"
                >
                  {{ item.name }}
                </p>
                <p
                  *ngIf="
                    (item.active && item.shortcut === 'mg') ||
                    item.shortcut === 'mg'
                  "
                  class="version-description"
                  style="font-size: 11px"
                >
                  v. {{ event?.footerInfo?.AppVersion }} |
                  {{ "permissions.variant" | translate }}
                  {{ event?.footerInfo?.VariantName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
