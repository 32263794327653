<ng-container *ngIf="!event.isLog; else isLogTemplate">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #isLogTemplate>
  @defer(when event.isLog) {
  <app-menu></app-menu>

  <app-nest
    *ngIf="event.isVersionForNestAvailable"
    [globalNest]="true"
  ></app-nest>

  }
  <app-application-status-message-bar></app-application-status-message-bar>
</ng-template>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#body' }"
  [(visible)]="event.loadingVisible"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="event.onShown(event.loadingMessage, event.canAbortLoading)"
  (onHidden)="event.onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader
      mode="primary"
      [message]="
        event?.loadingMessage?.length
          ? event.loadingMessage
          : ('loaderPrimaryText' | translate)
      "
      [canAbort]="event.canAbortLoading"
      [abortBtnText]="event.abortLoadingMessage"
      (onAbort)="event.onAbortLoading()"
    ></wapro-loader>
  </div>
</dx-load-panel>

<app-toast-reminder></app-toast-reminder>

<app-confirm-dialog
  *ngIf="isInventory"
  [isVisible]="true"
  [showIcon]="false"
  confirmText="inventory.warehouseIsInventory"
  textAligne="left"
  (onClosing)="onClosingIsInventory()"
  kindDialog="information"
  btnConfig="close"
  #InventoryDialog
>
</app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isVisibleLogout()"
  [isVisible]="true"
  confirmText="logoutQuestion"
  (onRemoving)="onLogout()"
  (onClosing)="onLogoutClosing()"
>
</app-confirm-dialog>

<app-confirm-dialog
  *ngIf="refreshPressed()"
  [isVisible]="true"
  [disableRefreshShortcut]="true"
  confirmText="continueRefresh"
  (onClosing)="onClosingRefresh()"
  (onRemoving)="onContinueRefresh()"
>
</app-confirm-dialog>

<app-confirm-dialog
  [btnConfig]="dynamicConfirmDialog.btnConfig"
  [kindDialog]="dynamicConfirmDialog.type"
  *ngIf="dynamicConfirmDialog.isVisible"
  [isVisible]="true"
  [confirmHeader]="dynamicConfirmDialog.header"
  [confirmText]="dynamicConfirmDialog.text"
  [dynamicBtns]="dynamicConfirmDialog.dynamicBtns"
  (onDynamicBtnClicked)="onConfirmDialogButtonClicked($event)"
  (onRemoving)="onConfirmDialogButtonClicked($event, 'onRemoving')"
  (onClosing)="onConfirmDialogButtonClicked($event, 'onClosing')"
  (onCanceling)="onConfirmDialogButtonClicked($event, 'onCanceling')"
  [radioBoxList]="dynamicConfirmDialog.radioBoxList"
></app-confirm-dialog>

@defer (when event.isExportFKVisible()) {
<app-export-fakir *ngIf="event.isExportFKVisible()"></app-export-fakir>
} @defer (when (event.isEmailSerialSendVisible() ||
event.isEmailBulkSendVisible())) {
<app-email-serial-send
  *ngIf="event.isEmailSerialSendVisible() || event.isEmailBulkSendVisible()"
  [serialSendMode]="event.isEmailSerialSendVisible()"
  [bulkSendMode]="event.isEmailBulkSendVisible()"
></app-email-serial-send>
}

<app-date-range
  *ngIf="event.isDateRangeVisible()"
  [isPopupMode]="true"
  [isVisible]="true"
  [isMenu]="true"
  [chipsVisible]="false"
>
</app-date-range>

<app-wapro-assistant
  *ngIf="
    event.isLog &&
    event.returnEnvironmentInfo() !== 'prod' &&
    event.returnEnvironmentInfo() !== 'stage' &&
    event.returnEnvironmentInfo() !== 'demo'
  "
  #waproAssistant
  [is-visible]="chatEvent.isAssistantOpen()"
  [api-path]="chatApiPath"
  [auth-token]="authToken"
  speech-recognition="client"
>
</app-wapro-assistant>
