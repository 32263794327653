<div
  class="version-content"
  *ngIf="event.deviceType == 'desktop'; else mobileView"
>
  <div class="separator-box"></div>
  <div>
    <dx-menu
      [dataSource]="menu"
      displayExpr="name"
      [focusStateEnabled]="true"
      orientation="vertical"
      submenuDirection="rightOrBottom"
      [hideSubmenuOnMouseLeave]="true"
      itemTemplate="items"
      [showFirstSubmenuMode]="showSubmenuModes[0]"
      cssClass="main-menu version-menu"
      tabindex="1"
      (onItemClick)="onItemClick($event)"
    >
      <div
        *dxTemplate="let item of 'items'; let i = index"
        routerLinkActive="active"
        [class.active]="item.name === event.footerInfo.VariantName"
      >
        <div *ngIf="item.node === 0" class="box-current-variant">
          <i
            class="icon"
            style="font-size: 20px"
            [ngClass]="{
              'absui-icon--select-one':
                event.footerInfo.VariantName === 'START',
              'absui-icon--select-inverse':
                event.footerInfo.VariantName === 'BIZNES',
              'absui-icon--select-triple-left':
                event.footerInfo.VariantName === 'PRESTIŻ',
              'absui-icon--select-all':
                event.footerInfo.VariantName === 'PRESTIŻ PLUS'
            }"
          ></i>
          <div class="varianName" [ngSwitch]="event.footerInfo.VariantName">
            <div *ngSwitchCase="'START'">
              <p class="varianName">Wariant<br />START</p>
            </div>
            <div *ngSwitchCase="'BIZNES'">
              <p class="varianName">Wariant<br />BIZNES</p>
            </div>
            <div *ngSwitchCase="'PRESTIŻ'">
              <p class="varianName">Wariant<br />PRESTIŻ</p>
            </div>
            <div *ngSwitchCase="'PRESTIŻ PLUS'">
              <p class="varianName">PRESTIŻ<br />PLUS</p>
            </div>
          </div>
        </div>

        <div
          class="dx-item-content dx-menu-item-content"
          *ngIf="item.node === 1"
        >
          <div class="text-center">
            <div class="d-flex flex-row bd-highlight mb-3">
              <div style="margin-right: 8px">
                <i class="{{ item.icon }}"></i>
              </div>
              <div class="text-left">
                <span class="span-name">{{ item.name }}</span>
                <p class="p-description" [innerHTML]="item.description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-menu>
  </div>
</div>

<ng-template #mobileView>
  <div class="separator-box" style="width: 100%"></div>

  <dx-menu
    [dataSource]="menu"
    displayExpr="name"
    [focusStateEnabled]="true"
    *ngIf="!isSubmenuItemClick"
    orientation="vertical"
    submenuDirection="auto"
    [hideSubmenuOnMouseLeave]="true"
    itemTemplate="items"
    (onItemClick)="onItemClickMobile($event)"
    tabindex="1"
    cssClass="mobile-main-menu"
  >
    <div *dxTemplate="let item of 'items'; let i = index">
      <div>
        <div
          class="item-menu-mobile"
          (touchstart)="touchStart(item)"
          (touchend)="touchEnd()"
          [ngClass]="{ 'tap-menu-mobile': isTap == item.id }"
        >
          <i
            class="icon"
            style="font-size: 20px"
            [ngClass]="{
              'absui-icon--select-one':
                event.footerInfo.VariantName === 'START',
              'absui-icon--select-inverse':
                event.footerInfo.VariantName === 'BIZNES',
              'absui-icon--select-triple-left':
                event.footerInfo.VariantName === 'PRESTIŻ',
              'absui-icon--select-all':
                event.footerInfo.VariantName === 'PRESTIŻ PLUS'
            }"
          ></i>
          <div
            [ngSwitch]="event.footerInfo.VariantName"
            style="display: inline"
          >
            <div
              *ngSwitchCase="'START'"
              style="display: inline; position: relative; top: -3px"
            >
              <p class="dx-menu-item-text-span-mobile">Wariant START</p>
            </div>
            <div
              *ngSwitchCase="'BIZNES'"
              style="display: inline; position: relative; top: -3px"
            >
              <p class="dx-menu-item-text-span-mobile">Wariant BIZNES</p>
            </div>
            <div
              *ngSwitchCase="'PRESTIŻ'"
              style="display: inline; position: relative; top: -3px"
            >
              <p class="dx-menu-item-text-span-mobile">Wariant PRESTIŻ</p>
            </div>
            <div
              *ngSwitchCase="'PRESTIŻ PLUS'"
              style="display: inline; position: relative; top: -3px"
            >
              <p class="dx-menu-item-text-span-mobile">PRESTIŻ PLUS</p>
            </div>
          </div>
          <!--
          <i class="dx-icon icon {{ item.icon }}"></i>
          <span class="dx-menu-item-text-span-mobile">{{
            item.name | translate
          }}</span>
          <i
            class="icon absui-icon--arrow-drop-right-user-panel"
            *ngIf="item.items && item.items.length >= 1"
            style="
              position: relative;
              left: 0px;
              top: -2px;
              margin-right: 10px;
              float: right;
              font-size: 20px !important;
            "
          ></i> -->
        </div>
      </div>
    </div>
  </dx-menu>

  <div *ngIf="isSubmenuItemClick">
    <ng-container *ngTemplateOutlet="mobileSubmenu"></ng-container>
  </div>
</ng-template>

<ng-template #mobileSubmenu>
  <div class="mobile-submenu-variant">
    <div class="header-submenu-main-menu-mobile">
      <div class="d-flex flex-row">
        <div>
          <div class="btn-header-submenu">
            <i
              class="icon absui-icon--arrow-back-mobile-menu"
              (click)="backToParent()"
            ></i>
          </div>
        </div>
        <div style="padding-top: 7px; width: calc(100vw - 80px)">
          <p class="title-menu-parent">{{ "menu.variants" | translate }}</p>
        </div>
        <div>
          <div class="btn-header-submenu">
            <!-- <i class="icon absui-icon--close" (click)="onCloseSubmenu()"></i> -->
          </div>
        </div>
      </div>
    </div>

    <div class="content-main-submenu-mobile">
      <div *ngFor="let item of itemChosed?.items">
        <div
          routerLinkActive="active"
          (click)="onItemClickMobile(item)"
          class="mobile-main-submenu"
          (touchstart)="touchStart(item)"
          (touchend)="touchEnd()"
          [ngClass]="{
            'tap-submenu': isTap == item.id,
            active: item.name === event.footerInfo.VariantName
          }"
        >
          <div class="text-center">
            <div class="d-flex flex-row bd-highlight mb-3">
              <div style="margin-right: 11px">
                <i class="dx-icon icon {{ item.icon }}"></i>
              </div>
              <div class="text-left" style="margin-top: -2px">
                <span class="span-name">{{ item.name }}</span>
                <p class="p-description" [innerHTML]="item.description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
