import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventService } from '../../event.service';
import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SubmenuShowMode } from 'devextreme/common';
import { AppServices } from '../../app-services.service';

@Component({
    selector: 'app-wapro-app-select',
    imports: [CommonModule, DxMenuModule, TranslateModule],
    templateUrl: './wapro-app-select.component.html',
    styleUrl: './wapro-app-select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaproAppSelectComponent implements OnInit {
  @Output() onChoosed = new EventEmitter();

  appList = [
    {
      name: 'sprzedaż i magazyn',
      shortcut: 'mg',
      active: true,
      node: 0,
      items: [
        {
          name: 'activeAplication',
          shortcut: null,
          active: true,
          visible: true,
          header: true,
          productId: null,
        },
        {
          name: 'sprzedaż i magazyn',
          shortcut: 'mg',
          active: true,
          visible: true,
          node: 1.1,
          productId: 8,
        },
        {
          name: 'yourAplications',
          shortcut: null,
          active: true,
          visible: true,
          header: true,
          productId: null,
        },
        {
          name: 'sprzedaż i magazyn',
          shortcut: 'mg',
          active: false,
          visible: true,
          node: 1.2,
          application: 'mag',
          productId: 8,
        },
        {
          name: 'jednolity plik kontrolny',
          shortcut: 'jpk',
          active: false,
          visible: true,
          node: 1.3,
          application: 'jpk',
          productId: 18,
        },
        {
          name: 'finanse i księgowość',
          shortcut: 'fk',
          active: false,
          visible: false,
          node: 1.4,
          application: 'fk',
          productId: 3,
        },
        {
          name: 'aukcje internetowe',
          shortcut: 'ak',
          active: false,
          visible: false,
          node: 1.5,
          application: 'auctions',
          productId: 17,
        },
        {
          name: 'RODO',
          shortcut: 'rd',
          active: false,
          visible: true,
          node: 1.6,
          application: 'gdpr',
          productId: null,
        },
      ],
    },
  ];

  showSubmenuModes = [
    {
      name: 'onHover' as SubmenuShowMode,
      delay: { show: 100, hide: 100 },
    },
    {
      name: 'onClick' as SubmenuShowMode,
      delay: { show: 0, hide: 300 },
    },
  ];

  isSubmenuItemClick: boolean = false;
  isTap: any = null;
  appServices = inject(AppServices);
  isPermisionToJPK = { IsJPKAccessGranted: false };

  constructor(
    public event: EventService,
    public translate: TranslateService,
    public cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    if (localStorage.getItem('app')) {
      this.appList[0].items.forEach((item) => {
        if (item.application === localStorage.getItem('app')) {
          this.appList[0].name = item.name;
          this.appList[0].shortcut = item.shortcut;
          this.appList[0].items[1].name = item.name;
          this.appList[0].items[1].shortcut = item.shortcut;
        }

        
      });
    }

    

    if (localStorage.getItem('activeApplicationWapro')) {
      this.setCurrentApp();
    }

    this.isPermisionToJPK = (await this.getPermissionToJPK()) as any;
    // this.initVisibleApp();

    if(this.event.returnEnvironmentInfo() === 'localhost' ||
              this.event.returnEnvironmentInfo() === 'dev' ||
              this.event.returnEnvironmentInfo() === 'qa') {
      this.appList[0].items.forEach((item) => { item.visible = true; });
    }
  }

  initVisibleApp() {
    this.appList[0].items.forEach((item) => {
      if (item.productId !== null)
        item.visible =
          (this.isVisibleApp(item.productId) &&
            item.productId === 18 &&
            this.isPermisionToJPK.IsJPKAccessGranted) ||
          item.productId !== 18;
    });
  }

  private isVisibleApp(productId: number) {
    return (
      this.event.footerInfo.AppIdList?.findIndex(
        (item: any) => item === productId
      ) != -1
    );
  }

  private getPermissionToJPK() {
    return new Promise((resolve) => {
      this.appServices
        .getAuth(
          `appPermissions/users?appUserId=${this.event.footerInfo.AppUserId}`
        )
        .subscribe({
          next: (data: any) => {
            resolve({ IsJPKAccessGranted: data.data[0].IsJPKAccessGranted });
          },
          error: (error) => {
            resolve({ IsJPKAccessGranted: false });
            this.event.httpErrorNotification(error);
          },
        });
    });
  }

  onItemClick(e) {
    if (e.itemData.application) {
      this.appList[0].name = e.itemData.name;
      this.appList[0].shortcut = e.itemData.shortcut;

      this.appList[0].items[1].name = e.itemData.name;
      this.appList[0].items[1].shortcut = e.itemData.shortcut;

      localStorage.setItem('app', e.itemData.application);

      this.onChoosed.emit(e.itemData.application);
    }
  }

  private setCurrentApp() {
    let name = '';
    let shortcut = '';
    switch (localStorage.getItem('activeApplicationWapro')) {
      case 'mag':
        name = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'mg'
        )?.name as string;
        shortcut = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'mg'
        )?.shortcut as string;

        break;

      case 'auctions':
        name = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'ak'
        )?.name as string;
        shortcut = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'ak'
        )?.shortcut as string;
        break;

      case 'jpk':
        name = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'jpk'
        )?.name as string;
        shortcut = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'jpk'
        )?.shortcut as string;
        break;

      case 'fk':
        name = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'fk'
        )?.name as string;
        shortcut = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'fk'
        )?.shortcut as string;
        break;

      case 'gdpr':
        name = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'rd'
        )?.name as string;
        shortcut = this.appList[0].items.find(
          (shortcut) => shortcut.shortcut === 'rd'
        )?.shortcut as string;
        break;
    }

    this.appList[0].items[1].name = name;
    this.appList[0].items[1].shortcut = shortcut;

    this.appList[0].name = name;
    this.appList[0].shortcut = shortcut;
  }

  itemChosed: any = [];
  isMenuMobile: boolean = false;
  onItemClickMobile(e) {
    if (e.itemData && e.itemData.items && e.itemData.items.length > 0) {
      this.isSubmenuItemClick = true;
      this.itemChosed = e.itemData;
      return;
    }

    this.onItemClick({ itemData: e });
    this.isSubmenuItemClick = false;
    this.cd.detectChanges();
  }

  touchStart = (e) => {
    this.isTap = e.node;
  };

  touchEnd = () => {
    this.isTap = null;
  };

  backToParent() {
    this.isSubmenuItemClick = false;
    this.cd.detectChanges();
  }
}
