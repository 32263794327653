<dx-popup
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [visible]="isVisible"
  [showCloseButton]="true"
  [width]="480"
  [height]="420"
  [minWidth]="390"
  [showTitle]="true"
  (visibleChange)="onVisibleChangedFinish($event)"
  titleTemplate="title"
>
  <div *dxTemplate="let data of 'title'">
    <div class="popup-header">
      <dx-button
        class="close"
        (onClick)="closePopup()"
        icon="icon absui-icon--close"
        type="default"
        stylingMode="text"
      >
      </dx-button>
    </div>
  </div>
  <div *dxTemplate="let content of 'content'">
    <div class="text-center" style="padding: 0 12px;">
      <img src="../../../assets/svg/notifications_active.svg" alt="Asseco BS" />

      <h4 class="try-for-free-heading">
        {{ "tryForFree.heading" | translate }}
      </h4>

      <p class="try-for-free-body"
        [innerHTML]="'tryForFree.body1' | translate "
      ></p>
      <p class="try-for-free-body"
        [innerHTML]="'tryForFree.body2' | translate : { link: registrationLink }"
      ></p>

      <div class="flex-row">
        <dx-button
          [text]="'tryForFree.tryForFree' | translate"
          type="success"
          (onClick)="openLink()"
        >
        </dx-button>

        <dx-button
          [text]="'tryForFree.remindMeLater' | translate"
          type="danger"
          (onClick)="closePopup()"
        >
        </dx-button>
      </div>
    </div>
  </div>
</dx-popup>
