<div *ngIf="isVisible()" class="footer">
  <div>
    <p class="p-warning-text-box-app">
      <i class="icon absui-icon--warning2 icon-warning-box"></i>
      <span
        [innerHTML]="
          'applicationStatusMassage.openingBalanceInProgress' | translate
        "
      ></span>
    </p>
  </div>
  <div class="error-icon-wrapper" (click)="deleteFooter()" [id]="'delete'">
    <i class="icon absui-icon--close"></i>
  </div>
</div>
