import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  signal,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { ChatUtilsService } from '../../services/chat-utils.service';
import { IMessage } from '../../interfaces/message';
import { ChatApiService } from '../../services/chat-api.service';
import { LoadingMessageComponent } from '../loading-message/loading-message.component';
import { EmptySpaceComponent } from '../empty-space/empty-space.component';
import {
  NgClass,
  NgFor,
  NgForOf,
  NgIf,
} from '@angular/common';
import { SingleMessageComponent } from '../single-message/single-message.component';
import { ChatHeaderComponent } from '../chat-header/chat-header.component';
import { ChatService } from '../../services/chat.service';
import {
  ISpeechRecognitionResult,
  SpeechRecognitionService,
} from '../../services/speech-recognition.service';
import { AudioRecorderService } from '../../services/audio-recorder.service';
import { ChatEventService } from '../../services/chat-event.service';
import { MessagesComponent } from '../messages/messages.component';
import { ChatContainerComponent } from '../chat-container/chat-container.component';
import { IWaproAssistantConfig } from '../../interfaces/config';
import { ChatHistoryService } from '../../services/chat-history.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-wapro-assistant',
    imports: [
        ChatContainerComponent,
        LoadingMessageComponent,
        EmptySpaceComponent,
        NgIf,
        NgFor,
        NgForOf,
        SingleMessageComponent,
        NgClass,
        ChatHeaderComponent,
        MessagesComponent,
    ],
    templateUrl: './wapro-assistant.component.html',
    styleUrl: './wapro-assistant.component.scss'
})
export class WaproAssistantComponent implements AfterViewInit, OnChanges {
  @Input('api-path') apiPath: string = '';
  @Input('auth-token') authToken: string = '';
  @Input('speech-recognition') speechRecognition: string = 'client';
  @Input() lang: string = navigator.language;
  @Input('remember-context') rememberContext: boolean = false;
  @Input('debug-mode') debugMode: boolean = false;
  @Input('is-visible') isVisible: boolean = false;

  @ViewChild('textArea') textArea: ElementRef<HTMLTextAreaElement>;
  @ViewChild('audio') audio: ElementRef<HTMLAudioElement>;
  @ViewChildren('singleMessage')
  singleMessages: QueryList<SingleMessageComponent>;

  @Output() 'wapro-assistant-close' = new EventEmitter(<any>{});
  @Output() 'wapro-assistant-open' = new EventEmitter(<any>{});
  @Output() 'wapro-assistant-mic-input' = new EventEmitter(<any>{});
  @Output() 'wapro-assistant-error' = new EventEmitter(<any>{});
  @Output() 'wapro-assistant-speech-recognition-result' = new EventEmitter(
    <any>{}
  );

  textInputValue: string = '';
  // chatHistory: IMessage[] = [];

  areButtonsDisabled: boolean = false;
  isHidden: boolean = false;
  isLoading: WritableSignal<boolean> = signal(false);
  isDragging: boolean;
  isSettingsOpened: boolean = false;

  constructor(
    public chat: ChatService,
    private utils: ChatUtilsService,
    private chatApiService: ChatApiService,
    public cd: ChangeDetectorRef,
    private speechRecognitionService: SpeechRecognitionService,
    private audioRecorderService: AudioRecorderService,
    public elementRef: ElementRef,
    public chatEvent: ChatEventService,
    public history: ChatHistoryService,
    private router: Router
  ) {
    this.lang = this.chat.lang();
    if (!this.apiPath) {
      // console.error('apiPath is required');
    }
    if (!this.authToken) {
      // console.error('authToken is required');
    }

    if (!this.isVisible) {
      const el = this.elementRef.nativeElement as HTMLElement;
      el.setAttribute('hidden', 'true');
    }

    // this.chat.visibilityHandler.subscribe((visibility) => {
    //   this.isVisible = visibility;

    //   if (!visibility) {
    //     this.close();
    //   } else {
    //     this.open();
    //     setTimeout(() => {
    //       this.setFocus();
    //     }, 0);
    //   }
    // });

    this.chatEvent.eventsHandler.subscribe((event) => {
      if (!event || !event.name) return;

      switch (event.name) {
        case 'close':
          this['wapro-assistant-close'].emit(event.data);
          break;
        case 'open':
          this['wapro-assistant-open'].emit(event.data);
          break;
        case 'mic-input':
          this['wapro-assistant-mic-input'].emit(event.data);
          break;
        case 'error':
          this['wapro-assistant-error'].emit(event.data);
          break;
        case 'speech-recognition-result':
          this['wapro-assistant-speech-recognition-result'].emit(event.data);
          break;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.close();
        this.history.clear();
      }
    });
  }

  ngAfterViewInit() {
    this.singleMessages.changes.subscribe(() => {
      this.scrollToLastMessage();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['speechRecognition']) {
    }

    if (changes['lang']) {
      this.chat.lang.set(this.lang);
    }

    if (changes['isVisible']) {
      changes['isVisible'].currentValue ? this.open() : this.close();
      // this.chat.visibilityHandler.next(this.isVisible);
      this.chatEvent.isAssistantOpen.set(this.isVisible);
    }
  }

  scrollToLastMessage() {
    if (this.singleMessages.length > 0) {
      const lastMessage: ElementRef = this.singleMessages.last.elementRef;
      lastMessage.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onTextAreaValueChange(_e: Event, textArea: HTMLTextAreaElement) {
    this.cd.detectChanges();
    this.resize(textArea);
  }

  onTextAreaCut(_event: ClipboardEvent, textArea: HTMLTextAreaElement) {
    this.delayedResize(textArea);
  }

  onTextAreaPaste(_event: ClipboardEvent, textArea: HTMLTextAreaElement) {
    this.delayedResize(textArea);
  }

  onTextAreaDrag(_event: DragEvent, textArea: HTMLTextAreaElement) {
    this.delayedResize(textArea);
  }

  onTextAreaKeyDown(_event: KeyboardEvent, textArea: HTMLTextAreaElement) {
    if (_event.key === 'Enter' && !_event.shiftKey) {
      _event.preventDefault();
      this.onSendButtonClick(null);
    }
    this.delayedResize(textArea);
  }

  onTextInput(event: Event): void {
    const inputElement = event.target as HTMLTextAreaElement;
    this.textInputValue = inputElement.value;
  }

  observeTextAreaResize(textArea: HTMLTextAreaElement) {
    textArea.focus();
    textArea.select();
  }

  private resize(element: HTMLElement) {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';

    if (element.scrollHeight > 100) {
      element.style.overflowY = 'scroll';
    } else {
      element.style.overflowY = 'hidden';
    }
  }

  delayedResize(element: HTMLElement) {
    /* 0-timeout to get the already changed text */
    setTimeout(() => {
      this.resize(element);
    }, 0);
  }

  async onMicButtonClick(_event: MouseEvent): Promise<void> {
    if (this.chat.isMicRecording()) {
      this.stopMicRecording();
      return;
    }

    switch (this.speechRecognition) {
      case 'server':
        this.handleServerSpeechRecognition();
        break;
      case 'client':
      default:
        this.handleClientSpeechRecognition();
        break;
    }
  }

  async handleServerSpeechRecognition(): Promise<void> {
    this.audio.nativeElement.src = '';
    const audioBlob = await this.audioRecorderService.init();
    this.audio.nativeElement.src = URL.createObjectURL(audioBlob);
  }

  onAudioRemoveButtonClick(_event: MouseEvent) {
    this.audio.nativeElement.src = '';
  }

  stopMicRecording() {
    switch (this.speechRecognition) {
      case 'server':
        this.audioRecorderService.stop();
        break;
      case 'client':
      default:
        this.speechRecognitionService.stop();
        break;
    }
  }

  async handleClientSpeechRecognition(): Promise<void> {
    if (this.chat.isMicRecording()) {
      this.speechRecognitionService.stop();
      return;
    }
    this.chat.isMicRecording.set(true);

    const speechRecognition: ISpeechRecognitionResult =
      await this.speechRecognitionService.init();

    this.chat.isMicRecording.set(false);
    if (speechRecognition.transcript) {
      this.sendMessage({
        text: speechRecognition.transcript,
        time: this.utils.getTime(),
        type: 'outgoing',
      });
    }
  }

  onSendButtonClick(_event: MouseEvent | null) {
    if (_event) _event.preventDefault();
    if (this.textInputValue.trim() === '') return;
    const outgoingMessage = <IMessage>{
      text: this.utils.escapeHTML(this.textInputValue),
      time: this.utils.getTime(),
      type: 'outgoing',
    };
    this.sendMessage(outgoingMessage);
    this.disableButtons();
  }

  sendMessage(outgoing: IMessage) {
    this.textInputValue = '';
    this.history.update(outgoing);
    this.isLoading.set(true);
    this.cd.detectChanges();
    this.chatApiService.post(outgoing, this.apiPath, this.authToken).subscribe({
      next: (incoming: IMessage) => {
        this.history.update(incoming);
        this.isLoading.set(false);
        this.cd.detectChanges();
        setTimeout(() => {
          this.setFocus();
          this.cd.detectChanges();
        }, 0);
      },
      error: (error) => {
        console.log(error);
        this.setFocus();
        this.cd.detectChanges();
      },
    });
  }

  reload() {
    let chatHistory = this.history.get();
    const lastMsgWithoutError = chatHistory
      .filter((msg: IMessage) => !msg.error)
      .pop();
    this.history.set(chatHistory);

    if (!lastMsgWithoutError) {
      this.history.update({
        text: 'Hello',
        time: this.utils.getTime(),
        type: 'incoming',
      });
    } else {
      this.chatApiService
        .post(lastMsgWithoutError, this.apiPath, this.authToken)
        .subscribe({
          next: (incoming: IMessage) => {
            this.textInputValue = '';
            this.history.update(incoming);
            this.isLoading.set(false);
            this.cd.detectChanges();
            setTimeout(() => {
              this.setFocus();
              this.cd.detectChanges();
            }, 0);
          },
        });
    }
  }

  disableButtons() {
    this.areButtonsDisabled = true;
    this.cd.detectChanges();
  }

  // toggleVisibility() {
  //   const el = this.elementRef.nativeElement as HTMLElement;
  //   if (this.isVisible) {
  //     el.removeAttribute('hidden');
  //   } else {
  //     el.setAttribute('hidden', 'true');
  //   }
  // }

  public init(config: IWaproAssistantConfig) {
    this.history.rememberContext.set(!!config.rememberContext);
    if (!!config.rememberContext) {
      this.history.restore();
    } else {
      this.history.clear();
    }
    this.apiPath = config.apiPath;
    this.authToken = config.authToken;
    this.speechRecognition = config.speechRecognition
      ? config.speechRecognition
      : 'client';
    this.lang = config.lang ? config.lang : navigator.language;
    this.chat.isDebugMode.set(!!config.debugMode);
    this.cd.detectChanges();
  }

  public close() {
    // this['wapro-assistant-close'].emit();
    // this.isVisible = false;
    const els = document.querySelectorAll('wapro-assistant');
    this.elementRef.nativeElement.setAttribute('hidden', 'true');
    els.forEach((el) => el.setAttribute('hidden', 'true'));
    this.chatEvent.isAssistantOpen.set(false);
    this.chatEvent.emit('close', {});
    this.chatEvent.setModalOpen(false);
  }

  public open() {
    // this['wapro-assistant-open'].emit();
    // this.isVisible = true;
    const el = this.elementRef.nativeElement as HTMLElement;
    el.removeAttribute('hidden');
    this.chatEvent.emit('open', {});
    setTimeout(() => {
      this.setFocus();
    }, 0);
    this.chatEvent.setModalOpen(true);
  }

  public setFocus() {
    this.textArea?.nativeElement?.focus();
  }

  offset = { x: 0, y: 0 };
  onHeaderMouseDown = (_event: MouseEvent): void => {
    const target = _event.target as HTMLElement;
    if (target.id === 'close-button' || target.closest('#close-button')) return;

    if (_event.target) {
      const el = this.elementRef.nativeElement as HTMLElement;
      el.setAttribute('dragging', 'true');
      this.isDragging = true;
      this.offset.x = _event.clientX - el.offsetLeft;
      this.offset.y = _event.clientY - el.offsetTop;

      document.addEventListener('mousemove', this.popupMove);
      document.addEventListener('mouseup', this.onHeaderMouseUp);

      document.body.style.userSelect = 'none';
    }
  };

  onHeaderMouseUp = (_event: MouseEvent): void => {
    this.isDragging = false;
    const el = this.elementRef.nativeElement as HTMLElement;
    el.removeAttribute('dragging');

    document.removeEventListener('mousemove', this.popupMove);
    document.removeEventListener('mouseup', this.onHeaderMouseUp);

    document.body.style.userSelect = '';
  };

  popupMove = (e: MouseEvent): void => {
    if (!this.isDragging) return;

    const el = this.elementRef.nativeElement as HTMLElement;
    const top = e.clientY - this.offset.y;
    const left = e.clientX - this.offset.x;

    el.style.top = `${this.getYPosition(top)}px`;
    el.style.left = `${this.getXPosition(left)}px`;
    // el.style.translate = 'transform(0,0)';
  };

  getYPosition(yPos: number): number {
    const el = this.elementRef.nativeElement as HTMLElement;
    if (yPos <= 0) return 0;
    if (yPos >= window.innerHeight - el.clientHeight)
      return window.innerHeight - el.clientHeight;
    return yPos;
  }

  getXPosition(xPos: number): number {
    const el = this.elementRef.nativeElement as HTMLElement;
    if (xPos <= 0) return 0;
    if (xPos >= window.innerWidth - el.clientWidth)
      return window.innerWidth - el.clientWidth;
    return xPos;
  }

  clearMessages() {
    this.history.clear();
    this.isSettingsOpened = false;
    this.setFocus();
    this.cd.detectChanges();
  }

  toggleSettings() {
    this.isSettingsOpened = !this.isSettingsOpened;
    this.cd.detectChanges();
  }
}
