<div class="chat-message chat-message-incoming chat-message-loading">
  <div class="chat-message-avatar">
    <div class="chat-message-avatar-bg"></div>
  </div>
  <div class="chat-message-content chat-message-content-loading">
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
    <div class="loading-dot"></div>
  </div>
</div>
