import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  AfterViewInit,
  inject,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmailData, EmailDataAttachment } from 'src/app/event.model';
import { EmailSerialMailComponent } from '../email-serial-mail/email-serial-mail.component';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { DxPopupModule } from 'devextreme-angular';
import { Router } from '@angular/router';
import { EmailService } from '../email.service';

@Component({
    selector: 'app-email-serial-send',
    imports: [
        DxPopupModule,
        EmailSerialMailComponent,
        CommonModule,
        ConfirmDialogComponent,
    ],
    templateUrl: './email-serial-send.component.html',
    styleUrls: ['./email-serial-send.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['bulkSendMode', 'serialSendMode']
})
export class EmailSerialSendComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  router = inject(Router);
  bulkSendMode: boolean = false;
  isBulkSendMode: boolean = false;
  serialSendMode: boolean = false;
  isSerialSendMode: boolean = false;
  widthWindow = 10;
  heightWindow = 0;
  fullScreen: boolean = false;
  unicalGuid;
  emailMessageTitle: string = '';
  emailQueue: EmailData[] = [];
  accountConfigurations = [];
  actualAccountConfiguration;
  actualAccountConfigurationPriv;
  actualAccountConfigurationSet;
  isNewEmailWindow: boolean = false;
  emailTo: string;
  subject: string;
  message: string;
  attachment: EmailDataAttachment;
  private: boolean = false;
  isViewConfirm: boolean = false;
  emailData: EmailData;

  constructor(
    public event: EventService,
    private appService: AppServices,
    private cd: ChangeDetectorRef,
    private emailService: EmailService
  ) {
    this.event.emailData
      .pipe(takeUntilDestroyed())
      .subscribe(async (e: EmailData) => {
        this.emailData = e;
        this.emailQueue.push(e);
        this.sendMailFromQueue();
      });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (this.serialSendMode) {
      this.isSerialSendMode = true;
    }
    if (this.bulkSendMode) {
      this.isBulkSendMode = true;
    }
  }

  ngOnDestroy(): void {
    this.isSerialSendMode = false;
    this.isBulkSendMode = false;
  }

  ngAfterViewInit(): void {
    this.getAccountConfiguration();
  }

  sendMailFromQueue() {
    if (
      this.actualAccountConfiguration !== undefined &&
      this.actualAccountConfiguration !== null
    ) {
      if (this.isNewEmailWindow) return;
      if (!this.emailQueue?.length) return;
      const email = this.emailQueue.shift();
      this.emailTo = email.emailTo;
      this.subject = email.subject;
      this.message = email.message;
      this.attachment = email.attachment;
      this.private = email.sender === 'company' ? false : true;
      if (!this.private) {
        if (this.actualAccountConfiguration) {
          this.actualAccountConfigurationSet = this.actualAccountConfiguration;
          this.isNewEmailWindow = true;
        } else {
          this.isViewConfirm = true;
        }
      }

      if (this.private) {
        if (this.actualAccountConfigurationPriv) {
          this.actualAccountConfigurationSet =
            this.actualAccountConfigurationPriv;
          this.isNewEmailWindow = true;
        } else {
          this.isViewConfirm = true;
        }
      }
      this.cd.detectChanges();
    }
  }

  goToEmail(): void {
    this.router.navigate(['crm/email']);
    this.closeForm();
  }

  goClose(): void {
    this.closeForm();
  }

  onClosingEmail(): void {
    this.isNewEmailWindow = false;
    if (this.emailQueue?.length > 0) {
      setTimeout(() => {
        this.sendMailFromQueue();
      }, 200);
    } else {
      this.closeForm();
    }
  }

  onSendMail(): void {
    this.onClosingEmail();
  }

  onSaveMail(): void {
    this.onClosingEmail();
  }

  getAccountConfiguration(): void {
    this.appService.getAuth(`emails/configurations`).subscribe(
      (res) => {
        this.accountConfigurations = res?.data;
        this.actualAccountConfigurationPriv = this.accountConfigurations.find(
          (x) => x.AppUserId === this.event.footerInfo.AppUserId
        );
        this.actualAccountConfiguration = this.accountConfigurations.find(
          (x) => x.AppUserId === null
        );
        if (
          (this.actualAccountConfiguration === undefined ||
            this.actualAccountConfiguration === null) &&
          (this.actualAccountConfigurationPriv === undefined ||
            this.actualAccountConfigurationPriv === null)
        ) {
          //this.setConfig();
        }
      },
      (error) => {
        if (JSON.parse(error).detail) {
          this.event.showNotification('error', JSON.parse(error).detail);
        } else {
          this.event.showNotification('error', JSON.parse(error).message);
        }
      }
    );
  }

  closeForm(): void {
    this.event.isEmailSerialSendVisible.set(false);
    this.emailService.bulkMailWindowCloseSubscription.next(true);
  }
}
