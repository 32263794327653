import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/event.service';
import { ErrorListRecord } from './../../event.model';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ErrorListService } from 'src/app/error-list.service';
import { Observable } from 'rxjs';
import { DxListModule, DxTooltipModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-error-list',
    templateUrl: './error-list.component.html',
    styleUrls: ['./error-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, DxListModule, DxTooltipModule, TranslateModule]
})
export class ErrorListComponent {
  @Input() width = 320;
  errorsList$: Observable<ErrorListRecord[]> = null;

  constructor(
    private errorList: ErrorListService,
    public event: EventService,
    private translate: TranslateService
  ) {
    this.errorsList$ = this.errorList.latestErrors$;
  }

  delete(index: number) {
    this.errorList.removeError(index);
  }

  copy(error: ErrorListRecord) {
    navigator.clipboard.writeText(JSON.stringify(error)).then(
      () => {
        this.event.showNotification(
          'success',
          this.translate.instant('savedToClipboard')
        );
      },
      () => {
        this.event.showNotification(
          'error',
          this.translate.instant('cantSaveToClipboard')
        );
      }
    );
  }
}
