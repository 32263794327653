import { Component, EventEmitter, Output } from '@angular/core';
// import { ChatService } from '../../services/chat.service';
import { ChatEventService } from '../../services/chat-event.service';

@Component({
    selector: 'app-chat-header',
    imports: [],
    templateUrl: './chat-header.component.html',
    styleUrl: './chat-header.component.scss'
})
export class ChatHeaderComponent {
  // private chat = inject(ChatService);
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  constructor(private chatEvent: ChatEventService) {}

  close(): void {
    // this.chat.visibilityHandler.next(false);
    this.chatEvent.isAssistantOpen.set(false);
  }
}
