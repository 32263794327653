import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  visibilityHandler: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isMicRecording: WritableSignal<boolean> = signal(false);
  lang: WritableSignal<string> = signal(navigator.language);
  isDragging: WritableSignal<boolean> = signal(false);
  isDebugMode: WritableSignal<boolean> = signal(false);
  constructor() {
    this.visibilityHandler.next(false);
  }
}
