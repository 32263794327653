<svg
  width="249"
  height="298"
  viewBox="0 0 249 298"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M91.9327 272.477L91.9287 272.477C78.1606 272.939 64.3924 271.568 50.9689 268.397C20.7288 258.792 6.98589 234.871 2.38431 207.907C-2.22039 180.925 2.3441 150.945 8.68748 129.392C24.9876 74.0067 72.1769 32.8174 125.078 31.6027C134.584 31.3892 144.077 32.4609 153.31 34.7902L153.31 34.7904C216.971 50.7729 254.985 121.233 239.312 187.281L239.688 187.37L239.312 187.281C233.009 213.847 211.557 234.792 183.944 249.282C156.337 263.769 122.629 271.771 91.9327 272.477Z"
    stroke="#BC1A22"
    stroke-opacity="0.19"
    stroke-width="0.775244"
  />
  <path
    d="M100.201 34.677L100.205 34.6752C112.023 28.6914 124.55 24.3051 137.471 21.6263C167.524 17.7373 188.73 33.0066 203.147 54.633C217.575 76.2756 225.171 104.247 227.965 125.6C235.143 180.464 209.928 235.483 164.302 257.987C156.101 262.026 147.416 264.941 138.472 266.654L138.472 266.654C76.8326 278.535 16.5123 232.552 4.67151 168.653L4.29339 168.729L4.67151 168.653C-0.0908693 142.955 10.5126 116.011 28.9799 92.1898C47.4427 68.3747 73.7257 47.7371 100.201 34.677Z"
    stroke="#BC1A22"
    stroke-opacity="0.36"
    stroke-width="0.775244"
  />
  <path
    d="M156.274 265.693L156.27 265.695C144.724 273.35 132.256 279.437 119.181 283.803C88.4864 291.533 64.7194 278.316 47.245 257.679C29.7578 237.026 18.6086 208.975 13.2118 187.193C-0.655532 131.223 19.0265 71.1031 63.6737 42.167C71.6994 36.9712 80.3585 32.8823 89.4273 30.006L89.4277 30.0058C151.915 10.1086 219.811 50.3403 239.569 115.099L239.934 114.98L239.569 115.099C247.516 141.146 239.684 170.374 223.338 197.331C206.996 224.282 182.179 248.902 156.274 265.693Z"
    stroke="#BC1A22"
    stroke-opacity="0.19"
    stroke-width="0.775244"
  />
  <g filter="url(#filter0_f_141_14853)">
    <path
      d="M144 208C144 208.552 137.284 209 129 209C120.716 209 114 208.552 114 208C114 207.448 120.716 207 129 207C137.284 207 144 207.448 144 208Z"
      fill="#BC1A22"
    />
  </g>
  <path
    d="M130.837 88.3845C131.416 88.6375 131.937 89.0071 132.367 89.4703C132.799 89.939 133.129 90.4921 133.336 91.0946C133.544 91.697 133.624 92.3356 133.573 92.9707L131.85 107.985C131.803 108.717 131.485 109.406 130.958 109.916C130.432 110.426 129.733 110.722 129 110.745C128.267 110.722 127.569 110.426 127.042 109.916C126.515 109.406 126.197 108.717 126.15 107.985L124.424 92.9399C124.376 92.3096 124.459 91.6762 124.667 91.0793C124.875 90.4825 125.204 89.9349 125.633 89.4707C126.063 89.0074 126.584 88.6375 127.163 88.3845C127.743 88.1314 128.368 88.0005 129 87.9997C129.632 88.0005 130.257 88.1314 130.837 88.3845Z"
    fill="url(#paint0_linear_141_14853)"
  />
  <path
    d="M149.663 170.513C147.851 172.424 145.777 174.07 143.504 175.4C140.041 177.372 136.327 178.869 132.464 179.848C128.696 181.109 125.266 183.213 122.434 186V178.735C122.578 176.526 123.284 174.391 124.484 172.531C125.684 170.672 127.338 169.149 129.292 168.107L136.213 164.45C137.679 163.672 139.011 162.663 140.156 161.462C142.257 159.255 143.656 156.476 144.177 153.475C144.698 150.473 144.317 147.384 143.082 144.599C141.848 141.814 139.815 139.458 137.241 137.828C133.79 135.644 129.612 134.919 125.626 135.813C121.64 136.708 118.173 139.149 115.987 142.599C114.357 145.173 113.527 148.172 113.602 151.217C113.677 154.263 114.653 157.217 116.407 159.708C118.161 162.199 120.614 164.114 123.457 165.211C123.647 165.284 123.841 165.338 124.036 165.391C124.153 165.424 124.271 165.456 124.387 165.492C120.78 168.334 118.414 172.462 117.786 177.01C112.923 174.948 108.728 171.574 105.672 167.266C102.423 162.653 100.615 157.18 100.477 151.539C100.338 145.898 101.876 140.343 104.895 135.576C108.945 129.185 115.368 124.663 122.751 123.006C130.133 121.349 137.872 122.691 144.265 126.737C149.032 129.756 152.798 134.121 155.085 139.279C157.371 144.438 158.076 150.159 157.111 155.718C156.147 161.278 153.555 166.426 149.663 170.513Z"
    fill="url(#paint1_linear_141_14853)"
  />
  <path
    d="M100.021 118.116L90.6218 106.281C90.1905 105.772 89.6538 105.363 89.0487 105.082C88.4435 104.801 87.7846 104.655 87.1175 104.655C86.5147 104.653 85.9175 104.771 85.3606 105.002C84.8037 105.233 84.2981 105.572 83.8731 105.999C83.4266 106.447 83.0772 106.981 82.8465 107.57C82.6158 108.158 82.5087 108.788 82.5321 109.42C82.5572 110.052 82.712 110.671 82.987 111.24C83.262 111.81 83.6516 112.316 84.1309 112.728L95.9908 122.146C96.5651 122.613 97.2795 122.873 98.0196 122.884C98.3796 122.885 98.7364 122.815 99.0691 122.678C99.4018 122.54 99.7039 122.338 99.958 122.083C100.46 121.548 100.745 120.845 100.756 120.111C100.768 119.378 100.506 118.667 100.021 118.116Z"
    fill="url(#paint2_linear_141_14853)"
  />
  <path
    d="M171.859 105.002C172.416 105.233 172.921 105.572 173.346 105.999C173.792 106.447 174.142 106.981 174.372 107.57C174.603 108.158 174.71 108.788 174.687 109.42C174.661 110.056 174.504 110.681 174.224 111.253C173.945 111.826 173.55 112.335 173.065 112.747L161.229 122.146C160.654 122.613 159.939 122.873 159.199 122.884C158.839 122.885 158.483 122.815 158.15 122.677C157.817 122.54 157.515 122.338 157.261 122.083C156.759 121.548 156.475 120.845 156.463 120.111C156.451 119.378 156.714 118.667 157.199 118.116L166.617 106.257C167.048 105.754 167.582 105.35 168.184 105.074C168.786 104.797 169.44 104.654 170.102 104.655C170.705 104.653 171.302 104.771 171.859 105.002Z"
    fill="url(#paint3_linear_141_14853)"
  />
  <path
    d="M85.9864 145.243L70.971 143.52C70.8208 143.508 70.702 143.503 70.5842 143.503C69.3681 143.506 68.2023 143.99 67.3429 144.85C66.4836 145.711 66.0006 146.877 66 148.093C66.0007 149.309 66.4839 150.475 67.3433 151.335C68.2028 152.196 69.3684 152.68 70.5846 152.682C70.7023 152.682 70.8211 152.678 70.9405 152.669L85.9868 150.942C86.719 150.896 87.4074 150.578 87.9179 150.051C88.4284 149.524 88.7244 148.826 88.7479 148.093C88.7245 147.359 88.4285 146.661 87.9179 146.134C87.4073 145.607 86.7186 145.29 85.9864 145.243Z"
    fill="url(#paint4_linear_141_14853)"
  />
  <path
    d="M187.059 143.517C187.179 143.508 187.298 143.503 187.416 143.503C188.632 143.506 189.798 143.99 190.657 144.85C191.516 145.711 191.999 146.877 192 148.093C191.999 149.309 191.516 150.475 190.657 151.335C189.797 152.196 188.631 152.68 187.415 152.682C187.297 152.682 187.179 152.678 187.029 152.666L172.013 150.942C171.281 150.896 170.592 150.578 170.082 150.051C169.571 149.524 169.275 148.826 169.252 148.093C169.275 147.36 169.571 146.661 170.082 146.134C170.592 145.607 171.281 145.29 172.013 145.243L187.059 143.517Z"
    fill="url(#paint5_linear_141_14853)"
  />
  <defs>
    <filter
      id="filter0_f_141_14853"
      x="107.023"
      y="200.023"
      width="43.9544"
      height="15.9544"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="BackgroundImageFix"
        result="shape"
      />
      <feGaussianBlur
        stdDeviation="3.4886"
        result="effect1_foregroundBlur_141_14853"
      />
    </filter>
    <linearGradient
      id="paint0_linear_141_14853"
      x1="110.748"
      y1="204.805"
      x2="173.247"
      y2="96.5573"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#BC1A22" />
      <stop offset="0.333333" stop-color="#D71D27" />
      <stop offset="0.666667" stop-color="#FF4852" />
      <stop offset="1" stop-color="#BC1A22" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_141_14853"
      x1="110.748"
      y1="204.805"
      x2="173.247"
      y2="96.5573"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#BC1A22" />
      <stop offset="0.333333" stop-color="#D71D27" />
      <stop offset="0.666667" stop-color="#FF4852" />
      <stop offset="1" stop-color="#BC1A22" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_141_14853"
      x1="110.748"
      y1="204.805"
      x2="173.247"
      y2="96.5573"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#BC1A22" />
      <stop offset="0.333333" stop-color="#D71D27" />
      <stop offset="0.666667" stop-color="#FF4852" />
      <stop offset="1" stop-color="#BC1A22" />
    </linearGradient>
    <linearGradient
      id="paint3_linear_141_14853"
      x1="110.748"
      y1="204.805"
      x2="173.247"
      y2="96.5573"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#BC1A22" />
      <stop offset="0.333333" stop-color="#D71D27" />
      <stop offset="0.666667" stop-color="#FF4852" />
      <stop offset="1" stop-color="#BC1A22" />
    </linearGradient>
    <linearGradient
      id="paint4_linear_141_14853"
      x1="110.748"
      y1="204.805"
      x2="173.247"
      y2="96.5573"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#BC1A22" />
      <stop offset="0.333333" stop-color="#D71D27" />
      <stop offset="0.666667" stop-color="#FF4852" />
      <stop offset="1" stop-color="#BC1A22" />
    </linearGradient>
    <linearGradient
      id="paint5_linear_141_14853"
      x1="110.748"
      y1="204.805"
      x2="173.247"
      y2="96.5573"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#BC1A22" />
      <stop offset="0.333333" stop-color="#D71D27" />
      <stop offset="0.666667" stop-color="#FF4852" />
      <stop offset="1" stop-color="#BC1A22" />
    </linearGradient>
  </defs>
</svg>

<ng-container [ngSwitch]="chat.lang()">
  <ng-container *ngSwitchCase="'en-EN'">
    <h1>I am your <b>Assistant</b></h1>
    <div class="chat-empty-space-body">
      <p>
        Ask me a question to get instructions<br />
        and tips on using the system.
      </p>
      <ng-container [ngTemplateOutlet]="logoGenius"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <h1>Jestem <b>Twoim Asystentem</b> Pomocy</h1>
    <div class="chat-empty-space-body">
      <p>
        Zadaj pytanie i otrzymaj instrukcje<br />
        oraz porady dotyczące obsługi systemu.
      </p>
      <ng-container [ngTemplateOutlet]="logoGenius"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #logoGenius>
  <slot name="genius-dark">
    <img src="https://wapro.pl/wp-content/uploads/2024/06/Genius.svg" alt="" />
  </slot>
</ng-template>
