[
  {
    "id": "1",
    "idPatch": "0",
    "name": "menu.apps",
    "icon": "icon icon-absui-icon--location",
    "url": null,
    "items": [],
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
    {
      "id": "2",
      "name": "menu.start",
      "idPatch": "1",
      "icon": "icon absui-icon--home-3",
      "url": "fk/dashboard",
      "items": [],
      "submenu": [
        {
          "id": "21",
          "idPatch": "0",
          "name": "menu.fk.dashboard",
          "url": "fk/dashboard",
          "code": null,
          "patch": "menu.start",
          "visible": true,
          "signature": "P",
          "classCss": "redHeader"
        },
        {
          "id": "22",
          "idPatch": "0",
          "name": "menu.fk.dashboard",
          "url": "fk/dashboard",
          "code": null,
          "patch": "menu.start",
          "visible": true,
          "signature": "P",
          "classCss": "redHeader"
        }
      ],
      "tooltip": false,
      "code": null,
      "visible": true
    },
    {
      "id": "3",
      "idPatch": "2",
      "name": "menu.fk.documents",
      "icon": "icon absui-icon--content-books-1",
      "url": "fk/documents",
      "items": [],
      "tooltip": false,
      "code": "dokumenty",
      "visible": true,
      "patch": "",
      "submenu": [
        {
          "id": "31",
          "idPatch": "0",
          "name": "menu.fk.accountingDocuments",
          "url": "fk/documents/accounting-documents",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": false,
          "signature": "DK",
          "classCss": "tealHeader"
        },
        {
          "id": "32",
          "idPatch": "1",
          "name": "menu.fk.listings",
          "url": "fk/documents/listings",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": false,
          "signature": "Z",
          "classCss": "blueGreyHeader"
        },
        {
          "id": "33",
          "idPatch": "2",
          "name": "menu.fk.externalEvidence",
          "url": "fk/documents/external-evidence",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": false,
          "signature": "DZ",
          "classCss": "amberSignature"
        },
        {
          "id": "34",
          "idPatch": "3",
          "name": "menu.fk.financialDocuments",
          "url": "fk/documents/financial-documents",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": false,
          "signature": "DF",
          "classCss": "limeSignature"
        },
        {
          "id": "35",
          "idPatch": "4",
          "name": "menu.fk.recordsSettlements",
          "url": "fk/documents/records-settlements",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": false,
          "signature": "ZR",
          "classCss": "deepOrangeSignature"
        },
        {
          "id": "36",
          "idPatch": "5",
          "name": "menu.fk.taxReturns",
          "url": "fk/documents/tax-returns",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": false,
          "signature": "DP",
          "classCss": "brownSignature"
        },
        {
          "id": "37",
          "idPatch": "6",
          "name": "constractors.fastSteps",
          "url": null,
          "code": null,
          "type": "listHeader",
          "visible": true,
          "patch": "constractors.fastSteps"
        },
        {
          "id": "38",
          "idPatch": "7",
          "name": "menu.fk.addSalesInvoice",
          "url": "fk/documents/accounting-documents/add",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": true,
          "signature": "DS",
          "classCss": "indigoSignature",
          "shortcut": "Ctrl+Alt+S"
        },
        {
          "id": "39",
          "idPatch": "8",
          "name": "menu.fk.addPurchaseInvoice",
          "url": "fk/documents/accounting-documents/add",
          "code": null,
          "patch": "menu.fk.documents",
          "visible": true,
          "signature": "DZ",
          "classCss": "indigoSignature",
          "shortcut": "Ctrl+Alt+Z"
        }
      ]
    },
    {
      "id": "4",
      "name": "menu.fk.settlements",
      "idPatch": "3",
      "icon": "icon absui-icon--table-coins",
      "url": "fk/settlements",
      "items": [],
      "submenu": [],
      "tooltip": false,
      "code": null,
      "visible": true,
      "patch": ""
    },
    {
      "id": "5",
      "name": "menu.fk.ledger",
      "idPatch": "4",
      "icon": "icon absui-icon--mdi-book",
      "url": "fk/ledger",
      "items": [],
      "submenu": [],
      "tooltip": false,
      "code": null,
      "visible": true,
      "patch": ""
    },
    {
      "id": "6",
      "name": "menu.fk.vat",
      "idPatch": "5",
      "icon": "absui-icon--file-coins",
      "url": "fk/vat",
      "items": [],
      "submenu": [],
      "tooltip": false,
      "code": null,
      "visible": true,
      "patch": ""
    },
    {
        "id": "7",
        "idPatch": "6",
        "name": "menu.library",
        "icon": "icon absui-icon--sheets",
        "url": "library",
        "items": [],
        "submenu": [
          {
            "id": "71",
            "idPatch": "0",
            "name": "menu.fk.vehicles",
            "url": "library/vehicles",
            "code": "Pojazdy",
            "visible": false,
            "signature": "P",
            "classCss": "blueGreyHeader",
            "patch": "menu.library"
          },
          {
            "id": "72",
            "idPatch": "1",
            "name": "menu.banks",
            "url": "library/banks",
            "code": "Banki",
            "visible": false,
            "signature": "B",
            "classCss": "redHeader",
            "patch": "menu.library"
          },
          {
            "id": "73",
            "idPatch": "2",
            "name": "menu.currency",
            "url": "library/currency",
            "code": "Waluty",
            "visible": false,
            "signature": "W",
            "classCss": "amberSignature",
            "patch": "menu.library"
          }
        ],
        "tooltip": false,
        "code": null,
        "visible": true,
        "patch": ""
      },
      {
        "id": "8",
        "name": "menu.fk.email",
        "idPatch": "7",
        "icon": "icon absui-icon--mail-back-menu",
        "url": "fk/email",
        "items": [],
        "patch": "",
        "submenu": [],
        "tooltip": false,
        "code": null,
        "visible": false
      },
      {
        "id": "9",
        "name": "menu.fk.other",
        "idPatch": "8",
        "icon": "icon absui-icon--set-settings",
        "url": "fk/other",
        "items": [],
        "patch": "",
        "submenu": [],
        "tooltip": false,
        "code": null,
        "visible": false
      },
      {
        "id": "10",
        "idPatch": "9",
        "name": "menu.administrator",
        "icon": "icon absui-icon--settings-user",
        "url": "fk/admin",
        "patch": "",
        "items": [],
        "submenu": [],
        "tooltip": false,
        "code": null,
        "visible": false
      },
      {
        "id": "11",
        "idPatch": "10",
        "name": "menu.help",
        "icon": "icon absui-icon--help-outline",
        "url": "fk/help",
        "patch": "",
        "items": [],
        "submenu": [],
        "tooltip": false,
        "code": null,
        "visible": false
      }
    
  ]
  
  