import { ICRMTaskWithReminder } from './../../event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/event.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DxTooltipModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-toast-reminder',
    templateUrl: './toast-reminder.component.html',
    styleUrls: ['./toast-reminder.component.scss'],
    imports: [CommonModule, DxTooltipModule, TranslateModule, RouterModule]
})
export class ToastReminderComponent implements OnInit {
  reminders: ICRMTaskWithReminder[] = [];
  reminders$: Subscription;

  constructor(public event: EventService, public translate: TranslateService) {}

  ngOnInit(): void {
    this.reminders$ = this.event.reminders.subscribe(
      ({ task, timeActive }) => {
        this.reminders.push(task);
        setTimeout(() => {
          this.closeReminderId(task.TaskId);
        }, timeActive);
      },
      () => {}
    );
  }

  ngOnDestroy(): void {
    this.reminders$?.unsubscribe();
  }

  closeReminderId(id: number): void {
    const index = this.reminders.findIndex((reminder) => reminder.TaskId == id);

    if (index > -1) {
      this.reminders.splice(index, 1);
    }
  }

  closeReminderIndex(index: number): void {
    this.reminders.splice(index, 1);
  }
}
