<div class="item item-grid" [ngClass]="'status-' + item.Status">
  <p class="item-name" [id]="'task-' + item.Status + '-' + item.TaskGuid">
    {{ item.TypeDescription }}
  </p>
  <div
    class="item-tag"
    [ngClass]="{ 'hide-on-hover': item.Status != 'finished' }"
  >
    <span class="item-tag-text" *ngIf="item.Status != 'finished'">{{
      item.StatusDescription
    }}</span>
    <dx-icon
      [ngClass]="['icon', 'absui-icon--check']"
      *ngIf="item.Status == 'finished'"
    ></dx-icon>
  </div>
  <div
    class="hover-button"
    [ngClass]="{ 'show-on-hover': item.Status != 'finished' }"
  >
    <dx-button
      text=""
      icon="icon absui-icon--symbol-forbidden"
      *ngIf="
        item.Status == 'undefined' ||
        item.Status == 'queued' ||
        item.Status == 'processing'
      "
      (onClick)="cancelTask(item)"
      [id]="'btn-cancel' + item.TaskGuid"
    ></dx-button>
    <dx-button
      text=""
      icon="icon icon-refresh"
      *ngIf="item.Status == 'canceled' || item.Status == 'failed' || item.Status == 'notAssigned'"
      (onClick)="retryTask(item)"
      [id]="'btn-refresh' + item.TaskGuid"
    ></dx-button>
  </div>
  <p class="item-time">
    <span [id]="'taskDesc' + item.TaskGuid">{{
      item.CreatedAt | date : "YYYY-MM-dd HH:mm"
    }}</span>
  </p>
  <div class="bacground-porgress" *ngIf="item.Status == 'processing'">
    <div class="progres-border"></div>
  </div>
</div>

<dx-tooltip
  [target]="'#btn-cancel' + item.TaskGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.cancel" | translate }}
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#btn-refresh' + item.TaskGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "fiscalPrinter.queueTask.retry" | translate }}
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#taskDesc' + item.TaskGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "inventory.lastStatusUpdate" | translate }}
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#task-' + item.Status + '-' + item.TaskGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
  *ngIf="item.ErrorDescription"
>
  <div *dxTemplate="let data of 'content'">
    {{ item.ErrorDescription }}
  </div>
</dx-tooltip>

<!-- <dx-tooltip
  [target]="'#task-' + item.Status + '-' + item.TaskGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
  *ngIf="!item.ErrorDescription"
>
  <div *dxTemplate="let data of 'content'">
    {{ item.StatusDescription | titlecase }}
  </div>
</dx-tooltip> -->

<div class="bacground-porgress" *ngIf="item.Status == 'processing'">
  <div class="progres-border"></div>
</div>
