<div class="chat-header">
  <div class="chat-header-left">
    <span class="chat-header-handle" id="header-handle">
      <svg
        width="11"
        height="16"
        viewBox="0 0 11 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_141_14845)">
          <path
            d="M4.82239 7.77776C4.82239 8.4647 4.26487 9.02221 3.57794 9.02221C2.89101 9.02221 2.3335 8.4647 2.3335 7.77776C2.3335 7.09083 2.89101 6.53332 3.57794 6.53332C4.26487 6.53332 4.82239 7.09083 4.82239 7.77776Z"
            fill="white"
          />
          <path
            d="M4.82239 12.7555C4.82239 13.4425 4.26487 14 3.57794 14C2.89101 14 2.3335 13.4425 2.3335 12.7555C2.3335 12.0686 2.89101 11.5111 3.57794 11.5111C4.26487 11.5111 4.82239 12.0686 4.82239 12.7555Z"
            fill="white"
          />
          <path
            d="M4.82239 2.79999C4.82239 3.48692 4.26487 4.04443 3.57794 4.04443C2.89101 4.04443 2.3335 3.48692 2.3335 2.79999C2.3335 2.11305 2.89101 1.55554 3.57794 1.55554C4.26487 1.55554 4.82239 2.11305 4.82239 2.79999Z"
            fill="white"
          />
          <path
            d="M8.55572 7.77776C8.55572 8.4647 7.99821 9.02221 7.31127 9.02221C6.62434 9.02221 6.06683 8.4647 6.06683 7.77776C6.06683 7.09083 6.62434 6.53332 7.31127 6.53332C7.99821 6.53332 8.55572 7.09083 8.55572 7.77776Z"
            fill="white"
          />
          <path
            d="M8.55572 12.7555C8.55572 13.4425 7.99821 14 7.31127 14C6.62434 14 6.06683 13.4425 6.06683 12.7555C6.06683 12.0686 6.62434 11.5111 7.31127 11.5111C7.99821 11.5111 8.55572 12.0686 8.55572 12.7555Z"
            fill="white"
          />
          <path
            d="M8.55572 2.79999C8.55572 3.48692 7.99821 4.04443 7.31127 4.04443C6.62434 4.04443 6.06683 3.48692 6.06683 2.79999C6.06683 2.11305 6.62434 1.55554 7.31127 1.55554C7.99821 1.55554 8.55572 2.11305 8.55572 2.79999Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_141_14845">
            <rect width="10.8889" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
    <span class="chat-header-title" id="wapro-assistant-title">
      Twój asystent
    </span>
  </div>
  <div class="chat-header-right">
    <span class="genius" id="genius-slot">
      <img
        src="https://wapro.pl/wp-content/uploads/2024/07/Genius-2.svg"
        alt=""
      />
    </span>
    <button class="chat-header-close" id="close-button" (click)="close()">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.60579 9.00001L13.5795 5.0263C13.6549 4.94916 13.7021 4.84287 13.7021 4.7263C13.7021 4.48887 13.5101 4.29773 13.2735 4.29773C13.1569 4.29773 13.0506 4.34402 12.9735 4.4203L8.99979 8.39402L5.02608 4.4203C4.94893 4.34487 4.84265 4.29773 4.72608 4.29773C4.48865 4.29773 4.2975 4.48973 4.2975 4.7263C4.2975 4.84287 4.34379 4.94916 4.42008 5.0263L8.39379 9.00001L4.42008 12.9737C4.34036 13.0517 4.2915 13.1597 4.2915 13.2797C4.2915 13.5172 4.4835 13.7083 4.72008 13.7083C4.84008 13.7083 4.94808 13.6586 5.02608 13.5797L8.99979 9.60601L12.9735 13.5797C13.0506 13.6552 13.1569 13.7023 13.2735 13.7023C13.5109 13.7023 13.7021 13.5103 13.7021 13.2737C13.7021 13.1572 13.6558 13.0509 13.5795 12.9737L9.60579 9.00001Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
</div>
