import { Injectable, signal, WritableSignal } from '@angular/core';
import { IMessage } from '../interfaces/message';

@Injectable({
  providedIn: 'root',
})
export class ChatHistoryService {
  rememberContext: WritableSignal<boolean> = signal(false);
  historySignal: WritableSignal<IMessage[]> = signal([]);
  constructor() {}

  public get(): IMessage[] {
    return this.historySignal();
  }

  public update(message: IMessage): IMessage[] {
    const history = this.historySignal();
    history.push(message);
    if (this.rememberContext()) {
      sessionStorage.setItem('chatHistory', JSON.stringify(history));
    }
    this.historySignal.set(history);
    return this.historySignal();
  }

  public set(history: IMessage[]): IMessage[] {
    if (this.rememberContext()) {
      sessionStorage.setItem('chatHistory', JSON.stringify(history));
    }
    this.historySignal.set(history);
    return this.historySignal();
  }

  public clear(): void {
    sessionStorage.removeItem('chatHistory');
    this.historySignal.set([]);
  }

  public restore(): void {
    this.historySignal.set(
      JSON.parse(sessionStorage.getItem('chatHistory') || '[]')
    );
  }
}
