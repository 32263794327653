<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  [minHeight]="540"
  [minWidth]="720"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="emailMessageTitle"
  [visible]="true"
  [resizeEnabled]="true"
  (onShown)="onPopupShown($event)"
  (onHidden)="onPopupHidden($event)"
  (onResizeEnd)="onResizeEnd($event)"
  [wrapperAttr]="{ id: unicalGuid }"
  [fullScreen]="fullScreen"
  *ngIf="event.deviceType !== 'mobile'; else mobilePopupTemplate"
>
  <div
    class="text-right title-pop-up"
    [ngClass]="{ boxShadow: scrollPosition > 0 }"
  >
    <ng-container [ngTemplateOutlet]="popupButtonsTemplate"></ng-container>
  </div>

  <ng-container [ngTemplateOutlet]="popupContentTemplate"></ng-container>
</dx-popup>

<ng-template #formTemplate>
  <form [formGroup]="form">
    <div class="col-12">
      <div #formHeader>
        <div
          [ngStyle]="{ display: emailData.isBulk ? 'none' : 'block' }"
          #toDiv
        >
          <div class="label-param" style="margin-top: 25px">
            <label class="label-param">{{ "email.toZ" | translate }}</label>
          </div>
          <div class="div-param">
            <dx-text-box
              #toField
              class="text-box-param"
              formControlName="To"
              valueChangeEvent="input"
            >
            </dx-text-box>
          </div>
          <br />
        </div>
        <div class="label-param">
          <label class="label-param">{{ "email.topicZ" | translate }}</label>
        </div>
        <div class="div-param" #subjectDiv>
          <dx-text-box
            #subjectFied
            class="text-box-param"
            formControlName="Subject"
            valueChangeEvent="input"
            [tabIndex]="0"
          >
          </dx-text-box>
        </div>
        <br />
        <div class="separatorW" *ngIf="!emailData.isBulk"></div>

        <div
          style="
            display: inline-flex;
            cursor: pointer;
            margin-right: 6px;
            position: relative;
            width: 100%;
            align-items: flex-start;
          "
          [style.flexDirection]="
            event.deviceType === 'mobile' ? 'column' : null
          "
          #attachmentDiv
        >
          <div
            class="label-param"
            [style.marginTop]="event.deviceType === 'mobile' ? null : 0"
          >
            <label class="label-param">Załączniki:</label>
          </div>
          <dx-scroll-view
            [width]="event.deviceType === 'mobile' ? '100%' : '77%'"
            direction="horizontal"
            [height]="event.deviceType != 'mobile' ? 'auto' : 26"
            [tabIndex]="-1"
            (onInitialized)="onScrollViewInitialized($event)"
          >
            <div
              [style.display]="
                event.deviceType === 'mobile' ? 'flex' : 'inline-flex'
              "
              [ngStyle]="{
                'flex-wrap': event.deviceType === 'mobile' ? 'nowrap' : 'wrap'
              }"
              style="gap: 5px 0; align-items: center"
            >
              <dx-button
                class="btn-dx-top-menu-folder"
                stylingMode="text"
                type="default"
                icon="icon absui-icon--add-circle"
                [id]="'btn-add' + unicalGuid"
                [disabled]="readOnly"
                (onClick)="addBtn()"
                [tabIndex]="0"
              >
              </dx-button>
              <app-email-attachment
                *ngFor="let attachment of attachments; let index = index"
                [fileName]="attachment.FileName"
                [fileId]="index"
                [readOnly]="readOnly"
                (onDeleteAttachment)="deleteAttachment($event)"
                (onDownloadAttachment)="downloadAttachment($event)"
              >
              </app-email-attachment>
            </div>
          </dx-scroll-view>

          <!-- [showFileLis]="false" -->
          <dx-file-uploader
            #fileuploader
            labelText=""
            [multiple]="false"
            allowCanceling:true="true"
            uploadMode="useForm"
            height="0"
            (onValueChanged)="onAttachmentChanged($event)"
            style="display: inline-block"
            class="edocuments"
            style="width: 41px"
            [disabled]="readOnly"
            icon="icon absui-icon--add-circle"
            [tabIndex]="-1"
          >
          </dx-file-uploader>
        </div>

        <div class="separatorW" *ngIf="!emailData.isBulk"></div>
        <br />
      </div>
      <div
        style="display: flex"
        [style.height.px]="scrollViewHeight - formHeader.scrollHeight"
        [ngStyle]="{
          'flex-direction': event.deviceType === 'mobile' ? 'column' : 'row'
        }"
      >
        <div class="label-param" *ngIf="event.deviceType !== 'mobile'">
          <label class="label-param">{{
            "status-type.text" | translate
          }}</label>
        </div>
        <div
          class="div-param"
          [style.width]="event.deviceType === 'mobile' ? '100%' : null"
          [style.height]="event.deviceType === 'mobile' ? '100%' : null"
        >
          <dx-html-editor
            #htmlEditor
            [height]="event.deviceType === 'mobile' ? '100%' : htmlEditorHeight"
            formControlName="Message"
            (onInitialized)="onHtmlEditorInitialized($event)"
            (onContentReady)="onHtmlEditorContentReady($event)"
            (onValueChanged)="onHtmlEditorValueChanged($event)"
            (onFocusIn)="onHtmlEditorFocusIn($event)"
            [tabIndex]="0"
          >
            <dxo-toolbar [multiline]="false">
              <!-- name="variable" -->
              <!-- [acceptedValues]="variableAcceptedValues" -->
              <dxi-item
                [options]="variableOptions"
                widget="dxSelectBox"
                location="before"
                [visible]="emailData.isBulk"
              >
              </dxi-item>
              <div *dxTemplate="let data of 'dropDownButton'" [tabIndex]="3">
                <i class="dx-icon dx-icon-spindown"></i>
              </div>

              <dxi-item
                name="separator"
                location="center"
                [visible]="emailData.isBulk"
              ></dxi-item>
              <dxi-item
                name="font"
                [acceptedValues]="[
                  'Calibri',
                  'Arial',
                  'Courier New',
                  'Georgia',
                  'Impact',
                  'Lucida Console',
                  'Tahoma',
                  'Times New Roman',
                  'Verdana'
                ]"
                [options]="{
                  inputAttr: { 'aria-label': 'Font family' },
                  placeholder:
                    ('externalDevices.labelPrinter.font' | translate),
                  value: 'Calibri',
                  hint: ('externalDevices.labelPrinter.font' | translate),
                  tabIndex: 3
                }"
                [baseSize]="500"
                [location]="emailData.isBulk ? 'after' : 'before'"
              ></dxi-item>
              <dxi-item
                name="size"
                [acceptedValues]="[
                  '8pt',
                  '10pt',
                  '12pt',
                  '14pt',
                  '18pt',
                  '24pt',
                  '36pt'
                ]"
                [options]="{
                  inputAttr: { 'aria-label': 'Font size' },
                  value: '10pt',
                  placeholder: ('additionalTables.size' | translate),
                  hint: ('additionalTables.size' | translate),
                  tabIndex: 3
                }"
                [location]="emailData.isBulk ? 'after' : 'before'"
              ></dxi-item>
              <dxi-item
                name="color"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  hint: ('textEditor.fontColor' | translate),
                  text: ('textEditor.fontColor' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="background"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.backgroundColor' | translate),
                  hint: ('textEditor.backgroundColor' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="separator"
                [location]="emailData.isBulk ? 'after' : 'before'"
              ></dxi-item>
              <dxi-item
                name="bold"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.bold' | translate),
                  hint: ('textEditor.bold' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="italic"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.italic' | translate),
                  hint: ('textEditor.italic' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="underline"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.underline' | translate),
                  hint: ('textEditor.underline' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="separator"
                [location]="emailData.isBulk ? 'after' : 'before'"
              ></dxi-item>
              <dxi-item
                name="alignLeft"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.alignLeft' | translate),
                  hint: ('textEditor.alignLeft' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="alignCenter"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.alignCenter' | translate),
                  hint: ('textEditor.alignCenter' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="alignRight"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.alignRight' | translate),
                  hint: ('textEditor.alignRight' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="alignJustify"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.alignJustify' | translate),
                  hint: ('textEditor.alignJustify' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="separator"
                [location]="emailData.isBulk ? 'after' : 'before'"
              ></dxi-item>
              <dxi-item
                name="orderedList"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.orderedList' | translate),
                  hint: ('textEditor.orderedList' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="bulletList"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.bulletList' | translate),
                  hint: ('textEditor.bulletList' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="decreaseIndent"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.indentDecrease' | translate),
                  hint: ('textEditor.indentDecrease' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
              <dxi-item
                name="increaseIndent"
                [location]="emailData.isBulk ? 'after' : 'before'"
                [options]="{
                  text: ('textEditor.indentIncrease' | translate),
                  hint: ('textEditor.indentIncrease' | translate),
                  tabIndex: 3
                }"
              ></dxi-item>
            </dxo-toolbar>
          </dx-html-editor>
          <!-- <div id="htmlEditorFooter" #htmlEditorFooterDiv></div> -->
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #contactsListTemplate>
  <!-- <div style="padding-top: 4px; padding-bottom: 4px" #filtersDiv>
    <app-custom-chips-button
      [name]="'Osoby odbierające'"
      [list]="contactsListFilters"
      [selectedValueInput]="'Kontrahenci'"
      [disabledClear]="true"
      (onValueChanged)="onContactsListChanged($event)"
    >
    </app-custom-chips-button>
  </div> -->
  <dx-data-grid
    [dataSource]="emailService.bulkSendContacts()"
    #contactsGrid
    [remoteOperations]="true"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="heightGrid"
    (onSelectionChanged)="onSelectionChanged($event)"
    [(focusedRowIndex)]="focusedRowIndex"
    [focusedRowEnabled]="true"
    (onKeyDown)="onKeyDown($event)"
    (onRowDblClick)="onRowDblClick()"
    [id]="'contactsGrid' + unicalGuid"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
    (onContentReady)="onContentReady($event)"
    selectAllMode="allPages"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-paging [pageSize]="200"></dxo-paging>
    <dxo-selection
      mode="multiple"
      showCheckBoxesMode="always"
      [allowSelectAll]="true"
    ></dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxi-column
      caption="{{ 'Adres' | translate }}"
      width="340"
      dataField="EmailAddress"
      [allowSorting]="false"
    ></dxi-column>
    <dxi-column
      caption="{{ 'Nazwisko i imię adresata' | translate }}"
      dataField="ContactName"
      [allowSorting]="false"
      alignment="left"
    ></dxi-column>

    <!-- cellTemplate="contactNameTemplate"
    <div *dxTemplate="let data of 'contactNameTemplate'">
      {{ data.data.LastName }} {{ data.data.FirstName }}
    </div> -->
    <dxi-column
      caption="{{ 'jpk.contractor' | translate }}"
      dataField="CustomerName"
      width="240"
      [allowSorting]="false"
    ></dxi-column>
  </dx-data-grid>
  <wapro-context-menu
    [width]="200"
    [target]="'#contactsGrid' + unicalGuid"
    [dataSource]="contactsGridContextMenu()"
    (onItemClick)="contextMenuClick($event)"
    (onItemRendered)="event.onItemRendered($event)"
  ></wapro-context-menu>
</ng-template>

<app-dynamic-component
  *ngIf="isDynamicPopupVisible"
  [popupId]="dynamicComponentFilterId"
  [chooseFilterId]="dynamicComponentFilterId"
  [componentData]="dynamicComponent"
  [extraData]="dynamicComponentExtraData"
>
</app-dynamic-component>

<wapro-keyboard-shortcuts [shortcuts]="shortcuts" [unicalGuid]="unicalGuid">
</wapro-keyboard-shortcuts>

<ng-template #mobilePopupTemplate>
  <dx-popup
    width="100%"
    height="100%"
    position="bottom"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="false"
    title=""
    [visible]="isVisible"
    (onShown)="onPopupShown($event)"
    (onHidden)="onPopupHidden($event)"
    [wrapperAttr]="{
      id: unicalGuid,
      class: 'document-brand document-form-mobile user-guide-fix',
    }"
    [tabIndex]="-1"
  >
    <div *dxTemplate="let content of 'content'" #popup>
      <app-mobile-popup-header (onClose)="onCloseForm()" #mobilePopupHeader>
        <ng-container popupTitle>
          {{ emailMessageTitle }}
        </ng-container>
      </app-mobile-popup-header>

      <ng-container
        [ngTemplateOutlet]="popupContentTemplate"
        [ngTemplateOutletContext]="{
          buttonsPanel,
          mobilePopupHeader
        }"
      ></ng-container>
      <div class="mobile-buttons-panel" #buttonsPanel>
        <ng-container [ngTemplateOutlet]="popupButtonsTemplate"></ng-container>
      </div>
    </div>
  </dx-popup>
</ng-template>

<ng-template #popupButtonsTemplate>
  <dx-button
    text="{{ 'buttons.save' | translate }}"
    type="normal"
    style="
      margin-right: 6px;
      width: 70px;
      height: 26px;
    "
    [id]="'save' + unicalGuid"
    (click)="SaveEmail()"
    [disabled]="sendOrSave"
    class="outline-secondary"
    stylingMode="outlined"
    *ngIf="!emailData.isBulk"
  ></dx-button>
  <dx-button
    text="{{ 'buffor.send' | translate }}"
    type="success"
    style="margin-right: 6px"
    (click)="SendEmail()"
    [id]="'send' + unicalGuid"
    [disabled]="sendOrSave"
  ></dx-button>
  <dx-button
    text="{{ 'buttons.close' | translate }}"
    type="danger"
    (click)="onCloseForm()"
    [id]="'close' + unicalGuid"
    [disabled]="sendOrSave"
  >
  </dx-button>
  <dx-tooltip
    [target]="'#fitToWidth' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "servicesDevices.fitToWidth" | translate }}
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #popupContentTemplate let-buttonsPanel="buttonsPanel">
  <dx-scroll-view
    width="100%"
    direction="vertical"
    [height]="
      event.deviceType != 'mobile'
        ? '100%'
        : 'calc(100% - ' + buttonsPanel.clientHeight + 'px - 56px)'
    "
    (onScroll)="onScroll($event)"
    [tabIndex]="-1"
    (onInitialized)="onScrollViewInitialized($event)"
    (onUpdated)="onScrollViewChanged($event)"
    #scrollView
  >
    <ng-container *ngIf="emailData.isBulk; else formTemplate">
      <dx-tab-panel
        #tabPanel
        width="100%"
        [items]="[
          'email.emailData' | translate,
          'email.receiversList' | translate
        ]"
        [(selectedIndex)]="selectedtabIndex"
        [animationEnabled]="false"
        [swipeEnabled]="false"
        (onSelectionChanged)="onTabSelectionChanged($event)"
      >
        <div *dxTemplate="let name of 'title'; let i = index">
          <div [id]="'tabToolTip' + i + unicalGuid">
            <span>{{ name }}</span>
          </div>
        </div>

        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name">
            <div *ngSwitchCase="'email.emailData' | translate">
              <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
            </div>
            <div *ngSwitchCase="'email.receiversList' | translate">
              <ng-container
                [ngTemplateOutlet]="contactsListTemplate"
              ></ng-container>
            </div>
          </ng-container>
        </div>
      </dx-tab-panel>
      <dx-tooltip
        *ngFor="
          let tabs of [
            'email.emailData' | translate,
            'email.receiversList' | translate
          ];
          let i = index
        "
        [target]="'#tabToolTip' + i + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data = data; of: 'content'">
          (Alt+{{ i + 1 }})
        </div>
      </dx-tooltip>
    </ng-container>
  </dx-scroll-view>
</ng-template>
