import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PrintService } from 'src/app/print.service';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';
import { ErrorListService } from './../../error-list.service';
import { QueueTask } from './queue-task.model';
import { SingleQueueTaskComponent } from './single-queue-task/single-queue-task.component';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxListModule,
  DxScrollViewModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { ErrorListComponent } from '../error-list/error-list.component';
import { Router } from '@angular/router';
import { ResizableModule } from 'angular-resizable-element';

const WAREHOUSES_KEY = 'warehouses';

@Component({
  selector: 'app-queue-tasks',
  templateUrl: './queue-tasks.component.html',
  styleUrls: ['./queue-tasks.component.scss'],
  inputs: ['isShowQueue'],
  imports: [
    CommonModule,
    DxTabPanelModule,
    SingleQueueTaskComponent,
    DxScrollViewModule,
    DxListModule,
    DxContextMenuModule,
    DxButtonModule,
    ErrorListComponent,
    TranslateModule,
    ResizableModule,
  ],
})
export class QueueTasksComponent implements OnInit {
  isShowQueue;
  selectedTabIndex: number = 0;
  queuetasksList: any[] = [];
  apiTasks: any[] = [];
  frontendTasks: any[] = [];
  @Output() onClosed = new EventEmitter();
  refreshTime: number;
  timeSession: number;
  notificationMsg = 'Remanent';
  isShowNotification: boolean = false;

  timeNotification: number = 5;
  myTimer;
  clickedItemData: QueueTask;
  notifications: { msg: string; route?: string }[] = [];
  progressNotification: { msg: string; value: number };

  constructor(
    public event: EventService,
    public print: PrintService,
    private appService: AppServices,
    public cd: ChangeDetectorRef,
    private translate: TranslateService,
    private errorList: ErrorListService,
    public router: Router
  ) {
    const channel = new BroadcastChannel('queuetasks');
    this.refreshTime = 30;
    this.refreshTime = 3;
    this.timeSession = this.refreshTime;

    channel.onmessage = async (ev: any) => {
      if (ev?.data) {
        await this.updateTasks(ev.data);
      }
      await this.getQueueTasks();

      this.myTimer = setInterval(() => {
        this.timeNotification--;
        if (this.timeNotification == 0) {
          this.isShowNotification = false;
          this.timeNotification = 5;
          clearInterval(this.myTimer);
        }
      }, 1000);
    };

    this.event.refreshProgressToast
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        if (!res.value) {
          this.progressNotification = null;
          return;
        }
        this.progressNotification = { msg: res.msg, value: res.value * 100 };
        setTimeout(
          () => {
            this.progressNotification = null;
          },
          res.value >= 1 ? 1000 : 10000
        );
      });

    this.event.refreshQueueTaskList
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        if (this.notifications.length > 4) {
          this.notifications.pop();
        }

        this.notifications.push(res);
        this.setNotificationTimeout();
        this.getQueueTasks();
      });

    setInterval(() => {
      this.timeSession--;
      if (this.timeSession == 0) {
        this.apiTasks.forEach((field) => {
          if (
            field.Type != 'PrintBill' &&
            field.Status !== 'finished' &&
            field.Status !== 'failed'
          ) {
            this.appService
              .getAuth(
                `queuetasks?taskGuid=${field.TaskGuid}&type=${field.Type}`
              )
              .subscribe((res) => {
                if (res?.data[0]?.Status != 'processing') {
                  this.apiTasks.forEach((field) => {
                    if (field.TaskId == res.data[0].TaskId) {
                      field.EndAt = res.data[0].EndAt;
                      field.ErrorDescription = res.data[0].ErrorDescription;
                      field.Status = res.data[0].Status;
                      field.StatusDescription = res.data[0].StatusDescription;
                      field.refreshStatus = res.data[0].EndAt;
                      this.cd.detectChanges();
                      let warehouseInfo = this.event.decryptString(
                        localStorage.getItem('sessionInfoWaproHtml')
                      );

                      if (field.Status == 'finished') {
                        if (
                          field.Type == 'DOCUMENT_INVENTORY' ||
                          field.Type == 'DOCUMENT_OPENING_BALANCE'
                        ) {
                          this.event.checkStatusWarehouse(
                            warehouseInfo.AppWarehouseId,
                            true
                          );
                          this.event.refreshInventoryList.next(true);

                          field.Type == 'DOCUMENT_INVENTORY'
                            ? this.event.showNotification(
                                'success',
                                this.translate.instant(
                                  'inventory.inventoryCompleted'
                                )
                              )
                            : this.event.showNotification(
                                'success',
                                this.translate.instant(
                                  'BalanceCompletedSuccessfully'
                                )
                              );
                          this.event.blockRemanent.next(false);
                        } else if (
                          field.Type == 'VARIANT_CONVERT' &&
                          field.TaskGuid === this.event.variantTaskId
                        ) {
                          this.event.variantTaskId = null;
                          this.event.readOnly = false;
                          this.event.sendReadOnlyInfo.next(false);
                          //wymus refreshu listy magazynow zapisanej w local storage
                          this.refreshWarehouses();
                          this.event.showNotification(
                            'success',
                            this.translate.instant('changeVariantCompleted')
                          );
                        }
                      }
                    }
                  });
                } else {
                  field.refreshStatus = new Date();
                }
                this.checkIsProcesing();
                this.cd.checkNoChanges();
              });
          }
        });
        this.timeSession = this.refreshTime;
      }
    }, 5000);
  }

  refreshWarehouses() {
    this.appService.getAuth(`warehouses?Skip=0&Take=200`).subscribe((res) => {
      this.event.saveSessionStorageData(WAREHOUSES_KEY, res.data);
    });
  }

  ngOnInit(): void {
    this.getQueueTasks();
  }

  async setNotificationTimeout() {
    setTimeout(async () => {
      this.notifications.shift();
    }, 5000);
  }

  updateTasks = (task) => {
    if (task.RemoveFromQueue) {
      this.frontendTasks = this.frontendTasks.filter((x) => {
        if (x.InterconnectorJobGuid) {
          return x.InterconnectorJobGuid != task.InterconnectorJobGuid;
        } else {
          return x.TaskGuid != task.TaskGuid;
        }
      });
      return;
    }
    let taskToUpdate = this.frontendTasks.find((x) => {
      if (x.InterconnectorJobGuid) {
        return x.InterconnectorJobGuid == task.InterconnectorJobGuid;
      } else {
        return x.TaskGuid == task.TaskGuid;
      }
    });
    if (taskToUpdate) {
      taskToUpdate = Object.assign(taskToUpdate, {
        ErrorDescription: task.ErrorDescription,
        Status: task.Status,
        StatusDescription: task.StatusDescription,
        EndAt: task.EndAt,
      });
    } else {
      this.frontendTasks.unshift(task);
    }
  };

  getQueueTasks = () => {
    return new Promise<void>((resolve) => {
      this.appService.getAuth(`queuetasks`).subscribe((res) => {
        this.apiTasks = res.data;
        this.apiTasks.forEach((field) => {
          if (field.Status != 'failed') {
            field.refreshStatus = field.EndAt;
          } else {
            field.refreshStatus = field.BeginAt;
          }
        });
        this.queuetasksList = [...this.frontendTasks, ...this.apiTasks];
        this.queuetasksList.sort((a, b) => {
          return b.CreatedAt - a.CreatedAt;
        });

        for (let i = this.queuetasksList.length - 1; i >= 0; i--) {
          if (typeof this.queuetasksList[i] === 'string') {
            this.queuetasksList.splice(i, 1);
          }
        }

        setTimeout(() => {
          if (
            this.queuetasksList.some(
              (task) =>
                task.TypeDescription === 'Konwersja wariantu' &&
                task.Status === 'processing'
            )
          ) {
            this.event.readOnly = true;
            this.event.sendReadOnlyInfo.next(true);
          }
        },0);
        this.checkIsProcesing();
        resolve();
      });
    });
  };

  checkIsProcesing = () => {
    let isProcesing = false;
    this.queuetasksList.forEach((field) => {
      if (field.Status == 'processing') {
        isProcesing = true;
      }
    });
    this.event.showDotQueueTaskList.next(isProcesing);
  };

  ngOnChanges() {}

  deleteAllErrors() {
    this.errorList.clearErrors();
  }

  cancelAllTasks() {
    this.print.newTask.next({ event: 'cancelPrint', data: 'all' });
  }

  removeNotification(i) {
    if (this.notifications[i].route) {
      this.router.navigate([this.notifications[i].route]);
    }
    this.notifications.splice(i, 1);
  }

  width = 320;
  startWidth = 320;
  resizing(event) {
    let currentWidth = this.startWidth - event.edges.left;
    if (currentWidth < 320) {
      currentWidth = 320;
    } else if (currentWidth > 600) {
      currentWidth = 600;
    }
    this.width = currentWidth;
  }

  mouseEnter = (isResize = false) => {
    if (isResize) {
      this.startWidth = this.width;
    }
  };

  mouseLeave = () => {};
}
