<div *ngIf="isTopMenu" class="top-menu-wrapper">
  <dx-scroll-view
    [width]="widthScrollWiew"
    height="35"
    [scrollByContent]="true"
    direction="horizontal"
    [(showScrollbar)]="showScrollBar"
    *ngIf="event.deviceType == 'desktop'; else mobileView"
  >
    <dx-menu
      #menu
      [dataSource]="submenu"
      displayExpr="name"
      [showFirstSubmenuMode]="showFirstSubmenuModes"
      orientation="horizontal"
      submenuDirection="auto"
      cssClass="top-menu"
      [hideSubmenuOnMouseLeave]="true"
      itemTemplate="menuItem"
      [focusStateEnabled]="true"
      submenuDirection="auto"
      (onItemClick)="clck($event)"
      style="display: inline-block; margin-left: 6px"
    >
      <div
        *dxTemplate="let item of 'menuItem'"
        class="top-menu-item text-left"
        routerLinkActive="active"
        [class.active]="item.patchMain"
        [ngClass]="item?.class"
      >
        <div
          class="d-flex flex-row"
          [id]="'toolTip' + item.id"
          *ngIf="!item?.type"
        >
          <div
            class="box-signature"
            [ngClass]="item?.classCss"
            *ngIf="item.signature"
          >
            <p>{{ item.signature }}</p>
          </div>

          <div
            *ngIf="item.url"
            [ngClass]="item?.classCss"
            style="background: transparent !important"
          >
            <a class="a-link" [routerLink]="[item.url]" tabIndex="-1">
              <span class="dx-menu-item-text">
                {{ item.name | translate }}</span
              >
            </a>
          </div>

          <div
            *ngIf="!item.url"
            [ngClass]="item?.classCss"
            style="background: transparent !important"
          >
            <span class="dx-menu-item-text" [ngClass]="item?.class">
              {{ item.name | translate }}</span
            >
            <i
              class="icon absui-icon--arrow-drop-right-user-panel"
              *ngIf="item.id.length >= 3 && item.id != 712"
              style="position: absolute; left: 160px; top: 1px; float: right"
            ></i>

            <i
              class="icon icon-expanded"
              *ngIf="item.id.length < 3 || item.id == 712"
              style="display: table-cell; margin-left: 5px; float: right"
            ></i>
          </div>

          <div class="box-after"></div>
        </div>

        <dx-tooltip
          *ngIf="item.shortcuts"
          [target]="'#toolTip' + item.id"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ item.shortcuts }}
          </div>
        </dx-tooltip>

        <!-- <div class="border-active-top-menu"></div> -->
      </div>
    </dx-menu>

    <dx-drop-down-button
      [width]="130"
      *ngIf="items?.url === 'trade-operations' && (perSale || perPurchase)"
      class="btn-dx-secondary"
      text="{{ 'constractors.fastSteps' | translate }}"
      [items]="exportList"
      stylingMode="text"
      type="default"
      [useItemTextAsTitle]="false"
      (onItemClick)="onItemClickExport($event)"
      itemTemplate="fontItem"
      style="
        display: inline-block;
        position: relative;

        margin-left: 6px;
      "
    >
      <div
        *dxTemplate="let data of 'fontItem'"
        [ngClass]="{ 'padding-retail': pathUrl.includes('retail-sales') }"
      >
        <span
          style="
            font-family: 'Roboto' !important;
            font-style: normal;
            font-size: 0.75rem !important;
          "
          >{{ data.text }}</span
        >
      </div>
    </dx-drop-down-button>

    <dx-drop-down-button
      [width]="185"
      *ngIf="items?.url === 'warehouse' && (perBil || perRem)"
      class="btn-dx-secondary"
      text="{{ 'menu.renament' | translate }} / {{
        'menu.openingBalance' | translate
      }} "
      [items]="exportListWarehouse"
      stylingMode="text"
      type="default"
      [useItemTextAsTitle]="false"
      (onItemClick)="onItemClickExport($event)"
      itemTemplate="fontItem"
      style="display: inline-block; position: relative; margin-left: 6px"
    >
      <div *dxTemplate="let data of 'fontItem'">
        <span>{{ data.text }}</span>
      </div>
    </dx-drop-down-button>

    <dx-drop-down-button
      [width]="185"
      *ngIf="items?.url === 'other'"
      class="btn-dx-secondary"
      text="{{ 'form-financial-document.operations' | translate }}"
      [items]="exportListPopup"
      stylingMode="text"
      type="default"
      [useItemTextAsTitle]="false"
      (onItemClick)="onItemClickExport($event)"
      itemTemplate="fontItem"
      style="
        display: inline-block;
        position: relative;

        margin-left: 6px;
      "
    >
      <div *dxTemplate="let data of 'fontItem'">
        <span>{{ data.text }}</span>
      </div>
    </dx-drop-down-button>
  </dx-scroll-view>
  <div
    style="margin: auto 0 auto auto"
    *ngIf="items?.isOperation || items?.isOperation == null"
  >
    <dx-button
      class="btn-more"
      icon="more-horizontal"
      type="default"
      stylingMode="text"
      style="position: relative"
      (onClick)="isOpened = !isOpened"
      [id]="'btn-columns' + unicalGuid"
      [visible]="items?.isOperation"
    ></dx-button>
    <dx-select-box
      [opened]="isOpened"
      [focusStateEnabled]="false"
      [hoverStateEnabled]="true"
      [dataSource]="submenuSelect"
      class="hidden-select-box star-menu"
      style="top: -2px !important"
      (onItemClick)="clck($event)"
      [dropDownOptions]="{
        wrapperAttr: { class: 'no-checkbox' }
      }"
    >
      <div *dxTemplate="let data of 'item'">
        <div class="custom-item">
          <div class="product-name" id="{{ data.name }}" [title]="data.name | translate">
            {{ (data.name | translate).length > 30 ? ((data.name | translate) | slice:0:30) + "..." : (data.name | translate) }}
          </div>
          <dx-button
            class="icon-star"
            [ngClass]="{ active: data.active }"
            (onClick)="setDefault(data)"
            [id]="'id' + data.tooltip"
            stylingMode="text"
          ></dx-button>
          <dx-tooltip
            [target]="'#id' + data.tooltip"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "menu.startTab" | translate }}
            </div>
          </dx-tooltip>
          <dx-tooltip
            [target]="'#' + data.name"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ data.name | translate }}
            </div>
          </dx-tooltip>
        </div>
      </div>
    </dx-select-box>
    <dx-button
      class="btn-more wapro-assistant-toggle"
      icon="icon absui-icon--geniuss"
      type="default"
      stylingMode="text"
      (onClick)="toggleAssistant()"
      [id]="'btn-columns' + unicalGuid"
      *ngIf="
        event.returnEnvironmentInfo() === 'localhost' ||
        event.returnEnvironmentInfo() === 'dev' ||
        event.returnEnvironmentInfo() === 'qa'
      "
    ></dx-button>
  </div>
</div>

<ng-template #mobileView>
  <dx-scroll-view
    [width]="
      event.deviceType == 'mobile' ? 'calc(100% - 6px)' : 'calc(100% - 70px)'
    "
    height="50"
    [scrollByContent]="true"
    direction="horizontal"
    showScrollbar="onScroll"
    #scrollMobile
  >
    <dx-menu
      #menu
      [dataSource]="submenu"
      displayExpr="name"
      [showFirstSubmenuMode]="showFirstSubmenuModes"
      orientation="horizontal"
      submenuDirection="auto"
      cssClass="top-menu"
      [hideSubmenuOnMouseLeave]="true"
      itemTemplate="menuItem"
      [focusStateEnabled]="true"
      submenuDirection="auto"
      (onItemClick)="clck($event)"
    >
      <div
        *dxTemplate="let item of 'menuItem'"
        class="top-menu-item text-left"
        routerLinkActive="active"
        [class.active]="item.patchMain"
      >
        <div *ngIf="item.url">
          <a class="a-link" [routerLink]="[item.url]" tabIndex="-1">
            <span class="dx-menu-item-text">{{ item.name | translate }}</span>
          </a>
        </div>
        <div *ngIf="!item.url">
          <span class="dx-menu-item-text">{{ item.name | translate }}</span>
          <i
            class="icon absui-icon--arrow-drop-right-user-panel"
            *ngIf="item.id.length >= 3 && item.id != 712"
            style="position: relative; left: 5px; top: -5px; float: right"
          ></i>

          <i
            class="icon icon-expanded"
            *ngIf="item.id.length < 3 || item.id == 712"
            style="display: table-cell; margin-left: 5px; float: right"
          ></i>
        </div>
        <div class="border-active-top-menu"></div>
      </div>
    </dx-menu>
  </dx-scroll-view>
  <div class="right-header-btn">
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--operations"
      style="position: relative; left: 17.5px; top: 8.5px"
      (onClick)="isShowTabs = !isShowTabs"
      [id]="'btn-columns' + unicalGuid"
    ></dx-button>
    <!-- <dx-select-box [opened]="isOpened" focusStateEnabled="false" hoverStateEnabled="false"
      [dataSource]="submenuSelect" class="hidden-select-box star-menu" style="top: -2px!important">
      <div *dxTemplate="let data of 'item'">
        <div class="custom-item">
          <div class="product-name">
            {{ data.name | translate }}
          </div>
          <dx-button class="icon-star" [ngClass]="{ 'active': data.active }" (onClick)="setDefault(data)"
            [id]="'id' + data.tooltip" stylingMode="text"></dx-button>
          <dx-tooltip [target]="'#id' + data.tooltip" hideEvent="dxhoverend" [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent">
            <div *dxTemplate="let data of 'content'">
              {{'menu.startTab' | translate}}
            </div>
          </dx-tooltip>
        </div>
      </div>
    </dx-select-box> -->
  </div>

  <dx-popup
    width="320px"
    height="auto"
    position="center"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slide', direction: 'left' },
      hide: { type: 'slide', direction: 'left' }
    }"
    [showTitle]="false"
    [visible]="isShowTabs"
    [shading]="true"
    [wrapperAttr]="{ class: 'settings-mobile' }"
  >
    <div *dxTemplate="let content of 'content'">
      <header class="header-list">
        <p class="p-header-settings">{{ "Bookmarks" | translate }}</p>
        <div
          class="btn-header-submenu"
          style="position: absolute; top: 10px; right: 8px"
        >
          <i class="icon absui-icon--close" (click)="isShowTabs = false"></i>
        </div>
      </header>

      <div style="margin-top: -10px">
        <div
          *ngFor="let item of submenuSelect; let i = index"
          class="item-list-mobile"
          [ngClass]="{ 'item-list-mobile-touched': isTouch == i }"
        >
          <i
            class="icon absui-icon--check-mobile icon-item-list"
            *ngIf="item.patchMain"
          ></i>
          <i
            class="icon-item-list"
            style="width: 20px"
            *ngIf="!item.patchMain"
          ></i>

          <p
            class="p-item-list-name"
            *ngIf="item.name"
            [ngClass]="{ activeUrl: item.patchMain }"
          >
            {{ item.name | translate }}
          </p>

          <p *ngIf="item.active" class="p_start">
            {{ "startTab" | translate }}
          </p>
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon"
            [ngClass]="{
              'icon-star-mobile-active': item.active,
              'icon-star-mobile': !item.active
            }"
            (onClick)="setDefault(item)"
            style="position: absolute; right: 0px; margin-top: -4px"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-popup>
</ng-template>

<!-- [unicalGuid]="unicalGuid" -->
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<ng-keyboard-shortcuts-help
  [key]="'shift + f1'"
  [closeKey]="'escape'"
  title="{{ 'menu.navigationPanelUser.help' | translate }}"
></ng-keyboard-shortcuts-help>
