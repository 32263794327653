import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  signal
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppServices } from '../app-services.service';

import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { EventService } from '../event.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { InterconnectorService } from '../interconnector.service';
import { CommonModule } from '@angular/common';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import {
  DxButtonModule,
  DxLoadPanelModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { WaproLoaderComponent } from '../core/wapro-loader/wapro-loader.component';
import { EmailService } from '../crm/email/email.service';
import { LoaderConfig } from '../core/wapro-loader/wapro-loader.interface';
import { LogoWaproSvgComponent } from 'src/assets/svg/logo-wapro-svg/logo-wapro-svg.component';
import { AbsLogoSvgComponent } from 'src/assets/svg/abs-logo-svg/abs-logo-svg.component';
import { RunDemoSvgComponent } from 'src/assets/svg/run-demo-svg/run-demo-svg.component';
const helper = new JwtHelperService();

@Component({
    selector: 'app-log-on',
    templateUrl: './log-on.component.html',
    styleUrls: ['./log-on.component.scss'],
    imports: [
        CommonModule,
        DxTextBoxModule,
        ReactiveFormsModule,
        DxButtonModule,
        DxLoadPanelModule,
        TranslateModule,
        WaproLoaderComponent,
        DxSelectBoxModule,
        LogoWaproSvgComponent,
        AbsLogoSvgComponent,
        RunDemoSvgComponent,
    ]
})
export class LogOnComponent implements OnInit, OnDestroy {
  @ViewChild('firstFocus') firstFocus;
  logForm;
  isCas: boolean = false;
  mode: string = 'password';
  // jwtHelper: JwtHelper = new JwtHelper();

  formError = {
    Username: '',
    Password: '',
  };

  validationMessages = null;

  submitted: boolean = false;
  isBlockBtnLog: boolean = false;
  logOutCas: boolean = false;

  isDemo: boolean = false;
  versionList = [
    'ANY FAKTUROWANIE',
    'ANY FAKTUROWANIE PRO',
    'ANY START',
    'ANY BIZNES',
    'ANY PRESTIŻ',
    'ANY PRESTIŻ PLUS',
    // '365 BIURO',
    // 'PRESTIŻ',
    // 'PRESTIŻ PLUS',
  ];
  selectedVersion: string = 'ANY START';
  configLoader;
  isShowLanguage: boolean = false;
  showHidenAdditionalOption = signal<boolean>(false);
  items: string[] = [environment.DBDATABASE];

  constructor(
    private appService: AppServices,
    private interconnector: InterconnectorService,
    private translate: TranslateService,
    public _route: Router,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public formBuilder: FormBuilder,
    private mailService: EmailService
  ) {
    this.validationMessages = {
      Username: {
        required: this.translate.instant('logOn.password'),
      },
      Password: {
        required: this.translate.instant('password-required'),
      },
    };

    this.configLoader = new LoaderConfig({
      maxWidth: '100%',
      maxHeight: '100%',
      backgroundColor: 'transparent',
      position: 'relative',
      logoColor: '#bc1a22',
      innerBgColor: '#fff',
      outerBgColor: '#EDEDED',
      spinnerColor: '#bc1a22',
      border: 'none',
      width: '100%',
      height: '140px',
    });

    if (environment.RUNENVIRONMENT === 'Demo') this.isDemo = true;
    this.onCreateForm();
  }

  ngOnInit() {
    this.translate.get('logOn.login-required').subscribe((text: string) => {
      this.validationMessages.Username.required = text;
    });
    this.translate.get('logOn.password-required').subscribe((text: string) => {
      this.validationMessages.Password.required = text;
    });

    this.logForm.valueChanges.subscribe(() => {
      this.event.onControlValueChanged(
        this.logForm,
        this.formError,
        this.validationMessages
      );
    });

    if (
      environment.AUTHAPI === 'https://qa-auth.assecobs.com/' ||
      environment.AUTHAPI === 'https://dev-auth.assecobs.com/' ||
      environment.AUTHAPI === 'https://auth.assecobs.com/'
    ) {
      this.isCas = true;
      //this.onShown();
    }

    if (
      !localStorage.getItem('WAPRO-HTML-token') &&
      (environment.AUTHAPI === 'https://qa-auth.assecobs.com/' ||
        environment.AUTHAPI === 'https://dev-auth.assecobs.com/' ||
        environment.AUTHAPI === 'https://auth.assecobs.com/') &&
      location.href.indexOf('ticket') === -1 &&
      location.href.indexOf('code') === -1
    ) {
      window.location.href = `${environment.AUTHAPI}login?service=${environment.oauthCallbackUrl}`;
    }

    if (localStorage.getItem('lang')) {
      this.selectLanbguage = localStorage.getItem('lang');
    }

    if(localStorage.getItem('dblist')){
      this.items = this.event.decryptString(localStorage.getItem('dblist'))
    }
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    if (!this.isCas) {
      this.event.setFocus(this.firstFocus);
    }
    //}, 1000);
  }

  ngOnDestroy() {
    document.body.style.background = 'none';

    this.event.isLog = true;

    this.isCas = false;
    this.onHidden();
  }

  onShown() {
    this.event.loadingVisible = true;
  }

  onHidden() {
    this.event.loadingVisible = false;
  }

  onCreateForm() {
    this.logForm = this.formBuilder.group({
      Username: [
        environment.RUNENVIRONMENT === 'Demo' ? 'admin' : '',
        Validators.required,
      ],
      Password: [
        environment.RUNENVIRONMENT === 'Demo' ? 'admin' : '',
        Validators.required,
      ],
      DbHost: [
        environment.DBHOST, Validators.required
      ],
      DBDATABASE:[
        environment.DBDATABASE, Validators.required
      ]
    });
  }

  clearValue = (controls) => {
    if (controls === 'Password') this.logForm.controls.Password.setValue('');
    else this.logForm.controls.Username.setValue('');
  };

  setLanguage = () => {
    return new Promise((resolve) => {
      const language = {
        Language: localStorage.getItem('lang') || 'pl',
      };
      this.appService.postAuth(`configurations/language`, language).subscribe(
        () => {
          resolve(true);
        },
        () => {
          resolve(true);
        }
      );
    });
  };

  strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  isCreateDemo = false;
  onStartDemo() {
    this.isCreateDemo = true;
    this.appService.post(`adminConsole/login-demo`, null).subscribe(
      async (res) => {
        this.appService.headers = this.appService.headers.set(
          'Authorization',
          'Bearer ' + res.Token
        );
        this.event.decodedToken = helper.decodeToken(res.Token);
        this.event.windowCount = 0;
        this.event.increaseWindowCount();
        localStorage.setItem('WAPRO-HTML-token', res.Token);
        localStorage.setItem('demo-version', this.selectedVersion);

        for (let element of this.event.elementsToLoad) {
          switch (element) {
            case 'defaultsParmters':
              await this.event.getDefaultParameters();
              break;
            case 'sessionInformation':
              const result: boolean = await this.event.getSessionInformation();
              this.interconnector.getAuthHeaders();
              if (!result) return;
              break;
            case 'currentCompany':
              await this.event.getCurrentCompany();
              break;
            case 'permissions':
              await this.event.getPermissions();
              break;
            case 'additionalTablesPermissions':
              if(this.event.isAdditionalTableAvailable()){
                await this.event.getAdditionalTablesPermissions();
              }
              break;
            case 'additionalOperationsPermissions':
              await this.event.getAdditionalOperationsPermissions();
              break;
            case 'nests':
              if (this.event.isVersionForNestAvailable) {
                // czy wersja == prestizplus
                await this.event.getNests();
              }
              break;
            case 'tables':
              if(this.event.isAdditionalTableAvailable()){
                await this.event.getTables();
              }
              break;
            case 'companyConfiguration':
              await this.event.getCompanyConfiguration();
              break;
            case 'dynamicFilters':
              await this.event.getDynamicFilters();
              break;
            case 'userConfiguration':
              await this.event.getUserConfiguration();
              break;
            case 'language':
              localStorage.setItem('lang', 'pl');
              this.setLanguage();
              break;
            case 'additionalOperationsGroupCodes':
              await this.event.additionalOperationsGroupCodes();
              break;
            case 'updateCurrencies':
              await this.event.updateCurrencies();
              break;
            case 'nestVariables':
              await this.event.getNestVariables();
              break;
            case 'devices':
              if (this.event.deviceType === 'desktop') {
                this.interconnector.getActiveDevices();
              }
              break;
            case 'invoiceDocumentTypes':
              await this.event.getInvoiceDocumentTypes();
              break;
            case 'emailConfiguration':
              // await this.mailService.getEmailConfiguration();
              this.mailService.listenForEmails();
              break;
            case 'reportsPerDocumentType':
              if (localStorage.getItem('reportsPerDocumentType')) {
                localStorage.removeItem('reportsPerDocumentType');
              }
              break;
            // case 'businessLink':
            //   await this.event.getBusinessLinkConfiguration();
            //   break;
            default:
              break;
          }
        }
        this._route.navigateByUrl('start/dashboard', {
          state: { load: true },
        });
      },
      (error) => {
        this.event.httpErrorNotification(error);
        setTimeout(() => {
          this.isCreateDemo = false;
        }, 0);
      }
    );
  }

  goToShop() {
    window.open(`https://portal.wapro.pl/registration/register-form?prgCode=3&variantName=${this.selectedVersion}`);
  }



  selectLanbguage = 'pl';
  selectLanguage(language) {
    localStorage.setItem('lang', language);
    this.selectLanbguage = language;
    this.isShowLanguage = false;
    this.event.useLanguage(language);
  }

  addCustomItem(e: any) {
    let newItem = e.text;
    this.items.push(newItem);
    e.customItem = newItem;
    localStorage.setItem('dblist', this.event.encryptString(this.items))
  }

  onSubmitForm(event) {
    this.submitted = true;
    // let dateToLog = `${event.Username}:${event.Password}`;
    // dateToLog = btoa(dateToLog);
    
 
    let dateToLog = {
      UserName: this.logForm.value.Username,
      Password: this.logForm.value.Password,
      DbPassword: (environment.RUNENVIRONMENT !== 'OnPremises')?'Abcd123$':null,
      DbUsername: (environment.RUNENVIRONMENT !== 'OnPremises')?'waproanywherelocaldev': null,
      Email: this.logForm.value.Username,
      Language: this.selectLanbguage,
      DbHost: this.logForm.value.DbHost,
      DbDatabase: this.logForm.value.DBDATABASE,
      //DbDatabase: 'ANY_5252776145_Mag',
      //DbDatabase: 'WAPROJPK_7740006210',
    };

    if (this.event.returnEnvironmentInfo() === 'localhost') {
      sessionStorage.setItem(
        'loginData',
        this.event.encryptString(JSON.stringify(dateToLog))
      );
    }

    if (this.logForm.invalid) {
      this.event.onControlValueChanged(
        this.logForm,
        this.formError,
        this.validationMessages
      );
      return;
    } else {
      let uri =
        environment.RUNENVIRONMENT === 'Demo'
          ? 'adminConsole/login-demo'
          : 'adminConsole/login';

      this.isCas = true;
      this.cd.detectChanges();
      this.appService.post(uri, dateToLog).subscribe(
        async (res) => {
          // this.event.loadingVisible = true;
          this.appService.headers = this.appService.headers.set(
            'Authorization',
            'Bearer ' + res.Token
          );
          this.event.decodedToken = helper.decodeToken(res.Token);
          this.event.windowCount = 0;
          this.event.increaseWindowCount();
          localStorage.setItem('WAPRO-HTML-token', res.Token);

          for (let element of this.event.elementsToLoad) {
            switch (element) {
              case 'defaultsParmters':
                await this.event.getDefaultParameters();
                break;
              case 'companyDefaultSettings':
                await this.event.getCompanyDefaultSettings();
                break;
              case 'sessionInformation':
                const result: boolean =
                  await this.event.getSessionInformation();
                this.interconnector.getAuthHeaders();
                if (!result) return;
                break;
              case 'currentCompany':
                await this.event.getCurrentCompany();
                break;
              case 'permissions':
                await this.event.getPermissions();
                break;
              case 'additionalTablesPermissions':
                if(this.event.isAdditionalTableAvailable()){
                  await this.event.getAdditionalTablesPermissions();
                }
                break;
              case 'additionalOperationsPermissions':
                await this.event.getAdditionalOperationsPermissions();
                break;
              // case 'userExtension':
              //   await this.event.getUserExtensionsLogin();
              //   break;
              case 'nests':
                if (this.event.isVersionForNestAvailable) {
                  // czy wersja == prestizplus
                  await this.event.getNests();
                }
                break;
              case 'tables':
              if(this.event.isAdditionalTableAvailable()){
                await this.event.getTables();
              }
                break;
              case 'companyConfiguration':
                await this.event.getCompanyConfiguration();
                break;
              case 'dynamicFilters':
                await this.event.getDynamicFilters();
                break;
              case 'userConfiguration':
                await this.event.getUserConfiguration();
                break;
              case 'language':
                event.Language = localStorage.getItem('lang') || 'pl';
                localStorage.setItem('lang', event.Language);
                this.setLanguage();
                break;
              case 'additionalOperationsGroupCodes':
                await this.event.additionalOperationsGroupCodes();
                break;
              case 'updateCurrencies':
                await this.event.updateCurrencies();
                break;
              case 'nestVariables':
                await this.event.getNestVariables();
                break;
              case 'devices':
                if (this.event.deviceType === 'desktop') {
                  this.interconnector.getActiveDevices();
                }
                break;
              case 'invoiceDocumentTypes':
                await this.event.getInvoiceDocumentTypes();
                break;
              case 'emailConfiguration':
                // await this.mailService.getEmailConfiguration();
                this.mailService.listenForEmails();
                break;
              case 'reportsPerDocumentType':
                if (localStorage.getItem('reportsPerDocumentType')) {
                  localStorage.removeItem('reportsPerDocumentType');
                }
                break;
              // case 'businessLink':
              //   await this.event.getBusinessLinkConfiguration();
              //   break;
              default:
                break;
            }
          }

          this.cd.detectChanges();
          this.event.loadingVisible = false;
          this._route.navigateByUrl(
            this.event.setAppMenu(this.event.footerInfo),
            {
              state: { load: true },
            }
          );

          //setTimeout(() => {
          //this.event.showChooseCompanies.next(true)
          //}, 500);
        },
        (error) => {
          this.event.loadingVisible = false;
          this.isCas = false;
          this.event.httpErrorNotification(error);
        }
      );
    }
  }
}
