<div class="header-submenu-main-menu-mobile">
  <div class="d-flex flex-row">
    <div>
      <div class="btn-header-submenu">
        <i
          class="icon absui-icon--arrow-back-mobile-menu"
          (click)="backToParent()"
        ></i>
      </div>
    </div>
    <div style="padding-top: 7px; width: calc(100vw - 80px)">
      <p class="title-menu-parent">{{ items.name | translate }}</p>
    </div>
    <div>
      <div class="btn-header-submenu">
        <i class="icon absui-icon--close" (click)="onCloseSubmenu()"></i>
      </div>
    </div>
  </div>
</div>

<div class="content-main-submenu-mobile">
  <div *ngFor="let item of items.items">
    <div
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="onItemClickMobile(item)"
      class="mobile-main-submenu"
      [ngClass]="{
        'tap-submenu': isTap == item.id,
        submenuActive: router.isActive(item.url, false) && item.url != null
      }"
      (touchstart)="touchStart(item)"
      (touchend)="touchEnd()"
      *ngIf="
        item.code != 'Detal' &&
        item.url != 'crm/crm-kanban' &&
        item.url != 'crm/service-orders-kanban' &&
        item.code != 'Intrastat' &&
        item.code != 'Sad' &&
        item.code != 'Bil' &&
        item.code != 'Przeceny' &&
        item.url != 'other/bussines-link'
      "
    >
      <p class="title-submenu">{{ item.name | translate }}</p>
      <i
        class="icon absui-icon--arrow-drop-right-user-panel"
        *ngIf="item.items && item.items.length >= 1"
        style="
          position: relative;
          left: 0px;
          top: -38px;
          margin-right: 10px;
          float: right;
        "
      ></i>
    </div>
  </div>

  <div *ngIf="items.id == 1">
    <div>
      <div class="hexagon-red">
        <p>mg</p>
      </div>

      <p
        class="title-submenu"
        style="position: relative; top: 4px; left: -10px"
      >
        sprzedaż i magazyn
      </p>
    </div>
  </div>
</div>
