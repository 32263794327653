import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, isCashId, isExistToken, isPermission } from './auth.guard';
import { LogOnComponent } from './log-on/log-on.component';
import { NestResolverService } from './nest-resolver.service';
import { isLocalhost } from './isLocalhost.guard';

export const routes: Routes = [
  {
    path: '',
    component: LogOnComponent,
    canActivate: [isExistToken],
  },
  {
    path: 'start',
    pathMatch: 'full',
    redirectTo: '/start/dashboard',
  },
  {
    path: 'select-company',
    loadComponent: () =>
      import('src/app/select-component/select-component.component').then(
        (m) => m.SelectComponentComponent
      ),
  },
  {
    path: 'start/dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard, isCashId],
  },
  {
    path: 'start/avp',
    loadComponent: () =>
      import('./start/home/home.component').then((m) => m.HomeComponent),
    canActivate: [AuthGuard, isCashId],
  },
  {
    path: 'warehouse',
    loadChildren: () =>
      import('./warehouse/warehouse.module').then((m) => m.WarehouseModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'trade-operations',
    loadChildren: () =>
      import('./comercial-operation/comercial-operation.module').then(
        (m) => m.ComercialOperationModule
      ),
    canActivate: [AuthGuard, isPermission],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'auctions',
    loadChildren: () =>
      import('./auctions/auctions.module').then((m) => m.AuctionsModule),
    canActivate: [AuthGuard, isPermission, isLocalhost],
  },
  {
    path: 'groups',
    loadChildren: () =>
      import('./groups/groups.module').then((m) => m.GroupsModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'administrator',
    loadChildren: () =>
      import('./administrator/administrator.module').then(
        (m) => m.AdministratorModule
      ),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'library',
    loadChildren: () =>
      import('./libraries/libraries.module').then((m) => m.LibrariesModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'crm',
    loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'jpk',
    loadChildren: () => import('./jpk/jpk.module').then((m) => m.JpkModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'fk',
    loadChildren: () => import('./fk/fk.module').then((m) => m.FkModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'gdpr',
    loadChildren: () =>
      import('./gdpr/gdpr/gdpr.module').then((m) => m.GdprModule),
    canActivate: [AuthGuard, isPermission],
  },
  {
    path: 'changes',
    loadChildren: () =>
      import('./gdpr/changes/changes.module').then((m) => m.ChangesModule),
    canActivate: [AuthGuard, isPermission],
  },
  {
    path: 'definitions',
    loadChildren: () =>
      import('./gdpr/definitions/definitions.module').then(
        (m) => m.DefinitionsModule
      ),
    canActivate: [AuthGuard, isPermission],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./libraries/libraries.module').then((m) => m.LibrariesModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'other',
    loadChildren: () =>
      import('./other/other.module').then((m) => m.OtherModule),
    canActivate: [AuthGuard, isPermission, isCashId],
    resolve: { nests: NestResolverService },
  },
  {
    path: 'no-permission',
    loadComponent: () =>
      import('src/app/no-found/no-found.component').then(
        (m) => m.NoFoundComponent
      ),
  },
  {
    path: 'no-access',
    loadComponent: () =>
      import('src/app/no-acces/no-acces.component').then(
        (m) => m.NoAccesComponent
      ),
  },
  {
    path: 'print-config',
    loadComponent: () =>
      import('src/app/prints-config/prints-config.component').then(
        (m) => m.PrintsConfigComponent
      ),
    canActivate: [isLocalhost],
  },
  {
    path: 'dev/svg-list',
    loadComponent: () =>
      import('src/app/dev/svg-list/svg-list.component').then(
        (m) => m.SvgListComponent
      ),

    canActivate: [AuthGuard, isLocalhost],
  },
  {
    path: 'no-permission-to-warehouse',
    loadComponent: () => {
      return import(
        'src/app/no-permission-to-warehouse/no-permission-to-warehouse.component'
      ).then((m) => m.NoPermissionToWarehouseComponent);
    },
    canActivate: [AuthGuard, isPermission, isCashId],
  },
  {
    path: '**',
    loadComponent: () =>
      import('src/app/no-found/no-found.component').then(
        (m) => m.NoFoundComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
