import {
  ChangeDetectionStrategy,
  Component,
  Output,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '../../event.service';

import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { SubmenuShowMode } from 'devextreme/common';

@Component({
    selector: 'app-version-selected',
    imports: [
        CommonModule,
        DxPopupModule,
        TranslateModule,
        DxSelectBoxModule,
        DxButtonModule,
        DxScrollViewModule,
        DxMenuModule,
    ],
    templateUrl: './version-selected.component.html',
    styleUrl: './version-selected.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['title', 'isVisible']
})
export class VersionSelectedComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  width = 600;

  title;
  isVisible: boolean = false;
  unicalGuid;

  isSubmenuItemClick: boolean = false;

  versionList: string[] = [];
  selectedVersion: string = '365 START';

  showSubmenuModes = [
    {
      name: 'onHover' as SubmenuShowMode,
      delay: { show: 100, hide: 100 },
    },
    {
      name: 'onClick' as SubmenuShowMode,
      delay: { show: 0, hide: 300 },
    },
  ];

  menu = [
    {
      icon: 'icon absui-icon--set-settings',
      name: 'PRESTIŻ PLUS',
      id: 1,
      items: [
        {
          name: 'FAKTUROWANIE',
          icon: 'icon absui-icon--select-one-outline',
          node: 1,
          id: 1.1,
          items: [],
          description:
            'Dla małej firmy, do obsługi faktur z dwoma użytkownikami.',
        },
        {
          name: 'FAKTUROWANIE PRO',
          icon: 'icon absui-icon--select-inverse-outline',
          node: 1,
          id: 1.2,
          items: [],
          description:
            'Dla średnich i dużych firm, do obsługi faktur i wielu użytkowników.',
        },
        {
          name: 'START',
          icon: 'icon absui-icon--select-one',
          node: 1,
          id: 1.3,
          items: [],
          description:
            'Przeznaczony dla małej firmy. Aplikacja pracuje z jednym magazynem i jednym użytkownikiem.',
        },
        {
          name: 'BIZNES',
          icon: 'icon absui-icon--select-inverse',
          node: 1,
          id: 1.4,
          items: [],
          description:
            'Przeznaczony dla średnich firm, z możliwością obsługi wielu magazynów i wielu użytkowników.',
        },
        {
          name: 'PRESTIŻ',
          icon: 'icon absui-icon--select-triple-left',
          node: 1,
          id: 1.5,
          items: [],
          description:
            'Przeznaczony dla średnich firm i dużych firm, realizujących skomplikowane procesy<br>a także wymagających wielowalutowości lub produkcji.',
        },
        {
          name: 'PRESTIŻ PLUS',
          icon: 'icon absui-icon--select-all',
          node: 1,
          id: 1.6,
          items: [],
          description:
            'Przeznaczony dla najbardziej wymagających firm. Pozwala na dedykowane rozbudowy<br>funkcjonalności programu pod potrzeby klienta.',
        },
      ],
      node: 0,
    },
  ];

  constructor(
    public translateService: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.versionList = [
      '365 START',
      '365 BIZNES',
      '365 PRESTIŻ',
      '365 PRESTIŻ PLUS',
      '365 BIURO',
      'PRESTIŻ',
      'PRESTIŻ PLUS',
    ];
  }

  onItemClick(e) {
    if (e.itemData.node === 0) return;
    localStorage.setItem('demo-version', e.itemData.name);
    this.event.footerInfo.VariantName = e.itemData.name;
    localStorage.setItem(
      'sessionInfoWaproHtml',
      this.event.encryptString(this.event.footerInfo)
    );
    this.event.dontLogOut = true;
    location.reload();
  }

  ngModelChange(e) {
    if (!e) {
      this.onClosing.emit();
    }
  }

  onSave() {
    this.onInserted.emit(this.selectedVersion);
  }

  itemChosed: any = [];
  isMenuMobile: boolean = false;
  onItemClickMobile(e) {
    if (e.itemData && e.itemData.items && e.itemData.items.length > 0) {
      this.isSubmenuItemClick = true;
      this.itemChosed = e.itemData;
      return;
    }

    this.onItemClick({ itemData: e });
  }

  isTap: any = null;
  touchStart = (e) => {
    this.isTap = e.id;
  };

  touchEnd = () => {
    this.isTap = null;
  };

  backToParent() {
    this.isSubmenuItemClick = false;
    this.cd.detectChanges();
  }
}
