<ng-container [ngSwitch]="type">
  <app-wapro-pl-svg *ngSwitchCase="'waproPl'"></app-wapro-pl-svg>
  <app-brand-wapro-anywhere-svg
    *ngSwitchCase="'anywhere'"
  ></app-brand-wapro-anywhere-svg>
  <app-mag-svg *ngSwitchCase="'mg'"></app-mag-svg>
  <app-wapro-pl-svg *ngSwitchDefault></app-wapro-pl-svg>
</ng-container>

<!-- <ng-container *ngIf="type === 'mg'">
  <svg
    width="193"
    height="32"
    viewBox="0 0 193 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    [class]="svgClass"
  >
    <g clip-path="url(#clip0_420_3109)">
      <path d="M10 0H0V10H10V0Z" fill="#EA494C" />
      <path d="M21 0H11V10H21V0Z" fill="#E8373A" />
      <path d="M10 11H0V21H10V11Z" fill="#E8373A" />
      <path d="M10 32H7.5C3.36 32 0 28.64 0 24.5V22H10V32Z" fill="#E42B2E" />
      <path d="M32 10H10V32H32V10Z" fill="#DC1E28" />
      <path d="M32 10H22V0H24.5C28.64 0 32 3.36 32 7.5V10Z" fill="#E42B2E" />
      <path d="M11 0H10V10H11V0Z" fill="#E53335" />
      <path d="M0 10L0 11L10 11L10 10L0 10Z" fill="#E53335" />
      <path d="M0 21L0 22L10 22L10 21L0 21Z" fill="#E53335" />
      <path d="M22 0H21V10H22V0Z" fill="#E53335" />
      <path
        d="M21.817 20.8601V25.0001H20.161V21.2441C20.161 20.8761 20.073 20.5881 19.897 20.3801C19.721 20.1641 19.481 20.0561 19.177 20.0561C18.873 20.0561 18.625 20.1641 18.433 20.3801C18.249 20.5881 18.157 20.8801 18.157 21.2561V25.0001H16.501V21.1841C16.501 20.8321 16.413 20.5561 16.237 20.3561C16.069 20.1561 15.833 20.0561 15.529 20.0561C15.225 20.0561 14.977 20.1641 14.785 20.3801C14.601 20.5881 14.509 20.8761 14.509 21.2441V25.0001H12.853V18.7241H14.473V19.4561C14.601 19.2081 14.805 19.0001 15.085 18.8321C15.373 18.6561 15.713 18.5681 16.105 18.5681C16.897 18.5681 17.469 18.8801 17.821 19.5041C18.221 18.8801 18.841 18.5681 19.681 18.5681C20.377 18.5681 20.905 18.7761 21.265 19.1921C21.633 19.6081 21.817 20.1641 21.817 20.8601Z"
        fill="#A2161E"
      />
      <path
        d="M23.5161 26.7281L24.0201 25.4561C24.5641 25.7441 25.1601 25.8881 25.8081 25.8881C26.9601 25.8881 27.5361 25.4721 27.5361 24.6401V24.3521C27.4721 24.4401 27.3761 24.5401 27.2481 24.6521C27.1201 24.7561 26.9121 24.8681 26.6241 24.9881C26.3361 25.1001 26.0281 25.1561 25.7001 25.1561C24.7961 25.1561 24.0681 24.8521 23.5161 24.2441C22.9721 23.6361 22.7001 22.8441 22.7001 21.8681C22.7001 20.9001 22.9841 20.1081 23.5521 19.4921C24.1281 18.8761 24.8641 18.5681 25.7601 18.5681C26.2241 18.5681 26.6161 18.6601 26.9361 18.8441C27.2641 19.0201 27.4961 19.2001 27.6321 19.3841V18.7241H29.1921V24.2081C29.1921 25.3041 28.8841 26.0921 28.2681 26.5721C27.6521 27.0601 26.8361 27.3041 25.8201 27.3041C24.9801 27.3041 24.2121 27.1121 23.5161 26.7281ZM27.5841 21.8561C27.5841 21.3201 27.4321 20.8841 27.1281 20.5481C26.8321 20.2041 26.4441 20.0321 25.9641 20.0321C25.4921 20.0321 25.1081 20.2001 24.8121 20.5361C24.5161 20.8721 24.3681 21.3121 24.3681 21.8561C24.3681 22.4001 24.5161 22.8441 24.8121 23.1881C25.1161 23.5241 25.5041 23.6921 25.9761 23.6921C26.4561 23.6921 26.8441 23.5241 27.1401 23.1881C27.4361 22.8521 27.5841 22.4081 27.5841 21.8561Z"
        fill="#A2161E"
      />
      <path
        d="M21.817 19.8601V24.0001H20.161V20.2441C20.161 19.8761 20.073 19.5881 19.897 19.3801C19.721 19.1641 19.481 19.0561 19.177 19.0561C18.873 19.0561 18.625 19.1641 18.433 19.3801C18.249 19.5881 18.157 19.8801 18.157 20.2561V24.0001H16.501V20.1841C16.501 19.8321 16.413 19.5561 16.237 19.3561C16.069 19.1561 15.833 19.0561 15.529 19.0561C15.225 19.0561 14.977 19.1641 14.785 19.3801C14.601 19.5881 14.509 19.8761 14.509 20.2441V24.0001H12.853V17.7241H14.473V18.4561C14.601 18.2081 14.805 18.0001 15.085 17.8321C15.373 17.6561 15.713 17.5681 16.105 17.5681C16.897 17.5681 17.469 17.8801 17.821 18.5041C18.221 17.8801 18.841 17.5681 19.681 17.5681C20.377 17.5681 20.905 17.7761 21.265 18.1921C21.633 18.6081 21.817 19.1641 21.817 19.8601Z"
        fill="white"
      />
      <path
        d="M23.5161 25.7281L24.0201 24.4561C24.5641 24.7441 25.1601 24.8881 25.8081 24.8881C26.9601 24.8881 27.5361 24.4721 27.5361 23.6401V23.3521C27.4721 23.4401 27.3761 23.5401 27.2481 23.6521C27.1201 23.7561 26.9121 23.8681 26.6241 23.9881C26.3361 24.1001 26.0281 24.1561 25.7001 24.1561C24.7961 24.1561 24.0681 23.8521 23.5161 23.2441C22.9721 22.6361 22.7001 21.8441 22.7001 20.8681C22.7001 19.9001 22.9841 19.1081 23.5521 18.4921C24.1281 17.8761 24.8641 17.5681 25.7601 17.5681C26.2241 17.5681 26.6161 17.6601 26.9361 17.8441C27.2641 18.0201 27.4961 18.2001 27.6321 18.3841V17.7241H29.1921V23.2081C29.1921 24.3041 28.8841 25.0921 28.2681 25.5721C27.6521 26.0601 26.8361 26.3041 25.8201 26.3041C24.9801 26.3041 24.2121 26.1121 23.5161 25.7281ZM27.5841 20.8561C27.5841 20.3201 27.4321 19.8841 27.1281 19.5481C26.8321 19.2041 26.4441 19.0321 25.9641 19.0321C25.4921 19.0321 25.1081 19.2001 24.8121 19.5361C24.5161 19.8721 24.3681 20.3121 24.3681 20.8561C24.3681 21.4001 24.5161 21.8441 24.8121 22.1881C25.1161 22.5241 25.5041 22.6921 25.9761 22.6921C26.4561 22.6921 26.8441 22.5241 27.1401 22.1881C27.4361 21.8521 27.5841 21.4081 27.5841 20.8561Z"
        fill="white"
      />
    </g>
    <path
      d="M44.4082 10.953C44.8502 11.752 45.8022 12.721 47.6042 12.721C49.4232 12.721 50.8172 11.65 50.8172 10.001C50.8172 6.87299 46.4822 7.58699 46.4822 5.88698C46.4822 5.27499 46.9922 4.91798 47.6722 4.91798C48.6582 4.91798 49.1342 5.49598 49.3892 5.90399L50.6642 5.05398C50.3242 4.37398 49.4402 3.40498 47.7742 3.40498C46.0572 3.40498 44.7312 4.35698 44.7312 5.92098C44.7312 8.99798 49.0492 8.38599 49.0492 10.086C49.0492 10.8 48.4712 11.191 47.6892 11.191C46.5842 11.191 45.9722 10.63 45.5642 10.035L44.4082 10.953Z"
      fill="#30393F"
    />
    <path
      d="M52.2755 15.679H54.1285V11.327C54.3665 11.701 55.3185 12.721 57.0015 12.721C59.5345 12.721 61.2175 10.8 61.2175 8.04598C61.2175 5.34298 59.4155 3.40498 56.8825 3.40498C55.1655 3.40498 54.2645 4.47598 54.0265 4.79899V3.62599H52.2755V15.679ZM54.0605 8.04598C54.0605 6.41399 55.0635 5.05398 56.6955 5.05398C58.2085 5.05398 59.3475 6.22698 59.3475 8.04598C59.3475 9.83099 58.2595 11.072 56.7125 11.072C55.2335 11.072 54.0605 9.96698 54.0605 8.04598Z"
      fill="#30393F"
    />
    <path
      d="M67.1197 3.40498C65.8617 3.40498 64.9267 4.13598 64.5867 5.01998V3.62599H62.7677V12.5H64.6207V8.67499C64.6207 6.75399 65.1817 5.27498 67.1197 5.27498V3.40498Z"
      fill="#30393F"
    />
    <path
      d="M68.1342 12.5H74.9852V10.953H70.3952L74.9172 4.84999V3.62599H68.2702V5.18999H72.6732L68.1342 11.293V12.5Z"
      fill="#30393F"
    />
    <path
      d="M75.7875 8.09699C75.7875 10.783 77.6235 12.721 80.3945 12.721C82.5025 12.721 83.6755 11.514 84.1005 10.783L82.8765 9.86498C82.5365 10.358 81.7715 11.106 80.4285 11.106C78.8475 11.106 77.7085 10.086 77.6745 8.50499H84.3555C84.3725 8.35199 84.3725 8.19898 84.3725 8.04598C84.3725 5.25798 82.7915 3.40498 80.1055 3.40498C77.6745 3.40498 75.7875 5.34299 75.7875 8.09699ZM77.7425 7.22999C77.8615 5.83599 78.8475 4.95198 80.1225 4.95198C81.3975 4.95198 82.4175 5.76798 82.4855 7.22999H77.7425Z"
      fill="#30393F"
    />
    <path
      d="M94.2584 0.208984H92.4054V4.81598C92.1674 4.42498 91.2154 3.40498 89.5324 3.40498C86.9994 3.40498 85.3164 5.34299 85.3164 8.07999C85.3164 10.8 87.1184 12.721 89.6514 12.721C91.3684 12.721 92.2694 11.65 92.5074 11.344V12.5H94.2584V0.208984ZM92.4734 8.09699C92.4734 9.72898 91.4704 11.089 89.8384 11.089C88.3254 11.089 87.1864 9.91599 87.1864 8.09699C87.1864 6.31199 88.2744 5.05398 89.8214 5.05398C91.3004 5.05398 92.4734 6.15899 92.4734 8.09699Z"
      fill="#30393F"
    />
    <path
      d="M95.792 9.76299C95.792 11.548 97.271 12.721 99.141 12.721C100.246 12.721 100.96 12.347 101.419 11.837L101.572 12.5H103.187V6.77099C103.187 4.83298 102.286 3.40498 99.651 3.40498C98.172 3.40498 96.982 3.96598 96.268 4.42498L96.982 5.71698C97.628 5.35998 98.478 5.01998 99.464 5.01998C100.637 5.01998 101.351 5.63199 101.351 6.65199V7.48498C101.011 7.17898 100.246 6.83898 99.226 6.83898C97.186 6.83898 95.792 8.04599 95.792 9.76299ZM97.645 9.71198C97.645 8.82798 98.393 8.18199 99.515 8.18199C100.637 8.18199 101.402 8.77698 101.402 9.71198C101.402 10.63 100.62 11.242 99.532 11.242C98.41 11.242 97.645 10.596 97.645 9.71198Z"
      fill="#30393F"
    />
    <path
      d="M104.608 12.5H111.459V10.953H106.869L111.391 4.84999V3.62599H104.744V5.18999H109.147L104.608 11.293V12.5ZM106.954 1.43298C106.954 2.07898 107.464 2.58899 108.11 2.58899C108.756 2.58899 109.266 2.07898 109.266 1.43298C109.266 0.786985 108.756 0.276985 108.11 0.276985C107.464 0.276985 106.954 0.786985 106.954 1.43298Z"
      fill="#30393F"
    />
    <path
      d="M117.304 12.5H119.157V3.62599H117.304V12.5ZM117.066 1.43298C117.066 2.07898 117.576 2.58899 118.222 2.58899C118.868 2.58899 119.378 2.07898 119.378 1.43298C119.378 0.786985 118.868 0.276985 118.222 0.276985C117.576 0.276985 117.066 0.786985 117.066 1.43298Z"
      fill="#30393F"
    />
    <path
      d="M138.034 6.70298C138.034 4.78198 137.014 3.40498 135.008 3.40498C133.784 3.40498 132.798 3.94898 132.271 4.88399C131.846 3.98298 130.962 3.40498 129.721 3.40498C128.514 3.40498 127.647 4.10198 127.324 4.78198C127.324 4.78198 127.324 4.37399 127.324 3.62599H125.505V12.5H127.358V7.16198C127.358 5.88698 128.14 5.07099 129.143 5.07099C130.197 5.07099 130.843 5.80198 130.843 7.00899V12.5H132.696V7.19599C132.696 5.83599 133.461 5.07099 134.498 5.07099C135.603 5.07099 136.198 5.80198 136.198 7.17898V12.5H138.034V6.70298Z"
      fill="#30393F"
    />
    <path
      d="M139.504 9.76299C139.504 11.548 140.983 12.721 142.853 12.721C143.958 12.721 144.672 12.347 145.131 11.837L145.284 12.5H146.899V6.77099C146.899 4.83298 145.998 3.40498 143.363 3.40498C141.884 3.40498 140.694 3.96598 139.98 4.42498L140.694 5.71698C141.34 5.35998 142.19 5.01998 143.176 5.01998C144.349 5.01998 145.063 5.63199 145.063 6.65199V7.48498C144.723 7.17898 143.958 6.83898 142.938 6.83898C140.898 6.83898 139.504 8.04599 139.504 9.76299ZM141.357 9.71198C141.357 8.82798 142.105 8.18199 143.227 8.18199C144.349 8.18199 145.114 8.77698 145.114 9.71198C145.114 10.63 144.332 11.242 143.244 11.242C142.122 11.242 141.357 10.596 141.357 9.71198Z"
      fill="#30393F"
    />
    <path
      d="M149.408 14.914C150.19 15.373 151.431 15.764 152.621 15.764C155.205 15.764 157.228 14.659 157.228 11.327V3.62599H155.477V4.81598C155.205 4.40798 154.253 3.40498 152.587 3.40498C150.071 3.40498 148.303 5.34299 148.303 8.07999C148.303 10.8 150.003 12.721 152.553 12.721C154.304 12.721 155.188 11.633 155.392 11.344V11.939C155.392 13.622 154.083 14.166 152.604 14.166C151.363 14.166 150.326 13.673 150.003 13.486L149.408 14.914ZM155.443 8.06298C155.443 9.81398 154.406 11.072 152.808 11.072C151.295 11.072 150.156 9.88198 150.156 8.06298C150.156 6.27798 151.261 5.05398 152.791 5.05398C154.321 5.05398 155.443 6.24398 155.443 8.06298Z"
      fill="#30393F"
    />
    <path
      d="M158.695 9.76299C158.695 11.548 160.174 12.721 162.044 12.721C163.149 12.721 163.863 12.347 164.322 11.837L164.475 12.5H166.09V6.77099C166.09 4.83298 165.189 3.40498 162.554 3.40498C161.075 3.40498 159.885 3.96598 159.171 4.42498L159.885 5.71698C160.531 5.35998 161.381 5.01998 162.367 5.01998C163.54 5.01998 164.254 5.63199 164.254 6.65199V7.48498C163.914 7.17898 163.149 6.83898 162.129 6.83898C160.089 6.83898 158.695 8.04599 158.695 9.76299ZM160.548 9.71198C160.548 8.82798 161.296 8.18199 162.418 8.18199C163.54 8.18199 164.305 8.77698 164.305 9.71198C164.305 10.63 163.523 11.242 162.435 11.242C161.313 11.242 160.548 10.596 160.548 9.71198Z"
      fill="#30393F"
    />
    <path
      d="M167.511 12.5H174.362V10.953H169.772L174.294 4.84999V3.62599H167.647V5.18999H172.05L167.511 11.293V12.5Z"
      fill="#30393F"
    />
    <path
      d="M179.052 15.543L183.693 3.62599H181.755L179.392 10.086L176.995 3.62599H175.006L178.525 12.347L177.267 15.543H179.052Z"
      fill="#30393F"
    />
    <path
      d="M189.174 3.40498C187.984 3.40498 187.066 4.08498 186.675 4.78198V3.62599H184.856V12.5H186.709V7.14498C186.709 5.93798 187.474 5.07099 188.647 5.07099C189.667 5.07099 190.432 5.78499 190.432 7.00899V12.5H192.268V7.16198C192.268 5.22398 191.469 3.40498 189.174 3.40498Z"
      fill="#30393F"
    />
    <path
      d="M50.8638 29.5H52.4368L54.5948 22.714H53.0608L51.5658 27.914C51.5658 27.914 51.5528 27.888 50.0188 22.714H48.6668C47.1198 27.888 47.1068 27.914 47.1068 27.914L45.6248 22.714H44.0908L46.2488 29.5H47.8088L49.3428 24.638L50.8638 29.5Z"
      fill="#30393F"
    />
    <path
      d="M55.0663 27.407C55.0663 28.772 56.1973 29.669 57.6273 29.669C58.4723 29.669 59.0183 29.383 59.3693 28.993L59.4863 29.5H60.7213V25.119C60.7213 23.637 60.0323 22.545 58.0173 22.545C56.8863 22.545 55.9763 22.974 55.4303 23.325L55.9763 24.313C56.4703 24.04 57.1203 23.78 57.8743 23.78C58.7713 23.78 59.3173 24.248 59.3173 25.028V25.665C59.0573 25.431 58.4723 25.171 57.6923 25.171C56.1323 25.171 55.0663 26.094 55.0663 27.407ZM56.4833 27.368C56.4833 26.692 57.0553 26.198 57.9133 26.198C58.7713 26.198 59.3563 26.653 59.3563 27.368C59.3563 28.07 58.7583 28.538 57.9263 28.538C57.0683 28.538 56.4833 28.044 56.4833 27.368Z"
      fill="#30393F"
    />
    <path
      d="M62.2108 31.931H63.6278V28.603C63.8098 28.889 64.5378 29.669 65.8248 29.669C67.7618 29.669 69.0488 28.2 69.0488 26.094C69.0488 24.027 67.6708 22.545 65.7338 22.545C64.4208 22.545 63.7318 23.364 63.5498 23.611V22.714H62.2108V31.931ZM63.5758 26.094C63.5758 24.846 64.3428 23.806 65.5908 23.806C66.7478 23.806 67.6188 24.703 67.6188 26.094C67.6188 27.459 66.7868 28.408 65.6038 28.408C64.4728 28.408 63.5758 27.563 63.5758 26.094Z"
      fill="#30393F"
    />
    <path
      d="M73.5622 22.545C72.6002 22.545 71.8852 23.104 71.6252 23.78V22.714H70.2342V29.5H71.6512V26.575C71.6512 25.106 72.0802 23.975 73.5622 23.975V22.545Z"
      fill="#30393F"
    />
    <path
      d="M75.5012 26.12C75.5012 24.794 76.3852 23.806 77.6592 23.806C78.9202 23.806 79.8432 24.781 79.8432 26.107C79.8432 27.433 78.9202 28.408 77.6592 28.408C76.3982 28.408 75.5012 27.459 75.5012 26.12ZM74.0712 26.12C74.0712 28.174 75.5792 29.669 77.6592 29.669C79.7262 29.669 81.2602 28.148 81.2602 26.107C81.2602 24.066 79.7522 22.545 77.6592 22.545C75.6182 22.545 74.0712 24.066 74.0712 26.12Z"
      fill="#30393F"
    />
    <path
      d="M95.2783 25.067C95.2783 23.598 94.4983 22.545 92.9643 22.545C92.0283 22.545 91.2743 22.961 90.8713 23.676C90.5463 22.987 89.8703 22.545 88.9213 22.545C87.9983 22.545 87.3353 23.078 87.0883 23.598C87.0883 23.598 87.0883 23.286 87.0883 22.714H85.6973V29.5H87.1143V25.418C87.1143 24.443 87.7123 23.819 88.4793 23.819C89.2853 23.819 89.7793 24.378 89.7793 25.301V29.5H91.1963V25.444C91.1963 24.404 91.7813 23.819 92.5743 23.819C93.4193 23.819 93.8743 24.378 93.8743 25.431V29.5H95.2783V25.067Z"
      fill="#DC1E28"
    />
    <path
      d="M96.4024 27.407C96.4024 28.772 97.5334 29.669 98.9634 29.669C99.8084 29.669 100.354 29.383 100.705 28.993L100.822 29.5H102.057V25.119C102.057 23.637 101.368 22.545 99.3534 22.545C98.2224 22.545 97.3124 22.974 96.7664 23.325L97.3124 24.313C97.8064 24.04 98.4564 23.78 99.2104 23.78C100.107 23.78 100.653 24.248 100.653 25.028V25.665C100.393 25.431 99.8084 25.171 99.0284 25.171C97.4684 25.171 96.4024 26.094 96.4024 27.407ZM97.8194 27.368C97.8194 26.692 98.3914 26.198 99.2494 26.198C100.107 26.198 100.692 26.653 100.692 27.368C100.692 28.07 100.094 28.538 99.2624 28.538C98.4044 28.538 97.8194 28.044 97.8194 27.368Z"
      fill="#DC1E28"
    />
    <path
      d="M103.976 31.346C104.574 31.697 105.523 31.996 106.433 31.996C108.409 31.996 109.956 31.151 109.956 28.603V22.714H108.617V23.624C108.409 23.312 107.681 22.545 106.407 22.545C104.483 22.545 103.131 24.027 103.131 26.12C103.131 28.2 104.431 29.669 106.381 29.669C107.72 29.669 108.396 28.837 108.552 28.616V29.071C108.552 30.358 107.551 30.774 106.42 30.774C105.471 30.774 104.678 30.397 104.431 30.254L103.976 31.346ZM108.591 26.107C108.591 27.446 107.798 28.408 106.576 28.408C105.419 28.408 104.548 27.498 104.548 26.107C104.548 24.742 105.393 23.806 106.563 23.806C107.733 23.806 108.591 24.716 108.591 26.107Z"
      fill="#DC1E28"
    />
    <defs>
      <clipPath id="clip0_420_3109">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</ng-container> -->
