import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  AfterViewInit,
  inject,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  computed,
  Signal,
  HostListener,
} from '@angular/core';
import { EventService } from '../../../event.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AppServices } from 'src/app/app-services.service';
import { DatePipe } from '@angular/common';
import { EmailAttachmentComponent } from '../email-attachment/email-attachment.component';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';

import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDropDownButtonModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxHtmlEditorModule,
  DxFileUploaderModule,
  DxTabPanelModule,
  DxDataGridModule,
  DxDataGridComponent,
  DxHtmlEditorComponent,
  DxTextBoxComponent,
  DxScrollViewComponent,
} from 'devextreme-angular';
import { PrintService } from 'src/app/print.service';
import { ChoosedFilterValue, EmailData } from 'src/app/event.model';
import { Subscription } from 'rxjs';
import { TasksService } from '../../tasks/tasks.service';
import { NewTaskComponent } from '../../tasks/new-task/new-task.component';
import { DynamicComponent } from 'src/app/core/dynamic-component/dynamic-component.component';
import { EmailService } from '../email.service';
import { CustomChipsButtonComponent } from 'src/app/core/custom-chips-button/custom-chips-button.component';
import { CoreModule } from 'src/app/core/core.module';
import {
  ContentReadyEvent as GridContentReadyEvent,
  FocusedRowChangedEvent,
  KeyDownEvent,
  SelectionChangedEvent as TabSelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import { DynamicConfirmDialogBehaviorSubject } from 'src/app/app.component';
import { ClosedEvent, ItemClickEvent } from 'devextreme/ui/select_box';
import { ConfirmDialogConfig } from 'src/app/core/confirm-dialog/confirm-dialog.model';
import { ResizeEndEvent, ShownEvent } from 'devextreme/ui/popup';
import {
  ContentReadyEvent,
  FocusInEvent,
  ValueChangedEvent as HtmlEditorValueChangedEvent,
  InitializedEvent,
} from 'devextreme/ui/html_editor';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from 'src/app/core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { SelectionChangedEvent } from 'devextreme/ui/tab_panel';
import { CustomerEmailContact } from '../interfaces/email-bulk';
import { MobilePopupHeaderComponent } from 'src/app/core/mobile-popup-header/mobile-popup-header.component';
@Component({
  selector: 'app-email-serial-mail',
  imports: [
    CoreModule,
    DxButtonModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxAccordionModule,
    DxSelectBoxModule,
    DxScrollViewModule,
    DxTooltipModule,
    DxDropDownButtonModule,
    DxPopupModule,
    DynamicComponent,
    TranslateModule,
    CommonModule,
    DxHtmlEditorModule,
    ReactiveFormsModule,
    EmailAttachmentComponent,
    DxFileUploaderModule,
    DxTabPanelModule,
    DxDataGridModule,
    CustomChipsButtonComponent,
    NgShortcutsComponent,
    MobilePopupHeaderComponent,
  ],
  templateUrl: './email-serial-mail.component.html',
  styleUrls: ['./email-serial-mail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: [
    'isVisible',
    'configuration',
    'emailData',
    'emailTo',
    'subject',
    'message',
    'attachment',
    'private',
    ]
})
export class EmailSerialMailComponent implements OnInit, AfterViewInit {
  @Output() onSaving = new EventEmitter();
  @Output() onSending = new EventEmitter();
  @Output() onClosing = new EventEmitter();
  @ViewChild('contactsGrid') contactsGrid: DxDataGridComponent;
  @ViewChild('htmlEditor') htmlEditor: DxHtmlEditorComponent;
  @ViewChild('fileuploader') fileuploaderRef;
  @ViewChild('toDiv') toDiv: ElementRef<HTMLDivElement>;
  @ViewChild('toField') toField: DxTextBoxComponent;
  @ViewChild('subjectDiv') subjectDiv: ElementRef<HTMLDivElement>;
  @ViewChild('subjectFied') subjectFied: DxTextBoxComponent;
  @ViewChild('attachmentDiv') attachmentDiv: ElementRef<HTMLDivElement>;
  @ViewChild('scrollView') scrollView: ElementRef<DxScrollViewComponent>;
  @ViewChild('htmlEditorFooterDiv')
  htmlEditorFooterDiv: ElementRef<HTMLDivElement>;
  @ViewChild('filtersDiv') filtersDiv: ElementRef<HTMLDivElement>;
  isConfirmDialogVisible: boolean;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (
      event.key === 'Escape' &&
      !this.sendOrSave &&
      !this.isConfirmDialogVisible
    )
      this.onCloseForm();
  }

  translate = inject(TranslateService);
  event = inject(EventService);
  printService = inject(PrintService);

  form: FormGroup;
  htmlEditorHeight = 390;
  widthWindow = '75%';
  heightWindow = '90%';
  fullScreen: boolean = false;
  unicalGuid;
  emailMessageTitle: string = '';
  isVisible;
  sendOrSave: boolean = false;
  scrollPosition = 0;
  configuration;
  emailTo;
  emailData: EmailData;
  subject;
  message;
  attachment;
  attachments = [];
  private;
  attachmentTitle: string = '';
  readOnly: boolean = false;
  isOpenUpload: boolean = false;
  addCrmTaskAfterSending: boolean = false;

  isDynamicPopupVisible: boolean = false;
  dynamicComponent: any = null;
  dynamicComponentExtraData: any = null;
  dynamicComponentFilterId: string = 'print-report';
  popupSub: Subscription = null;
  crmTaskComponent = NewTaskComponent;
  tasksSrvc = inject(TasksService);
  selectedtabIndex = 0;
  heightGrid;
  focusedRowIndex;
  escapeChar = ['<', '>'];
  variableAcceptedValues = [
    { value: 'Kontakt.Imię', label: 'Imie', escapeChar: this.escapeChar },
    {
      value: 'Kontakt.Nazwisko',
      label: 'Nazwisko',
      escapeChar: this.escapeChar,
    },
    { value: 'Kontakt.Tytuł', label: 'Tytuł', escapeChar: this.escapeChar },
    {
      value: 'Kontakt.Stanowisko',
      label: 'Stanowisko',
      escapeChar: this.escapeChar,
    },
    {
      value: 'Kontakt.e-mail',
      label: 'Adres e-mail',
      escapeChar: this.escapeChar,
    },
    {
      value: 'Kontrahent.Nazwa',
      label: 'Nazwa kontrahenta',
      escapeChar: this.escapeChar,
    },
    {
      value: 'Kontrahent.Nazwa pełna',
      label: 'Nazwa pełna kontrahenta',
      escapeChar: this.escapeChar,
    },
  ];
  variableDataSource = [
    {
      items: [
        { value: 'Kontakt.Imię', label: 'Imię', escapeChar: this.escapeChar },
        {
          value: 'Kontakt.Nazwisko',
          label: 'Nazwisko',
          escapeChar: this.escapeChar,
        },
        { value: 'Kontakt.Tytuł', label: 'Tytuł', escapeChar: this.escapeChar },
        {
          value: 'Kontakt.Stanowisko',
          label: 'Stanowisko',
          escapeChar: this.escapeChar,
        },
        {
          value: 'Kontakt.e-mail',
          label: 'Adres e-mail',
          escapeChar: this.escapeChar,
        },
      ],
    },
    {
      items: [
        {
          value: 'Kontrahent.Nazwa',
          label: 'Nazwa kontrahenta',
          escapeChar: this.escapeChar,
        },
        {
          value: 'Kontrahent.Nazwa pełna',
          label: 'Nazwa pełna kontrahenta',
          escapeChar: this.escapeChar,
        },
      ],
    },
  ];
  isVariableDropdownOpened: boolean = false;
  variableOptions = {
    dataSource: this.variableDataSource,
    grouped: true,
    height: 26,
    stylingMode: 'outlined',
    displayExpr: 'label',
    valueExpr: 'value',
    placeholder: 'Wstaw zmienną',
    dropDownOptions: { wrapperAttr: { class: 'no-checkbox' } },
    tabIndex: -1,
    // elementAttr: { id: 'elementId', class: 'dx-button dx-button-default ' },
    // opened: this.isVariableDropdownOpened,
    // onClosed: (e) => {
    // },
    onItemClick: (e: ItemClickEvent) => this.onVariableItemClick(e),
    onClosed: (_e: ClosedEvent) => this.htmlEditor.instance.focus(),
  };
  popupContentHeight: number;
  contactsListFilters: { value: any; label: string }[] = [
    { value: 0, label: 'Kontrahenci' },
    { value: 1, label: 'Wszyscy z listy kontaktów' },
    { value: 2, label: 'Pierwszy z listy kontaktów' },
    { value: 3, label: 'Kontakty domyślne' },
  ];

  contactsGridContextMenu: Signal<any[]> = computed(() => {
    return [
      {
        text: this.translate.instant('repairFunctions.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 1,
      },
      {
        text: this.translate.instant('articles.setUnselectedAll'),
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 2,
      },
    ];
  });
  editorIndex: number = 0;
  selectedRowsData: CustomerEmailContact[] = [];
  shortcuts: ShortcutInput[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public appService: AppServices,
    public cd: ChangeDetectorRef,
    public emailService: EmailService
  ) {
    this.createForm();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.popupSub = this.event.choosedFilterValue.subscribe(
      (choosedFilterValue: ChoosedFilterValue) => {
        if (choosedFilterValue.filterId === this.dynamicComponentFilterId) {
          this.onClosingDynamicPopup();
        }
      }
    );
  }

  onVariableItemClick(e: ItemClickEvent): void {
    const data = e.itemData;
    const formatValue = {
      value: data.value,
      label: data.label,
      escapeChar: data.escapeChar,
    };
    try {
      this.htmlEditor.instance.insertText(
        this.editorIndex,
        ` `,
        'variable',
        formatValue
      );
    } catch (e) {
      // kontrolka dx wypluwa błąd mimo poprawnego działania
      // i wstrzymuje wywołanie dalszych funkcji
    }

    // try {
    this.editorIndex += 1; // spacja + zmienna
    this.htmlEditor.instance.setSelection(this.editorIndex + 1, 0);
    // } catch (e) {
    //   this.editorIndex--;
    // }
    this.htmlEditor.instance.focus();
    e.component.reset();
  }

  ngOnInit(): void {
    if (this.event.deviceType === 'mobile') this.fullScreen = true;
    const printReportConfig = this.printService.getCachedPrintReportConfig();
    if (
      this.event.isEmailAfterPrint() &&
      printReportConfig.crmValues.CrmCreateTaskAfterEmail
    ) {
      this.addCrmTaskAfterSending = true;
    }
    this.event.isEmailAfterPrint.set(false);
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: ['f10'],
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (this.sendOrSave) return;
          if (!e.event.ctrlKey) {
            this.SendEmail();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + f10'],
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.sendOrSave || this.emailData.isBulk) return;
          this.SaveEmail();
        },
        preventDefault: true,
      },
      {
        key: ['alt + 1'],
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 0;
          this.cd.detectChanges();
        },
      },
      {
        key: ['alt + 2'],
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 1;
          this.cd.detectChanges();
        },
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.sendOrSave) return;
          this.onCloseForm();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.sendOrSave) return;
          this.onCloseForm();
        },
        preventDefault: true,
      }
    );
    if (this.emailData.isBulk) {
      this.emailMessageTitle =
        this.translate.instant('email.sendToSelected') +
        ' (' +
        this.configuration?.EmailAddress +
        ')';
      this.selectedRowsData = this.emailService.bulkSendContacts();
    } else {
      this.translate
        .get('email.newMessage')
        .subscribe(
          (text) =>
            (this.emailMessageTitle =
              text + ' (' + this.configuration?.EmailAddress + ')')
        );
    }

    this.form.controls.From.setValue(this.configuration.EmailAddress);
    this.form.controls.ConfigurationId.setValue(
      this.configuration.EmailConfigurationId
    );
    if (this.emailTo !== '') this.form.controls.To.setValue(this.emailTo);
    if (this.subject !== '') this.form.controls.Subject.setValue(this.subject);
    if (this.message !== '') this.form.controls.Message.setValue(this.message);
    if (this.attachment) {
      this.attachments = [this.attachment];
      this.form.controls.Attachments.setValue(this.attachments);
      this.attachmentTitle = this.attachment.FileName;
    }
    if (this.event.deviceType === 'mobile') this.fullScreen = true;
  }

  deleteAttachment(e: any): void {
    this.attachments.splice(e, 1);
    this.form.controls.Attachments.setValue(this.attachments);
  }

  downloadAttachment(e: any): void {
    const src = `data:${this.attachments[e].FileExtension};base64,${this.attachments[e].FileData}`;
    const link = document.createElement('a');
    link.href = src;
    link.download = this.attachments[e].FileName;
    link.click();
    link.remove();
  }

  addBtn(): void {
    if (!this.readOnly) {
      let element: HTMLElement = document.getElementsByClassName(
        'dx-fileuploader-button'
      )[0] as HTMLElement;
      element.click();
    }
  }

  toBase64: any = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async onAttachmentChanged(e: ValueChangedEvent): Promise<void> {
    if (e.value[0].size <= 5242880) {
      const attachmentName = e.value[0].name;
      const attachmentType = e.value[0].type;
      let base64: string = await this.toBase64(e.value[0]);
      const index = base64.indexOf(',') + 1;
      base64 = base64.substring(index);
      this.attachments.push({
        FileName: attachmentName,
        FileData: base64,
        FileExtension: attachmentType,
      });
      this.fileuploaderRef.element.nativeElement.classList.add(
        'dx-fileuploader-empty'
      );
      this.isOpenUpload = false;
      this.form.controls.Attachments.setValue(this.attachments);
      this.cd.detectChanges();
    } else {
      this.event.showNotification(
        'warning',
        'Rozmiar załącznika nie może przekroczyć 5MB'
      );
    }
  }

  onCloseForm(): void {
    this.onPopupHidden();
    this.onClosing.emit();
  }

  SaveEmail(): void {
    this.saveData(3);
  }

  async SendEmail(): Promise<void> {
    if (!this.form.controls.Subject.value) {
      const confirmSendWithoutSubject: ConfirmDialogConfig = {
        isVisible: true,
        header: 'Wysłać wiadomość bez tematu?',
        text: 'Nie wprowadzono tematu wiadomości. Czy chcesz ją wysłać mimo to?',
        btnConfig: 'yesno',
      };
      const sendWithoutSubject = await this.getConfirmDialogAnswer(
        confirmSendWithoutSubject
      );
      if (!sendWithoutSubject) {
        this.setFocusOnSubject();
        return;
      }
    }
    if (!this.form.controls.Message.value) {
      const confirmSendWithoutMessage: ConfirmDialogConfig = {
        isVisible: true,
        header: 'Wysłać wiadomość bez treści?',
        text: 'Nie wprowadzono treści wiadomości. Czy chcesz ją wysłać mimo to?',
        btnConfig: 'yesno',
      };
      const sendWithoutMessage = await this.getConfirmDialogAnswer(
        confirmSendWithoutMessage
      );
      if (!sendWithoutMessage) {
        this.setFocusOnMessage();
        return;
      }
    }
    if (this.emailData.isBulk) {
      this.sendBulkEmail();
      return;
    }
    this.saveData(5);
  }

  confirmDialogSubscription: Subscription;
  getConfirmDialogAnswer(confirmDialog: ConfirmDialogConfig): Promise<boolean> {
    this.appService.confirmDialog.next({
      event: 'question',
      data: confirmDialog,
    });
    this.isConfirmDialogVisible = true;
    return new Promise<boolean>((resolve) => {
      this.confirmDialogSubscription = this.appService.confirmDialog.subscribe({
        next: (res: DynamicConfirmDialogBehaviorSubject) => {
          if (res.event !== 'answer') {
            return;
          }
          resolve(res.data.staticData === 'onRemoving');
          this.confirmDialogSubscription.unsubscribe();
          this.isConfirmDialogVisible = false;
        },
        error: (error) => {
          this.event.showNotification('error', error);
          resolve(false);
          this.confirmDialogSubscription.unsubscribe();
        },
      });
    });
  }

  setFocusOnSubject(): void {
    if (this.emailData.isBulk && this.selectedtabIndex !== 0) {
      this.selectedtabIndex = 0;
      this.cd.detectChanges();
    }
    // setTimeout(() => {
    this.subjectFied.instance.focus();
    // }, 100);
  }

  setFocusOnMessage(): void {
    if (this.emailData.isBulk && this.selectedtabIndex !== 0) {
      this.selectedtabIndex = 0;
      this.cd.detectChanges();
    }
    // setTimeout(() => {
    this.htmlEditor.instance.focus();
    // }, 100);
  }

  saveData(folderNumber: number): void {
    this.sendOrSave = true;
    var newdate = new DatePipe('en-US').transform(
      new Date().getTime(),
      'yyyy-MM-ddTHH:mm:ss'
    );
    this.form.controls.AttachmentCount.setValue(this.attachments.length);
    this.form.controls.Date.setValue(newdate);
    this.form.controls.FolderId.setValue(3);
    this.form.controls.Priority.setValue('Normalny');
    if (folderNumber === 5) {
      this.appService.postAuth(`emails/send`, this.form.value).subscribe(
        () => {
          this.event.showNotification('success', 'Email został wysłany');
          this.sendOrSave = false;

          if (this.addCrmTaskAfterSending) {
            this.addCrmTask();
          } else {
            this.onSending.emit();
            this.onCloseForm();
          }
        },
        (error) => {
          if (JSON.parse(error).detail) {
            this.event.showNotification('error', JSON.parse(error).detail);
          } else {
            this.event.showNotification('error', JSON.parse(error).message);
          }
          this.sendOrSave = false;
        }
      );
    } else {
      this.appService.postAuth(`emails/save`, this.form.value).subscribe(
        () => {
          this.event.showNotification('success', 'Email został zapisany');
          this.onSaving.emit();
          this.onCloseForm();
        },
        (error) => {
          if (JSON.parse(error).detail) {
            this.event.showNotification('error', JSON.parse(error).detail);
          } else {
            this.event.showNotification('error', JSON.parse(error).message);
          }
          this.sendOrSave = false;
        }
      );
    }
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      EmailId: 0,
      From: this.configuration?.EmailAddress,
      To: '',
      Bc: '',
      Bcc: '',
      Date: new Date().getTime(),
      AttachmentCount: 0,
      IcsAttachment: '',
      ReadConfirmation: [true],
      Priority: '',
      Subject: '',
      MessageId: '',
      FolderId: 3,
      CategoryId: 0,
      ConfigurationId: this.configuration?.EmailConfigurationId,
      IsRead: true,
      Pop3UniqueId: '',
      Pop3InternalId: '',
      CompanyId: this.event.footerInfo.AppCompanyId,
      IsDraft: true,
      IsSent: false,
      AppId: 3,
      UserId: this.event.footerInfo.AppUserId,
      Message: '',
      Mime: '',
      Attachments: [],
    });
  }

  async addCrmTask(): Promise<void> {
    this.dynamicComponent = this.crmTaskComponent;
    this.isDynamicPopupVisible = true;

    const types = await this.tasksSrvc.getTaskTypes();
    const customerId =
      this.emailData.reportData.documentData?.CustomerId ||
      this.emailData.reportData.documentData?.PayerId ||
      null;
    this.dynamicComponentExtraData = {
      isVisible: true,
      readOnly: false,
      selectedId: null,
      customerId: customerId,
      mode: 'add',
      dateStart: new DatePipe('en-EN').transform(new Date(), 'yyyy-MM-dd'),
      dateEnd: new DatePipe('en-EN').transform(new Date(), 'yyyy-MM-dd'),
      subject: this.translate.instant('reports.crmTaskSubjectEmail'),
      colour: '4DB6AC',
      taskTypes: types,
      typeId: types.find((x) => x.Name === 'Telefon')?.TypeId,
      isDynamicallyLoaded: true,
    };

    this.cd.detectChanges();
  }

  onClosingDynamicPopup(): void {
    this.isDynamicPopupVisible = false;
    this.dynamicComponentExtraData = null;
    this.onSending.emit();
    this.onCloseForm();
    this.cd.detectChanges();
  }

  onSelectionChanged(e: TabSelectionChangedEvent): void {
    this.selectedRowsData = e.selectedRowsData;
  }

  onKeyDown(_e: KeyDownEvent): void {}

  onRowDblClick(): void {}

  onFocusedRowChanged(_e: FocusedRowChangedEvent): void {}

  onPopupShown(e: ShownEvent): void {
    this.event.onShownPopUp();
    const contentHeight = e.component.content().getBoundingClientRect().height;
    this.calculateEditorHeight(contentHeight);
    this.calculateGridHeight(contentHeight);
    this.setFirstFocus();
  }

  onPopupHidden(_e?): void {
    this.event.onHiddenPopUp();
  }

  setFirstFocus(): void {
    if (this.emailData.isBulk) {
      this.subjectFied.instance.focus();
      return;
    }
    if (!this.form.controls['To'].value) {
      this.toField.instance.focus();
      return;
    }
    if (!this.form.controls['Subject'].value) {
      this.subjectFied.instance.focus();
      return;
    }
    this.htmlEditor.instance.focus();
  }

  onResizeEnd(e: ResizeEndEvent): void {
    const contentHeight = e.component.content().getBoundingClientRect().height;
    this.calculateEditorHeight(contentHeight);
    this.calculateGridHeight(contentHeight);
  }

  calculateEditorHeight(contentHeight: number): void {
    // get this.htmlEditorFooter height with Renderer2
    const contentPaddingY = 12 + 16;
    const tabsHeight = 34 + 16;
    const toHeight = this.toDiv.nativeElement.getBoundingClientRect().height;
    const subjectHeight =
      this.subjectDiv.nativeElement.getBoundingClientRect().height;
    const attachmentHeight =
      this.attachmentDiv.nativeElement.getBoundingClientRect().height;
    // const footerHeight =
    //   this.htmlEditorFooterDiv.nativeElement.getBoundingClientRect().height;
    this.htmlEditorHeight =
      contentHeight -
      (contentPaddingY +
        toHeight +
        subjectHeight +
        tabsHeight +
        attachmentHeight);
    // + footerHeight

    this.cd.detectChanges();
  }

  calculateGridHeight(contentHeight: number): void {
    const contentPaddingY = 12 + 16;
    const tabsHeight = 34 + 16;
    const filtersHeight = 34;

    this.heightGrid =
      contentHeight - (contentPaddingY + tabsHeight + filtersHeight);
    this.cd.detectChanges();
  }

  onContactsListChanged(_e: any): void {
    this.appService.getAuth(`customers/0/contacts/email`, {}).subscribe({
      next: (res) => {
        this.emailService.bulkSendContacts.set(res.data);
        this.contactsGrid.instance.refresh();
        this.contactsGrid.instance.selectAll();
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  onContentReady(e: GridContentReadyEvent): void {
    e.component.selectAll();
  }

  contextMenuClick(e): void {
    switch (e.itemData.itemIndex) {
      case 1:
        this.contactsGrid.instance.selectAll();
        break;

      case 2:
        this.contactsGrid.instance.deselectAll();
        break;
    }
  }

  async sendBulkEmail(): Promise<void> {
    this.sendOrSave = true;
    var newdate = new DatePipe('en-US').transform(
      new Date().getTime(),
      'yyyy-MM-ddTHH:mm:ss'
    );
    this.form.controls.AttachmentCount.setValue(this.attachments.length);
    this.form.controls.Date.setValue(newdate);
    this.form.controls.FolderId.setValue(3);
    this.form.controls.Priority.setValue('Normalny');

    let parsed: string = await this.replaceVariablesInMessage();
    parsed = parsed.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

    try {
      if (this.selectedRowsData.length === 0) {
        this.event.showNotification(
          'warning',
          'Nie zaznaczono żadnych kontaktów'
        );
        this.selectedtabIndex = 1;
        this.cd.detectChanges();
        throw new Error('noSelectedRowsData');
      }
      const bulkArray = this.selectedRowsData.map((contact) => {
        return {
          ...this.form.value,
          To: contact.EmailAddress,
          Message: parsed,
          ContactId: contact.ContactId > 0 ? contact.ContactId : null,
          CustomerId: contact.ContactId < 0 ? contact.ContactId : null,
        };
      });

      // return;
      this.appService.postAuth(`emails/send/multiple`, bulkArray).subscribe({
        next: () => {
          this.event.showNotification(
            'success',
            'Wiadomość została wysłana do wybranych kontaktów'
          );
          this.sendOrSave = false;
          this.onSending.emit();
          this.onCloseForm();
          this.cd.detectChanges();
        },
        error: (error) => {
          this.sendOrSave = false;
          this.event.httpErrorNotification(error);
          this.cd.detectChanges();
        },
      });
    } catch (e) {
      this.sendOrSave = false;
    }
  }

  replaceVariablesInMessage(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        // create DOM element from string
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          this.form.controls.Message.value,
          'text/html'
        );

        // replace .dx-variable with
        // ${data-var-start-esc-char}${data-var-value}${data-var-end-esc-char}

        const variables = Array.from(doc.getElementsByClassName('dx-variable'));
        for await (let variable of variables) {
          // create span with variable value after .dx-variable
          const span = document.createElement('span');
          span.innerText = `<${variable.getAttribute('data-var-value')}>`;

          // remove .dx-variable
          variable.replaceWith(span);
        }
        resolve(doc.body.innerHTML);
      } catch (e) {
        reject(e);
      }
    });
  }

  onHtmlEditorInitialized(e: InitializedEvent): void {
    // e.element.addEventListener('click', () => {
    //   this.editorIndex = this.getCursorIndex();
    // })

    setTimeout(() => {
      const textarea = e.element.querySelector('.dx-htmleditor-content');

      textarea.addEventListener(
        'mousedown',
        async () => (this.editorIndex = await this.getCursorIndex('mousedown'))
      ); // Click down
      textarea.addEventListener(
        'touchstart',
        async () => (this.editorIndex = await this.getCursorIndex('touchstart'))
      ); // Mobile
      textarea.addEventListener(
        'paste',
        async () => (this.editorIndex = await this.getCursorIndex('paste'))
      ); // Clipboard actions
      textarea.addEventListener(
        'cut',
        async () => (this.editorIndex = await this.getCursorIndex('cut'))
      );
      textarea.addEventListener(
        'select',
        async () => (this.editorIndex = await this.getCursorIndex('select'))
      ); // Some browsers support this event
      textarea.addEventListener(
        'selectstart',
        async () =>
          (this.editorIndex = await this.getCursorIndex('selectstart'))
      ); // Some browsers support this event
    }, 1000);
  }

  onHtmlEditorContentReady(e: ContentReadyEvent): void {
    const dropdownBtns = e.element.querySelectorAll('.dx-dropdownmenu-button');
    dropdownBtns.forEach((btn) => {
      btn.setAttribute('tabindex', '-1');
    });
  }

  async onHtmlEditorValueChanged(
    _e: HtmlEditorValueChangedEvent
  ): Promise<void> {
    this.editorIndex = await this.getCursorIndex('onHtmlEditorValueChanged');
  }

  onHtmlEditorFocusIn(_e: FocusInEvent): void {
    // this.editorIndex = this.getCursorIndex('onHtmlEditorFocusIn');
  }

  getCursorIndex(_event?: string): Promise<number> {
    return new Promise<number>((resolve) => {
      setTimeout(() => {
        let cursorIndex;
        const selection = this.htmlEditor.instance.getSelection();

        if (selection) {
          cursorIndex = selection.index;
          // !== 0
          //   ? selection.index
          //   : this.editorIndex || this.htmlEditor.instance.getLength() - 1 || 0;
        } else {
          resolve(
            this.editorIndex || this.htmlEditor.instance.getLength() - 1 || 0
          );
        }
        resolve(cursorIndex);
      }, 0);
    });
  }

  onTabSelectionChanged(e: SelectionChangedEvent): void {
    if (e.addedItems.includes(this.translate.instant('email.receiversList'))) {
      this.focusedRowIndex = 0;
      setTimeout(() => {
        this.contactsGrid.instance.focus();
      }, 0);
    }
  }

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  scrollViewHeight: number;

  onScrollViewChanged(e) {
    this.scrollViewHeight = e.component
      .element()
      .getBoundingClientRect().height;
  }

  onScrollViewInitialized(e) {
    e.component.option('useKeyboard', false);
    /*
    https://supportcenter.devexpress.com/ticket/details/t806218/scroll-view-s-scrollable-container-contains-tabindex-which-makes-it-focusable
    */
  }
}
