import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-message',
  standalone: true,
  templateUrl: './loading-message.component.html',
  styleUrl: './loading-message.component.scss'
})
export class LoadingMessageComponent {

}
