[
    {
      "id": "1",
      "idPatch": "0",
      "name": "menu.apps",
      "icon": "icon icon-absui-icon--location",
      "url": null,
      "items": [],
      "submenu": [],
      "tooltip": false,
      "code": null,
      "visible": false
    },
    {
      "id": "2",
      "name": "menu.jpk",
      "idPatch": "1",
      "icon": "icon absui-icon--docs-out",
      "url": "jpk/journal",
      "items": [],
      "patch": "",
      "submenu": [],
      "tooltip": false,
      "code": null,
      "visible": true,
      "isOperation": false
    },
    {
      "id": "3",
      "idPatch": "2",
      "name": "menu.administrator",
      "icon": "icon absui-icon--settings-user",
      "url": "jpk/parameters",
      "patch": "",
      "items": [],
      "submenu": [],
      "tooltip": false,
      "code": null,
      "visible": false
    },
    {
      "id": "4",
      "idPatch": "3",
      "name": "menu.help",
      "icon": "icon absui-icon--help-outline",
      "url": "jpk/help",
      "patch": "",
      "items": [],
      "submenu": [],
      "tooltip": false,
      "code": null,
      "visible": false
    }
  ]
  
  