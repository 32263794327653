import {
  ChangeDetectionStrategy,
  Component,
  signal,
  inject,
  OnInit,
  AfterViewInit,
  WritableSignal,
  OnDestroy,
  Signal,
  computed,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventService } from '../../event.service';
import { AppServices } from '../../app-services.service';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../core/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';
import { TryForFreeComponent } from '../try-for-free/try-for-free.component';

interface RequestTestModeEnd {
  CompanyVat: string;
}

interface ResponseTestModeEnd {
  status: boolean;
  description: string;
}

@Component({
  selector: 'app-license-box-info',
  imports: [
    CommonModule,
    DxButtonModule,
    TranslateModule,
    ConfirmDialogComponent,
    DxPopupModule,
    TryForFreeComponent,
  ],
  templateUrl: './license-box-info.component.html',
  styleUrl: './license-box-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseBoxInfoComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected event = inject(EventService);
  private appService = inject(AppServices);

  msgType = signal<string>('');
  isHideLicenseInfo = signal<boolean>(false);
  daysDifference = signal<number>(0);
  isEndTrial = signal<boolean>(false);
  isOpenLink = signal<boolean>(false);
  paymentLink: string = '';
  isDemo: boolean = false;
  isTryForFreeVisible: WritableSignal<boolean> = signal(false);
  isTryForFreeInterval: any;
  public promoCode: string = '';

  public variants: any[] = [
    { variantName: 'START', variantCode: 13 },
    { variantName: 'BIZNES', variantCode: 14 },
    { variantName: 'PRESTIŻ', variantCode: 15 },
    { variantName: 'PRESTIŻ PLUS', variantCode: 16 },
  ];

  public registrationLink: Signal<string> = computed(() => {
    return `https://portal.wapro.pl/registration/register-form?prgCode=3&variantName=ANY%20${this.getVariantName()}&promotionCode=${
      this.promoCode
    }`;
  });

  constructor() {
    const env = this.event.returnEnvironmentInfo();
    // const env = 'demo';
    if (env === 'demo') {
      this.msgType.set('warning');
      this.isDemo = true;
      this.isHideLicenseInfo.set(true);
      this.startTryForFreeInterval();
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.isActiveLicense();
  }

  ngOnDestroy(): void {
    if (this.isTryForFreeInterval) {
      clearInterval(this.isTryForFreeInterval);
    }
  }

  private isCurrentDateInRange(startDate, endDate) {
    try {
      // Pobieramy bieżącą datę
      const currentDate = new Date();

      // Konwertujemy podane daty na obiekty typu Date
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Sprawdzamy, czy bieżąca data mieści się w zakresie start - end
      return currentDate >= start && currentDate <= end;
    } catch {
      return false;
    }
  }

  private isActiveLicense() {
    if (this.event.footerInfo.IsExpired) {
      this.msgType.set('error');
      this.isHideLicenseInfo.set(true);
      return;
    }

    try {
      if (this.event.footerInfo.IsTesting) {
        this.msgType.set('info');
        this.isHideLicenseInfo.set(true);
      }
      const currentDate = new Date();
      const target = new Date(this.event.footerInfo.ConditionalExpireDate);
      //const target = new Date('2024-09-18');

      const timeDifference = target.getTime() - currentDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      this.daysDifference.set(daysDifference);

      if (
        this.isCurrentDateInRange(
          this.event.footerInfo.ExpireDate,
          this.event.footerInfo.ConditionalExpireDate
        )
      ) {
        this.msgType.set('warning');
        this.isHideLicenseInfo.set(true);
      }
    } catch {}
  }

  goToMyAccount() {
    window.open('https://sklep.assecobs.pl/', '_blank');
  }

  goToPortal() {
    window.open(environment.PORTAL_URL + '?licenses=true', '_blank');
  }

  httpSuccessNotification(res: ResponseTestModeEnd) {
    if (res.status) {
      this.paymentLink = res.description;
      this.event.onHidden();
      this.isOpenLink.set(true);
    }
    this.event.onHidden();
  }

  openLink() {
    window.open(this.paymentLink, '_blank');
    this.isOpenLink.set(false);
  }

  accpetEndTrial() {
    this.isEndTrial.set(false);

    const request: RequestTestModeEnd = {
      CompanyVat: this.event.footerInfo.CompanyVat,
    };

    this.event.onShown();
    this.appService
      .postAuth(`appPermissions/licenses/testmode/end`, request)
      .subscribe({
        next: (res) => {
          this.httpSuccessNotification(res);
        },
        error: (err) => {
          this.event.onHidden();
          this.event.httpErrorNotification(err);
        },
      });
  }

  protected onVisibleChangedFinish(isVisible: boolean): void {
    if (!isVisible) {
      this.isOpenLink.set(false);

      window.open(this.paymentLink, '_blank');
    }
  }

  getBtnClass() {
    switch (this.msgType()) {
      case 'warning':
        return 'license-box-action-btn--warning';
      case 'error':
        return 'license-box-action-btn--danger';
      case 'success':
        return 'license-box-action-btn--success';
      case 'info':
      default:
        return 'license-box-action-btn--info';
    }
  }

  startTryForFreeInterval(): void {
    const timeout = 600000; // 10 minut
    this.isTryForFreeInterval = setInterval(() => {
      this.isTryForFreeVisible.set(true);
      clearInterval(this.isTryForFreeInterval);
    }, timeout);
  }

  closeTryForFree(): void {
    this.isTryForFreeVisible.set(false);
    this.startTryForFreeInterval();
  }

  // private getVariantCode(): number {
  //   let variantName = this.event.footerInfo.VariantName || 'PRESTIŻ PLUS';
  //   return this.variants.find((v) => v.variantName === variantName).variantCode;
  // }

  private getVariantName(): string {
    return this.event.footerInfo.VariantName || 'PRESTIŻ PLUS';
  }

  tryForTreeLinkClick(): void {
    window.open(this.registrationLink(), '_blank');
  }

  hideLicenseInfo(): void {
    this.isHideLicenseInfo.set(false);
    if (this.isDemo) {
      this.startTryForFreeInterval();
    }
  }
}
