import { ErrorListService } from './error-list.service';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorListRecord } from './event.model';

@Injectable()
export class SaveErrorInterceptor implements HttpInterceptor {
  constructor(private errorList: ErrorListService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorToAdd: ErrorListRecord = {
          date: new Date(),
          message: this.getErrorDetail(error),
          statusCode: error.status,
          url: error.url,
          method: request.method,
        };

        this.errorList.addLatestError(errorToAdd);
        return throwError(error);
      })
    );
  }

  getErrorDetail(error: HttpErrorResponse): string {
    if (!error?.error) return '';
    else if (error?.error?.detail) return error.error.detail;
    else if (error?.error?.ErrorMessage) return error.error.ErrorMessage;
    else return error?.error?.title + JSON.stringify(error?.error?.errors);
  }
}
