<div class="toast-wrapper">
  <div
    *ngFor="let reminder of reminders; let index = index"
    class="dx-toast-reminder single-toast"
  >
    <div>
      <div class="title">
        <div class="d-inline-block w-35">
          <i class="icon icon-reminder"></i>
        </div>
        <div class="d-inline-block title-text w-250 toast-title-text">
          {{ "toastReminder.title" | translate }}
        </div>
        <i
          class="icon absui-icon--close close-icon pointer"
          (click)="closeReminderIndex(index)"
        ></i>
      </div>
      <div class="content">
        <div class="d-inline-block w-35"></div>
        <div class="d-inline-block content-text w-250">
          <a
            class="go-to-task"
            routerLink="/crm/crm-tasks"
            [state]="{ TaskId: reminder.TaskId }"
            >{{ reminder.Subject }}</a
          >
        </div>
      </div>
      <div class="l-h-18">
        <div class="d-inline-block w-35"></div>
        <div class="d-inline-block content-text w-250">
          <div class="d-inline-block w-90">
            {{ "toastReminder.start" | translate }}:
          </div>
          <div class="d-inline-block time-text w-80">
            {{
              reminder.DateStart
                | date : "dd.MM.yyyy" : translate.currentLang || "pl"
            }}
          </div>
          <div class="d-inline-block time-text w-50">
            {{
              reminder.DateStart
                | date : "HH:mm" : translate.currentLang || "pl"
            }}
          </div>
        </div>
      </div>
      <div class="content l-h-18">
        <div class="d-inline-block w-35"></div>
        <div class="d-inline-block content-text w-250">
          <div class="d-inline-block w-90">
            {{ "toastReminder.end" | translate }}:
          </div>
          <div class="d-inline-block time-text w-80">
            {{
              reminder.DateEnd
                | date : "dd.MM.yyyy" : translate.currentLang || "pl"
            }}
          </div>
          <div class="d-inline-block time-text w-50">
            {{
              reminder.DateEnd | date : "HH:mm" : translate.currentLang || "pl"
            }}
          </div>
        </div>
      </div>
      <div>
        <div class="d-inline-block w-35"></div>
        <div class="d-inline-block content-text w-250">
          <span class="time-box" [id]="'taskTooltip' + reminder.TaskId">
            {{ reminder.hours > 0 ? reminder.hours + "h " : "" }}
            {{ reminder.minutes > 0 ? reminder.minutes + "min " : "" }}
          </span>
          <dx-tooltip
            [target]="'#taskTooltip' + reminder.TaskId"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let x of 'content'">
              {{ "toastReminder.timeToStart" | translate }}:
              {{ reminder.hours > 0 ? reminder.hours + "h " : "" }}
              {{ reminder.minutes > 0 ? reminder.minutes + "min " : "" }}
            </div>
          </dx-tooltip>
        </div>
      </div>
    </div>
  </div>
</div>
