<div
  [ngClass]="{ 'queue-tasks-hide': !isShowQueue, 'queue-tasks': isShowQueue }"
  style="min-width: 320px; max-width: 600px; border: 1px solid transparent"
  [style.width.px]="width"
  [style.right.px]="isShowQueue ? 0 : -width - 100"
  mwlResizable
  (resizeStart)="mouseEnter(true)"
  (resizing)="resizing($event)"
  (resizeEnd)="mouseLeave()"
>
  <div
    class="resize-handle-left"
    mwlResizeHandle
    [resizeEdges]="{ left: true }"
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
  ></div>
  <div
    style="
      padding: 0 14px;
      height: 46px;
      display: flex;
      justify-content: space-between;
    "
  >
    <div class="bar-text">{{ "notifications" | translate }}</div>
    <div style="width: 18px; height: 18px">
      <i class="icon absui-icon--close" (click)="onClosed.emit()"></i>
    </div>
  </div>
  <dx-tab-panel
    #tabPanel
    [items]="['taskQueue' | translate, 'lastErrors' | translate]"
    [loop]="false"
    [animationEnabled]="false"
    [(selectedIndex)]="selectedTabIndex"
    [swipeEnabled]="false"
  >
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'taskQueue' | translate">
          <div
            class="d-flex flex-column"
            style="justify-content: space-between; height: calc(100vh - 122px)"
          >
            <dx-scroll-view direction="vertical" height="calc(100vh - 72px)">
              <dx-list
                [dataSource]="queuetasksList"
                height="100%"
                [searchEnabled]="false"
                class="queue-tasks-list"
                *ngIf="queuetasksList.length; else emptyState"
                [id]="'queuetasksList'"
              >
                <div
                  *dxTemplate="let item of 'item'"
                  [ngClass]="{ 'error-status': item.Status == 'failed' }"
                >
                  <app-single-queue-task [item]="item"> </app-single-queue-task>
                </div>
              </dx-list>
              <dx-context-menu
                [dataSource]="[]"
                [width]="200"
                target="#queuetasksList"
              ></dx-context-menu>
            </dx-scroll-view>
            <div class="task-queue-footer">
              <hr style="margin-bottom: 0.5rem" />
              <dx-button
                icon="icon absui-icon--symbol-forbidden"
                class="btn-dx-top-menu"
                [text]="'buttons.cancelAll' | translate"
                (onClick)="cancelAllTasks()"
              ></dx-button>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'lastErrors' | translate">
          <div
            class="d-flex flex-column"
            style="justify-content: space-between; height: calc(100vh - 122px)"
          >
            <app-error-list [width]="width"></app-error-list>
            <div>
              <hr style="margin-bottom: 0.5rem" />
              <dx-button
                icon="icon absui-icon--highlight-off-visible"
                class="btn-dx-top-menu"
                [text]="'buttons.deleteAll' | translate"
                (onClick)="deleteAllErrors()"
              >
              </dx-button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</div>

<!-- <div class="notification-queue" *ngIf="isShowNotification">
  <p>{{ notificationMsg }}</p>
</div> -->

<ng-container *ngIf="notifications.length || progressNotification">
  <div class="notification-queue">
    <p
      class="notification"
      *ngFor="let notification of notifications; let i = index"
      (click)="removeNotification(i)"
    >
      {{ notification.msg || 'debug' }}
    </p>
    <p
      class="notification"
      *ngIf="progressNotification"
      (click)="progressNotification = null"
    >
      {{ progressNotification.msg }}
      <span
        class="notification-progress-bar"
        *ngIf="progressNotification.value"
        [style.width.%]="progressNotification.value"
      >
      </span>
    </p>
  </div>
</ng-container>

<ng-template #emptyState>
  <div style="margin: 0 auto">
    <div
      class="emptyState"
      style="height: calc(100vh - 157px)"
      [style.width.px]="width"
    >
      <img
        src="../../../assets/img/Empty-state/no-data-queue.svg"
        alt="Asseco Business Solutions SA"
        loading="lazy"
      />
      <p class="p-emptyState">
        {{ "noDataText" | translate }}
      </p>
    </div>
  </div>
</ng-template>
