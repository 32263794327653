import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatUtilsService {
  constructor() {}

  verifyUrl = (url: string): boolean => {
    const pattern = new RegExp('^(https)://');
    return pattern.test(url);
  };

  escapeHTML = (str: string): string => {
    return str.replace(/[&<>"']/g, function (match: string) {
      const escapeMap: any = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
      };
      return escapeMap[match];
    });
  };

  getTime = (): string => {
    return new Date().toISOString();
  };

  async getPermissions(device: string): Promise<string> {
    if (!!navigator?.permissions) {
      return (
        navigator.permissions
          // @ts-ignore - ignore because microphone is not in the enum of name for all browsers
          ?.query({ name: device })
          .then((result) => result.state)
          .catch(() => {
            return 'prompt';
          })
      );
    }
    return 'prompt';
  }
}
