<div class="main-container">
  <div
    style="
      border-radius: 5px;
      justify-content: center;
      padding-top: calc(50vh - 432px);
    "
    class="d-flex flex-row"
  >
    <div
      style="
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1),
          0px 2px 1px 0px rgba(0, 0, 0, 0.06);
        display: flex;
        border-radius: 8px;
      "
    >
      <div
        class="left-container"
        *ngIf="!isCas; else casPanel"
        [ngClass]="{ 'less-padding': isDemo }"
      >
        <div *ngIf="!isDemo; else demoPanel">
          <div class="absui-login-panel__lang" style="margin-bottom: 24px;">
            <div class="absui-dropdown" [ngClass]="{ show: isShowLanguage }">
              <button
                class="absui-btn absui-btn--d"
                aria-haspopup="true"
                aria-expanded="true"
                (click)="isShowLanguage = !isShowLanguage"
              >
                <span class="absui-btn__title" style="margin-right: 2px;">
                  {{ selectLanbguage }}
                </span>
                <span
                  class="icon"
                  style="position: relative; top: 2px;"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down-box': !isShowLanguage,
                    'dx-icon-spinup': isShowLanguage
                  }"
                ></span>
              </button>
              <ul
                class="absui-dropdown__menu absui-dropdown__menu--right"
                aria-labelledby="dropdownMenu1"
              >
                <li
                  id="locale-pl"
                  class="absui-dropdown__item"
                  (click)="selectLanguage('pl')"
                >
                  <span
                    class="absui-dropdown__link locale-url"
                    data-locale="pl"
                  >
                    PL - Polski
                  </span>
                </li>
                <li
                  id="locale-en"
                  class="absui-dropdown__item"
                  (click)="selectLanguage('en')"
                >
                  <span
                    class="absui-dropdown__link locale-url"
                    data-locale="en"
                  >
                    EN - English
                  </span>
                </li>
                <li
                  id="locale-ua"
                  class="absui-dropdown__item"
                  (click)="selectLanguage('ua')"
                >
                  <span
                    class="absui-dropdown__link locale-url"
                    data-locale="ua"
                  >
                    UA - Yкраїнська
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <app-logo-wapro-svg
            style="margin-bottom: 8px;"
            alt="logo"
          ></app-logo-wapro-svg>
          <p class="title-log-page">{{ 'logOn.title' | translate }}</p>

          <form [formGroup]="logForm" class="form-log">
            <div class="form-group">
              <label for="login" class="label-login">
                {{ 'logOn.login' | translate }}
                <strong>*</strong>
              </label>
              <dx-text-box
                name="login"
                placeholder=""
                id="login"
                formControlName="Username"
                [showClearButton]="false"
                [inputAttr]="{ autocomplete: 'off' }"
                mode="email"
                [ngClass]="{
                  'input-required':
                    !logForm.controls.Username.valid && submitted
                }"
                valueChangeEvent="input"
                [readOnly]="isDemo"
                #firstFocus
                width="360"
                autocapitalize="none"
                style="max-width: 360px;"
                (onFocusIn)="event.onFocusCountry('Username', logForm)"
                (onFocusOut)="event.onFocusOut('Username', logForm)"
                (keyup.enter)="onSubmitForm(logForm.value)"
              ></dx-text-box>

              <small
                class="text-danger"
                *ngIf="
                  submitted &&
                  formError.Username &&
                  logForm.get('Username').focus
                "
              >
                {{ 'logOn.login-required' | translate }}
              </small>
            </div>

            <div class="form-group">
              <label for="password" class="label-login">
                {{ 'logOn.password' | translate }}
                <strong>*</strong>
              </label>
              <dx-text-box
                name="password"
                placeholder=""
                id="password"
                formControlName="Password"
                [inputAttr]="{ autocomplete: 'off' }"
                [readOnly]="isDemo"
                mode="password"
                [ngClass]="{
                  'input-required':
                    !logForm.controls.Password.valid && submitted
                }"
                (onFocusIn)="event.onFocusCountry('Password', logForm)"
                (onFocusOut)="event.onFocusOut('Password', logForm)"
                (keyup.enter)="onSubmitForm(logForm.value)"
                autocapitalize="none"
                valueChangeEvent="input"
                width="360"
                style="max-width: 360px;"
              ></dx-text-box>
              <small
                class="text-danger"
                *ngIf="
                  submitted &&
                  formError.Password &&
                  logForm.get('Password').focus
                "
              >
                {{ 'logOn.password-required' | translate }}
              </small>
            </div>

            <div class="form-group">
              <p
                class="additionOptions"
                (click)="
                  showHidenAdditionalOption.set(!showHidenAdditionalOption())
                "
              >
                Dodatkowe opcje >>
              </p>
            </div>

            @if(showHidenAdditionalOption()){
            <div class="form-group">
              <label for="password" class="label-login">
                Serwer bazy danych
                <strong>*</strong>
              </label>
              <dx-text-box
                placeholder=""
                formControlName="DbHost"
                [inputAttr]="{ autocomplete: 'off' }"
                [readOnly]="isDemo"
                [ngClass]="{
                  'input-required': !logForm.controls.DbHost.valid && submitted
                }"
                (keyup.enter)="onSubmitForm(logForm.value)"
                autocapitalize="none"
                valueChangeEvent="input"
                width="360"
                style="max-width: 360px;"
              ></dx-text-box>
            </div>

            <div class="form-group">
              <label for="password" class="label-login">
                Baza danych
                <strong>*</strong>
              </label>
              <dx-select-box
                [items]="items"
                [acceptCustomValue]="true"
                [inputAttr]="{ autocomplete: 'off' }"
                [readOnly]="isDemo"
                width="360"
                style="max-width: 360px;"
                [ngClass]="{
                  'input-required':
                    !logForm.controls.DBDATABASE.valid && submitted
                }"
                autocapitalize="none"
                formControlName="DBDATABASE"
                (onCustomItemCreating)="addCustomItem($event)"
              ></dx-select-box>
            </div>
            }

            <dx-button
              mode="submit"
              [disabled]="isBlockBtnLog"
              (onClick)="onSubmitForm(logForm.value)"
              id="btn-log"
              class="btn-dx-primary"
              text="{{ 'logOn.btn-log' | translate }}"
              [useSubmitBehavior]="true"
              [height]="48"
              style="max-width: 360px;"
              width="360"
            ></dx-button>

            <div class="text-center" style="width: 360px;">
              <app-abs-logo-svg
                fillColor="var(--text-light-gray, #666666)"
                style="
                  width: 100px;
                  display: inline-block;
                  margin: auto;
                  margin-top: 32px;
                "
              ></app-abs-logo-svg>
            </div>
          </form>
        </div>
      </div>
      <div class="right-container"></div>
    </div>
  </div>
</div>

<ng-template #casPanel>
  <div class="cas-panel">
    <div class="cas-loader-box">
      <wapro-loader
        [config]="configLoader"
        mode="primary"
        [loaderText]="false"
      ></wapro-loader>
    </div>

    <p class="title-log-page" style="margin-bottom: 6px; margin-top: 20px;">
      {{ 'dataIsLoading' | translate }}
    </p>
    <p class="demo-title-2">{{ 'pleaseWait' | translate }}</p>
  </div>
</ng-template>

<ng-template #createDemo>
  <div class="text-center" style="padding-top: 110px;">
    <wapro-loader
      [config]="configLoader"
      mode="primary"
      [loaderText]="false"
    ></wapro-loader>

    <p
      class="description-create-demo"
      style="margin-bottom: 6px; margin-top: -13px;"
    >
      <strong>
        Tworzymy Twoje indywidualne
        <br />
        środowisko demonstracyjne
      </strong>
    </p>
    <p class="description-create-demo" style="margin-bottom: 56px;">
      Pozwoli Ci ono w pełni korzystać z naszego systemu. Może to potrwać kilka
      sekund.
    </p>
    <app-logo-wapro-svg
      style="width: 165px; display: block; height: 22px; margin: auto;"
    ></app-logo-wapro-svg>
  </div>
</ng-template>

<ng-template #demoPanel>
  <div class="text-center" *ngIf="!isCreateDemo; else createDemo">
    <app-run-demo-svg></app-run-demo-svg>

    <p class="demo-title" style="margin-bottom: 6px;">
      Uruchomienie w trybie demonstracyjnym
    </p>
    <p class="demo-title-2" style="margin-bottom: 24px;">
      Wypróbuj możliwości naszego systemu.
    </p>

    <label class="label-login">Wybierz wariant programu</label>
    <dx-select-box
      [dataSource]="versionList"
      [(value)]="selectedVersion"
      [width]="360"
      style="width: 360px; max-width: 360px; margin-bottom: 10px;"
    ></dx-select-box>

    <div class="description-variant">
      <div [ngSwitch]="selectedVersion">
        <div *ngSwitchCase="'ANY START'">
          <p>
            Przeznaczony dla małej firmy. Aplikacja pracuje z jednym magazynem i
            dwoma użytkownikami.
          </p>
        </div>

        <div *ngSwitchCase="'ANY BIZNES'">
          <p>
            Przeznaczony dla średnich firm, z możliwością obsługi wielu
            magazynów i wielu użytkowników.
          </p>
        </div>

        <div *ngSwitchCase="'ANY PRESTIŻ'">
          <p>
            Przeznaczony dla średnich firm i dużych firm, realizujących
            skomplikowane procesy a także wymagających wielowalutowości lub
            produkcji.
          </p>
        </div>

        <div *ngSwitchCase="'ANY PRESTIŻ PLUS'">
          <p>
            Przeznaczony dla najbardziej wymagających firm. Pozwala na
            dedykowane rozbudowy funkcjonalności programu pod potrzeby klienta.
          </p>
        </div>

        <div *ngSwitchCase="'ANY BIURO'">
          <p>
            Przeznaczony dla biur rachunkowych. Funkcjonalnie odpowiada lini
            Prestiż - pozwala na wiele firm na jednej licencji biura
            rachunkowego.
          </p>
        </div>

        <div *ngSwitchCase="'PRESTIŻ'">
          <p>
            Przeznaczony dla średnich firm i dużych firm, realizujących
            skomplikowane procesy a także wymagających wielowalutowości lub
            produkcji.
          </p>
        </div>

        <div *ngSwitchCase="'PRESTIŻ PLUS'">
          <p>
            Przeznaczony dla najbardziej wymagających firm. Pozwala na
            dedykowane rozbudowy funkcjonalności programu pod potrzeby klienta.
          </p>
        </div>

        <div *ngSwitchCase="'ANY FAKTUROWANIE'">
          <p>
            Dla małej firmy, do obsługi faktur z dwoma możliwymi użytkownikami.
          </p>
        </div>

        <div *ngSwitchCase="'ANY FAKTUROWANIE PRO'">
          <p>
            Dla średnich i dużych firm, do obsługi faktur i wielu użytkowników.
          </p>
        </div>
        <div *ngSwitchDefault>
          <p>
            Przeznaczony dla małej firmy. Aplikacja pracuje z jednym magazynem i
            jednym użytkownikiem.
          </p>
        </div>
      </div>
    </div>

    <!-- <p class="diferentBetweenVewr">Czym różnią się warianty aplikacji?</p> -->

    <dx-button
      text="Uruchom aplikację"
      [useSubmitBehavior]="true"
      [height]="26"
      style="max-width: 360px;"
      width="360"
      type="success"
      (onClick)="onStartDemo()"
      data-cy="start-demo-btn"
    ></dx-button>

    <p class="p-or">lub</p>

    <dx-button
      class="outline-secondary"
      stylingMode="outlined"
      text="Zamów pełną wersję programu już dziś"
      style="max-width: 360px; margin-bottom: 56px;"
      width="360"
      [height]="26"
      (onClick)="goToShop()"
    ></dx-button>

    <app-logo-wapro-svg
      style="width: 165px; display: block; height: 22px; margin: auto;"
    ></app-logo-wapro-svg>
  </div>
</ng-template>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#body' }"
  [visible]="event.loadingVisible && !event.isLoaderRunning"
  [showIndicator]="false"
  [shading]="false"
  [hideOnOutsideClick]="false"
  (onShown)="event.onShown()"
  (onHidden)="event.onHidden()"
>
  <div *dxTemplate="let content of 'content'">
    <wapro-loader mode="primary" message=""></wapro-loader>
  </div>
</dx-load-panel>
