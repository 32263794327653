import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDropDownButtonModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { Router } from '@angular/router';
import { ExportFakirService } from '../export-fakir.service';
import { OpenFakirUrl } from './export-fakir-model';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-export-fakir',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        DxButtonModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxAccordionModule,
        DxSelectBoxModule,
        DxScrollViewModule,
        DxTooltipModule,
        DxDropDownButtonModule,
        DxPopupModule,
    ],
    templateUrl: './export-fakir.component.html',
    styleUrls: ['./export-fakir.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportFakirComponent {
  fb = inject(FormBuilder);
  translate = inject(TranslateService);
  router = inject(Router);
  appService = inject(AppServices);
  exportFakirService = inject(ExportFakirService);
  event = inject(EventService);
  widthWindow: any = 800;
  heightWindow: any = '90%';
  defaultFileName = `exportFK_${this.event.dataFormatted({
    value: new Date(),
  })}`;
  form: FormGroup;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  choosen: string = 'chosen';
  contentDataSource = [
    {
      label: 'Wersja pełna (wszystkie dostępne elementy)',
      value: false,
    },
    {
      label: 'Wersja skrócona (zoptymalizowana)',
      value: true,
    },
  ];

  readonly clearSettings = {
    icon: 'clear',
    type: 'default',
    disabled: false,
  };

  onClearButtonSad = {
    ...this.clearSettings,
    onClick: () => {
      this.openUrl('sad');
    },
  };

  onClearButtonRkwb = {
    ...this.clearSettings,
    onClick: () => {
      this.openUrl('rkwb');
    },
  };

  onClearButtonWarehouse = {
    ...this.clearSettings,
    onClick: () => {
      this.openUrl('warehouse');
    },
  };

  onClearButtonFinancial = {
    ...this.clearSettings,
    onClick: () => {
      this.openUrl('financial');
    },
  };

  onClearButtonComercial = {
    ...this.clearSettings,
    onClick: () => {
      this.openUrl('comercial');
    },
  };

  selections$ = this.exportFakirService.selections.asObservable();
  fileName: string = '';
  isLoading = false;

  constructor() {
    this.getTranslations();
    this.createForm();

    if (this.exportFakirService.formValue) {
      this.form.patchValue(this.exportFakirService.formValue);
    }
  }

  getTranslations() {
    this.contentDataSource[0].label = this.translate.instant(
      'exportFakir.fullVersion'
    );
    this.contentDataSource[1].label = this.translate.instant(
      'exportFakir.optimizedVersion'
    );
    this.choosen = this.translate.instant('exportFakir.selectedElements');
  }

  createForm() {
    this.form = this.fb.group({
      ExportedType: new FormControl(true),
      SetAccountingTag: new FormControl(false),
      SkipAccounted: new FormControl(false),
      SkipCustomers: new FormControl(false),
      SkipLines: new FormControl(false),
      SkipCostLines: new FormControl(false),
      SkipSettlement: new FormControl(false),
      SkipDocumentAdditionalField: new FormControl(false),
      SkipLinesAdditionaField: new FormControl(false),
      SkipCustomerAdditionalField: new FormControl(false),
      SkipProductAdditionalField: new FormControl(false),
    });
  }

  openUrl(val: OpenFakirUrl) {
    if (val === 'comercial') {
      this.router.navigate(['/trade-operations/trader-documents']);
    } else if (val === 'warehouse') {
      this.router.navigate(['/warehouse/warehouse-documents']);
    } else if (val === 'financial') {
      this.router.navigate(['/trade-operations/finances-documents']);
    } else if (val === 'rkwb') {
      this.router.navigate(['/trade-operations/financial-raports']);
    } else if (val === 'sad') {
      this.router.navigate(['/warehouse/sad']);
    }
    setTimeout(() => {
      this.exportFakirService.isSelectMode.set(true);
      this.exportFakirService.formValue = this.form.value;
    }, 1000);
    this.hidePopup();
  }

  onSave() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.event.loadingVisible = true;
    this.appService
      .postAuth(`ExportToWaproXml/Export`, this.form.value, undefined, 'text')
      .subscribe({
        next: (data: string) => {
          this.downloadXML(data);
          this.onCancel();
          this.removeLoading();
        },
        error: (error) => {
          this.removeLoading();
          const parsedError = JSON.parse(error);
          this.event.showNotification(
            'error',
            parsedError?.message || 'Nieznany błąd'
          );
        },
      });
  }

  removeLoading() {
    this.isLoading = false;
    this.event.loadingVisible = false;
  }

  downloadXML(content: string) {
    const a = document.createElement('a');
    const blob = new Blob([content], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', this.fileName || this.defaultFileName);
    a.click();
  }

  onCancel() {
    this.createForm();
    this.exportFakirService.formValue = null;
    this.fileName = '';

    this.resetCommercialSelections();
    this.resetFinancialSelections();
    this.resetWarehouseSelections();
    this.resetSadSelections();
    this.resetRKWBSelections();

    this.hidePopup();
  }

  onValueChanged(e, val: OpenFakirUrl) {
    if (e?.value === null) {
      if (val === 'comercial') {
        this.resetCommercialSelections();
      } else if (val === 'financial') {
        this.resetFinancialSelections();
      } else if (val === 'warehouse') {
        this.resetWarehouseSelections();
      } else if (val === 'sad') {
        this.resetSadSelections();
      } else if (val === 'rkwb') {
        this.resetRKWBSelections();
      }
    }
  }

  resetCommercialSelections() {
    this.appService.deleteAuth('invoices/selection/documents').subscribe({
      next: () => {
        const value = this.exportFakirService.selections.getValue();
        this.exportFakirService.selections.next({ ...value, Commercial: 0 });
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  resetWarehouseSelections() {
    this.appService
      .deleteAuth('warehouseDocuments/selection/documents')
      .subscribe({
        next: () => {
          const value = this.exportFakirService.selections.getValue();
          this.exportFakirService.selections.next({ ...value, Warehouse: 0 });
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  resetFinancialSelections() {
    this.appService.deleteAuth('finances/selection/document').subscribe({
      next: () => {
        const value = this.exportFakirService.selections.getValue();
        this.exportFakirService.selections.next({ ...value, Financial: 0 });
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  resetSadSelections() {
    this.appService.deleteAuth('sadDocuments/selection/documents').subscribe({
      next: () => {
        const value = this.exportFakirService.selections.getValue();
        this.exportFakirService.selections.next({ ...value, Sad: 0 });
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  resetRKWBSelections() {
    this.appService.deleteAuth('finances/selection/cashBankReports').subscribe({
      next: () => {
        const value = this.exportFakirService.selections.getValue();
        this.exportFakirService.selections.next({ ...value, RKWB: 0 });
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  visibleChange(e: boolean) {
    if (!e) {
      this.hidePopup();
    }
  }

  hidePopup() {
    this.event.isExportFKVisible.set(false);
  }
}
