{
    "pl": {
        "Yes": "Tak",
        "No": "Nie",
        "Cancel": "Anuluj",
        "Clear": "Czyść",
        "Done": "Gotowe",
        "Loading": "Ładowanie danych...",
        "Select": "Wybierz...",
        "Search": "Szukaj",
        "Back": "Wstecz",
        "OK": "OK",

        "dxCollectionWidget-noDataText": "Brak danych do wyświetlenia",

        "dxDropDownEditor-selectLabel": "Wybierz",

        "validation-required": "Required",
        "validation-required-formatted": "{0} jest wymagane",
        "validation-numeric": "Wartość musi być liczbą",
        "validation-numeric-formatted": "{0} musi być liczbą",
        "validation-range": "Wartość poza zakresem",
        "validation-range-formatted": "{0} jest poza zakresem",
        "validation-stringLength": "The length of the value is not correct",
        "validation-stringLength-formatted": "The length of {0} is not correct",
        "validation-custom": "Wartość jest niepoprawna",
        "validation-custom-formatted": "{0} is invalid",
        "validation-async": "Wartość jest niepoprawna",
        "validation-async-formatted": "{0} is invalid",
        "validation-compare": "Values do not match",
        "validation-compare-formatted": "{0} does not match",
        "validation-pattern": "Value does not match pattern",
        "validation-pattern-formatted": "{0} does not match pattern",
        "validation-email": "Email is invalid",
        "validation-email-formatted": "{0} is invalid",
        "validation-mask": "Wartość jest niepoprawna",

        "dxLookup-searchPlaceholder": "Minimum character number: {0}",

        "dxList-pullingDownText": "Pull down to refresh...",
        "dxList-pulledDownText": "Release to refresh...",
        "dxList-refreshingText": "Ładowanie...",
        "dxList-pageLoadingText": "Ładowanie danych...",
        "dxList-nextButtonText": "Pokaż więcej...",
        "dxList-selectAll": "Zaznacz wszystko",
        "dxListEditDecorator-delete": "Usuń",
        "dxListEditDecorator-more": "Więcej",

        "dxScrollView-pullingDownText": "Pull down to refresh...",
        "dxScrollView-pulledDownText": "Release to refresh...",
        "dxScrollView-refreshingText": "Ładowanie...",
        "dxScrollView-reachBottomText": "Ładowanie danych...",

        "dxDateBox-simulatedDataPickerTitleTime": "Wybierz godzinę",
        "dxDateBox-simulatedDataPickerTitleDate": "Wybierz datę",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Wybierz datę i godzinę",
        "dxDateBox-validation-datetime": "Podaj poprawną datę i godzinę",

        "dxFileUploader-selectFile": "Wybierz plik",
        "dxFileUploader-dropFile": "lub przeciągnij plik tutaj",
        "dxFileUploader-bytes": "bytes",
        "dxFileUploader-kb": "kb",
        "dxFileUploader-Mb": "Mb",
        "dxFileUploader-Gb": "Gb",
        "dxFileUploader-upload": "Upload",
        "dxFileUploader-uploaded": "Uploaded",
        "dxFileUploader-readyToUpload": "Ready to upload",
        "dxFileUploader-uploadFailedMessage": "Upload failed",
        "dxFileUploader-invalidFileExtension": "File type is not allowed",
        "dxFileUploader-invalidMaxFileSize": "File is too large",
        "dxFileUploader-invalidMinFileSize": "File is too small",

        "dxRangeSlider-ariaFrom": "From",
        "dxRangeSlider-ariaTill": "Till",
        "dxSwitch-switchedOnText": "ON",
        "dxSwitch-switchedOffText": "OFF",

        "dxForm-optionalMark": "optional",
        "dxForm-requiredMessage": "{0} is required",

        "dxNumberBox-invalidValueMessage": "Value must be a number",
        "dxNumberBox-noDataText": "No data",

        "dxDataGrid-columnChooserTitle": "Wybór kolumny",
        "dxDataGrid-columnChooserEmptyText": "Przeciągnij kolumnę tutaj, aby ją ukryć",
        "dxDataGrid-groupContinuesMessage": "Kontynuuje na następnej stronie",
        "dxDataGrid-groupContinuedMessage": "Kontynuuje na poprzedniej stronie",
        "dxDataGrid-groupHeaderText": "Grupuj według tej kolumny",
        "dxDataGrid-ungroupHeaderText": "Rozgrupuj",
        "dxDataGrid-ungroupAllText": "Rozgrupuj wszystkie",
        "dxDataGrid-editingEditRow": "Edytuj",
        "dxDataGrid-editingSaveRowChanges": "Zapisz",
        "dxDataGrid-editingCancelRowChanges": "Anuluj",
        "dxDataGrid-editingDeleteRow": "Usuń",
        "dxDataGrid-editingUndeleteRow": "Cofnij usuń",
        "dxDataGrid-editingConfirmDeleteMessage": "Czy na pewno chcesz usunąć ten rekord?",
        "dxDataGrid-validationCancelChanges": "Anuluj zmiany",
        "dxDataGrid-groupPanelEmptyText": "Przeciągnij tutaj nagłówek kolumny, aby pogrupować wyniki",
        "dxDataGrid-noDataText": "Brak danych do wyświetlenia",
        "dxDataGrid-searchPanelPlaceholder": "Szukaj...",
        "dxDataGrid-filterRowShowAllText": "(Wszystkie)",
        "dxDataGrid-filterRowResetOperationText": "Reset",
        "dxDataGrid-filterRowOperationEquals": "Równa się",
        "dxDataGrid-filterRowOperationNotEquals": "Nie równa się",
        "dxDataGrid-filterRowOperationLess": "Mniej niż",
        "dxDataGrid-filterRowOperationLessOrEquals": "Mniejszy lub równy",
        "dxDataGrid-filterRowOperationGreater": "Wiekszy niż",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Większy lub równy",
        "dxDataGrid-filterRowOperationStartsWith": "Zaczyna na",
        "dxDataGrid-filterRowOperationContains": "Zawiera",
        "dxDataGrid-filterRowOperationNotContains": "Nie zawiera",
        "dxDataGrid-filterRowOperationEndsWith": "Kończy na",
        "dxDataGrid-filterRowOperationBetween": "Pomiędzy",
        "dxDataGrid-filterRowOperationBetweenStartText": "Start",
        "dxDataGrid-filterRowOperationBetweenEndText": "Koniec",
        "dxDataGrid-applyFilterText": "Nałóż filtr",
        "dxDataGrid-trueText": "prawda",
        "dxDataGrid-falseText": "fałsz",
        "dxDataGrid-sortingAscendingText": "Sortuj rosnąco",
        "dxDataGrid-sortingDescendingText": "Sortuj malejąco",
        "dxDataGrid-sortingClearText": "Wyczyść sortowanie",
        "dxDataGrid-editingSaveAllChanges": "Zapisz zmiany",
        "dxDataGrid-editingCancelAllChanges": "Porzuć zmiany",
        "dxDataGrid-editingAddRow": "Dodaj wiersz",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Min to {1} jest {0}",
        "dxDataGrid-summaryMax": "Max: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Max to {1} jest {0}",
        "dxDataGrid-summaryAvg": "Avg: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Avg of {1} is {0}",
        "dxDataGrid-summarySum": "Suma: {0}",
        "dxDataGrid-summarySumOtherColumn": "Sum of {1} is {0}",
        "dxDataGrid-summaryCount": "Łącznie: {0}",
        "dxDataGrid-columnFixingFix": "Fix",
        "dxDataGrid-columnFixingUnfix": "Unfix",
        "dxDataGrid-columnFixingLeftPosition": "W lewo",
        "dxDataGrid-columnFixingRightPosition": "W prawo",
        "dxDataGrid-exportTo": "Export",
        "dxDataGrid-exportToExcel": "Export do Excel",
        "dxDataGrid-excelFormat": "plik Excel",
        "dxDataGrid-selectedRows": "Wybrane wiersze",
        "dxDataGrid-exportSelectedRows": "Exportuj wybrane wiersze",
        "dxDataGrid-exportAll": "Exportuj wszystkie dane",
        "dxDataGrid-headerFilterEmptyValue": "(Puste)",
        "dxDataGrid-headerFilterOK": "Zapisz",
        "dxDataGrid-headerFilterCancel": "Anuluj",
        "dxDataGrid-ariaColumn": "Kolumna",
        "dxDataGrid-ariaValue": "Wartość",
        "dxDataGrid-ariaFilterCell": "Filtruj kolimnę",
        "dxDataGrid-ariaCollapse": "Collapse",
        "dxDataGrid-ariaExpand": "Rozszerz",
        "dxDataGrid-ariaDataGrid": "Data grid",
        "dxDataGrid-ariaSearchInGrid": "Szukaj w danych",
        "dxDataGrid-ariaSelectAll": "Zaznacz wszystko",
        "dxDataGrid-ariaSelectRow": "Zaznacz wiersz",
        "dxDataGrid-filterBuilderPopupTitle": "Konstruktor filtrów",
        "dxDataGrid-filterPanelCreateFilter": "Twórz filtr",
        "dxDataGrid-filterPanelClearFilter": "Wyczyśc",
        "dxDataGrid-filterPanelFilterEnabledHint": "Włącz filtr",

        "dxTreeList-ariaTreeList": "Lista",
        "dxTreeList-editingAddRowToNode": "Dodaj",

        "dxPager-infoText": "Strona {0} z {1} ({2} rekordów)",
        "dxPager-pagesCountText": "z",

        "dxPivotGrid-grandTotal": "Grand Total",
        "dxPivotGrid-total": "{0} Total",
        "dxPivotGrid-fieldChooserTitle": "Field Chooser",
        "dxPivotGrid-showFieldChooser": "Show Field Chooser",
        "dxPivotGrid-expandAll": "Expand All",
        "dxPivotGrid-collapseAll": "Collapse All",
        "dxPivotGrid-sortColumnBySummary": "Sort \"{0}\" by This Column",
        "dxPivotGrid-sortRowBySummary": "Sort \"{0}\" by This Row",
        "dxPivotGrid-removeAllSorting": "Remove All Sorting",
        "dxPivotGrid-dataNotAvailable": "N/A",
        "dxPivotGrid-rowFields": "Row Fields",
        "dxPivotGrid-columnFields": "Column Fields",
        "dxPivotGrid-dataFields": "Data Fields",
        "dxPivotGrid-filterFields": "Filter Fields",
        "dxPivotGrid-allFields": "All Fields",
        "dxPivotGrid-columnFieldArea": "Drop Column Fields Here",
        "dxPivotGrid-dataFieldArea": "Drop Data Fields Here",
        "dxPivotGrid-rowFieldArea": "Drop Row Fields Here",
        "dxPivotGrid-filterFieldArea": "Drop Filter Fields Here",

        "dxScheduler-editorLabelTitle": "Temat",
        "dxScheduler-editorLabelStartDate": "Data startu",
        "dxScheduler-editorLabelEndDate": "Data końca",
        "dxScheduler-editorLabelDescription": "Opis",
        "dxScheduler-editorLabelRecurrence": "Powtórz",

        "dxScheduler-openAppointment": "Open appointment",

        "dxScheduler-recurrenceNever": "Nigdy",
        "dxScheduler-recurrenceDaily": "Codziennie",
        "dxScheduler-recurrenceWeekly": "Co tydzień",
        "dxScheduler-recurrenceMonthly": "Co miesiąc",
        "dxScheduler-recurrenceYearly": "Co rok",

        "dxScheduler-recurrenceRepeatEvery": "Repeat Every",
        "dxScheduler-recurrenceRepeatOn": "Repeat On",
        "dxScheduler-recurrenceEnd": "End repeat",
        "dxScheduler-recurrenceAfter": "After",
        "dxScheduler-recurrenceOn": "On",

        "dxScheduler-recurrenceRepeatDaily": "day(s)",
        "dxScheduler-recurrenceRepeatWeekly": "week(s)",
        "dxScheduler-recurrenceRepeatMonthly": "month(s)",
        "dxScheduler-recurrenceRepeatYearly": "year(s)",

        "dxScheduler-switcherDay": "Dzień",
        "dxScheduler-switcherWeek": "Tydzień",
        "dxScheduler-switcherWorkWeek": "Tydzień roboczy",
        "dxScheduler-switcherMonth": "Miesiąc",

        "dxScheduler-switcherAgenda": "Agenda",

        "dxScheduler-switcherTimelineDay": "Linia dnia",
        "dxScheduler-switcherTimelineWeek": "Linia tygodnia",
        "dxScheduler-switcherTimelineWorkWeek": "Linia tygodnia roboczego",
        "dxScheduler-switcherTimelineMonth": "Linia miesiąca",

        "dxScheduler-recurrenceRepeatOnDate": "on date",
        "dxScheduler-recurrenceRepeatCount": "occurrence(s)",
        "dxScheduler-allDay": "Cały dzień",

        "dxScheduler-confirmRecurrenceEditMessage": "Do you want to edit only this appointment or the whole series?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Do you want to delete only this appointment or the whole series?",

        "dxScheduler-confirmRecurrenceEditSeries": "Edit series",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Delete series",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Edit appointment",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Delete appointment",

        "dxScheduler-noTimezoneTitle": "No timezone",
        "dxScheduler-moreAppointments": "Jeszcze {0}",

        "dxCalendar-todayButtonText": "Dzisiaj",
        "dxCalendar-ariaWidgetName": "Kalendarz",

        "dxColorView-ariaRed": "Czerwony",
        "dxColorView-ariaGreen": "Zielony",
        "dxColorView-ariaBlue": "Niebieski",
        "dxColorView-ariaAlpha": "Przezroczystość",
        "dxColorView-ariaHex": "Kod koloru",

        "dxTagBox-selected": "{0} selected",
        "dxTagBox-allSelected": "All selected ({0})",
        "dxTagBox-moreSelected": "Jeszcze {0}",

        "vizExport-printingButtonText": "Drukuj",
        "vizExport-titleMenuText": "Eksportuj/drukuj",
        "vizExport-exportButtonText": "{0} plik",

        "dxFilterBuilder-and": "And",
        "dxFilterBuilder-or": "Or",
        "dxFilterBuilder-notAnd": "Not And",
        "dxFilterBuilder-notOr": "Not Or",
        "dxFilterBuilder-addCondition": "Add Condition",
        "dxFilterBuilder-addGroup": "Add Group",
        "dxFilterBuilder-enterValueText": "<enter a value>",
        "dxFilterBuilder-filterOperationEquals": "Equals",
        "dxFilterBuilder-filterOperationNotEquals": "Does not equal",
        "dxFilterBuilder-filterOperationLess": "Is less than",
        "dxFilterBuilder-filterOperationLessOrEquals": "Is less than or equal to",
        "dxFilterBuilder-filterOperationGreater": "Is greater than",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Is greater than or equal to",
        "dxFilterBuilder-filterOperationStartsWith": "Starts with",
        "dxFilterBuilder-filterOperationContains": "Contains",
        "dxFilterBuilder-filterOperationNotContains": "Does not contain",
        "dxFilterBuilder-filterOperationEndsWith": "Ends with",
        "dxFilterBuilder-filterOperationIsBlank": "Is blank",
        "dxFilterBuilder-filterOperationIsNotBlank": "Is not blank",
        "dxFilterBuilder-filterOperationBetween": "Is between",
        "dxFilterBuilder-filterOperationAnyOf": "Is any of",
        "dxFilterBuilder-filterOperationNoneOf": "Is none of",

        "dxHtmlEditor-dialogColorCaption": "Change Font Color",
        "dxHtmlEditor-dialogBackgroundCaption": "Change Background Color",
        "dxHtmlEditor-dialogLinkCaption": "Add Link",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Text",
        "dxHtmlEditor-dialogLinkTargetField": "Open link in new window",
        "dxHtmlEditor-dialogImageCaption": "Add Image",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Alternate text",
        "dxHtmlEditor-dialogImageWidthField": "Width (px)",
        "dxHtmlEditor-dialogImageHeightField": "Height (px)",
        "dxHtmlEditor-heading": "Heading",
        "dxHtmlEditor-normalText": "Normal text",

        "dxFileManager-newDirectoryName": "Untitled directory",
        "dxFileManager-rootDirectoryName": "Files",
        "dxFileManager-errorNoAccess": "Access denied. The operation cannot be completed.",
        "dxFileManager-errorDirectoryExistsFormat": "Directory '{0}' already exists.",
        "dxFileManager-errorFileExistsFormat": "File '{0}' already exists.",
        "dxFileManager-errorFileNotFoundFormat": "File '{0}' not found.",
        "dxFileManager-errorDirectoryNotFoundFormat": "Directory '{0}' not found.",
        "dxFileManager-errorWrongFileExtension": "File extension is not allowed.",
        "dxFileManager-errorMaxFileSizeExceeded": "File size exceeds the maximum allowed size.",
        "dxFileManager-errorInvalidSymbols": "This name contains invalid characters.",
        "dxFileManager-errorDefault": "Unspecified error.",

        "dxFileManager-commandCreate": "New directory",
        "dxFileManager-commandRename": "Rename",
        "dxFileManager-commandMove": "Move",
        "dxFileManager-commandCopy": "Copy",
        "dxFileManager-commandDelete": "Delete",
        "dxFileManager-commandDownload": "Download",
        "dxFileManager-commandUpload": "Upload files",
        "dxFileManager-commandRefresh": "Refresh",
        "dxFileManager-commandThumbnails": "Thumbnails View",
        "dxFileManager-commandDetails": "Details View",
        "dxFileManager-commandClear": "Clear selection",

        "dxFileManager-dialogDirectoryChooserTitle": "Select Destination Directory",
        "dxFileManager-dialogDirectoryChooserButtonText": "Select",
        "dxFileManager-dialogRenameItemTitle": "Rename",
        "dxFileManager-dialogRenameItemButtonText": "Save",
        "dxFileManager-dialogCreateDirectoryTitle": "New directory",
        "dxFileManager-dialogCreateDirectoryButtonText": "Create",

        "dxFileManager-editingCreateSingleItemProcessingMessage": "Creating a directory inside {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Created a directory inside {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "Directory wasn't created",
        "dxFileManager-editingCreateCommonErrorMessage": "Directory wasn't created",

        "dxFileManager-editingRenameSingleItemProcessingMessage": "Renaming an item inside {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Renamed an item inside {0}",
        "dxFileManager-editingRenameSingleItemErrorMessage": "Item wasn't renamed",
        "dxFileManager-editingRenameCommonErrorMessage": "Item wasn't renamed",

        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Deleting an item from {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Deleting {0} items from {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Deleted an item from {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Deleted {0} items from {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "Item wasn't deleted",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} items weren't deleted",
        "dxFileManager-editingDeleteCommonErrorMessage": "Some items weren't deleted",

        "dxFileManager-editingMoveSingleItemProcessingMessage": "Moving an item to {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Moving {0} items to {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Moved an item to {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Moved {0} items to {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "Item wasn't moved",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} items weren't moved",
        "dxFileManager-editingMoveCommonErrorMessage": "Some items weren't moved",

        "dxFileManager-editingCopySingleItemProcessingMessage": "Copying an item to {0}",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copying {0} items to {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Copied an item to {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copied {0} items to {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "Item wasn't copied",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} items weren't copied",
        "dxFileManager-editingCopyCommonErrorMessage": "Some items weren't copied",

        "dxFileManager-editingUploadSingleItemProcessingMessage": "Uploading an item to {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Uploading {0} items to {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Uploaded an item to {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Uploaded {0} items to {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "Item wasn't uploaded",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} items weren't uploaded",
        "dxFileManager-editingUploadCanceledMessage": "Anulowano",

        "dxFileManager-listDetailsColumnCaptionName": "Name",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Date Modified",
        "dxFileManager-listDetailsColumnCaptionFileSize": "File Size",

        "dxFileManager-listThumbnailsTooltipTextSize": "Size",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Date Modified",

        "dxFileManager-notificationProgressPanelTitle": "Progress",
        "dxFileManager-notificationProgressPanelEmptyListText": "No operations",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Anulowano",

        "dxDiagram-categoryGeneral": "Główny",
        "dxDiagram-categoryFlowchart": "Flowchart",
        "dxDiagram-categoryOrgChart": "Organizational Chart",
        "dxDiagram-categoryContainers": "Kontener",
        "dxDiagram-categoryCustom": "Custom",
        "dxDiagram-commandProperties": "Właściwości",
        "dxDiagram-commandExport": "Eksport",
        "dxDiagram-commandExportToSvg": "Eksportuj do SVG",
        "dxDiagram-commandExportToPng": "Eksportuj do PNG",
        "dxDiagram-commandExportToJpg": "Eksportuj do JPEG",
        "dxDiagram-commandUndo": "Cofnij",
        "dxDiagram-commandRedo": "Powtórz",
        "dxDiagram-commandFontName": "Nazwa czcionki",
        "dxDiagram-commandFontSize": "Rozmiar czcionki",
        "dxDiagram-commandBold": "Pogrubione",
        "dxDiagram-commandItalic": "Kursywa",
        "dxDiagram-commandUnderline": "Podkreślenie",
        "dxDiagram-commandTextColor": "Kolor tekstu",
        "dxDiagram-commandLineColor": "Kolor lini",
        "dxDiagram-commandFillColor": "Kolor wypełnienia",
        "dxDiagram-commandAlignLeft": "Wyrównanie do lewej",
        "dxDiagram-commandAlignCenter": "Wyśrodkowanie",
        "dxDiagram-commandAlignRight": "Wyrównanie do prawej",
        "dxDiagram-commandConnectorLineType": "Connector Line Type",
        "dxDiagram-commandConnectorLineStraight": "Straight",
        "dxDiagram-commandConnectorLineOrthogonal": "Orthogonal",
        "dxDiagram-commandConnectorLineStart": "Connector Line Start",
        "dxDiagram-commandConnectorLineEnd": "Connector Line End",
        "dxDiagram-commandConnectorLineNone": "None",
        "dxDiagram-commandConnectorLineArrow": "Arrow",
        "dxDiagram-commandAutoLayout": "Auto Layout",
        "dxDiagram-commandAutoLayoutTree": "Tree",
        "dxDiagram-commandAutoLayoutLayered": "Layered",
        "dxDiagram-commandAutoLayoutHorizontal": "Horizontal",
        "dxDiagram-commandAutoLayoutVertical": "Vertical",
        "dxDiagram-commandFullscreen": "Full Screen",
        "dxDiagram-commandUnits": "Units",
        "dxDiagram-commandPageSize": "Page Size",
        "dxDiagram-commandPageOrientation": "Page Orientation",
        "dxDiagram-commandPageOrientationLandscape": "Landscape",
        "dxDiagram-commandPageOrientationPortrait": "Portrait",
        "dxDiagram-commandPageColor": "Page Color",
        "dxDiagram-commandShowGrid": "Show Grid",
        "dxDiagram-commandSnapToGrid": "Snap to Grid",
        "dxDiagram-commandGridSize": "Grid Size",
        "dxDiagram-commandZoomLevel": "Zoom Level",
        "dxDiagram-commandAutoZoom": "Auto Zoom",
        "dxDiagram-commandSimpleView": "Simple View",
        "dxDiagram-commandCut": "Wytnij",
        "dxDiagram-commandCopy": "Kopiuj",
        "dxDiagram-commandPaste": "Wklej",
        "dxDiagram-commandSelectAll": "Zaznacz wszystko",
        "dxDiagram-commandDelete": "Usuń",
        "dxDiagram-commandBringToFront": "Przesuń na wierzch",
        "dxDiagram-commandSendToBack": "Wyślij wstecz",
        "dxDiagram-commandLock": "Zablokowany",
        "dxDiagram-commandUnlock": "Odblokowany",
        "dxDiagram-commandInsertShapeImage": "Wstaw zdjęcie...",
        "dxDiagram-commandEditShapeImage": "Zmień zdjęcie...",
        "dxDiagram-commandDeleteShapeImage": "Usuń zdjęcie",
        "dxDiagram-unitIn": "in",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",
        "dxDiagram-dialogButtonOK": "OK",
        "dxDiagram-dialogButtonCancel": "Anuluj",
        "dxDiagram-dialogInsertShapeImageTitle": "Wstaw zdjęcie",
        "dxDiagram-dialogEditShapeImageTitle": "Zmień zdjęcie",
        "dxDiagram-dialogEditShapeImageSelectButton": "Wybierz zdjęcie",
        "dxDiagram-dialogEditShapeImageLabelText": "lub upuść plik tutaj"
    }
}
