import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  enableProdMode,
  signal,
  inject,
  AfterViewInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import CryptoJS from 'crypto-js';
import { loadMessages, locale } from 'devextreme/localization';
import { Subscription } from 'rxjs';
import enMessages from '../assets/devextreme/localization/messages/en.json';
import plMessages from '../assets/devextreme/localization/messages/pl.json';
import { environment } from '../environments/environment';
import { AppServices } from './app-services.service';
import { DateRange, DateType, GlobalDate } from './core/date-range/GlobalDate';
import { EventService } from './event.service';
import { InterconnectorService } from './interconnector.service';
import { ConfirmDialogConfig } from './core/confirm-dialog/confirm-dialog.model';
import dxPopup, { ResizeEvent, ShownEvent } from 'devextreme/ui/popup';
import { EmailService } from './crm/email/email.service';

import { Meta } from '@angular/platform-browser';
// import { ColorTheme } from './event.model';
import { ChatEventService } from './wapro-assistant/services/chat-event.service';
import { ColorTheme } from './event.model';

// import { DOCUMENT } from '@angular/common';

const helper = new JwtHelperService();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit, AfterViewInit {
  locale: string;
  currentDate: GlobalDate = new GlobalDate();
  rtlEnabled = false;
  isMobile: boolean = false;
  widthMenu = 200;

  userInfo = {
    firstName: '',
    lastName: '',
  };

  timeSession: number = 10;
  timeRefreshToken = 1700;

  isVisiblePopUpSessionEnd: boolean = false;
  param = { timeSession: this.timeSession };
  isInventory: boolean = false;
  session;
  history: any;
  refreshPressed = signal(false);
  refreshPressedSubject: Subscription = null;
  isVisibleLogout = signal(false);
  readonly VAPID_PUBLIC_KEY =
    'BLfO5YRiabfAFvqzmwpKos58YGqvxfPaX3LI6xHQLurEDOZZJJema4MJ0Z7xKtNDmi7uI1xNVsRp-h7__akbEyE';

  @HostListener('window:beforeunload', ['$event']) onClose(event) {
    this.deleteSpecificCookie('MOD_AUTH_CAS');
    this.deleteSpecificCookie('route');
    event.preventDefault();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.event.deviceType = this.deviceType();
    this.event.maxHeightSignal.set(window.innerHeight);
    this.event.maxWidthSignal.set(window.innerWidth);
  }

  @HostListener('window:beforeunload')
  clearForm() {
    this.event.decreaseWindowCount();
  }

  @HostListener('document:keydown', ['$event'])
  onKey(e) {
    if (e.key === 'F5') {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    let orientation = event.target.screen.orientation.type;
    this.event.orientation.set(
      orientation.includes('landscape') ? 'landscape' : 'portrait'
    );
  }

  @ViewChild('InventoryDialog') InventoryDialog;

  activeUrlArray: any[] = [];
  activeUrl: string = '';

  isDynamicConfirmDialogVisible: boolean = false;
  dynamicConfirmDialog: ConfirmDialogConfig = {
    isVisible: false,
    type: 'question',
    btnConfig: 'close',
    header: '',
    text: '',
    dynamicBtns: [],
    radioBoxList: [],
  };
  authToken: string;
  chatApiPath: string = environment.domain + 'assistant/';

  public preferedDarkColorTheme: MediaQueryList = window.matchMedia(
    '(prefers-color-scheme: dark)'
  );

  private translate = inject(TranslateService);
  private appServices = inject(AppServices);
  private interconnector = inject(InterconnectorService);
  private http = inject(HttpClient);
  private cd = inject(ChangeDetectorRef);
  private route = inject(Router);
  private routeActive = inject(ActivatedRoute);
  private meta = inject(Meta);
  protected event = inject(EventService);
  protected chatEvent = inject(ChatEventService);
  private mailService = inject(EmailService);

  constructor() {
    this.preferedDarkColorTheme.addEventListener('change', () => {
      const themeSetting: ColorTheme =
        (localStorage.getItem('colorTheme') as ColorTheme) || 'auto';
      if (themeSetting !== 'auto') return;
      this.event.setColorTheme(themeSetting);
    });

    this.refreshPressedSubject = this.event.refreshKeyPressed.subscribe({
      next: () => {
        this.refreshPressed.set(true);
      },
    });

    if (environment.production) {
      enableProdMode();
    }

    this.event.displayLogoutPopup.pipe(takeUntilDestroyed()).subscribe(() => {
      this.isVisibleLogout.set(true);
    });
    this.event.isInventory.pipe(takeUntilDestroyed()).subscribe((res) => {
      if (
        res &&
        this.route.url !== '/inventory' &&
        this.route.url !== '/opening-balance'
      ) {
        setTimeout(() => {
          this.isInventory = true;
        }, 500);
      }
    });

    this.appServices.confirmDialog.subscribe(
      (e: { event: string; data?: ConfirmDialogConfig | any }) => {
        if (!e) return;
        if (e.event == 'question') {
          let obj: ConfirmDialogConfig = {
            isVisible: false,
            type: 'question',
            btnConfig: 'close',
            header: '',
            text: '',
            dynamicBtns: [],
            radioBoxList: [],
          };
          this.dynamicConfirmDialog = Object.assign(obj, e.data);
        }
        this.cd.detectChanges();
      }
    );

    this.authToken = 'Bearer ' + localStorage.getItem('WAPRO-HTML-token');
  }

  async ngOnInit() {
    if (this.event.isLog) {
      this.event.setInitialColorTheme();
    }

    if (
      this.event.returnEnvironmentInfo() === 'qa' ||
      this.event.returnEnvironmentInfo() === 'stage'
    ) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }

    // this.setScript();
    this.event.isFullscreen.set(document.fullscreenElement !== null);

    if (
      !localStorage.getItem('sessionInfoWaproHtml') &&
      window.localStorage.getItem('WAPRO-HTML-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('WAPRO-HTML-token')).exp * 1000
    ) {
      await this.event.getSessionInformation();
    }
  }

  ngAfterViewInit(): void {
    this.chesckIsExistActiveToken();
    this.initMessages();
    this.event.deviceType = this.deviceType();
    this.initializeTranslation();
    this.setupDevExtremePopup();
    this.setupBroadcastChannels();
    this.initDateAppLocalStorage();
    this.handleNavigationEnd();
    this.initGetRefreshToken();
    this.getLocalTables();
    this.event.increaseWindowCount();
    this.checkIsAppIsReadonly();

    this.handleRouter();

    setTimeout(() => {
      if (
        localStorage.getItem('TGC') &&
        !localStorage.getItem('WAPRO-HTML-token')
      ) {
        localStorage.removeItem('TGC');
        location.reload();
      }
    }, 5000);

    if (this.event.isLog) {
      this.mailService.listenForEmails();
    }
  }

  private initDateAppLocalStorage() {
    // pobranie daty o ile jakas jest w localstore
    //const data = this.setDate(localStorage.getItem('currentDateRange'));
    const data = localStorage.getItem('currentDateRange');
    if (data) {
      const decrypted = this.setDate(this.event.decryptString(data));

      decrypted.dateFrom = new Date(decrypted.dateFrom);
      decrypted.dateTo = new Date(decrypted.dateTo);
      // check if is less < 30 days
      const windowCountStorage = localStorage.getItem('windowCount');
      if (windowCountStorage && parseInt(windowCountStorage) === 1) {
        // sprawdzić
        if (
          this.isWideRange(
            decrypted.range,
            decrypted.dateFrom,
            decrypted.dateTo
          )
        ) {
          this.event.setCurrentDate(new GlobalDate(), false, true);
        } else {
          this.event.setCurrentDate(decrypted, false, true);
        }
      } else {
        this.event.setCurrentDate(decrypted, false, true);
      }
    } else {
      const today = new Date();
      let dateFrom = new Date(new Date().setDate(1));
      let dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      let year = undefined;
      let range: DateRange = 'last30days';
      let type: DateType = 'DateOfSell';
      this.event.setCurrentDate(
        new GlobalDate(range, year, type, {
          dateFrom: dateFrom,
          dateTo: dateTo,
        }),
        false,
        true
      );
    }
  }

  deleteSpecificCookie(name) {
    // Ustawiamy czas wygaśnięcia ciasteczka na wcześniejszą datę
    document.cookie =
      name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  getLocalTables() {
    const tablesString = localStorage.getItem('additionalTables');
    if (!tablesString) {
      return;
    }

    const tablesDecrypted = this.event.decryptString(tablesString);
    if (!this.event.additionalTables.length) {
      this.event.additionalTables = tablesDecrypted;
    }
  }

  ngOnDestroy() {
    this.refreshPressedSubject?.unsubscribe();
  }

  deviceType = () => {
    if (window.innerWidth <= 1000) {
      return 'mobile';
    }

    return 'desktop';
  };

  refreshToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    const payload = new HttpParams()
      .append('grant_type', 'refresh_token')
      .append('redirect_uri', environment.oauthCallbackUrl)
      .append('refresh_token', localStorage.getItem('refresh_token'))
      .append('client_id', environment.CASCLIENTID);
    this.http
      .post(environment.AUTHAPI + 'oauth2.0/accessToken', payload, httpOptions)
      .subscribe((response: any) => {
        this.appServices.headers.set(
          'Authorization',
          'Bearer ' + response.access_token
        );
        localStorage.setItem('WAPRO-HTML-token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
      });
  }

  strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  setLocale(locale: 'pl' | 'en') {
    localStorage.setItem('lang', locale);
  }

  // private setScript() {
  //   if (!environment.HOTJAR_ACTIVE) return;

  //   const s = this.doc.createElement('script');
  //   s.type = 'text/javascript';
  //   s.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3875009,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  //   const head = this.doc.getElementsByTagName('head')[0];
  //   head.appendChild(s);
  // }

  setDate = (date) => {
    const today = new Date();
    let globDate: GlobalDate = null;
    if (date.range == 'lastWeek') {
      date.dateFrom = new Date(new Date().setDate(today.getDate() - 6));
      date.dateTo = today;
      date.year = undefined;
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'last7days') {
      date.dateFrom = new Date(new Date().setDate(today.getDate() - 6));
      date.dateTo = today;
      date.year = undefined;
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'last30days') {
      date.dateFrom = new Date(new Date().setDate(today.getDate() - 29));
      date.dateTo = today;
      date.year = undefined;
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'currentDay') {
      date.dateFrom = today;
      date.dateTo = today;
      date.year = today.getFullYear();
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'currentMonth') {
      date.dateFrom = new Date(new Date().setDate(1));
      date.dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      date.year = today.getFullYear();
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'lastMonth') {
      date.dateFrom = new Date(new Date().setDate(1));
      date.dateTo = new Date(date.dateFrom.setMonth(today.getMonth() - 1));
      date.year = undefined;
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'currentYear') {
      date.dateFrom = new Date(today.getFullYear(), 0, 1);
      date.dateTo = new Date(today.getFullYear(), 12, 0);
      date.year = today.getFullYear();
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'previousYear') {
      date.dateFrom = new Date(today.getFullYear() - 1, 0, 1);
      date.dateTo = new Date(today.getFullYear() - 1, 12, 0);
      date.year = today.getFullYear() - 1;
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'lastYear') {
      date.dateFrom = this.event.getDatePlusDay(-365, today);
      date.dateTo = today;
      date.year = today.getFullYear();
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType);
    } else if (date.range == 'any') {
      localStorage.setItem('currentDateRange', this.event.encryptString(date));
      globDate = new GlobalDate(date.range, date.year, date.dateType, {
        dateFrom: new Date(date.dateFrom),
        dateTo: new Date(date.dateTo),
      });
    }

    return globDate;
  };

  setLanguage = () => {
    const language = {
      Language: localStorage.getItem('lang') || 'pl',
    };
    this.appServices
      .postAuth(`configurations/language`, language)
      .subscribe(() => {});
  };

  getAccessToken(code: string, state: string) {
    return new Promise((resolve) => {
      if (state !== localStorage.getItem('state')) {
        alert('Invalid state');
        return;
      }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };

      const payload = new HttpParams()
        .append('grant_type', 'authorization_code')
        .append('code', code)
        .append('code_verifier', localStorage.getItem('codeVerifier'))
        .append('redirect_uri', environment.oauthCallbackUrl)
        .append('state', localStorage.getItem('state'))
        .append('client_id', environment.CASCLIENTID);
      this.http
        .post(
          environment.AUTHAPI + 'oauth2.0/accessToken',
          payload,
          httpOptions
        )
        .subscribe(
          (response: any) => {
            this.appServices.headers.set(
              'Authorization',
              'Bearer ' + response.access_token
            );
            localStorage.setItem('WAPRO-HTML-token', response.access_token);
            localStorage.setItem('refresh_token', response.refresh_token);
            localStorage.removeItem('TGC');
            resolve(true);
          },
          () => {
            resolve(false);
            localStorage.removeItem('TGC');
            localStorage.removeItem('state');
            localStorage.removeItem('codeVerifier');
          }
        );
    });
  }

  initMessages() {
    loadMessages(plMessages);
    loadMessages(enMessages);
  }

  getLocale() {
    var locale = localStorage.getItem('lang');
    return locale != null ? locale : 'pl';
  }

  navigateToChild(param3: string) {
    this.route.navigate(['/' + param3], { relativeTo: this.routeActive });
  }

  logOut = () => {
    this.event.clearLocalStorage();
    this.route.navigate(['']);
  };

  onClosingIsInventory = () => {
    this.isInventory = false;
    this.cd.detectChanges();
  };

  goToLoginPage() {
    //logownaie cas
    const state = this.strRandom(40);
    const codeVerifier = this.strRandom(128);

    localStorage.setItem('state', state);
    localStorage.setItem('codeVerifier', codeVerifier);
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(
      CryptoJS.enc.Base64
    );
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const params = [
      'response_type=code',
      'state=' + state,
      'client_id=' + environment.CASCLIENTID,
      // 'scope=openid profile email offline_access',
      'code_challenge=' + codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + encodeURIComponent(environment.oauthCallbackUrl),
    ];
    window.location.href =
      environment.AUTHAPI + 'oauth2.0/authorize?' + params.join('&');
    localStorage.setItem('TGC', 'state');
  }

  onClosingRefresh() {
    this.refreshPressed.set(false);
  }

  onContinueRefresh() {
    location.reload();
  }

  onLogout() {
    this.isVisibleLogout.set(false);
    this.event.logout();
  }

  onLogoutClosing() {
    this.isVisibleLogout.set(false);
  }

  isWideRange(range: DateRange, dateFrom: Date, dateTo: Date) {
    if (range === 'any') {
      return !this.isLessThan30Days(dateFrom, dateTo);
    } else {
      return (
        range === 'currentYear' ||
        range === 'lastYear' ||
        range === 'year' ||
        range === 'previousYear'
      );
    }
  }

  // check if substract of two dates is less than 30 days
  isLessThan30Days(dateFrom: Date, dateTo: Date) {
    const diffTime = Math.abs(dateTo.getTime() - dateFrom.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 30;
  }

  onConfirmDialogButtonClicked = (dynamicData, staticData?) => {
    let answer: { dynamicData: any; staticData: any } = {
      dynamicData: dynamicData,
      staticData: staticData,
    };
    this.appServices.confirmDialog.next({ event: 'answer', data: answer });
    this.resetConfirmDialog();
  };

  resetConfirmDialog() {
    this.dynamicConfirmDialog = {
      isVisible: false,
      type: 'question',
      btnConfig: 'close',
      header: '',
      text: '',
      dynamicBtns: [],
      radioBoxList: [],
    };
    this.cd.detectChanges();
  }

  private chesckIsExistActiveToken() {
    if (
      window.localStorage.getItem('WAPRO-HTML-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('WAPRO-HTML-token')).exp * 1000
    ) {
      this.event.decodedToken = helper.decodeToken(
        localStorage.getItem('WAPRO-HTML-token')
      );
      this.event.isLog = true;
    } else {
      this.event.isLog = false;
      this.event.clearLocalStorage();
    }
  }

  private checkIsAppIsReadonly() {
    //sprawdzam wersje i czy aplikacja jest w trybie tylko do odczytu jesli jest ważny token
    if (
      window.localStorage.getItem('WAPRO-HTML-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('WAPRO-HTML-token')).exp * 1000
    ) {
      let warehouseInfo = this.event.decryptString(
        localStorage.getItem('sessionInfoWaproHtml')
      );
      this.event.footerInfo = warehouseInfo;
      //I check warehouse status
      this.event.checkStatusWarehouse(warehouseInfo?.AppWarehouseId);
    }
  }

  private initGetRefreshToken() {
    this.session = setInterval(() => {
      if (localStorage.getItem('WAPRO-HTML-token')) {
        let token =
          helper.decodeToken(localStorage.getItem('WAPRO-HTML-token')).exp *
          1000;

        let newDate = new Date(token).setMinutes(
          new Date(token).getMinutes() - 5
        );

        if (new Date().getTime() >= newDate) {
          if (
            environment.AUTHAPI === 'https://qa-auth.assecobs.com/' ||
            environment.AUTHAPI === 'https://dev-auth.assecobs.com/' ||
            environment.AUTHAPI === 'https://auth.assecobs.com/'
          ) {
            this.refreshToken();
          }
        }
      }
    }, 5000);
  }

  private initializeTranslation(): void {
    this.locale = this.getLocale();
    locale(this.locale);
    this.translate.addLangs(['pl', 'en']);
    const localLanguage = localStorage.getItem('lang');
    this.translate.setDefaultLang(localLanguage || 'pl');
  }

  private handleNavigationEnd() {
    this.route.events.subscribe((e) => {
      this.chatEvent.isAssistantOpen.set(false);
      if (!environment.production || this.event.deviceType === 'mobile') {
        return;
      }
      if (event instanceof NavigationError) {
        window.location.reload();
      }
      if (e instanceof NavigationEnd) {
        if (localStorage.getItem('activeUrlArray')) {
          this.activeUrlArray = JSON.parse(
            localStorage.getItem('activeUrlArray')
          );
        }
        for (let i = 0; i < this.activeUrlArray.length; i++) {
          if (this.activeUrlArray[i] == this.activeUrl) {
            this.activeUrlArray.splice(i, 1);
          }
          localStorage.setItem(
            'activeUrlArray',
            JSON.stringify(this.activeUrlArray)
          );
        }

        let isExist = this.activeUrlArray.findIndex((x) => x == e.url);
        if (
          isExist != -1 &&
          this.activeUrlArray.length > 0 &&
          e.url != '/start/dashboard' &&
          e.url != '/no-permission' &&
          e.url != '/no-permission?log=true' &&
          e.url.indexOf('/library/e-documents') == -1 &&
          e.url != ''
        ) {
          this.route.navigate(['start/dashboard']);
          this.event.showNotification(
            'error',
            'Moduł otwarty na innej zakładce'
          );
        } else {
          this.activeUrl = e.url;
          this.activeUrlArray.push(e.url);
          localStorage.setItem(
            'activeUrlArray',
            JSON.stringify(this.activeUrlArray)
          );
        }
      }
    });
  }

  private setupBroadcastChannels() {
    const channel = new BroadcastChannel('logout');
    const checkStatusWarehouse = new BroadcastChannel('checkStatusWarehouse');

    checkStatusWarehouse.onmessage = (ev: any) => {
      this.event.readOnly = ev.data;
      this.event.sendReadOnlyInfo.next(ev.data);
    };

    channel.onmessage = (ev) => {
      if (ev.data === 'logOutUser') {
        this.event.clearLocalStorage();
        if (
          environment.AUTHAPI === 'https://qa-auth.assecobs.com/' ||
          environment.AUTHAPI === 'https://dev-auth.assecobs.com/' ||
          environment.AUTHAPI === 'https://auth.assecobs.com/'
        ) {
          this.deleteSpecificCookie('MOD_AUTH_CAS');
          this.deleteSpecificCookie('route');

          localStorage.setItem('logOut', 'true');
          window.location.href = `${environment.AUTHAPI}logout?service=https://${location.host}`;
        } else {
          this.route.navigate(['']);
          this.event.isLog = false;
          this.cd.detectChanges();
        }
      }
    };

    const openArrayDocument = new BroadcastChannel('openArrayDocument');
    openArrayDocument.onmessage = (ev: any) => {
      this.event.openArrayDocument = ev.data;
    };

    const reloadChangeComapny = new BroadcastChannel('reloadChangeComapny');
    reloadChangeComapny.onmessage = () => {
      location.reload();
    };
  }

  private setupDevExtremePopup() {
    dxPopup.defaultOptions({
      options: {
        onShowing: () => {
          this.event.setModalOpen(true);
        },
        onHiding: () => {
          this.event.setModalOpen(false);
          setTimeout(() => {
            this.cd.detectChanges();
          }, 0);
        },
        onShown: (e: ShownEvent) => {
          const content = e.component.content();
          const width = content.getBoundingClientRect().width;
          if (width < 1124) {
            content.classList.add('form-1-column');
            content.classList.remove('form-2-columns');
          } else {
            content.classList.add('form-2-columns');
            content.classList.remove('form-1-column');
          }
        },
        visibleChange: () => {
          document.body.classList.remove('modal-open');
          this.cd.detectChanges();
        },
        onResize: (e: ResizeEvent) => {
          if (e.width < 1124) {
            e.component.content().classList.add('form-1-column');
            e.component.content().classList.remove('form-2-columns');
          } else {
            e.component.content().classList.add('form-2-columns');
            e.component.content().classList.remove('form-1-column');
          }
        },
      },
    });
  }

  private handleRouter() {
    this.routeActive.queryParams.subscribe((params) => {
      if (params.code) {
        localStorage.setItem('codeWapro', params.code);
        localStorage.setItem('stateWapro', params.state);

        /* PRZEKIEROWANIE & CAS */
        if (!window.localStorage.getItem('WAPRO-HTML-token')) {
          this.getAccessToken(params.code, params.state).then(async (res) => {
            if (res) {
              for (let element of this.event.elementsToLoad) {
                switch (element) {
                  case 'defaultsParmters':
                    await this.event.getDefaultParameters();
                    break;
                  case 'companyDefaultSettings':
                    await this.event.getCompanyDefaultSettings();
                    break;
                  case 'sessionInformation':
                    const result: boolean =
                      await this.event.getSessionInformation();
                    this.interconnector.getAuthHeaders();
                    if (!result) {
                      return;
                    }
                    break;
                  case 'currentCompany':
                    await this.event.getCurrentCompany();
                    break;
                  case 'permissions':
                    await this.event.getPermissions();
                    break;
                  case 'additionalTablesPermissions':
                    if (this.event.isAdditionalTableAvailable()) {
                      await this.event.getAdditionalTablesPermissions();
                    }
                    break;
                  case 'additionalOperationsPermissions':
                    await this.event.getAdditionalOperationsPermissions();
                    break;
                  // case 'userExtension':
                  //   await this.event.getUserExtensionsLogin();
                  //   break;
                  case 'nests':
                    if (this.event.isVersionForNestAvailable) {
                      // czy wersja == prestizplus
                      await this.event.getNests();
                    }
                    break;
                  case 'tables':
                    await this.event.getTables();
                    break;
                  case 'companyConfiguration':
                    await this.event.getCompanyConfiguration();
                    break;
                  case 'dynamicFilters':
                    await this.event.getDynamicFilters();
                    break;
                  case 'userConfiguration':
                    await this.event.getUserConfiguration();
                    break;
                  case 'language':
                    localStorage.setItem(
                      'lang',
                      localStorage.getItem('lang') || 'pl'
                    );
                    this.setLanguage();
                    break;
                  case 'additionalOperationsGroupCodes':
                    await this.event.additionalOperationsGroupCodes();
                    break;
                  case 'updateCurrencies':
                    await this.event.updateCurrencies();
                    break;
                  case 'nestVariables':
                    await this.event.getNestVariables();
                    break;
                  case 'devices':
                    if (this.event.deviceType === 'desktop') {
                      this.interconnector.getActiveDevices();
                    }
                    break;
                  case 'invoiceDocumentTypes':
                    await this.event.getInvoiceDocumentTypes();
                    break;
                  case 'emailConfiguration':
                    // await this.mailService.getEmailConfiguration();
                    this.mailService.listenForEmails();
                    break;
                  case 'reportsPerDocumentType':
                    if (localStorage.getItem('reportsPerDocumentType')) {
                      localStorage.removeItem('reportsPerDocumentType');
                    }
                    break;
                  // case 'businessLink':
                  //   await this.event.getBusinessLinkConfiguration();
                  //   break;
                  default:
                    break;
                }
              }

              this.route.navigateByUrl(
                this.event.setAppMenu(this.event.footerInfo),
                {
                  state: { load: true },
                }
              );
              this.event.isLog = true;
              this.event.loadingVisible = false;
            }
          });
        }
      }

      if (params.ticket) {
        this.goToLoginPage();
      }
    });
  }
}

export interface DynamicConfirmDialogAnswer {
  dynamicData?: any;
  staticData?: any;
}

export interface DynamicConfirmDialogBehaviorSubject {
  event: 'question' | 'answer';
  data: ConfirmDialogConfig | DynamicConfirmDialogAnswer | any;
}
