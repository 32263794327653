import { Injectable } from '@angular/core';
import { ChatUtilsService } from './chat-utils.service';
import { ChatEventService } from './chat-event.service';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class AudioRecorderService {
  constructor(
    private utils: ChatUtilsService,
    private chatEvent: ChatEventService,
    private chat: ChatService
  ) {}

  public async init(): Promise<Blob> {
    // check if user has given permission to use microphone
    const permissions = await this.utils.getPermissions('microphone');
    if (permissions !== 'granted') {
      try {
        // ask for permission to use microphone
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        return this.startRecording();
      } catch (error) {
        // if user denies permission
        this.chatEvent.emit('error', {
          message: 'No access to the microphone',
          translationSrc: 'waproAssistant.noAccessToMic',
        });
        return <Blob>{};
      }
    } else {
      return this.startRecording();
    }
  }

  audioCtx: AudioContext;
  micInput: Blob[];
  recorder: MediaRecorder;
  source: MediaStreamAudioSourceNode;
  micTimeout: NodeJS.Timeout;

  async startRecording(): Promise<Blob> {
    return new Promise(async (resolve) => {
      this.micInput = [];
      this.audioCtx = new AudioContext();
      let audioBlob = new Blob(undefined, { type: 'audio/webm' });

      this.chat.isMicRecording.set(true);
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        this.source = this.audioCtx.createMediaStreamSource(stream);
        this.recorder = new MediaRecorder(stream);
        this.recorder.ondataavailable = (event) => {
          this.micInput.push(event.data);
          audioBlob = new Blob(this.micInput, { type: 'audio/webm' });

          this.chatEvent.emit('mic-input', { audioBlob });
          this.stop();
          resolve(audioBlob);
        };
        this.recorder.start();

        // Stop recording after 5 seconds and get the audio data
        if (this.micTimeout) clearTimeout(this.micTimeout);
        this.micTimeout = setTimeout(() => {
          // Możesz tutaj dodać dodatkowe przetwarzanie audio
          if (this.chat.isMicRecording()) {
            this.stop();
            resolve(audioBlob);
          }
        }, 10000);
      } catch (err) {
        this.chat.isMicRecording.set(false);
        this.chatEvent.emit('error', {
          message: 'No access to the microphone',
          translationSrc: 'waproAssistant.noAccessToMic',
        });
        resolve(audioBlob);
      }
    });
  }

  stop(): void {
    this.recorder.stop();
    this.recorder?.stream.getTracks().forEach((track) => {
      track.stop();
    });
    this.chat.isMicRecording.set(false);
  }
}
