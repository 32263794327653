<!-- <dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="emailMessageTitle"
  [visible]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [fullScreen]="fullScreen"
  *ngIf="isSerialSendMode"
>
</dx-popup> -->

<app-email-serial-mail
  *ngIf="isNewEmailWindow" 
  [isVisible]="isNewEmailWindow"
  [configuration]="actualAccountConfigurationSet"
  [emailTo]="emailTo"
  [subject]="subject"
  [message]="message"
  [attachment]="attachment"
  [private]="private"
  [emailData]="emailData"
  (onClosing)="onClosingEmail()"
  (onSending)="onSendMail()"
  (onSaving)="onSaveMail()"
>
</app-email-serial-mail>

<app-confirm-dialog
  *ngIf="isViewConfirm"
  kindDialog="question"
  confirmHeader="email.noConfigurationTitle"
  confirmText="email.noConfigurationText"
  [isVisible]="isViewConfirm"
  (onRemoving)="goToEmail()"
  (onClosing)="goClose()"
>
</app-confirm-dialog>
