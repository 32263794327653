import { Injectable } from '@angular/core';
import { ChatEventService } from './chat-event.service';
import { ChatUtilsService } from './chat-utils.service';

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {
  recognition: SpeechRecognition;
  constructor(
    private chatEvent: ChatEventService,
    private utils: ChatUtilsService
  ) {}

  public async init(): Promise<ISpeechRecognitionResult> {
    // check if browser supports speech recognition
    if (
      !('webkitSpeechRecognition' in window) &&
      !('SpeechRecognition' in window)
    ) {
      this.chatEvent.emit('error', 'speechRecognition.noBrowserSupport');
      return <ISpeechRecognitionResult>{
        errorTranslationSrc: 'speechRecognition.noBrowserSupport',
        transcript: '',
      };
    }

    // check if user has given permission to use microphone
    const permissions = await this.utils.getPermissions('microphone');
    if (permissions !== 'granted') {
      try {
        // ask for permission to use microphone
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        return this.startSpeechRecognition();
      } catch (error) {
        // if user denies permission
        this.chatEvent.emit('error', 'waproAssistant.noAccessToMic');
        return <ISpeechRecognitionResult>{
          errorTranslationSrc: 'waproAssistant.noAccessToMic',
          transcript: '',
        };
      }
    } else {
      return this.startSpeechRecognition();
    }
  }

  async startSpeechRecognition(): Promise<ISpeechRecognitionResult> {
    return new Promise((resolve) => {
      let isResolved = false;
      this.recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();

      this.recognition.lang = navigator.language;
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 1;
      this.recognition.continuous = false;

      this.recognition.onresult = (_event) => {
        this.chatEvent.emit('speech-recognition-result', _event.results[0][0].transcript);
        if (_event.results.length > 0) {
          isResolved = true;
          resolve(<ISpeechRecognitionResult>{
            transcript: _event.results[0][0].transcript,
            confidence: _event.results[0][0].confidence,
          });
          this.recognition.stop();
        }
        return;
      };
      this.recognition.onerror = (_event) => {
        isResolved = true;
        resolve(<ISpeechRecognitionResult>{
          errorTranslationSrc: `speechRecognition.${_event.error}`,
          transcript: '',
        });
        this.chatEvent.emit('error', `speechRecognition.${_event.error}`);
        return;
      };
      this.recognition.onend = (_event) => {
        if (!isResolved) {
          resolve(<ISpeechRecognitionResult>{
            transcript: '',
          });
          this.chatEvent.emit('warning', 'speechRecognition.noResult');
        }
        this.recognition.stop();
        return;
      };
      this.recognition.start();
    });
  }

  stop(): void {
    if (this.recognition) {
      this.recognition.stop();
    }
  }
}

export interface ISpeechRecognitionResult {
  transcript: string;
  confidence?: number;
  errorTranslationSrc?: string;
}
