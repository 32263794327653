import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { EventService } from 'src/app/event.service';

@Component({
  selector: 'app-try-for-free',
  imports: [DxPopupModule, DxButtonModule, TranslateModule],
  templateUrl: './try-for-free.component.html',
  styleUrl: './try-for-free.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TryForFreeComponent {
  protected event = inject(EventService);
  @Input() isVisible: boolean = false;
  @Input() registrationLink: string = '';
  @Output() onClosing: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public openLink(): void {
    window.open(this.registrationLink, '_blank');
  }

  public closePopup(): void {
    this.isVisible = false;
    this.onClosing.emit();
  }

  protected onVisibleChangedFinish(isVisible: boolean): void {
    if (!isVisible) {
      this.isVisible = false;
    }
  }
}
