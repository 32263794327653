<app-chat-container
  class="chat-container"
  [ngClass]="{ 'is-loading': isLoading() }"
>
  <app-chat-header
    (mousedown)="onHeaderMouseDown($event)"
    (mouseup)="onHeaderMouseUp($event)"
    (onClose)="close()"
  ></app-chat-header>
  <div class="chat-body" #chatBody>
    <app-messages
      class="chat-messages"
      id="messages"
      *ngIf="history.historySignal().length > 0 || isLoading(); else emptySpace"
    >
      <app-single-message
        *ngFor="let message of history.historySignal(); index as i"
        class="chat-message"
        [ngClass]="'chat-message-' + message.type"
        [message]="message"
        (onChoiceFromMsg)="sendMessage($event)"
        (onReload)="reload()"
        [isLast]="i === history.historySignal().length - 1"
        #singleMessage
      ></app-single-message>
      <app-loading-message *ngIf="isLoading()"></app-loading-message>
    </app-messages>
  </div>

  <div class="chat-footer">
    <ng-container *ngIf="history.historySignal().length > 0">
      <div
        class="chat-settings-container"
        [ngClass]="{ 'is-opened': isSettingsOpened }"
        #settingsContainer
      >
        <button
          class="chat-message-button chat-clear-messages"
          [ngClass]="{
            'is-hidden': isLoading() || history.historySignal().length === 0
          }"
          z-index="1"
          (click)="clearMessages()"
          #clearMessagesButton
        >
          <span class="icon absui-icon--clear-filter"></span>
        </button>
      </div>
      <button
        class="chat-settings-toggle-button"
        (click)="toggleSettings()"
        tabindex="-1"
      >
        <span
          class="icon"
          [ngClass]="{
            'absui-icon--expand-more': isSettingsOpened,
            'absui-icon--expand-less': !isSettingsOpened
          }"
        ></span>
      </button>
    </ng-container>

    <div class="chat-input-container" #chatInputContainer>
      <div class="chat-input-border">
        <textarea
          class="chat-input"
          id="chat-input"
          name="textarea"
          rows="1"
          placeholder="Zadaj mi pytanie..."
          (keyup)="onTextAreaValueChange($event, textArea)"
          (change)="onTextAreaValueChange($event, textArea)"
          (cut)="onTextAreaCut($event, textArea)"
          (paste)="onTextAreaPaste($event, textArea)"
          (drop)="onTextAreaDrag($event, textArea)"
          (keydown)="onTextAreaKeyDown($event, textArea)"
          [value]="textInputValue"
          (input)="onTextInput($event)"
          [disabled]="chat.isMicRecording() || isLoading()"
          #textArea
        ></textarea>
        <audio
          src=""
          controls
          class="chat-input-audio"
          id="audio"
          #audio
        ></audio>
        <button
          class="chat-input-button chat-input-audio-remove"
          id="chat-input-audio-remove"
          (click)="onAudioRemoveButtonClick($event)"
        ></button>
        <button
          class="chat-input-button chat-input-button-mic"
          [ngClass]="{ 'is-listening': chat.isMicRecording() }"
          id="chat-input-button-mic"
          (click)="onMicButtonClick($event)"
          [disabled]="isLoading()"
        >
          <span
            class="icon"
            [ngClass]="{
            'absui-icon--microphone': !chat.isMicRecording(),
          }"
          ></span>
        </button>
        <button
          class="chat-input-button chat-input-button-send"
          id="chat-input-button-send"
          (click)="onSendButtonClick($event)"
          [disabled]="isLoading() || chat.isMicRecording()"
        >
          <span class="icon absui-icon--send"></span>
        </button>
      </div>
    </div>
  </div>
</app-chat-container>

<ng-template #emptySpace>
  <div class="chat-empty-space">
    <app-empty-space id="empty-space"> </app-empty-space>
  </div>
</ng-template>
