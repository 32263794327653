import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-notification',
    imports: [CommonModule],
    templateUrl: './notification.component.html',
    styleUrl: './notification.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['type', 'visible', 'message']
})
export class NotificationComponent {
  @Output() onClose = new EventEmitter();
  type: string = 'info' || 'success' || 'warning' || 'error' || 'neutral';
  visible: boolean = false;
  message: string = '';

  constructor() {}

  closeNotification() {
    this.onClose.emit(true);
  }
}
