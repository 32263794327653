import {
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import {
  Component,
  ContentChildren,
  ElementRef,
  inject,
  QueryList,
} from '@angular/core';
import { ChatService } from '../../services/chat.service';

@Component({
    selector: 'app-empty-space',
    templateUrl: './empty-space.component.html',
    styleUrl: './empty-space.component.scss',
    imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet]
})
export class EmptySpaceComponent {
  public chat = inject(ChatService);
  constructor() {}
  @ContentChildren('slot') slots: QueryList<ElementRef>;

  isSlotEmpty(slotName: string): boolean {
    const slot = this.slots.find(
      (slot) => slot.nativeElement.getAttribute('slot') === slotName
    );
    return !slot || slot.nativeElement.innerHTML.trim() === '';
  }
}
