[
  {
    "id": "1",
    "idPatch": "0",
    "name": "menu.apps",
    "icon": "icon icon-absui-icon--location",
    "url": null,
    "items": [],
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "2",
    "name": "menu.start",
    "idPatch": "1",
    "icon": "icon absui-icon--home-3",
    "url": "start/dashboard",
    "items": [],
    "patch": "",
    "submenu": [
      {
        "id": "21",
        "idPatch": "0",
        "name": "menu.dashboard",
        "url": "start/dashboard",
        "code": null,
        "patch": "menu.start",
        "visible": true,
        "signature": "P",
        "classCss": "menuSignature redHeader"
      },
      {
        "id": "22",
        "idPatch": "1",
        "name": "menu.avp",
        "url": "start/avp",
        "code": null,
        "patch": "menu.start",
        "visible": true,
        "signature": "A",
        "classCss": "menuSignature blueGreyHeader"
      }
    ],
    "tooltip": false,
    "code": null,
    "visible": true
  },
  {
    "id": "3",
    "idPatch": "2",
    "name": "menu.warehouse",
    "patch": "",
    "icon": "icon absui-icon--package-front",
    "url": "warehouse",
    "items": [],
    "tooltip": false,
    "code": "Magazyn",
    "visible": false,
    "submenu": [
      {
        "id": "31",
        "idPatch": "0",
        "name": "menu.warehouseDocuments",
        "url": "warehouse/warehouse-documents",
        "code": "DokMag",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "DM",
        "classCss": "menuSignature redHeader",
        "shortcuts": "Shift+M"
      },
      {
        "id": "32",
        "idPatch": "1",
        "name": "menu.orders",
        "url": "warehouse/orders",
        "code": "Zam",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "Z",
        "classCss": "menuSignature amberSignature",
        "shortcuts": "Shift+W"
      },
      {
        "id": "33",
        "idPatch": "2",
        "name": "menu.productionDocuments",
        "url": "warehouse/production-documents",
        "code": "Prod",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "ZP",
        "classCss": "menuSignature limeSignature",
        "shortcuts": "Shift+P"
      },
      {
        "id": "34",
        "idPatch": "3",
        "name": "menu.intrastateDocuments",
        "url": "warehouse/intrastate-documents",
        "code": "Intrastat",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "DI",
        "classCss": "menuSignature deepOrangeSignature"
      },
      {
        "id": "351",
        "idPatch": "4",
        "name": "menu.sales",
        "url": "warehouse/discounts",
        "code": "Przeceny",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "P",
        "classCss": "menuSignature brownSignature"
      },
      {
        "id": "35",
        "idPatch": "5",
        "name": "menu.sadDocuments",
        "url": "warehouse/sad",
        "code": "Sad",
        "visible": true,
        "patch": "menu.warehouse",
        "signature": "DS",
        "classCss": "menuSignature orangeSignature"
      },

      {
        "id": "36",
        "idPatch": "6",
        "name": "menu.warehouseSelection",
        "url": "warehouse/warehouse-select",
        "code": "Mag",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "M",
        "shortcuts": "Shift+G",
        "classCss": "menuSignature indigoSignature"
      },

      {
        "id": "37",
        "idPatch": "7",
        "name": "form-financial-document.operations",
        "url": null,
        "code": null,
        "visible": true,
        "type": "listHeader",
        "patch": "form-financial-document.operations"
      },

      {
        "id": "38",
        "idPatch": "8",
        "name": "menu.renament",
        "url": "warehouse/inventory",
        "code": "Rem",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "R",
        "shortcut": "Ctrl+Alt+R"
      },

      {
        "id": "39",
        "idPatch": "9",
        "name": "menu.openingBalance",
        "url": "warehouse/opening-balance",
        "code": "Bil",
        "visible": false,
        "patch": "menu.warehouse",
        "signature": "B",
        "shortcut": "Ctrl+Alt+O"
      }
    ]
  },
  {
    "id": "4",
    "idPatch": "3",
    "name": "menu.comercial-operation",
    "icon": "icon absui-icon--table-coins",
    "url": "trade-operations",
    "items": [],
    "patch": "",
    "submenu": [
      {
        "id": "41",
        "idPatch": "0",
        "name": "menu.retailSales",
        "url": "trade-operations/retail-sales",
        "code": "Detal",
        "visible": false,
        "patch": "menu.comercial-operation",
        "signature": "SD",
        "classCss": "menuSignature brownSignature",
        "shortcuts": "Shift+D"
      },
      {
        "id": "42",
        "idPatch": "1",
        "name": "menu.commercialDocuments",
        "url": "trade-operations/trader-documents",
        "code": "DokHandl",
        "visible": false,
        "signature": "DH",
        "patch": "menu.comercial-operation",
        "classCss": "menuSignature indigoSignature",
        "shortcuts": "Shift+H"
      },
      {
        "id": "43",
        "idPatch": "2",
        "name": "menu.settlements",
        "url": "trade-operations/settlements",
        "code": "Rozr",
        "visible": false,
        "patch": "menu.comercial-operation",
        "signature": "R",
        "classCss": "menuSignature blueSignature",
        "shortcuts": "Shift+R"
      },
      {
        "id": "44",
        "idPatch": "3",
        "name": "menu.financiaDocuments",
        "url": "trade-operations/finances-documents",
        "code": "DokFin",
        "visible": false,
        "patch": "menu.comercial-operation",
        "signature": "DF",
        "classCss": "menuSignature cyanSignature",
        "shortcuts": "Shift+F"
      },
      {
        "id": "45",
        "idPatch": "4",
        "name": "menu.change-cash-box",
        "url": "trade-operations/change-cash-box",
        "code": null,
        "visible": true,
        "patch": "menu.comercial-operation",
        "signature": "K",
        "classCss": "menuSignature blueGreyHeader"
      },
      {
        "id": "46",
        "idPatch": "5",
        "name": "constractors.fastSteps",
        "url": null,
        "code": null,
        "type": "listHeader",
        "visible": true,
        "patch": "constractors.fastSteps"
      },
      {
        "id": "47",
        "idPatch": "6",
        "name": "menu.sale",
        "url": "trade-operations/sales",
        "code": "Sprzedaz",
        "visible": false,
        "patch": "menu.comercial-operation",
        "signature": "DH",
        "classCss": "menuSignature indigoSignature",
        "shortcut": "Ctrl+Alt+S"
      },
      {
        "id": "48",
        "idPatch": "7",
        "name": "menu.purchase",
        "url": "trade-operations/buy",
        "code": "Zakup",
        "visible": false,
        "patch": "menu.comercial-operation",
        "signature": "DH",
        "classCss": "menuSignature indigoSignature",
        "shortcut": "Ctrl+Alt+Z"
      }
    ],
    "tooltip": false,
    "code": "OperacjeHandl",
    "visible": false
  },
  {
    "id": "5",
    "name": "CRM",
    "idPatch": "4",
    "icon": "icon absui-icon--control-date-time",
    "url": "crm",
    "items": [],
    "patch": "",
    "submenu": [
      {
        "id": "51",
        "idPatch": "0",
        "name": "menu.taskList",
        "url": "crm/crm-tasks",
        "code": "CrMZadania",
        "visible": false,
        "patch": "CRM",
        "signature": "ZC",
        "classCss": "menuSignature tealSignature",
        "shortcuts": "Shift+C"
      },
      {
        "id": "58",
        "idPatch": "1",
        "name": "menu.taskKanban",
        "url": "crm/crm-kanban",
        "code": "CrMZadania",
        "visible": false,
        "patch": "CRM",
        "class": "tasks-step-1",
        "signature": "TZ",
        "classCss": "menuSignature tealSignature"
      },
      {
        "id": "52",
        "idPatch": "2",
        "name": "menu.taskCalendar",
        "url": "crm/task-calendars",
        "code": "CrMKalendarz",
        "visible": false,
        "patch": "CRM",
        "signature": "KZ",
        "classCss": "menuSignature tealSignature"
      },
      {
        "id": "53",
        "idPatch": "3",
        "name": "menu.eMail",
        "url": "crm/email",
        "code": "Serwerpocztowy",
        "visible": false,
        "patch": "CRM",
        "signature": "EM",
        "classCss": "menuSignature blueGreyHeader"
      },
      {
        "id": "55",
        "idPatch": "4",
        "name": "menu.serviceOrders",
        "url": "crm/service-orders",
        "code": "SerwisZlec",
        "visible": false,
        "patch": "CRM",
        "signature": "ZS",
        "classCss": "menuSignature limeSignature",
        "shortcuts": "Shift+I"
      },
      {
        "id": "59",
        "idPatch": "5",
        "name": "menu.ordersKanban",
        "url": "crm/service-orders-kanban",
        "code": "SerwisZlec",
        "visible": false,
        "patch": "CRM",
        "signature": "TZ",
        "classCss": "menuSignature limeSignature"
      },
      {
        "id": "56",
        "idPatch": "6",
        "name": "menu.offers",
        "url": "crm/offers",
        "code": "Ofe",
        "visible": false,
        "patch": "CRM",
        "signature": "O",
        "classCss": "menuSignature lightGreen",
        "shortcuts": "Shift+O"
      },
      {
        "id": "57",
        "idPatch": "7",
        "name": "menu.courierParcels",
        "url": "crm/parcels",
        "code": "Paczki",
        "visible": false,
        "patch": "CRM",
        "signature": "PK",
        "classCss": "menuSignature yellowSignature"
      }
    ],
    "tooltip": false,
    "code": "CrM",
    "visible": false
  },
  {
    "id": "6",
    "idPatch": "5",
    "name": "menu.reports",
    "icon": "icon absui-icon--stats",
    "url": "reports",
    "items": [],
    "submenu": [
      {
        "id": "61",
        "idPatch": "0",
        "name": "menu.stocksAndReservations",
        "url": "reports/stock-and-reservations",
        "code": "RapObrStanMag",
        "visible": false,
        "signature": "SM",
        "classCss": "menuSignature redHeader",
        "patch": "menu.reports"
      },
      {
        "id": "62",
        "idPatch": "1",
        "name": "menu.ordersAndReservations",
        "url": "reports/orders-and-reservations",
        "code": "RapStanZamRez",
        "visible": false,
        "signature": "SZ",
        "classCss": "menuSignature amberSignature",
        "patch": "menu.reports"
      },
      {
        "id": "63",
        "idPatch": "2",
        "name": "menu.statements",
        "url": "reports/statements",
        "code": "RapWydrZest",
        "visible": false,
        "signature": "Z",
        "classCss": "menuSignature lightGreen",
        "patch": "menu.reports"
      },

      {
        "id": "64",
        "idPatch": "3",
        "name": "menu.liquidityAnalysis",
        "url": "reports/stock-availability",
        "code": "RapAnalPlynnosci",
        "visible": false,
        "signature": "AP",
        "patch": "menu.reports",
        "classCss":"blueSignature"
      },
      {
        "id": "65",
        "idPatch": "4",
        "name": "menu.changeDate",
        "url": null,
        "signalName": "",
        "code": "changeDate",
        "visible": true,
        "type": "popup",
        "signature": "D",
        "patch": "menu.reports"
      }
    ],
    "tooltip": false,
    "code": "Rap",
    "visible": false,
    "patch": ""
  },
  {
    "id": "7",
    "idPatch": "6",
    "name": "menu.library",
    "icon": "icon absui-icon--sheets",
    "url": "library",
    "items": [],
    "patch": "",
    "submenu": [
      {
        "id": "71",
        "idPatch": "0",
        "name": "menu.articles",
        "url": "library/articles",
        "code": "Artyk",
        "visible": false,
        "patch": "menu.library",
        "signature": "A",
        "classCss": "menuSignature brownSignature",
        "shortcuts": "Shift+A"
      },
      {
        "id": "72",
        "idPatch": "1",
        "name": "menu.relatedToArticles",
        "url": null,
        "patch": "",
        "class": "articles-step-1",
        "classCss": "menuSignature brownSignature",
        "signature": "PA",
        "items": [
          {
            "id": "721",
            "idPatch": "1",
            "name": "menu.assortmentCategories",
            "url": "library/products-categories",
            "code": "KatAsort",
            "patch": "menu.library",
            "patch2": "menu.relatedToArticles",
            "visible": false
          },
          {
            "id": "722",
            "idPatch": "1",
            "name": "menu.priceBoxes",
            "url": "library/prices",
            "code": "GrupCen",
            "patch": "menu.library",
            "patch2": "menu.relatedToArticles",
            "visible": false
          },
          {
            "id": "723",
            "idPatch": "1",
            "name": "menu.measureUnits",
            "url": "library/size-units",
            "code": "JedMiar",
            "patch": "menu.library",
            "patch2": "menu.relatedToArticles",
            "visible": false
          },
          {
            "id": "724",
            "idPatch": "1",
            "name": "menu.vatPrices",
            "url": "library/vat-prices",
            "code": "StawkiVAT",
            "patch": "menu.library",
            "patch2": "menu.relatedToArticles",
            "visible": false
          },
          {
            "id": "725",
            "idPatch": "1",
            "name": "menu.multiLevelCategories",
            "url": "library/categories-tree",
            "code": "KatAsortTree",
            "patch": "menu.library",
            "patch2": "menu.relatedToArticles",
            "visible": false
          },
          {
            "id": "726",
            "idPatch": "1",
            "name": "menu.locations",
            "url": "library/locations",
            "code": "LokalizMagazynu",
            "patch": "menu.library",
            "patch2": "menu.relatedToArticles",
            "visible": false
          },
          {
            "id": "727",
            "idPatch": "1",
            "name": "menu.packagingTypes",
            "url": "library/packaging-types",
            "code": "",
            "patch": "menu.library",
            "patch2": "menu.relatedToArticles",
            "visible": false
          }
        ]
      },
      {
        "id": "73",
        "idPatch": "2",
        "name": "menu.contractors",
        "url": "library/customers",
        "code": "Kontr",
        "patch": "menu.library",
        "classCss": "menuSignature greenSignature",
        "visible": false,
        "signature": "K",
        "shortcuts": "Shift+K"
      },
      {
        "id": "74",
        "idPatch": "3",
        "name": "menu.relatedToContractors",
        "url": null,
        "class": "contractors-step-1",
        "classCss": "menuSignature greenSignature",
        "signature": "PK",
        "items": [
          {
            "id": "741",
            "idPatch": "3",
            "name": "menu.classificationOfContractors",
            "url": "library/customer-classifications",
            "code": "TypyKontr",
            "patch": "menu.library",
            "patch2": "menu.relatedToContractors",
            "visible": false
          },
          {
            "id": "742",
            "idPatch": "3",
            "name": "menu.priceGroups",
            "url": "library/customer-groups",
            "code": "GrupCen",
            "visible": false,
            "patch": "menu.library",
            "patch2": "menu.relatedToContractors"
          },
          {
            "id": "743",
            "idPatch": "3",
            "name": "menu.distinguishCodes",
            "url": "library/identity-masks",
            "patch": "menu.library",
            "patch2": "menu.relatedToContractors"
          },
          {
            "id": "744",
            "idPatch": "3",
            "name": "routes.routes",
            "url": "library/routes",
            "patch": "menu.library",
            "patch2": "menu.relatedToContractors"
          }
        ],
        "patch": "menu.library"
      },
      {
        "id": "75",
        "idPatch": "4",
        "name": "menu.workers",
        "url": "library/employees",
        "code": "Prac",
        "patch": "menu.library",
        "classCss": "menuSignature pinkSignature",
        "signature": "P",
        "visible": false
      },
      {
        "id": "76",
        "idPatch": "5",
        "name": "menu.offices",
        "url": "library/offices",
        "code": "Urzedy",
        "classCss": "menuSignature purpleSignature",
        "patch": "menu.offices",
        "signature": "U",
        "visible": false
      },
      {
        "id": "77",
        "idPatch": "6",
        "name": "menu.financialLibraries",
        "url": null,
        "patch": "menu.library",
        "classCss": "menuSignature cyanSignature",
        "signature": "KF",
        "items": [
          {
            "id": "771",
            "idPatch": "6",
            "name": "menu.cashBoxes",
            "url": "library/cash-boxes",
            "code": "Kasy",
            "patch": "menu.library",
            "patch2": "menu.financialLibraries",
            "visible": false
          },
          {
            "id": "772",
            "idPatch": "6",
            "name": "menu.banks",
            "url": "library/banks",
            "code": "Banki",
            "patch": "menu.library",
            "patch2": "menu.financialLibraries",
            "visible": false
          },
          {
            "id": "773",
            "idPatch": "6",
            "name": "menu.currency",
            "url": "library/currency",

            "code": "Waluty",
            "patch": "menu.library",
            "patch2": "menu.financialLibraries",
            "visible": false
          },
          {
            "id": "774",
            "idPatch": "6",
            "name": "constractors.interestTable",
            "url": "library/interest",

            "code": "TabOds",
            "patch": "menu.library",
            "patch2": "menu.financialLibraries",
            "visible": false
          },
          {
            "id": "775",
            "idPatch": "6",
            "name": "menu.formsOfPayment",
            "url": "library/payment-forms",

            "code": "FormPlat",
            "patch": "menu.library",
            "patch2": "menu.financialLibraries",
            "visible": false
          }
        ]
      },
      {
        "id": "78",
        "idPatch": "7",
        "signature": "FD",
        "name": "menu.formsOfDelivery",
        "url": "library/delivery",
        "classCss": "menuSignature yellowSignature",
        "patch": "menu.library"
      },
      {
        "id": "79",
        "idPatch": "8",
        "signature": "TZ",
        "name": "menu.taskTypes",
        "url": "library/task-types",
        "code": "CrmTyp",
        "classCss": "menuSignature tealSignature",
        "patch": "menu.library",
        "visible": false
      },
      {
        "id": "710",
        "idPatch": "9",
        "name": "menu.crmCalendars",
        "classCss": "menuSignature tealSignature",
        "url": "library/crm-calendars",
        "code": "CrmKalendarzS",
        "patch": "menu.library",
        "signature": "KC",
        "visible": false
      },
      {
        "id": "711",
        "idPatch": "10",
        "name": "menu.eDocuments",
        "classCss": "menuSignature blueGreyHeader",
        "url": "library/e-documents",
        "code": "eDokDokSystem",
        "patch": "menu.library",
        "signature": "eD",
        "visible": false
      },
      {
        "id": "712",
        "name": "menu.relatedToService",
        "patch": "menu.library",
        "idPatch": "11",
        "classCss": "menuSignature limeSignature",
        "url": null,
        "signature": "PS",
        "items": [
          {
            "id": "7111",
            "idPatch": "11",
            "name": "menu.servicesGroups",
            "url": "library/services-groups",
            "code": "SerwisGrupa",
            "visible": false,

            "patch": "menu.library",
            "patch2": "menu.relatedToService"
          },
          {
            "id": "7112",
            "idPatch": "11",
            "name": "servicesDevices.rolesOfContractors",
            "url": "library/role-customer",
            "code": "Kartoteki",
            "patch": "menu.library",
            "patch2": "menu.relatedToService"
          },
          {
            "id": "7113",
            "idPatch": "11",
            "name": "menu.devices",
            "url": "library/devices",

            "code": "SerwisUrzadzenie",
            "patch": "menu.library",
            "patch2": "menu.relatedToService",
            "visible": false
          },
          {
            "id": "7114",
            "idPatch": "11",
            "name": "menu.typesOrders",
            "url": "library/order-type",

            "code": "SerwisTypZlecenie",
            "patch": "menu.library",
            "patch2": "menu.relatedToService",
            "visible": false
          }
        ]
      }
    ],
    "tooltip": false,
    "code": "Kartoteki",
    "visible": false
  },
  {
    "id": "8",
    "idPatch": "7",
    "name": "menu.other",
    "icon": "icon absui-icon--set-settings",
    "url": "other",
    "items": [],
    "patch": "",
    "signature": "BD",
    "submenu": [
      {
        "id": "81",
        "idPatch": "0",
        "name": "menu.documentsBuffer",
        "url": "other/documents-buffer",
        "code": "BUF_Dokument",
        "signature": "BD",
        "patch": "menu.other",
        "visible": false,
        "classCss": "menuSignature BufferHeader"
      },
      {
        "id": "82",
        "idPatch": "1",
        "name": "menu.BussinesLink",
        "url": "other/bussines-link",
        "code": null,
        "signature": "BL",
        "patch": "menu.other",
        "visible": true,
        "classCss": "menuSignature BusinesslinkHeader"
      },
      {
        "id": "83",
        "idPatch": "2",
        "name": "form-financial-document.operations",
        "url": null,
        "code": null,
        "type": "listHeader",
        "visible": true,
        "patch": "form-financial-document.operations"
      },
      {
        "id": "84",
        "idPatch": "3",
        "name": "menu.exportFakir",
        "url": null,
        "code": "EksportWFFAKIR",
        "visible": false,
        "patch": "menu.other",
        "type": "popup",
        "signature": null,
        "classCss": "menuSignature indigoSignature"
      }
    ],
    "tooltip": false,
    "code": "Inne",
    "visible": false
  },
  {
    "id": "9",
    "idPatch": "8",
    "name": "menu.administrator",
    "icon": "icon absui-icon--settings-user",
    "url": "administrator/user-configuration",
    "patch": "",
    "items": [],
    "submenu": [
      {
        "id": "91",
        "idPatch": "0",
        "name": "menu.configuration",
        "classCss": "menuSignature blueGreyHeader",
        "url": null,
        "signature": "K",
        "items": [
          {
            "id": "911",
            "idPatch": "0",

            "name": "menu.userConfiguration",
            "url": "administrator/user-configuration",
            "code": null,
            "visible": true,
            "patch": "menu.administrator",
            "patch2": "menu.configuration"
          },
          {
            "id": "912",
            "idPatch": "0",

            "name": "menu.companyConfiguration",
            "url": "administrator/company-configuration",
            "code": null,
            "visible": true,
            "patch": "menu.administrator",
            "patch2": "menu.configuration"
          },
          {
            "id": "913",
            "idPatch": "0",

            "name": "menu.packagesConfiguration",
            "url": "administrator/packages-configuration",
            "code": null,
            "visible": true,
            "patch": "menu.administrator",
            "patch2": "menu.configuration"
          }
        ],
        "patch": "menu.administrator",
        "code": "KonfSys",
        "visible": false
      },
      {
        "id": "93",
        "signature": "UG",
        "classCss": "menuSignature pinkSignature",
        "idPatch": "1",
        "name": "menu.userAndGroups",
        "url": null,
        "patch": "menu.administrator",
        "items": [
          {
            "id": "931",
            "idPatch": "1",

            "name": "menu.users",
            "url": "administrator/users",
            "code": "KonfUzyt",
            "patch": "menu.administrator",
            "patch2": "menu.userAndGroups",
            "visible": false
          },
          {
            "id": "932",
            "idPatch": "1",

            "name": "menu.groups",
            "patch": "menu.administrator",
            "patch2": "menu.userAndGroups",
            "url": "administrator/group",
            "code": "OGrup",
            "visible": false
          },
          {
            "id": "933",
            "idPatch": "1",

            "patch": "menu.administrator",
            "patch2": "menu.userAndGroups",
            "name": "menu.userActivity",
            "url": "administrator/user-activity",
            "items": [],
            "code": "Log",
            "visible": false
          }
        ]
      },
      {
        "id": "92",
        "idPatch": "2",
        "name": "menu.externalDevices",
        "url": null,
        "signature": "UZ",
        "classCss": "menuSignature lightBlueSignature",
        "code": "UrzZew",
        "visible": false,
        "patch": "menu.administrator",
        "items": [
          {
            "id": "921",
            "idPatch": "2",
            "patch": "menu.administrator",
            "patch2": "menu.externalDevices",
            "name": "menu.fiscalPrinters",
            "url": "administrator/fiscal-printers",
            "code": "DrukFis",
            "visible": false
          },
          {
            "id": "922",
            "idPatch": "2",
            "patch": "menu.administrator",
            "patch2": "menu.externalDevices",
            "name": "menu.labelPrinters",
            "url": "administrator/label-printers",
            "code": "DrukEtyk",
            "visible": true
          },
          {
            "id": "923",
            "idPatch": "2",
            "patch": "menu.administrator",
            "patch2": "menu.externalDevices",
            "name": "menu.documentPrinters",
            "url": "administrator/document-printers",
            "visible": true
          },
          {
            "id": "924",
            "idPatch": "2",
            "patch": "menu.administrator",
            "patch2": "menu.externalDevices",
            "name": "dataCollectors.title",
            "url": "administrator/data-collectors",
            "code": "KolDan",
            "visible": false
          }
        ]
      },
      {
        "id": "95",
        "idPatch": "3",
        "name": "menu.definitions",
        "patch": "menu.administrator",
        "classCss": "menuSignature blueGreyHeader",
        "url": null,
        "signature": "D",
        "items": [
          {
            "id": "951",
            "idPatch": "3",
            "name": "menu.documentTypes",
            "url": null,

            "patch": "menu.administrator",
            "patch2": "menu.definitions",
            "items": [
              {
                "id": "9511",
                "idPatch": "3",
                "name": "menu.warehouse-documents",
                "url": "administrator/warehouse-document-type",
                "code": "TypDokMag",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentTypes",
                "visible": false
              },
              {
                "id": "9512",
                "idPatch": "3",
                "name": "menu.invoice-documents",
                "url": "administrator/invoice-document-type",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentTypes",
                "code": "TypDokHandl",
                "visible": false
              },
              {
                "id": "9513",
                "idPatch": "3",
                "name": "menu.finance-documents",
                "url": "administrator/finance-document-type",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentTypes",
                "code": "TypDokFin",
                "visible": false
              },
              {
                "id": "9514",
                "idPatch": "3",
                "name": "menu.order",
                "url": "administrator/order-document-type",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentTypes",
                "code": "TypDokZam",
                "visible": false
              },
              {
                "id": "9515",
                "idPatch": "3",
                "name": "menu.offer",
                "url": "administrator/offer-document-type",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentTypes",
                "code": "TypDokOfe",
                "visible": false
              }
            ]
          },
          {
            "id": "952",
            "idPatch": "3",
            "name": "menu.documentStatuses",
            "patch": "menu.administrator",

            "patch2": "menu.definitions",
            "url": null,
            "items": [
              {
                "id": "9521",
                "idPatch": "3",

                "name": "status-type.orderStatus",
                "url": "administrator/status/Z",
                "code": "StatusyZam",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentStatuses",
                "visible": false
              },
              {
                "id": "9522",
                "idPatch": "3",

                "name": "menu.statusOfServiceOrders",
                "url": "administrator/status/S",
                "code": "StatusySerwisZlec",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentStatuses",
                "visible": false
              },
              {
                "id": "9523",
                "idPatch": "3",

                "name": "menu.offerStatuses",
                "url": "administrator/status/O",
                "code": "StatusyOfert",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentStatuses",
                "visible": false
              },
              {
                "id": "9524",
                "idPatch": "3",

                "name": "menu.businesslinkStarises",
                "url": "administrator/status/B",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentStatuses",
                "visible": true
              },
              {
                "id": "9525",
                "idPatch": "3",

                "name": "form-commercial-operation.commercialDocumentStatuses",
                "url": "administrator/status/H",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentStatuses",
                "visible": true
              },
              {
                "id": "9526",
                "idPatch": "3",

                "name": "form-commercial-operation.crmTasksStatuses",
                "url": "administrator/status/C",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentStatuses",
                "visible": true
              },
              {
                "id": "9527",
                "idPatch": "3",

                "name": "form-commercial-operation.warehouseDocumentsStatuses",
                "url": "administrator/status/M",
                "patch": "menu.administrator",
                "patch2": "menu.definitions",
                "patch3": "menu.documentStatuses",
                "visible": true
              }
            ]
          },
          {
            "id": "953",
            "idPatch": "3",

            "name": "menu.documentNumbering",
            "url": "administrator/numeration",
            "items": [],
            "code": "Numeracja",
            "patch": "menu.administrator",
            "patch2": "menu.definitions",
            "visible": false
          },
          {
            "id": "954",
            "idPatch": "3",
            "name": "menu.labels",

            "url": "administrator/labels",
            "items": [],
            "code": "EtykDok",
            "patch": "menu.administrator",
            "patch2": "menu.definitions",
            "visible": false
          },
          {
            "id": "955",
            "idPatch": "3",

            "name": "menu.user-extensions",
            "url": "administrator/user-extensions",
            "code": "PolaDodatkowe",
            "patch": "menu.administrator",
            "patch2": "menu.definitions",
            "visible": false
          },
          {
            "id": "956",

            "idPatch": "3",
            "name": "menu.additionalTables",
            "url": "administrator/additional-tables",
            "code": "TabeleDodatkowe",
            "patch": "menu.administrator",
            "patch2": "menu.definitions",
            "visible": false
          },
          {
            "id": "957",
            "idPatch": "3",

            "name": "menu.additionalDictionaries",
            "url": "administrator/additional-dictionaries",
            "code": "SlownikiDodatkowe",
            "patch": "menu.administrator",
            "patch2": "menu.definitions",
            "visible": false
          },
          {
            "id": "958",
            "idPatch": "3",

            "name": "menu.expansionSlote",
            "url": "administrator/expansion-slots",
            "code": "GniazdaRozProg",
            "patch": "menu.administrator",
            "patch2": "menu.definitions",
            "visible": false
          }
        ]
      },
      {
        "id": "96",
        "idPatch": "4",
        "name": "menu.repairFunctions",
        "signature": "FN",
        "classCss": "menuSignature purpleSignature",
        "url": null,
        "items": [
          {
            "id": "961",
            "idPatch": "4",

            "name": "menu.functionsData",
            "url": "administrator/repair-functions",
            "code": null,
            "patch": "menu.administrator",
            "patch2": "menu.repairFunctions",
            "visible": true
          }
        ],
        "patch": "menu.administrator",
        "code": "NapStan",
        "visible": false
      },

      {
        "id": "97",
        "idPatch": "5",
        "name": "load.unsavedDocuments",
        "url": "administrator/unsaved-documents",
        "items": [],
        "signature": "DN",
        "classCss": "menuSignature redHeader",
        "patch": "menu.administrator",
        "code": "DokNiez",
        "visible": true
      },
      {
        "id": "98",
        "idPatch": "6",
        "name": "menu.companies",
        "url": "administrator/companies",
        "items": [],
        "classCss": "menuSignature deepPurple",
        "signature": "F",
        "patch": "menu.administrator",
        "code": null,
        "visible": true
      }
    ],
    "tooltip": false,
    "code": "Administrator",
    "visible": false
  }
]
