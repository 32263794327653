[
  {
    "id": "1",
    "idPatch": "0",
    "name": "menu.apps",
    "icon": "icon icon-absui-icon--location",
    "url": null,
    "items": [],
    "submenu": [],
    "tooltip": false,
    "code": null,
    "visible": false
  },
  {
    "id": "2",
    "name": "menu.gdpr",
    "idPatch": "1",
    "icon": "icon absui-icon--lock-rodo",
    "url": "gdpr",
    "items": [],
    "patch": "",
    "code": "gdpr",
    "tooltip": false,
    "visible": false,
    "submenu": [
      {
        "id": "21",
        "idPatch": "0",
        "name": "menu.dataSets",
        "url": "gdpr/data-sets",
        "visible": false,
        "patch": null,
        "classCss": "redHeader",
        "shortcuts": ""
      },
      {
        "id": "22",
        "idPatch": "1",
        "name": "menu.processingActivities",
        "url": "gdpr/processing-activities",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "23",
        "idPatch": "2",
        "name": "menu.dataProcessingConsents",
        "url": "gdpr/data-processing-consents",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "24",
        "idPatch": "3",
        "name": "menu.externalAuthorizations",
        "url": "gdpr/external-authorizations",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "25",
        "idPatch": "4",
        "name": "menu.personalDataExports",
        "url": "gdpr/personal-data-exports",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "26",
        "idPatch": "5",
        "name": "menu.violations",
        "url": "gdpr/violations",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "27",
        "idPatch": "6",
        "name": "menu.requests",
        "url": "gdpr/requests-personal-data",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "28",
        "idPatch": "7",
        "name": "menu.dataAnonymization",
        "url": "gdpr/data-anonymization",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "29",
        "idPatch": "8",
        "name": "menu.gdprDocuments",
        "url": "gdpr/documents",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "30",
        "idPatch": "9",
        "name": "gdpr.searchForData",
        "url": "gdpr/search-for-data",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      }
    ]
  },
  {
    "id": "3",
    "name": "menu.registersСhanges",
    "idPatch": "2",
    "icon": "icon absui-icon--review-track-changes",
    "url": "changes",
    "items": [],
    "patch": "",
    "submenu": [
      {
        "id": "33",
        "idPatch": "0",
        "name": "menu.changesPersonalData",
        "url": "changes/personal-data",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "34",
        "idPatch": "1",
        "name": "menu.changesAuthorizations",
        "url": "changes/permissions-changes",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      }
    ],
    "tooltip": false,
    "code": "registersСhanges",
    "visible": true
  },
  {
    "id": "5",
    "name": "menu.definitionsGdpr",
    "idPatch": "4",
    "icon": "icon absui-icon--script-user",
    "url": "definitions",
    "items": [],
    "patch": "",
    "submenu": [
      {
        "id": "51",
        "idPatch": "0",
        "name": "gdpr.entities",
        "url": "definitions/entities",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "52",
        "idPatch": "1",
        "name": "gdpr.dataProcessingPrograms",
        "url": "definitions/programs",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "53",
        "idPatch": "2",
        "name": "gdpr.categoriesOfPeople",
        "url": "definitions/person-categories",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "54",
        "idPatch": "3",
        "name": "gdpr.audienceCategories",
        "url": "definitions/audience-categories",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "55",
        "idPatch": "4",
        "name": "gdpr.categoriesOfDataProcessing",
        "url": "definitions/data-processing-categories",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "56",
        "idPatch": "5",
        "name": "gdpr.purposesOfDataProcessing",
        "url": "definitions/data-processing-purposes",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "57",
        "idPatch": "6",
        "name": "gdpr.theBasisForProcessingData",
        "url": "definitions/bases",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "58",
        "idPatch": "7",
        "name": "gdpr.contentOfConsents",
        "url": "definitions/content-of-consents",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "59",
        "idPatch": "8",
        "name": "gdpr.sourcesOfObtainingAndWithdrawingConsent",
        "url": "definitions/data-sources",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "60",
        "idPatch": "9",
        "name": "gdpr.dataRange",
        "url": "definitions/data-ranges",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      },
      {
        "id": "61",
        "idPatch": "10",
        "name": "constractors.registeredTables",
        "url": "definitions/registered-tables",
        "code": null,
        "visible": false,
        "patch": null,
        "shortcuts": ""
      }
    ],
    "tooltip": false,
    "code": "definitionsGdpr",
    "visible": true
  }
]

