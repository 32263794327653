//idPath powinno być ustawione na idPatch pierwszego poziomu submenu. pierwszy poziom submenu ma idPatch numerowany od 0, każdy kolejny poziom ma idPath równy idPatch pierwszego poziomu do którego nalezy.
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Socket, SocketMap } from './../../socket.model';
import { NestService } from './../../nest-service.service';
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
  signal,
  inject,
  WritableSignal,
  Signal,
  computed,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '../../event.service';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterModule,
} from '@angular/router';
import { AppServices } from '../../app-services.service';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import DataSource from 'devextreme/data/data_source';

import { first, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { NotificationComponent } from '../notification/notification.component';

import { ButtonType } from 'devextreme/common';
import dataMenu from './menu.json';
import dataMenuJpk from './menu-jpk.json';
import dataMenuFK from './menu-fk.json';
import dataMenuAuctions from './menu-auctions.json';
import dataMenuGdpr from './menu-gdpr.json';
import { HelpService } from '../../help-service.service';
import { TableUserExtensionComponent } from 'src/app/core/table-user-extension/table-user-extension.component';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDrawerModule,
  DxListModule,
  DxMenuModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSwitchModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { NestComponent } from 'src/app/nest/nest.component';
import { QueueTasksComponent } from '../queue-tasks/queue-tasks.component';
import { MobileMainSubmenuComponent } from '../mobile-main-submenu/mobile-main-submenu.component';
import { TopMenuComponent } from '../top-menu/top-menu.component';
import { MobileListComponent } from 'src/app/core/mobile-list/mobile-list.component';
import { ConfirmDialogComponent } from 'src/app/core/confirm-dialog/confirm-dialog.component';
import { WaproLogoComponent } from 'src/app/core/wapro-logo/wapro-logo.component';

import { VersionSelectedComponent } from '../../core/version-selected/version-selected.component';
import { CASH_BOXES_KEY, COMPANIES_KEY, WAREHOUSES_KEY } from 'src/app/consts';
import { WaproAppSelectComponent } from '../../core/wapro-app-select/wapro-app-select.component';
import { SubmenuShowMode } from 'devextreme/common';
import { OpenedStateMode, RevealMode } from 'devextreme/ui/drawer';
import DevExpress from 'devextreme';
import PanelLocation = DevExpress.ui.dxDrawer.PanelLocation;
import { AuctionsService } from 'src/app/auctions.service';
import { AuctionAccount } from 'src/app/auctions.model';
import {
  ISpeechRecognitionResult,
  SpeechRecognitionService,
} from 'src/app/speech-recognition.service';
import { LicenseBoxInfoComponent } from '../license-box-info/license-box-info.component';
import { JpkServices } from 'src/app/jpk/services/jpk-services.service';

const helper = new JwtHelperService();

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [
    LicenseBoxInfoComponent,
    CommonModule,
    DxButtonModule,
    DxTooltipModule,
    NestComponent,
    DxPopupModule,
    DxMenuModule,
    NotificationComponent,
    TableUserExtensionComponent,
    QueueTasksComponent,
    MobileMainSubmenuComponent,
    VersionSelectedComponent,
    TopMenuComponent,
    MobileListComponent,
    DxToolbarModule,
    DxDrawerModule,
    DxScrollViewModule,
    RouterModule,
    TranslateModule,
    DxListModule,
    DxDataGridModule,
    ConfirmDialogComponent,
    DxSwitchModule,
    WaproLogoComponent,
    DxTextBoxModule,
    DxScrollViewModule,
    WaproAppSelectComponent,
  ],
})
export class MenuComponent implements OnInit, AfterViewInit {
  @ViewChild('menu') menu;
  @ViewChild('header') header;
  @ViewChild('listMenuSearch') listMenuSearch;
  @ViewChild('userPanelHeader') userPanelHeader;
  @ViewChild('bottomNavigationBar') bottomNavigationBar;

  auctionsService = inject(AuctionsService);
  isDefaultOnboarding = false;
  isDrawerOpen: boolean = true;
  isVersionSelected: boolean = true;
  selectedOpenMode: OpenedStateMode = 'shrink';
  selectedPosition: PanelLocation = 'left';
  selectedRevealMode: RevealMode = 'slide';
  submenu;
  code;
  animationEnabled: boolean = false;
  isAboutVersion: boolean = false;
  isMobileSettings: boolean = false;
  pageRouter = null;
  companyTables = [];

  navigation: any[] = [];
  widthPanelApps: boolean = false;
  navigationPanelUser: Signal<any[]> = computed(() => {
    return [
      {
        id: '1',
        name: 'menu.navigationPanelUser.passwordChange',
        icon: 'icon absui-icon--lock-full',
        url: null,
        items: [],
        visible: false,
      },
      {
        id: '2',
        name: 'menu.navigationPanelUser.applicationInformation',
        icon: 'icon absui-icon--info',
        url: null,
        action: () => {
          this.showAppInfo();
        },
        items: [],
      },
      {
        id: '3',
        name: 'menu.navigationPanelUser.languageApplications',
        icon: 'icon absui-icon--public',
        url: null,
        items: [
          {
            id: '3.1',
            name: 'menu.navigationPanelUser.polishLanguage',
            icon: '',
            url: 'start',
            role: 'lang-selection',
            active: localStorage.lang === 'pl',
            action: () => {
              localStorage.setItem('dontLogOut', 'true');
              this.event.useLanguage('pl');
            },
          },
          {
            id: '3.2',
            name: 'menu.navigationPanelUser.englishLanguage',
            icon: '',
            url: 'start',
            role: 'lang-selection',
            active: localStorage.lang === 'en',
            action: () => {
              localStorage.setItem('dontLogOut', 'true');
              this.event.useLanguage('en');
            },
          },
          {
            id: '3.3',
            name: 'menu.navigationPanelUser.ukrainianLanguage',
            icon: '',
            url: 'start',
            role: 'lang-selection',
            active: localStorage.lang === 'ua',
            action: () => {
              localStorage.setItem('dontLogOut', 'true');
              this.event.useLanguage('ua');
            },
          },
        ],
      },
      {
        id: '4',
        name: 'menu.onboarding',
        icon: 'icon absui-icon--book-open',
        url: null,
        items: [],
      },
      {
        id: '7',
        name: 'menu.colorTheme',
        icon: 'icon absui-icon--colors',
        url: null,
        items: [
          {
            id: '7.1',
            name: 'menu.auto',
            icon: '',
            role: 'theme-selection',
            url: null,
            active:
              this.event.colorTheme() === 'auto' || !this.event.colorTheme(),
            action: () => this.event.switchColorTheme('auto'),
          },
          {
            id: '7.2',
            name: 'menu.light',
            icon: '',
            role: 'theme-selection',
            url: null,
            active: this.event.colorTheme() === 'light',
            action: () => this.event.switchColorTheme('light'),
          },
          {
            id: '7.3',
            name: 'menu.dark',
            icon: '',
            role: 'theme-selection',
            url: null,
            active: this.event.colorTheme() === 'dark',
            action: () => this.event.switchColorTheme('dark'),
          },
        ],
        visible: true,
        // this.event.returnEnvironmentInfo() !== 'qa' &&
        // this.event.returnEnvironmentInfo() !== 'prod' &&
        // this.event.returnEnvironmentInfo() !== 'stage' &&
        // this.event.returnEnvironmentInfo() !== 'demo',
      },
      {
        id: '5',
        name: 'buttons.tables',
        icon: 'icon absui-icon--tables',
        url: null,
        items: [],
        visible: this.event.checkAppVersion('TabeleDodatkowe'),
        action: () => {
          this.isExternalTableVisible = true;
        },
      },
    ];
  });

  navigationPanelUserMobile: Signal<any[]> = computed(() => {
    return [
      // {
      //   id: '1',
      //   name: 'menu.navigationPanelUser.passwordChange',
      //   icon: 'icon absui-icon--lock-full',
      //   url: null,
      //   items: [],
      // },
      {
        id: '2',
        name: 'menu.navigationPanelUser.applicationInformation',
        icon: 'icon absui-icon--info',
        url: null,
        items: [],
        action: () => {
          this.showAppInfo();
        },
      },
      {
        id: '3',
        name: 'menu.navigationPanelUser.languageApplications',
        icon: 'icon absui-icon--public',
        url: null,
        items: [
          {
            id: '3.1',
            name: 'menu.navigationPanelUser.polishLanguage',
            icon: '',
            url: 'start',
            action: () => {
              localStorage.setItem('dontLogOut', 'true');
              this.event.useLanguage('pl');
            },
          },
          {
            id: '3.2',
            name: 'menu.navigationPanelUser.englishLanguage',
            icon: '',
            url: 'start',
            action: () => {
              localStorage.setItem('dontLogOut', 'true');
              this.event.useLanguage('en');
            },
          },
          {
            id: '3.3',
            name: 'menu.navigationPanelUser.ukrainianLanguage',
            icon: '',
            url: 'start',
            action: () => {
              localStorage.setItem('dontLogOut', 'true');
              this.event.useLanguage('ua');
            },
          },
        ],
      },
      {
        id: '7',
        name: 'menu.colorTheme',
        icon: 'icon absui-icon--colors',
        url: null,
        items: [
          {
            id: '7.1',
            name: 'menu.auto',
            icon: '',
            role: 'theme-selection',
            url: null,
            active: this.event.colorTheme() === 'auto',
            action: () => {
              this.event.switchColorTheme('auto');
            },
          },
          {
            id: '7.2',
            name: 'menu.light',
            icon: '',
            role: 'theme-selection',
            url: null,
            active: this.event.colorTheme() === 'light',
            action: () => {
              this.event.switchColorTheme('light');
            },
          },
          {
            id: '7.3',
            name: 'menu.dark',
            icon: '',
            role: 'theme-selection',
            url: null,
            active: this.event.colorTheme() === 'dark',
            action: () => {
              this.event.switchColorTheme('dark');
            },
          },
        ],
        visible: true,
      },
      {
        id: '100',
        name: 'menu.log-out',
        icon: 'icon icon-sign-out',
        url: null,
        items: [],
        action: () => {
          this.logOut();
        },
      },
    ];
  });
  helpPanel: Signal<any[]> = computed(() => {
    return [
      {
        id: '6',
        name: 'menu.ecommerce',
        icon: 'icon absui-icon--shop',
        url: null,
        items: [],
        visible:
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO',
        action: () => {
          this.route.navigate([`administrator/e-shop`]);
        },
      },
      {
        id: '8',
        name: 'Wapro Portal Anycloud (zarządzanie usługą)',
        icon: 'icon absui-icon--security-user',
        url: null,
        items: [],
        action: () => {
          window.open(environment.PORTAL_URL, '_blank');
        },
      },
      {
        id: '7',
        name: 'menu.avp',
        icon: 'icon absui-icon--news-small',
        url: null,
        items: [],
        action: () => {
          this.route.navigate(['start/avp']);
        },
      },
      {
        id: '9',
        name: 'menu.navigationPanelUser.helpDocs',
        icon: 'icon absui-icon--help',
        url: null,
        items: [],
        action: () => {
          this.navigateToHelp();
        },
      },
      {
        id: '10',
        name: 'menu.log-out',
        icon: 'icon icon-sign-out',
        url: null,
        items: [],
        action: () => {
          this.logOut();
        },
      },
    ];
  });
  isSelectedVersion: boolean = false;
  showSubmenuModes: any;
  showFirstSubmenuModes: any;
  rtlEnabled = false;
  isMobile: boolean = false;
  widthMenu = 200;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  userInfo = {
    firstName: '',
    lastName: '',
  };

  pathname = '';
  timeOutHandler;

  isHideUserPanel: boolean = false;
  isHelpVisible: boolean = false;
  isExternalTableVisible: boolean = false;
  timeSession: number = 60;
  permissions;

  isVisiblePopUpSessionEnd: boolean = false;
  param = { timeSession: this.timeSession };
  browserName = '';
  BrowserVersino = '';

  shearchInMenu: boolean = false;
  navigationSearch = [];
  navigationSearchData: DataSource;

  isAuctions = signal(false);
  isSearchResult: boolean = false;
  onFocus: boolean = false;
  onboardingSub: Subscription = null;
  searchValue = '';
  resultSearchData: any = { store: [] };
  isSearchMenuResultVisible: boolean = true;
  widthUserPanel = 127;
  chooseCompany: boolean = false;
  chooseWarehouse: boolean = false;
  heightSearchMenu = 390;
  heightSearchData = 390;
  countData = 0;
  isWarehouseMobile: boolean = false;
  isMenuMobile: boolean = false;
  isShowQueue: boolean = false;
  showDotQueueTask: boolean = false;
  chooseCash: boolean = false;
  dropdownMarginsR = {
    cash: 486,
    warehouse: 100,
    company: 264,
    user: 113,
    help: 80,
  };
  dropdownVisible = {
    cash: true,
    warehouse: true,
    company: true,
    user: true,
    help: true,
    search: true,
  };

  cashList: any[] = [];

  cashPerABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  companyPerABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  selectedCompanyId = 'a9cb7d84-bdea-4e0c-ba28-01377093b545';
  warehouses = [];
  currentWarehouseSymbol: string;
  currentWarehouseName: string;
  warehouseAlertTriggered: boolean = false;

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };
  EKonfSys: boolean = false;

  isBottomNavigationBarVisible: boolean = false;
  notifficationList = [];
  isEndTrial = signal<boolean>(false);
  application: string = 'mag | jpk | fk | gdpr';
  isDemo: boolean = false;

  runenviroment: string = '';

  isSpeechRecognitionButtonDisabled: WritableSignal<boolean> = signal(false);
  searchBoxButtons: (
    | string
    | import('devextreme/bundles/dx.all').default.common.TextEditorButton
  )[] = [
    {
      location: 'after',
      name: 'clearSearch',
      options: {
        visible: this.searchValue.length > 0,
        icon: 'icon absui-icon--close',
        onClick: (_e: DevExpress.ui.dxButton.ClickEvent) => {
          this.searchValue = '';
        },
      },
    },
    {
      location: 'after',
      name: 'microphone',
      options: {
        icon: 'icon absui-icon--microphone',
        disabled: this.isSpeechRecognitionButtonDisabled(),
        onClick: async (_e: DevExpress.ui.dxButton.ClickEvent) => {
          // disable button to prevent multiple clicks
          // add class 'active-focus' to the button to show the user that the button is active
          setTimeout(() => {
            let el = _e.component.element() as unknown as HTMLElement;
            el.classList.add('dx-state-active');
          }, 0);
          if (this.isSpeechRecognitionButtonDisabled()) {
            this.speechRecognitionService.stop();
            return;
          }
          this.isSpeechRecognitionButtonDisabled.set(true);

          const speechRecognition: ISpeechRecognitionResult =
            await this.speechRecognitionService.init();

          this.isSpeechRecognitionButtonDisabled.set(false);
          setTimeout(() => {
            let el = _e.component.element() as unknown as HTMLElement;
            el.classList.remove('dx-state-active');
          }, 0);
          if (speechRecognition.transcript) {
            this.searchValue = speechRecognition.transcript;
          }
        },
      },
    },
  ];

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose() {
    if (
      !this.route.url.includes('auctions') &&
      localStorage.getItem('activeUrlArray') &&
      JSON.parse(localStorage.getItem('activeUrlArray')).length <= 1 &&
      !localStorage.getItem('dontLogOut') &&
      !this.event.dontLogOut
    ) {
      this.event.logout();
    }

    if (
      this.route.url.includes('auctions') ||
      this.route.url === '/start/dashboard'
    ) {
      return true;
    }

    return this.event.isShowConfirmClose;
  }

  isLastTabInDomain(): boolean {
    const tabCount = Number(localStorage.getItem('tabCount')) || 0;

    if (tabCount <= 1) {
      localStorage.removeItem('tabCount');
      return true;
    } else {
      localStorage.setItem('tabCount', (tabCount - 1).toString());
      return false;
    }
  }

  focusedWarehouseIndex: number;

  getWarehouses(fromLogin: boolean = false): void {
    this.focusedWarehouseIndex = -1;
    const localData = this.event.getSessionStorageData(WAREHOUSES_KEY);
    if (localData) {
      this.warehouses = localData;
      this.warehouses = this.event.removeWarehouseWithoutPer(this.warehouses);
      this.mapColorsToWarehouses();
      if (fromLogin) {
        this.checkVariantForce();
      }
    } else {
      this.appService.getAuth(`warehouses?Skip=0&Take=200`).subscribe((res) => {
        this.warehouses = res.data;
        this.warehouses = this.event.removeWarehouseWithoutPer(this.warehouses);
        this.mapColorsToWarehouses();
        this.event.saveSessionStorageData(WAREHOUSES_KEY, res.data);
        if (fromLogin) {
          this.checkVariantForce();
        }
      });
    }
  }

  isChangeVariantVisible = signal(false);
  confirmHeader = 'changeVariantHeader';
  confirmText = 'changeVariantText';
  dynamicBtnConfig = [
    {
      type: 'success' as ButtonType,
      text: 'Rozpocznij konwersję',
      action: 'accepted',
    },
  ];

  onDynamicBtnClicked(_e) {
    this.isChangeVariantVisible.set(false);
    this.getUnsavedDocuments();
  }

  checkVariantForce() {
    if (
      !this.event.footerInfo.VariantName.includes('FAKTUROWANIE') &&
      this.warehouses?.length &&
      this.warehouses[0]?.ForceAppVariantConversion
    ) {
      this.isChangeVariantVisible.set(true);
    } else {
      this.event.isForceVariantChecked.set(true);
    }
  }

  getUnsavedDocuments() {
    const documents = this.appService.getAuth(
      'document/unsavedDocuments?skip=0&take=1000'
    );
    const files = this.appService.getAuth(
      'document/unsavedFiles?skip=0&take=1000'
    );

    forkJoin([documents, files]).subscribe(
      (res) => {
        const data = res[0].data.concat(res[1].data);
        data.forEach((el) => {
          if (el.FileType) el.DocumentKind = el.FileType;
        });
        this.event.unsavedDocsToBlock.set(data);
        this.event.blockCloseUnsavedDocsWithNonEmptyList.set(!!data.length);
        this.event.isUnsavedDocs.set(!!data.length);
        this.event.isForceVariantChecked.set(true);
        if (!data.length) {
          this.callVariantForce();
        } else {
          this.route.navigate(['administrator/unsaved-documents'], {
            queryParams: { blockClose: true },
          });
        }
      },
      () => {
      }
    );
  }

  callVariantForce() {
    this.appService.postAuth(`inventory/convertAppVariant`, null).subscribe({
      next: (data: { TaskGuid: string }) => {
        const channel = new BroadcastChannel('queuetasks');
        channel.postMessage(null);
        this.event.showNotification(
          'success',
          this.translate.instant('changeVariantStarted')
        );
        this.event.sendReadOnlyInfo.next(true);
        this.event.readOnly = true;
        this.event.variantTaskId = data.TaskGuid;
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  }

  mapColorsToWarehouses() {
    let warehouseColorId = 0;
    for (let i = 0; i < this.warehouses.length; i++) {
      if (warehouseColorId > this.event.colorWarehouse.length - 1) {
        warehouseColorId = 0;
      }
      this.warehouses[i].Color = this.event.colorWarehouse[warehouseColorId];
      warehouseColorId++;
    }
    // dataField="Color"

    this.currentColorWarehouse = this.warehouses.find(
      (field) => field.WarehouseId == this.event.footerInfo.AppWarehouseId
    )?.Color;

    this.sortWarehouses();
  }

  companies = [];
  currentCompany = {
    CompanyId: '',
    CompanyName: '',
    CompanyVat: null,
  };
  companyAttachment: number = 0;

  sortCompanies() {
    try {
      this.companies.forEach((element) => {
        element.CompanyId === this.event.footerInfo.AppCompanyId
          ? (element.IsActiveSession = true)
          : (element.IsActiveSession = false);
      });

      this.companies.sort((a, b) => b.IsActiveSession - a.IsActiveSession);
    } catch {
  }
  }

  sortWarehouses() {
    try {
      this.warehouses.forEach((element) => {
        element.WarehouseId === this.event.footerInfo.AppWarehouseId
          ? (element.IsActiveSession = true)
          : (element.IsActiveSession = false);
      });
      this.warehouses.sort((a, b) => b.IsActiveSession - a.IsActiveSession);
      this.focusedWarehouseIndex = this.warehouses.findIndex(
        (field) => field.WarehouseId == this.event.footerInfo.AppWarehouseId
      );
    } catch {
  }
  }

  sortCashBox() {
    try {
      this.cashList.forEach((element) => {
        element.CashId === this.event.footerInfo.AppCashId
          ? (element.IsActiveSession = true)
          : (element.IsActiveSession = false);
      });
      this.cashList.sort((a, b) => b.IsActiveSession - a.IsActiveSession);
    } catch {
  }
  }

  getCompanies(): void {
    const localData = this.event.getSessionStorageData(COMPANIES_KEY);
    if (localData) {
      this.companies = localData;
      this.sortCompanies();

      return;
    } else {
      this.appService.getAuth(`companies?Skip=0&Take=200`).subscribe((res) => {
        this.companies = res.data;
        this.event.saveSessionStorageData(COMPANIES_KEY, res.data);
        this.sortCompanies();
      });
    }
  }

  currentUserName: string;

  getCurrentUserName(): void {
    try {
      let token = localStorage.getItem('WAPRO-HTML-token');
      let decoded = helper.decodeToken(token);
      let mail = decoded.mail;
      this.currentUserName =
        mail.indexOf('@') > -1 ? mail.substring(0, mail.indexOf('@')) : mail;
    } catch {
  }
  }

  isOnlySymbol: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 575.98) {
      this.isMobile = true;
      this.checkAccess(this.navigation, 0);
    }

    if (window.innerWidth < 1124) {
      this.isOnlySymbol = true;
    } else {
      this.isOnlySymbol = false;
    }
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.code == 'KeyF') {
      this.shearchInMenu = true;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.event.deviceType === 'mobile') {
      this.event.throttleFunc(() => {
        const header = this.header.nativeElement;
        const topMenuPanel = document.querySelector('.top-menu-panel');
        let offset = window.scrollY;
        let height = window.innerHeight;
        if (offset > height * 1) {
          header.style.top = '-80px';
          topMenuPanel.classList.add('stick-to-top');
          this.showBottomNavigationBar();
          document.body.style.paddingBottom = '48px';
        } else {
          header.style.removeProperty('top');
          topMenuPanel.classList.remove('stick-to-top');
          this.hideBottomNavigationBar();
          document.body.style.removeProperty('padding-bottom');
        }
      }, 300);
    } else {
      removeEventListener('scroll', this.onWindowScroll, true);
    }
  }

  scroll(direction) {
    if (direction == 'up') {
      window.scrollTo(window.scrollX, 0);
    } else {
      window.scrollTo(window.scrollX, document.body.scrollHeight);
    }
  }

  showBottomNavigationBar() {
    const bottomNav = this.bottomNavigationBar.nativeElement;
    bottomNav.classList.add('show');
    this.isBottomNavigationBarVisible = true;
  }

  hideBottomNavigationBar() {
    const bottomNav = this.bottomNavigationBar.nativeElement;
    bottomNav.classList.remove('show');
    setTimeout(() => {
      this.isBottomNavigationBarVisible = false;
    }, 600);
  }

  isShowSubmenu: boolean = false;

  toggleDefault() {
    this.isShowSubmenu = true;
  }

  toggleHide() {
    this.isShowSubmenu = false;
  }

  AutLogOutUser: any = null;
  timeSessionLogOut: number = 0;

  paramsMsg = {
    time: 0,
  };

  ofTheDay: string = 'z dnia';

  constructor(
    private translate: TranslateService,
    public event: EventService,
    public route: Router,
    public routeActive: ActivatedRoute,
    private appService: AppServices,
    private bnIdle: UserIdleService,
    public cd: ChangeDetectorRef,
    private nestService: NestService,
    private helpService: HelpService,
    private speechRecognitionService: SpeechRecognitionService,
    public jpkServices: JpkServices
  ) {
    this.pageRouter = this.route.url;

    this.event.showDotQueueTaskList
      .pipe(takeUntilDestroyed())
      .subscribe((res) => (this.showDotQueueTask = res));

    this.showSubmenuModes = [
      {
        name: 'onHover' as SubmenuShowMode,
        delay: { show: 100, hide: 100 },
      },
      {
        name: 'onClick' as SubmenuShowMode,
        delay: { show: 0, hide: 300 },
      },
    ];
    this.showFirstSubmenuModes = this.showSubmenuModes[0];

    this.bnIdle.startWatching();

    try {
      this.AutLogOutUser =
        this.event.getConfigurationParameter('AutLogOutUser')?.Value;
    } catch {
      this.AutLogOutUser = null;
    }

    if (this.AutLogOutUser != null) {
      if (
        this.AutLogOutUser != 'Nigdy' &&
        this.AutLogOutUser != '' &&
        this.AutLogOutUser != ' ' &&
        this.AutLogOutUser != 0
      ) {
        let timeToLogOut = this.AutLogOutUser.split(' ');
        timeToLogOut[0] = timeToLogOut[0].replace('#', '');

        if (timeToLogOut[1] != 'godzina') {
          this.timeSessionLogOut = Number(timeToLogOut[0] * 60);
        } else {
          this.timeSessionLogOut = Number(timeToLogOut[0] * 60 * 60);
        }

        // Start watching when user idle is starting.
        this.bnIdle.onTimerStart().subscribe(() => {
          this.timeSessionLogOut--;
          this.paramsMsg = {
            time: this.timeSessionLogOut,
          };
          if (this.timeSessionLogOut === 30) {
            this.isVisiblePopUpSessionEnd = true;
          }

          if (this.timeSessionLogOut === 0) {
            this.bnIdle.stopTimer();
            this.logOut();
          }
        });
      }
    }

    window.addEventListener('click', () => {
      this.onSetTimeLogOut();

      this.bnIdle.resetTimer();

      this.isVisiblePopUpSessionEnd = false;
      this.timeSession = this.timeSessionLogOut;
    });

    window.addEventListener('keydown', () => {
      this.onSetTimeLogOut();
      this.bnIdle.resetTimer();
      this.isVisiblePopUpSessionEnd = false;
      this.timeSession = this.timeSessionLogOut;
    });

    this.dynamicBtnConfig[0].text = this.translate.instant(
      this.dynamicBtnConfig[0].text
    );
    this.translate
      .get('baseCurrencies.ofTheDay')
      .subscribe((text) => (this.ofTheDay = text));

    this.createUserPanelNav();

    setTimeout(() => {
      this.navigation = dataMenu;

      this.getPermissionsToMenu();
      //this.getNests();
      this.getTables();
      this.navigation.forEach((element) => {
        element.items = element.submenu;
      });
    }, 0);

    setTimeout(() => {
      try {
        for (let i = 2; i < this.navigation.length; i++) {
          this.addToMenuSearch(this.navigation[i], 0);
        }

        const codesToDelete = [];
        for (let item of this.navigationSearch) {
          if (!this.event.checkPermissions(item.code)) {
            codesToDelete.push(item.code);
          }
        }
        for (let code of codesToDelete) {
          this.navigationSearch = this.navigationSearch.filter(
            (item) => item.code !== code
          );
        }

        this.navigationSearchData = new DataSource({
          store: this.navigationSearch,
          paginate: true,
          pageSize: 8,
        });
      } catch {
      }
    }, 2000);

    this.onboardingSub = this.event.isAutoOnboarding$.subscribe((value) => {
      this.isDefaultOnboarding = value;
    });
  }

  createUserPanelNav() {
  }

  toolbarContent = [
    {
      widget: 'dxButton',
      location: 'before',
      cssClass: 'menu-toggle-btn',
      options: {
        width: 'j0px',
        icon: 'menu',
        onClick: (e) => {
          e.element.blur();
          this.animationEnabled = true;
          setTimeout(() => {
            this.isDrawerOpen = !this.isDrawerOpen;
            this.animationEnabled = false;
          }, 0);
        },
      },
    },

    toObservable(this.auctionsService.account)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (acc) => {
          this.currentAuctionsAccount.set(acc);
          if (this.isAuctions()) {
            this.navigation = dataMenuAuctions;
            this.navigation.forEach((element) => {
              element.items = element.submenu;
            });
            if (this.currentAuctionsAccount()?.SystemId != 1) {
              this.navigation = this.navigation.filter((el) => el.id != 5);
            }

            this.setNavigationVisible(acc);
          }
        },
      }),
  ];

  setNavigationVisible(account: AuctionAccount) {
    if (account) {
      this.navigation.forEach((el) => (el.visible = true));
    } else {
      this.navigation.forEach((el) => {
        if (el.url !== 'auctions/accounts') {
          el.visible = false;
        }
      });
    }
  }

  currentAuctionsAccount = signal(null);

  onSetTimeLogOut() {
    try {
      this.AutLogOutUser =
        this.event.getConfigurationParameter('AutLogOutUser')?.Value;
    } catch {
      this.AutLogOutUser = null;
    }
    if (this.AutLogOutUser != null) {
      if (
        this.AutLogOutUser != 'Nigdy' &&
        this.AutLogOutUser != '' &&
        this.AutLogOutUser != ' ' &&
        this.AutLogOutUser != 0
      ) {
        let timeToLogOut = this.AutLogOutUser.split(' ');
        timeToLogOut[0] = timeToLogOut[0].replace('#', '');

        if (timeToLogOut[1] != 'godzina') {
          this.timeSessionLogOut = Number(timeToLogOut[0] * 60);
        } else {
          this.timeSessionLogOut = Number(timeToLogOut[0] * 60 * 60);
        }
      }
    }
  }

  onPageLoading = () => {
    if (this.heightSearchMenu + 390 < window.innerHeight) {
      this.heightSearchMenu += 390;
    }
  };

  searchValueChange = () => {
    setTimeout(() => {
      let height = this.listMenuSearch.items.length * 48;
      if (this.listMenuSearch.items.length >= 8) {
        this.heightSearchMenu = 400;
      } else {
        this.heightSearchMenu = height;
      }
    }, 0);
  };

  onItemClickMenuSearch = (e) => {
    let isPermission = true;
    if (e.itemData.code !== null && e.itemData.code !== 'changeDate') {
      isPermission = this.event.checkPermissions(e.itemData.code);
    }

    if (isPermission && e.itemData.url !== null) {
      this.onFocus = false;
      this.isSearchResult = false;
      this.shearchInMenu = false;
      this.isHideUserPanel = false;
      this.chooseCompany = false;
      this.chooseWarehouse = false;
      this.route.navigate([e.itemData.url]);
      this.searchValue = '';
    }

    if (!isPermission) {
      this.event.showNotification(
        'info',
        this.translate.instant('permissions.noPermission')
      );
      return;
    }

    if (e.itemData.url === null && e.itemData.code) {
      try {
        switch (e.itemData.code) {
          case 'changeDate':
            this.event.isDateRangeVisible.set(true);
        }
      } catch (_e) {
    }
    }
    this.isSearchResult = false;
  };

  isSubmenuItemClick: boolean = false;
  itemChosed = [];
  onItemClickMobile = (e) => {
    if (e.itemData.items.length > 0) {
      this.isSubmenuItemClick = true;
      this.itemChosed = e.itemData;
      return;
    }

    if (e.itemData.id == 1) {
      this.isSubmenuItemClick = true;
      this.itemChosed = e.itemData;
      return;
    }

    if (e.itemData.url == 'help') {
      this.navigateToHelp();
      this.isMenuMobile = false;
      return;
    }
  };

  addToMenuSearch = (item, level) => {
    let dontPush = false;

    /* Nie pokazuj w wyszukiwarce nagłówków list np. Szybkie akcje*/
    if (item.type === 'listHeader') dontPush = true;

    /* Nie pokazuj głównych sekcji menu np. Magazyn, Kartoteki, Admin */
    if (item?.submenu?.length > 0 || item?.items?.length > 0) dontPush = true;

    if (!dontPush) {
      this.navigationSearch.push({
        name: this.translate.instant(item.name),
        code: item.code,
        url: item.url,
        patch: item.patch ? this.translate.instant(item.patch) : null,
        patch2: item.patch2 ? this.translate.instant(item.patch2) : null,
        patch3: item.patch3 ? this.translate.instant(item.patch3) : null,
      });
    }
    if (level == 0) {
      try {
        item.submenu.forEach((element) => {
          this.addToMenuSearch(element, 1);
        });
      } catch {
      }
    } else {
      try {
        item.items.forEach((element) => {
          this.addToMenuSearch(element, 1);
        });
      } catch {
    }
    }
  };

  appDetails;

  getUserInfo(): void {
    this.appService.getAuth(`appPermissions/users`).subscribe(() => {
    });
  }

  refreshLastActivity() {
    this.timeOutHandler = null;
    this.appService
      .postAuth(`AdminConsole/refreshLastActivity`, null)
      .subscribe(() => {
      });
    this.timeOutHandler = setTimeout(() => {
      this.refreshLastActivity();
    }, 3 * 60 * 1000);
  }

  noCloseNotification() {
    this.isShowNotification = false;
    this.notifficationListToShow.push(
      this.notifficationList[0]?.NotificationId
    );
    this.notifficationList.splice(0, 1);

    localStorage.setItem(
      'notifficationList',
      JSON.stringify(this.notifficationListToShow)
    );

    if (this.notifficationList.length > 0) {
      setTimeout(() => {
        this.notificationText = this.notifficationList[0].Content;
        this.isShowNotification = true;
      }, 300);
    }
    this.cd.detectChanges();
  }

  notifficationListToShow: any = [];
  isShowNotification: boolean = false;
  notificationText: string = '';

  checkNotiffication() {
    this.notifficationListToShow = [];
    if (localStorage.getItem('notifficationList')) {
      this.notifficationListToShow = JSON.parse(
        localStorage.getItem('notifficationList')
      );
    }
    this.notifficationList = [];
    this.appService.getAuth(`configurations/notifications`).subscribe((res) => {
      res.forEach((notifficatino) => {
        let index = this.notifficationListToShow.findIndex(
          (field) => field == notifficatino.NotificationId
        );
        if (
          index == -1 &&
          new Date(notifficatino.DateFrom).getTime() <= new Date().getTime() &&
          (new Date(notifficatino.DateTo).getTime() >= new Date().getTime() ||
            notifficatino.DateTo == null)
        ) {
          this.notifficationList.push(notifficatino);
        }
      });

      if (this.notifficationList.length > 0) {
        this.notificationText = this.notifficationList[0].Content;
        this.isShowNotification = true;
        this.cd.detectChanges();
      }
    });

    setTimeout(() => {
      this.checkNotiffication();
    }, 10 * 60 * 1000);
  }

  czyTypDate(zmienna) {
    return zmienna instanceof Date;
  }

  isShowChangeDBConpanies: boolean = false;

  ngOnInit(): void {
    this.runenviroment = environment.RUNENVIRONMENT;

    if(environment.RUNENVIRONMENT === 'Demo'){
      this.isDemo = true;
    }

    const magPer = this.event
      .decryptString(localStorage.getItem('appPermissions'))
      .filter((field) => field.Code === 'SYS');
    if (
      magPer.length > 1 &&
      this.event.returnEnvironmentInfo() !== 'prod' &&
      !this.isDemo &&
      this.event.footerInfo.AppUserId !== 3000001
    ) {
      const index = magPer.findIndex(
        (field) => field.ParentObjectId === this.event.footerInfo.AppWarehouseId
      );

      if (index === -1) {
        this.route.navigate(['no-permission-to-warehouse']);
      }
    }

    

    if (localStorage.getItem('configurationCountCompanies')) {
      this.event.decryptString(
        localStorage.getItem('configurationCountCompanies')
      ) > 1
        ? (this.isShowChangeDBConpanies = true)
        : (this.isShowChangeDBConpanies = false);
    }

    this.application = localStorage.getItem('activeApplicationWapro');
    if (!this.application) {
      this.application = 'mag';
    }

    this.EKonfSys = this.event.checkPermissions('EKonfSys');
    setTimeout(() => {
      this.checkNotiffication();
    }, 3000);

    this.refreshLastActivity();
    const tabCount = +localStorage.getItem('tabCount') || 0;
    localStorage.setItem('tabCount', (tabCount + 1).toString());
    localStorage.removeItem('dontLogOut');

    if (!localStorage.getItem('provincesListWapro')) {
      this.getProvinces();
    }

    this.perABD = this.event.checkPermissionsBtn('DMag', 'EMag', 'UMag', null);
    if (window.innerWidth < 1200) {
      this.isMobile = true;
    }
    this.getUserInfo();
    this.getAccesGlobalApp();

    this.pageRouter = this.pageRouter.substr(1, this.pageRouter.length);

    if (localStorage.getItem('sessionInfoWaproHtml')) {
      this.event.footerInfo = this.event.decryptString(
        localStorage.getItem('sessionInfoWaproHtml')
      );
    }

    this.event.setNameWarehouse.subscribe((nameWarehouse) => {
      try {
        if (nameWarehouse) {
          this.event.footerInfo.AppWarehouseName = nameWarehouse.WarehouseName;
          this.event.footerInfo.AppWarehouseId = nameWarehouse.WarehouseId;
          this.currentWarehouseSymbol = nameWarehouse.WarehouseName;
          this.appDetails.user[3].body.content =
            this.event.footerInfo.AppWarehouseName;
        } else {
          this.event.footerInfo.AppWarehouseName = nameWarehouse.WarehouseName;
        }

        this.currentColorWarehouse = this.warehouses.find(
          (field) => field.WarehouseId == nameWarehouse.WarehouseId
        )?.Color;

        localStorage.setItem(
          'sessionInfoWaproHtml',
          this.event.encryptString(this.event.footerInfo)
        );
      } catch {
      }
    });

    this.browserName = this.getBrowserName();
    this.BrowserVersino = this.getBrowserVersion();

    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAuctions.set(event.url.indexOf('auctions') > -1);
        if (this.isAuctions()) {
          if (!this.currentAuctionsAccount()) {
            this.getCurrentAuctionsAccount();
          }
          this.navigation = dataMenuAuctions;
          this.navigation.forEach((element) => {
            element.items = element.submenu;
          });
          if (this.currentAuctionsAccount()?.SystemId != 1) {
            this.navigation = this.navigation.filter((el) => el.id != 5);
          }
          this.navigation.findIndex((el) => el.id == 5);
          this.checkAccess(this.navigation, 0);
          this.dropdownVisible = {
            cash: false,
            warehouse: false,
            company: true,
            user: true,
            help: true,
            search: false,
          };
        }
        this.setSubmenu();
        this.hideSidePanel();
      }
    });

    this.appDetails = {
      ver: [
        {
          header: {
            content: 'menu.navigationPanelUser.version',
            translate: true,
          },
          body: {
            content:
              environment.BUILD_ID +
              ' ' +
              this.ofTheDay +
              ' ' +
              environment.ABS_BUILD_DATE,
            translate: false,
          },
        },

        {
          header: {
            content: 'menu.navigationPanelUser.AppVersion',
            translate: true,
          },
          body: {
            content: this.event.footerInfo.AppVersion,
            translate: false,
          },
        },
        {
          header: {
            content: 'menu.navigationPanelUser.AppDbVersion',
            translate: true,
          },
          body: {
            content: this.event.footerInfo.AppDbVersion,
            translate: false,
          },
        },
        {
          header: {
            content: 'document-type.type',
            translate: true,
          },
          body: {
            content: 'htmlAccessApplication',
            translate: true,
          },
        },
        {
          header: {
            content: 'publicationDate',
            translate: true,
          },
          body: {
            content: new Date().getFullYear(),
            translate: false,
          },
        },
        {
          header: {
            content: 'menu.navigationPanelUser.browser',
            translate: true,
          },
          body: {
            content: this.getBrowserVersion(),
            translate: false,
          },
        },
        {
          header: {
            content: 'menu.navigationPanelUser.applicationVersion',
            translate: true,
          },
          body: {
            content: this.event.footerInfo.VariantName,
            translate: false,
          },
        },
      ],
      user: [
        {
          header: {
            content: 'users.identyficator',
            translate: true,
          },
          body: {
            content: this.event.footerInfo.AppUserId,
            translate: false,
          },
        },
        {
          header: {
            content: 'companies.grid.columns.name',
            translate: true,
          },
          body: {
            content: this.event.footerInfo.Email,
            translate: false,
          },
        },
        {
          header: {
            content: 'constractors.company',
            translate: true,
          },
          body: {
            content: this.event.footerInfo.CompanyName,
            translate: false,
          },
        },
        {
          header: {
            content: 'menu.warehouse',
            translate: true,
          },
          body: {
            content: this.event.footerInfo.AppWarehouseName,
            translate: false,
          },
        },
      ],
    };

    this.getWarehouses(true);
    this.getCompanies();
    // this.event.getCurrentCompany().then((res) => {
    //   let pom = this.event.decryptString(localStorage.getItem('staticData'));
    //   this.event.refreshBankList.next(pom?.companyBankAccounts);
    // });
    // this.event.getWarehouse();
    // this.getCurrentWarehouseSymbol();
    this.getCurrentUserName();

    this.companyPerABD = this.event.checkPermissionsBtn(
      'DFirmy',
      'EDanePodm',
      'UFirmy',
      'OFirmy'
    );
    this.companyPerABD.editBtn = true;

    this.cashPerABD = this.event.checkPermissionsBtn(
      'DKasy',
      'EKasy',
      'UKasy',
      'OKasy'
    );

    if (
      this.event.returnEnvironmentInfo() === 'localhost' ||
      this.event.returnEnvironmentInfo() === 'dev' ||
      this.event.returnEnvironmentInfo() === 'qa'
    ) {
      this.apps.push(
        {
          shortcut: 'ak',
          master: 'aukcje',
          detail: 'internetowe',
          app: 'auctions',
        },
      );
    }

    this.goApp(this.application, false);
  }

  hideSidePanel() {
    this.isShowQueue = false;
  }

  warehousePopupShown = (list) => {
    let items = list.instance._dataSource._items;
    let activeItem = items.find(
      (x) => x.WarehouseId == this.event.footerInfo.AppWarehouseId
    );
    let activeIndex = items.indexOf(activeItem);
    list.instance.selectItem(activeIndex);
    list.instance.reorderItem(activeIndex, 0);
  };

  companyPopupShown = (list) => {
    let items = list.instance._dataSource._items;
    let activeItem = items.find(
      (x) => x.CompanyId == this.currentCompany.CompanyId
    );
    let activeIndex = items.indexOf(activeItem);
    list.instance.selectItem(activeIndex);
    list.instance.reorderItem(activeIndex, 0);
  };

  nameToReturn;
  findSmth = (arr: any[], url: string) => {
    const found = arr.find((element: any) => {
      if (element.url == url) {
        this.nameToReturn = this.translate.instant(element.name);
        return true;
      } else {
        if (element.items) {
          this.findSmth(element.items, url);
        }
      }
      return false;
    });
    return found;
  };

  getCurrentAuctionsAccount() {
    this.appService.getAuth(`allegro/GetSelectedAccount`).subscribe({
      next: (res) => {
        this.auctionsService.account.set(res);
        sessionStorage.setItem(
          'auctionsAccount',
          this.event.encryptString(res)
        );
      },
      error: (err) => {
        this.auctionsService.account.set(null);
        sessionStorage.removeItem('auctionsAccount');
        this.route.navigate(['/auctions/accounts']);
        this.setNavigationVisible(null);
        this.event.httpErrorNotification(err);
      },
    });
  }

  getAccesGlobalApp() {
    this.appService
      .getAuth(
        `appPermissions/users/${this.event.footerInfo.AppUserId}/permissionGlobalTree`
      )
      .subscribe({
        next: (res) => {
          this.jpkServices.accesApp.set(res);
          sessionStorage.setItem('accesApp', this.event.encryptString(res));
        },
        error: (err) => {
          this.auctionsService.account.set(null);
          sessionStorage.removeItem('accesApp');
          this.event.httpErrorNotification(err);
        },
      });
  }

  setSubmenu = () => {
    setTimeout(() => {
      let page;
      this.pathname = location.pathname;

      this.findSmth(this.navigation, location.pathname.replace('/', ''));
      window.document.title = this.nameToReturn + ' • Wapro Sprzedaż i Magazyn';
      page = this.pathname.split('/');

      if (this.isAuctions()) {
        this.submenu = [];
      } else {
        this.submenu = this.navigation.find((el) => {
          this.code = el.code;
          if (el.url) {
            let url = el.url.split('/');

            if (
              (url[0] === page[1] && page[1] !== 'fk') ||
              (url[1] === page[2] && page[1] === 'fk')
            ) {
              return el;
            }
          }
        });
      }
    }, 0);
  };

  checkAuctionsMenu() {
  }

  navigateToHelp = () => {
    this.helpService.openHelp('intro');
  };

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'IE';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Other';
    }
  }

  private hideForVersionInvoices(element) {
    // if (
    //   (element.name === 'menu.relatedToService' ||
    //     element.name === 'menu.reports' ||
    //     element.name === 'menu.start' ||
    //     element.id == '82') &&
    //   this.event.footerInfo.VariantName === 'START'
    // ) {
    //   element.visible = false;
    // }
    if (
      (element.name === 'menu.relatedToService' ||
        element.id == '82' ||
        element.id === '933' ||
        element.id === '45' ||
        element.id === '9527' ||
        element.id === '9526' ||
        element.id === '727' ||
        element.id === '744' ||
        element.id === '37' ||
        element.id === '913') &&
      (this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
        this.event.footerInfo.VariantName === 'FAKTUROWANIE' ||
        this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE PRO' ||
        this.event.footerInfo.VariantName === 'FAKTUROWANIE PRO')
    ) {
      element.visible = false;
    }
    if (
      element.name === 'CRM' &&
      (this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
        this.event.footerInfo.VariantName === 'FAKTUROWANIE')
    ) {
      element.visible = false;
    }

    if (
      (this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
        this.event.footerInfo.VariantName === 'FAKTUROWANIE' ||
        this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE PRO' ||
        this.event.footerInfo.VariantName === 'FAKTUROWANIE PRO') &&
      element.code === 'Magazyn'
    ) {
      element.name = this.translate.instant('menu.documents');
    }
  }

  checkAccess = (navigation, level) => {
    navigation.forEach((element) => {
      if (!environment.production) {
        element.visible = true;
      }
      // let men = this.permissions.find((field) => field.Code == element.code);
      // if (men) {
      element.visible =
        element.code &&
        element.code !== 'changeDate' &&
        element.code !== 'definitionsGdpr' &&
        element.code !== "registersСhanges" &&
        element.code !== 'gdpr'
          ? this.event.checkPermissions(element.code)
          : true;
      // }

      if (level == 0) {
        this.checkAccess(element.submenu, 1);
      } else if (element.items) {
        this.checkAccess(element.items, 1);
      }

      if (element.id == '421' && this.event.deviceType != 'mobile') {
        element.visible = false;
      }

      /* Business Link */
      if (
        element.id == '82' &&
        (this.event.returnEnvironmentInfo() === 'prod' ||
          this.event.returnEnvironmentInfo() === 'demo' ||
          this.event.returnEnvironmentInfo() === 'stage')
      ) {
        element.visible = false;
      }

      // Route
      // if (
      //   element.id == '744' &&
      //   (this.event.returnEnvironmentInfo() === 'prod' ||
      //     this.event.returnEnvironmentInfo() === 'demo' ||
      //     this.event.returnEnvironmentInfo() === 'stage')
      // ) {
      //   element.visible = false;
      // }

      if (element.id == '1') {
        element.visible = false;
      }

      // ukrywanie dla wersji FAKTUROWANIE i FAKTUROWANIE PRO
      this.hideForVersionInvoices(element);
      //

      // email
      if (
        element.id == '53' &&
        !this.event.checkPermissions('Serwerpocztowy')
      ) {
        element.visible = false;
      }

      // tymczasowo dla FK (brak uprawnien)
      if (
        this.application === 'fk' &&
        this.event.returnEnvironmentInfo() !== 'prod' &&
        element.id > 1
      ) {
        element.visible = true;
      }
    });
  };

  getPermissionsToMenu = () => {
    // setTimeout(() => {
    this.permissions = this.event.decryptString(
      localStorage.getItem('appPermissions')
    );
    this.checkAccess(this.navigation, 0);
    // }, 800);
  };

  getTables = () => {
    if (this.event.isAdditionalTableAvailable()) {
      this.companyTables = this.event.getTablesByGroupCode('Firma');
    }
  };

  getNests = () => {
    //setTimeout(()=>{
    if (true) {
      // czy prestiz plus
      const nestsString = localStorage.getItem('nests');
      if (!nestsString) {
        return;
      }

      const nestsDecrypted = this.event.decryptString(nestsString);
      this.nestService.setNewNests(nestsDecrypted);
    }
    //}, 800)
  };

  getBrowserVersion() {
    var userAgent = navigator.userAgent,
      tem,
      matchTest =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }

    matchTest = matchTest[2]
      ? [matchTest[1], matchTest[2]]
      : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    return matchTest.join(' ');
  }

  ngAfterViewInit() {
    if (this.event.deviceType == 'desktop') {
      this.openedChange();
    }
  }

  //ustawia sciezke routingu na pasku nawigacyjnym
  setRouterUrl = () => {
    let page = this.pageRouter.split('/');
    this.translate.get('menu.' + page[page.length - 1]).subscribe((text) => {
      this.pageRouter = text;
    });
  };

  openedChange = () => {
    this.isHideUserPanel = false;

    /* na mobile nie wyłapuje elementu w DOM */
    // let text = document.getElementsByClassName(
    //   'dx-toolbar-item-content'
    // ) as HTMLCollectionOf<HTMLElement>;

    // menuOpened
    let text = document.querySelector(
      '.menu-toggle-btn .dx-toolbar-item-content'
    ) as Element;
    if (this.isDrawerOpen) {
      // text[0].classList.add('isopen');
      text?.classList.add('isopen');
    } else {
      // text[0].classList.remove('isopen');
      text?.classList.remove('isopen');
    }
    this.event.menuOpened(this.isDrawerOpen);

    if (this.isDrawerOpen) {
      setTimeout(() => {
        this.isVersionSelected = true;
        this.cd.detectChanges();
      }, 200);
    } else {
      this.isVersionSelected = false;
      this.cd.detectChanges();
    }
  };

  itemClick = (data) => {
    if (data.itemData.action && typeof data.itemData.action === 'function') {
      data.itemData.action();
    }
    if (data.itemData.url !== null) {
      this.route.navigate([`./${data.itemData.url}`]);
    }
  };

  isNestRunning: boolean = false;
  nest: Socket;
  nestSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  nestsSub: Subscription;
  logOut = async () => {
    //const channel = new BroadcastChannel('logout');
    if (!this.event.isVersionForNestAvailable) {
      //channel.postMessage('logOutUser');
      this.event.checkLogout(true);
      return;
    }
    await this.runBeforeLogoutNest();
    //channel.postMessage('logOutUser');
    this.event.checkLogout(true);
  };

  runBeforeLogoutNest() {
    return new Promise((resolve) => {
      this.nestService.nests.pipe(first()).subscribe((res: SocketMap[]) => {
        const socket: SocketMap = res.find((el) => el.SocketCode === 'XGSS');
        if (!socket) {
          resolve(true);
          return;
        }
        this.nestsSub = this.nestService.nestRunning.subscribe((res) => {
          if (res) {
            return;
          }

          this.nestsSub?.unsubscribe();
          resolve(true);
        });
        const nest: Socket = this.nestService.findNest(
          socket.nest.Sockets,
          'XGSSZ'
        );

        if (!nest) {
          resolve(true);
          return;
        }
        this.nestService.nestRunning.next(true);
        this.nestService.runGlobalNest({ nest, nextUrl: null });
      });
    });
  }

  onNestFinished() {
    this.nestSubject.next(true);
  }

  findSocketAndRun(socket: Socket, SocketCode: string) {
    const newNest = this.nestService.findNest(socket.Sockets, SocketCode);

    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      // gniazdo po - koniec
      this.nestSubject.next(true);
      return true; // nieznalazlo
    }
  }

  getProvinces = () => {
    this.appService.getAuth(`provinces?skip=0&take=20`).subscribe((res) => {
      localStorage.setItem(
        'provincesListWapro',
        this.event.encryptString(res.data)
      );
    });
  };

  selectDatabase() {
    this.chooseCompany = false;
    this.route.navigateByUrl('select-company', {
      state: { load: true },
    });
  }

  onItemClick = (e) => {
    if (e.itemData?.type === 'popup') {
      return;
    }

    this.changeRouter(e.itemData);

    this.chooseCompany = false;

    this.chooseWarehouse = false;

    this.chooseCash = false;
  };

  hideApp: boolean = true;
  hideAppPanel = (e) => {
    if (e == '1') {
      if (this.widthPanelApps) {
        setTimeout(() => {
          if (this.hideApp) {
            this.widthPanelApps = !this.widthPanelApps;
          }
        }, 200);
      } else {
        setTimeout(() => {
          if (this.isShowSubmenu) {
            this.widthPanelApps = !this.widthPanelApps;
          }
        }, 600);
      }
    } else {
      this.widthPanelApps = false;
      this.hideApp = true;
    }
  };

  showPanelApp = () => {
    this.hideApp = false;
  };

  hidePanelApp = () => {
    this.hideApp = true;
    this.widthPanelApps = false;
  };

  onToolbarClick() {
    this.widthPanelApps = false;
  }

  changeRouter(item) {
    try {
      if (item.url === 'jpk/help') {
        window.open('https://wapro.pl/dokumentacja-erp/anywhere/', '_blank');
        return;
      }

      //if (item.name !== 'menu.start') {
      this.searchValue = '';
      this.countData = 0;
      this.isSearchResult = false;
      this.isHideUserPanel = false;
      const strConf = localStorage.getItem('defaultMenuConfiguration');
      const defaultConfiguration = strConf ? JSON.parse(strConf) : {};
      if (defaultConfiguration[item.code]) {
        this.route.navigateByUrl(defaultConfiguration[item.code]);
      } else {
        let index = 0,
          found = false;
        if (item.name == 'menu.comercial-operation') {
          index = 1;
        }

        if (
          item.code == 'Magazyn' &&
          (this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
            this.event.footerInfo.VariantName === 'FAKTUROWANIE' ||
            this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE PRO' ||
            this.event.footerInfo.VariantName === 'FAKTUROWANIE PRO')
        ) {
          index = 1;
        }

        if (
          this.event.footerInfo.VariantName.includes('FAKTUROWANIE') &&
          item.code === 'Rap'
        ) {
          index = 2;
        }

        if (item.name == 'menu.administrator') {
          this.route.navigateByUrl(item.submenu[0].items[0].url);
          return;
        }

        // if (item.name == 'menu.gdpr') {
        //   this.route.navigateByUrl(item.submenu[0].url);
        //   return;
        // }

        if (item.url === 'start/dashboard') {
          this.route.navigateByUrl('start/dashboard');
          return;
        }

        if (item.submenu) {
          while (!found || index > item.submenu.length) {
            const foundItem = this.permissions.find(
              (field) => field.Code == item.submenu[index].code
            );
            if (
              foundItem ||
              item.url === 'gdpr' ||
              item.url === 'changes' ||
              item.url === 'definitions'
            ) {
              found = true;
            } else {
              index++;
            }
          }
        }

        if (found) {
          localStorage.setItem(
            'lastNavigateWapro_' + this.application,
            item.submenu[index].url
          );
          this.route.navigateByUrl(item.submenu[index].url);
        } else {
          localStorage.setItem(
            'lastNavigateWapro_' + this.application,
            item.url
          );
          this.route.navigateByUrl(item.url);
        }
      }
      //}
    } catch {
  }
  }

  clickContent = () => {
    this.onFocus = false;
    this.isSearchResult = false;
    this.isHideUserPanel = false;
    this.chooseCompany = false;
    this.chooseWarehouse = false;
    this.chooseCash = false;
    this.isHelpVisible = false;
    this.searchValue = '';
    this.countData = 0;
  };

  keyEnter = (url) => {
    if (url.name == 'menu.apps') {
      this.widthPanelApps = !this.widthPanelApps;
    }
    if (url.name == 'menu.navigationPanelUser.help') {
      window.open(
        'https://wapro.pl/dokumentacja-erp/mag/webframe.htm',
        '_blank'
      );
    }
  };

  myTimer;
  onValueChanged = (e) => {
    this.isSearchLoading.set(true);
    e.component.getButton('clearSearch').option('visible', e.value.length != 0);

    this.countData = 0;
    setTimeout(() => {
      try {
        if (this.listMenuSearch.items.length > 0) {
          this.isSearchMenuResultVisible = true;
        } else {
          this.isSearchMenuResultVisible = false;
        }
      } catch {
      }
    }, 0);

    if (this.myTimer) {
      clearTimeout(this.myTimer);
    }

    this.myTimer = setTimeout(() => {
      this.searchRes();
    }, 250);

    if (this.searchValue.length >= 3) {
      // this.navigationSearchData.filter([['name', 'contains', this.searchValue]]);
      this.navigationSearchData.searchValue(this.searchValue);
      this.navigationSearchData.load();

      if (this.navigationSearchData.items().length > 0) {
        this.isSearchMenuResultVisible = true;
      } else {
        this.isSearchMenuResultVisible = false;
      }
    } else {
      this.navigationSearchData.searchValue('');
      this.navigationSearchData.load();

      if (this.navigationSearchData.items().length > 0) {
        this.isSearchMenuResultVisible = true;
      } else {
        this.isSearchMenuResultVisible = false;
      }
    }
  };

  onItemClickDateSearch = (e) => {
    switch (e.itemData.Context) {
      case 'WarehouseDocument':
        if (e.itemData.ObjectId) {
          this.route.navigate(['warehouse/warehouse-documents'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['warehouse/warehouse-documents']);
        }
        break;
      case 'InvoiceDocument':
        if (e.itemData.ObjectId) {
          this.route.navigate(['trade-operations/trader-documents'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['trade-operations/trader-documents']);
        }
        break;
      case 'SettlementDocument':
        if (e.itemData.ObjectId) {
          this.route.navigate(['trade-operations/settlements'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['trade-operations/settlements']);
        }
        break;
      case 'OrderDocument':
        if (e.itemData.ObjectId) {
          this.route.navigate(['warehouse/orders'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['warehouse/orders']);
        }
        break;
      case 'OfferDocument':
        if (e.itemData.ObjectId) {
          this.route.navigate(['crm/offers'], {
            queryParams: { id: e.itemData.Description },
          });
        } else {
          this.route.navigate(['crm/offers']);
        }
        break;
      case 'FinanceDocument':
        if (e.itemData.ObjectId) {
          this.route.navigate(['trade-operations/finances-documents'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['trade-operations/finances-documents']);
        }
        break;
      case 'DocumentServiceOrder':
        if (e.itemData.ObjectId) {
          this.route.navigate(['crm/service-orders'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['crm/service-orders']);
        }
        break;
      case 'CRM':
        this.route.navigate(['crm/crm-tasks']);
        break;
      case 'ProductionDocument':
        this.route.navigate(['warehouse/production-documents']);
        break;
      case 'Product':
        if (e.itemData.ObjectId) {
          this.route.navigate(['library/articles'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['library/articles']);
        }
        break;
      case 'Customer':
        if (e.itemData.ObjectId) {
          this.route.navigate(['library/customers'], {
            queryParams: { id: e.itemData.ObjectId },
          });
        } else {
          this.route.navigate(['library/customers']);
        }
        break;
    }
    this.isSearchResult = false;
  };

  onClickSearchInput(e) {
    this.onFocus = !this.onFocus;
    this.isSearchResult = !this.isSearchResult;
    this.isHideUserPanel = false;
    this.chooseCompany = false;
    this.heightSearchMenu = 400;
    e.currentTarget.blur();

    setTimeout(() => {
      try {
        let height = this.listMenuSearch.items.length * 55;
        if (this.listMenuSearch.items.length >= 8) {
          this.heightSearchMenu = 400;
        } else {
          this.heightSearchMenu = height;
        }
      } catch {
      }
    }, 0);
  }

  isSearchLoading: WritableSignal<boolean> = signal(false);
  searchRes = () => {
    if (this.searchValue.length >= 3) {
      this.isSearchLoading.set(true);
      this.appService
        .getAuth(`userExtensions/searchGlobal?searchKey=${this.searchValue}`)
        .subscribe({
          next: (response) => {
            this.resultSearchData = new DataSource({
              store: response.data,
              paginate: true,
              pageSize: 8,
            });
            this.countData = response.data.length;
            if (response.data.length >= 8) {
              this.heightSearchData = 410;
            } else {
              this.heightSearchData = response.data.length * 55;
            }

            this.isSearchLoading.set(false);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
            this.isSearchLoading.set(false);
          },
        });
      this.isSearchResult = true;

      // this.navigationSearchData.filter(['name', 'contains', this.searchValue]);
      this.navigationSearchData.searchExpr([
        'name',
        'patch',
        'patch2',
        'patch3',
        'url',
      ]);
      this.navigationSearchData.searchValue(this.searchValue);
      this.navigationSearchData.load();
    }
  };

  onKeyDown(e) {
    if (e.event.keyCode == 40 || e.event.keyCode == 38) {
      this.event.setFocus(this.listMenuSearch);
    }
  }

  onPageLoadingData = () => {
    if (this.heightSearchData + 410 < window.innerHeight) {
      this.heightSearchData += 390;
    }
  };

  currentColorWarehouse: string = '';

  onRowClickWarehouse(data) {
    if (data.data.WarehouseId === this.event.footerInfo.AppWarehouseId) {
      this.chooseWarehouse = false;
      return;
    }

    this.currentColorWarehouse = data.data.Color;
    this.onWarehouseChange({ itemData: data.data });
  }

  getDocumentsNumerations() {
    return new Promise((resolve) => {
      this.appService.getAuth('document/sales/numeration').subscribe(
        (res) => {
          let staticData = this.event.decryptString(
            localStorage.getItem('staticData')
          );

          staticData.documentsNumerations = res;

          localStorage.setItem(
            'staticData',
            this.event.encryptString(staticData)
          );
          resolve(true);
        },
        () => {
          resolve(true);
        }
      );
    });
  }

  async afterChangeCompanu() {
    this.event.onShown();
    await this.event.getPermissions();
    await this.event.getCompanyConfiguration();
    await this.event.getUserConfiguration();
    await this.event.getSessionInformation();
    await this.event.getInvoiceDocumentTypes();
    await this.getDocumentsNumerations();
    // await this.event.getBusinessLinkConfiguration();

    sessionStorage.clear();
    const reloadChangeComapny = new BroadcastChannel('reloadChangeComapny');
    reloadChangeComapny.postMessage('reload');
    this.event.dontLogOut = true;
    location.reload();
  }

  async onChoosedCompany(e) {
    this.choosedCompanyId = e.data.CompanyId;
    if (e.data.CompanyId === this.event.footerInfo.AppCompanyId) {
      this.chooseCompany = false;
      return;
    }

    if (this.event.openArrayDocument.length > 0) {
      this.event.showNotification(
        'warning',
        this.translate.instant('youCanOnlyhave1tabOpenToChangeCompanyWarehouse')
      );
      return;
    }

    this.chooseCompany = false;
    let currentCompany;
    const obj = {
      FieldCode: 'DomFirma',
      Value: `${e.data.CompanyId}`,
    };
    this.event.onShown();
    this.appService
      .postAuth(`configurations/users/currentUser/config`, [obj])
      .pipe(
        switchMap(() => {
          this.event.footerInfo.CompanyName = e.data.CompanyName;
          this.event.footerInfo.CompanyId = e.data.CompanyId;

          return this.appService.getAuth(`companies`);
        }),
        switchMap((res: any) => {
          currentCompany = res?.data?.find(
            (field) => field.CompanyId == e.data.CompanyId
          );
          return this.appService.getAuth(
            `companies/${currentCompany?.CompanyId}/bankAccounts`
          );
        })
      )
      .subscribe({
        next: (bank: any) => {
          let pom = this.event.decryptString(
            localStorage.getItem('staticData')
          );
          pom.companyBankAccounts = bank.data;
          this.event.refreshBankList.next(pom.companyBankAccounts);
          this.event.footerInfo.AppCompanyId = currentCompany.CompanyId;
          localStorage.setItem('staticData', this.event.encryptString(pom));
          this.saveToLocalStorage(obj);
          this.afterChangeCompanu();
          // this.event.getPermissions().then(() => {
          //   this.event.getCompanyConfiguration().then(() => {
          //     this.event.getUserConfiguration().then(() => {
          //       this.event.getSessionInformation().then(() => {
          //         this.event.getInvoiceDocumentTypes().then(() => {
          //           location.reload();
          //         });
          //       });
          //     });
          //   });
          // });
        },
        error: (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        },
      });
  }

  onWarehouseChange = (e) => {
    this.event.removeSessionStorageData(WAREHOUSES_KEY);
    if (this.event.getWindowCount() > 1) {
      this.event.showNotification(
        'warning',
        this.translate.instant('youCanOnlyhave1tabOpenToChangeCompanyWarehouse')
      );
      return;
    }

    const obj = {
      FieldCode: 'LastUsedWarehouse',
      Value: `${e.itemData.WarehouseId}`,
    };
    this.chooseWarehouse = false;
    this.appService
      .putAuth(`configurations/users/currentUser/currentWarehouse`, [obj])
      .subscribe(
        () => {
          this.event.footerInfo.AppWarehouseName = e.itemData.WarehouseName;
          this.event.footerInfo.AppWarehouseId = e.itemData.WarehouseId;
          this.appDetails.user[3].body.content =
            this.event.footerInfo.AppWarehouseName;
          localStorage.setItem(
            'sessionInfoWaproHtml',
            this.event.encryptString(this.event.footerInfo)
          );
          this.event.checkStatusWarehouse(e.itemData.WarehouseId);
          this.saveToLocalStorage(obj);
          this.currentWarehouseSymbol = e.itemData.WarehouseName;
          this.event.footerInfo.AppWarehouseId = e.itemData.WarehouseId;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  selectedKeys = [1];

  isTap: any = null;
  touchStart = (e) => {
    this.isTap = e.id;
  };

  touchEnd = () => {
    this.isTap = null;
  };

  onDefaultOnboardingChanged(e) {
    if (!e.event) {
      return;
    }

    this.event.isAutoOnboarding.next(e.value);
    this.saveGuideParameter(e.value ? 'Tak' : 'Nie');
  }

  saveGuideParameter(Value: string) {
    const obj = {
      FieldCode: 'ShowAnywhereGuide',
      Value,
    };
    this.appService
      .postAuth(`configurations/users/currentUser/config`, [obj])
      .subscribe({
        next: () => {
          this.event.showNotification(
            'success',
            this.translate.instant('updated-record')
          );

          this.saveToLocalStorage(obj);
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  onDestroy() {
    this.onboardingSub?.unsubscribe();
  }

  keyUpSearch = (e) => {
    if (e.key == 'Enter') {
      this.searchRes();
    }
  };

  adjustRightMargin = (type: string, event?: MouseEvent) => {
    let btn = event.currentTarget as HTMLElement;
    let rightMargin = window.innerWidth - btn.getBoundingClientRect().right;
    this.dropdownMarginsR[type] = rightMargin;
  };

  choosedCompanyId: number;
  onChooseCompany = (e) => {
    this.choosedCompanyId = this.event.footerInfo.AppCompanyId;
    this.chooseCompany = !this.chooseCompany;
    this.chooseCash = false;
    this.isHideUserPanel = false;
    this.isSearchResult = false;
    this.onFocus = false;
    this.isShowQueue = false;
    this.chooseWarehouse = false;
    this.isHelpVisible = false;
    this.adjustRightMargin('company', e);
    this.getCompanies();
    e.currentTarget.blur();
  };

  onChooseWarehouse = (e) => {
    this.getWarehouses();
    this.chooseWarehouse = !this.chooseWarehouse;
    this.chooseCash = false;

    this.chooseCompany = false;
    this.isHideUserPanel = false;
    this.isSearchResult = false;
    this.isShowQueue = false;
    this.isHelpVisible = false;
    this.adjustRightMargin('warehouse', e);
    e.currentTarget.blur();
  };

  onUserPanelClick = (e) => {
    this.isHideUserPanel = !this.isHideUserPanel;
    this.chooseCompany = false;
    this.isShowQueue = false;
    this.chooseCash = false;
    this.chooseWarehouse = false;
    this.isHelpVisible = false;
    this.adjustRightMargin('user', e);
    e.currentTarget.blur();
  };

  onHelpClick = (e) => {
    this.isHelpVisible = !this.isHelpVisible;
    this.isHideUserPanel = false;
    this.chooseCompany = false;
    this.isShowQueue = false;
    this.chooseWarehouse = false;
    this.adjustRightMargin('help', e);
    e.currentTarget.blur();
  };

  onNotificationsClick = (e) => {
    this.isShowQueue = !this.isShowQueue;
    this.chooseCompany = false;
    this.isHideUserPanel = false;
    this.chooseWarehouse = false;
    this.isHelpVisible = false;
    e.currentTarget.blur();
  };

  focusedCashId: number = null;

  onChooseCash(e) {
    this.focusedCashId = this.event.footerInfo.AppCashId;
    this.chooseCash = !this.chooseCash;
    this.chooseCompany = false;
    this.isHideUserPanel = false;
    this.isSearchResult = false;
    this.onFocus = false;
    this.isShowQueue = false;
    this.chooseWarehouse = false;
    this.isHelpVisible = false;
    this.adjustRightMargin('cash', e);
    this.getCashList();
    e.currentTarget.blur();
  }

  getCashList() {
    const localData = this.event.getSessionStorageData(CASH_BOXES_KEY);
    if (localData) {
      this.cashList = localData;
      this.sortCashBox();
      return;
    } else {
      this.appService
        .getAuth(`finances/cashBoxes?skip=0&take=200`)
        .subscribe((res) => {
          this.event.saveSessionStorageData(CASH_BOXES_KEY, res.data);
          this.cashList = res.data;
          this.sortCashBox();
        });
    }
  }

  saveToLocalStorage(parameter: { FieldCode: string; Value: string }) {
    const paramsStr = localStorage.getItem('userConfiguration');
    if (!paramsStr) {
      return;
    }

    const localParameters = this.event.decryptString(paramsStr);
    const foundParameterIndex: number = localParameters.findIndex(
      (el) => el.Field.FieldCode === parameter.FieldCode
    );
    if (foundParameterIndex !== -1) {
      localParameters[foundParameterIndex].Value = parameter.Value;
    }

    this.event.userParameters = localParameters;

    localStorage.setItem(
      'userConfiguration',
      this.event.encryptString(localParameters)
    );
  }

  onChoosedCash(e) {
    const obj = {
      FieldCode: 'DomKasa',
      Value: String(e.data.CashId),
    };

    this.appService
      .postAuth(`configurations/users/currentUser/config`, [obj])
      .subscribe({
        next: () => {
          this.event.footerInfo.AppCashId = e.data.CashId;
          this.event.footerInfo.AppCashName = e.data.Name;
          this.event.footerInfo.AppCashSymbol = e.data.Symbol;
          this.saveToLocalStorage(obj);
          this.chooseCash = false;
          this.event.refreshCash.next(true);
          this.focusedCashId = e.data.CashId;
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  runPopup(item) {
    if (item.code === 'EksportWFFAKIR') {
      this.event.isExportFKVisible.set(true);
    } else if (item.code === 'changeDate') {
      this.event.isDateRangeVisible.set(true);
    }
  }

  onInsertedVersion(e) {
    this.event.footerInfo.VariantName = e.replace('365 ', '');

    this.checkAccess(this.navigation, 0);
    localStorage.setItem(
      'sessionInfoWaproHtml',
      this.event.encryptString(this.event.footerInfo)
    );

    this.isSelectedVersion = false;
    this.cd.detectChanges();
  }

  navigateByLastUrl(def: string): void {
    if (localStorage.getItem('lastNavigateWapro_' + this.application)) {
      this.route.navigateByUrl(
        localStorage.getItem('lastNavigateWapro_' + this.application)
      );
    } else {
      this.route.navigateByUrl(def);
    }
  }

  goApp(app: string, redirect: boolean = true): void {
    setTimeout(() => {
      switch (app) {
        case 'mag':
          this.application = 'mag';
          this.navigation = dataMenu;
          this.dropdownVisible = {
            cash: true,
            warehouse: true,
            company: true,
            user: true,
            help: true,
            search: true,
          };
          localStorage.setItem('activeApplicationWapro', this.application);
          if (redirect) {
            this.navigateByLastUrl('start/dashboard');
          }
          this.refreshLastActivity();
          break;
        case 'jpk':
          this.application = 'jpk';
          this.navigation = dataMenuJpk;
          this.dropdownVisible = {
            cash: false,
            warehouse: false,
            company: true,
            user: true,
            help: true,
            search: false,
          };
          localStorage.setItem('activeApplicationWapro', this.application);
          if (redirect || this.route.url.indexOf('jpk/') < 0) {
            this.navigateByLastUrl('jpk/journal');
          }
          this.refreshLastActivity();
          break;
        case 'fk':
          this.application = 'fk';
          this.navigation = dataMenuFK;
          this.dropdownVisible = {
            cash: false,
            warehouse: false,
            company: true,
            user: true,
            help: true,
            search: false,
          };
          //this.setSubmenu();
          localStorage.setItem('activeApplicationWapro', this.application);
          if (redirect) {
            this.navigateByLastUrl('fk/dashboard');
          }
          this.refreshLastActivity();
          break;
        case 'auctions':
          this.application = 'auctions';
          this.navigation = dataMenuAuctions;
          this.dropdownVisible = {
            cash: false,
            warehouse: false,
            company: true,
            user: true,
            help: true,
            search: false,
          };
          this.submenu = [];
          localStorage.setItem('activeApplicationWapro', this.application);
          if (redirect) {
            this.navigateByLastUrl('auctions/dashboard');
          }
          this.refreshLastActivity();
          break;
        case 'gdpr':
          this.application = 'gdpr';
          this.navigation = dataMenuGdpr;
          this.dropdownVisible = {
            cash: false,
            warehouse: false,
            company: true,
            user: true,
            help: true,
            search: false,
          };
          localStorage.setItem('activeApplicationWapro', this.application);
          if (redirect) {
            this.navigateByLastUrl('gdpr/data-sets');
          }
      }

      this.getPermissionsToMenu();
      this.getTables();
      this.navigation.forEach((element) => {
        element.items = element.submenu;
      });
      this.checkAccess(this.navigation, 0);
    }, 0);
  }

  apps = [
    { shortcut: 'mg', master: 'sprzedaż', detail: 'i magazyn', app: 'mag' },
    {
      shortcut: 'jpk',
      master: 'jednolity plik',
      detail: 'kontrolny',
      app: 'jpk',
    },
    { shortcut: 'fk', master: 'finanse', detail: 'i księgowość', app: 'fk' },
    {
      shortcut: 'rd',
      master: 'RODO',
      detail: '',
      app: 'gdpr',
    }
  ];

  goToUsers() {
    this.route.navigate(['/auctions/accounts']);
  }

  isTopMenuVisible() {
    return (
      !this.isAuctions() ||
      this.route.url.includes('auctions/dashboard') ||
      this.route.url.includes('auctions/news')
    );
  }

  showAppInfo():void {
    this.isAboutVersion = true
    this.cd.detectChanges();
  }

  closeAppInfo(): void {
    this.isAboutVersion = false
    this.cd.detectChanges();
  }
}
