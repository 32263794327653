<ng-container *ngIf="errorsList$ | async as dataSource">
  <dx-list
    [dataSource]="dataSource"
    class="error-list"
    *ngIf="dataSource.length; else emptyState"
  >
    <div *dxTemplate="let item of 'item'; let index = index">
      <div id="error" class="d-flex error-record error">
        <div class="error-icon"></div>

        <div class="d-flex flex-column error-record-first-col">
          <div class="error-title">{{ item.message }}</div>
          <div class="error-date">
            {{ item.date | date : "yyyy-MM-dd HH:mm:ss" }}
          </div>
        </div>
        <div class="d-flex flex-column error-record-second-col">
          <div
            class="error-icon-wrapper"
            (click)="delete(index)"
            [id]="'delete' + index"
          >
            <i class="icon absui-icon--close"></i>
          </div>
          <div
            class="error-icon-wrapper"
            (click)="copy(item)"
            [id]="'copy' + index"
          >
            <i class="icon absui-icon--docs-many"></i>
          </div>
        </div>
      </div>
      <dx-tooltip
        [target]="'#delete' + index"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#copy' + index"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.copy" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-list>
</ng-container>

<ng-template #emptyState>
  <div style="margin: 0 auto">
    <div
      class="emptyState"
      style="height: calc(100vh - 157px)"
      [style.width.px]="width"
    >
      <img
        src="../../../assets/img/Empty-state/no-data-queue.svg"
        alt="Asseco Business Solutions SA"
        loading="lazy"
      />
      <p class="p-emptyState">
        {{ "noDataText" | translate }}
      </p>
    </div>
  </div>
</ng-template>
