import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BrandWaproAnywhereSvgComponent } from 'src/assets/svg/brand-wapro-anywhere-svg/brand-wapro-anywhere-svg.component';
import { MagSvgComponent } from 'src/assets/svg/mag-svg/mag-svg.component';
import { WaproPlSvgComponent } from 'src/assets/svg/wapro-pl-svg/wapro-pl-svg.component';

@Component({
    selector: 'wapro-logo',
    templateUrl: 'wapro-logo.component.html',
    styleUrls: ['./wapro-logo.component.scss'],
    inputs: ['type', 'width', 'height', 'fillColorPrimary', 'fillColorSecondary'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, WaproPlSvgComponent, MagSvgComponent, BrandWaproAnywhereSvgComponent]
})
export class WaproLogoComponent {
  @Input() type: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() fillColorPrimary?: string;
  @Input() fillColorSecondary?: string;
  @Input() svgClass?: string;

  ngOnInit(): void {
    this.fillColorPrimary = this.fillColorPrimary
      ? `fill-${this.fillColorPrimary}`
      : 'fill-brand-neutral-10';
    this.fillColorSecondary = this.fillColorSecondary
      ? `fill-${this.fillColorSecondary}`
      : 'fill-brand-20';
  }
}
