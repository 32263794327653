import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IMessage, IMessageButton } from '../../interfaces/message';
import { CommonModule, NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-single-message',
  imports: [NgIf, NgForOf, CommonModule],
  templateUrl: './single-message.component.html',
    styleUrl: './single-message.component.scss'
})
export class SingleMessageComponent {
  constructor(public elementRef: ElementRef) {}
  @Input() message: IMessage = <IMessage>{};
  @Input() isLast: boolean = false;
  @Output() onChoiceFromMsg = new EventEmitter<IMessage>();
  @Output() onReload = new EventEmitter<string>();

  onButtonClick(button: IMessageButton) {
    const message = <IMessage>{
      text: button.text,
      time: new Date().toISOString(),
      type: 'outgoing',
    };

    this.message.buttons!.find((b) => b.value === button.value)!.chosen = true;
    this.onChoiceFromMsg.emit(message);
  }

  reload(e: MouseEvent) {
    const btn = e.target as HTMLButtonElement;
    btn.classList.add('chosen');
    this.onReload.emit();
  }
}
