import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragulaModule } from 'ng2-dragula';
import { EventService } from 'src/app/event.service';
import { ErrorListService } from './error-list.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DxButtonModule } from 'devextreme-angular/ui/button';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  // ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppServices } from './app-services.service';
import { NestService } from './nest-service.service';

import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { LogOnComponent } from './log-on/log-on.component';

import { DxTooltipModule } from 'devextreme-angular';

import { UserIdleModule } from 'angular-user-idle';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { QuicklinkModule } from 'ngx-quicklink';
import { NumerationDeactivateGuard } from './administrator/numeration-deactivate-guard';
import {
  AuthGuard,
  chechCassLog,
  isCashId,
  isExistToken,
  isPermission,
  WarehouseDocumentsGuard,
  ComercialDocumentsGuard,
  CrmPackagesGuard,
  SettlementsGuard,
  CustomersGuard,
  EDocumentsGuard,
} from './auth.guard';
import { NestDeactivateGuard } from './can-deactive-guard';
import { ConfigurationNestService } from './expansion-slote/configuration-nest.service';
import { SaveErrorInterceptor } from './save-error.interceptor';
import { ToastReminderComponent } from './core/toast-reminder/toast-reminder.component';
import { ConfirmDialogComponent } from './core/confirm-dialog/confirm-dialog.component';
import { WaproLoaderComponent } from './core/wapro-loader/wapro-loader.component';
import { NgShortcutsComponent } from './core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { NestComponent } from './nest/nest.component';
import { MenuComponent } from './menu-wapro/menu/menu.component';
import { ApplicationStatusMessageBarComponent } from './application-status-message-bar/application-status-message-bar.component';
import { ExportFakirComponent } from './other/export-fakir/export-fakir.component';
import { EmailSerialSendComponent } from './crm/email/email-serial-send/email-serial-send.component';
import { EmailSerialMailComponent } from './crm/email/email-serial-mail/email-serial-mail.component';
import { EmailAttachmentComponent } from './crm/email/email-attachment/email-attachment.component';
import { isLocalhost } from './isLocalhost.guard';
import { ComercialOperationModule } from './comercial-operation/comercial-operation.module';
import { CoreModule } from './core/core.module';

import config from 'devextreme/core/config';
import { WaproAssistantComponent } from './wapro-assistant/components/wapro-assistant/wapro-assistant.component';

const licenseKey =
  'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjEyMWExN2Y3LWZmMTUtNDdhYS1iYTY2LThhNDJjYTY1ZTI2MiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.B0EsatLpIUdG1pQ3koQgaggtK1aL0IiSOiQSSw3pmG9EuZpeEEcY/SpLGdglb7rNhMhUmdLZ1qhfE1xs5X5LcedswvQTYZ/T+Vf5mvjMCWWRD2881A1oVbJFK4clnbj8RLWJyQ==';

config({ licenseKey });

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    QuicklinkModule,
    ConfirmDialogComponent,
    DxPopupModule,
    DxTooltipModule,
    WaproLoaderComponent,
    MenuComponent,
    BrowserModule,
    HttpClientModule,
    LogOnComponent,
    ToastReminderComponent,
    DxToolbarModule,
    AppRoutingModule,
    DxButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,
    DxMenuModule,
    DxScrollViewModule,
    DxLoadPanelModule,
    NgShortcutsComponent,
    ComercialOperationModule,
    UserIdleModule.forRoot({ idle: 10, timeout: 60, ping: 120 }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    //ServiceWorkerModule.register('ngsw-worker.js'),
    DragulaModule.forRoot(),
    NestComponent,
    ApplicationStatusMessageBarComponent,
    ExportFakirComponent,
    EmailSerialSendComponent,
    EmailSerialMailComponent,
    EmailAttachmentComponent,
    CoreModule,
    WaproAssistantComponent,
  ],
  providers: [
    AppServices,
    AuthGuard,
    chechCassLog,
    isCashId,
    isLocalhost,
    DatePipe,
    ErrorListService,
    EventService,
    NestService,
    isExistToken,
    isPermission,
    WarehouseDocumentsGuard,
    ComercialDocumentsGuard,
    CrmPackagesGuard,
    SettlementsGuard,
    CustomersGuard,
    EDocumentsGuard,
    ConfigurationNestService,
    NestDeactivateGuard,
    NumerationDeactivateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SaveErrorInterceptor,
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
