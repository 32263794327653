<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [resizeEnabled]="true"
  [showTitle]="true"
  [title]="'menu.exportFakir' | translate"
  [maxHeight]="event.maxHeightSignal()"
  [visible]="true"
  [resizeEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (visibleChange)="visibleChange($event)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <!-- , class: 'document-brand big-form-mobile' -->
  <div *dxTemplate="let content of 'content'">
    <!-- [ngClass]="{ boxShadow: scrollPosition > 0 }" -->
    <!-- *ngIf="event.deviceType != 'mobile'; else mobileHeaderTemplate" -->
    <div class="text-right title-pop-up">
      <!-- <i
        class="icon"
        [ngClass]="{
          'absui-icon--fullscreen2': !fullScreen,
          'absui-icon--fullscreen-off': fullScreen
        }"
        (click)="fullScreen = !fullScreen"
        style="position: relative; top: 5px"
        [id]="'fitToWidth' + unicalGuid"
      ></i> -->
      <dx-button
        text="{{ 'buttons.export' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'save' + unicalGuid"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        style="margin-right: 10px"
        (onClick)="onCancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#save' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.export" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <!-- (onScroll)="onScroll($event)" -->
    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 80px)'"
      direction="vertical"
      [bounceEnabled]="false"
      [scrollByContent]="true"
    >
      <div class="header-bar">
        <i class="btn-details icon" style="margin-right: 5px"></i>
        <div class="header-bar_text">
          Aby dodać dokumenty do eksportu przejdź do wybranej listy dokumentów.
        </div>
      </div>
      <div
        class="d-flex justify-content-center flex-wrap flex-box-wrapper"
        [formGroup]="form"
      >
        <div
          class="form-group-inline-right flex-box-column"
          style="width: 600px"
        >
          <div class="form-box">
            <div class="box-title">
              <div class="c-frame-row">
                <h5 style="margin-top: 5px; text-align: left; width: 100%">
                  Wybrane dokumenty
                </h5>
              </div>
            </div>
            <div
              class="flex-box-column-content"
              *ngIf="selections$ | async as selections"
            >
              <div class="box-row">
                <label style="width: 120px">{{
                  "menu.comercial-operation" | translate
                }}</label>

                <dx-text-box
                  class="textbox-with-btns"
                  [readOnly]="true"
                  [value]="selections?.Commercial + choosen"
                >
                  <dxi-button
                    name="comercialClear"
                    location="after"
                    [options]="onClearButtonComercial"
                  >
                  </dxi-button>
                </dx-text-box>
                <dx-button
                  id="comercial-btn"
                  [height]="28"
                  (onClick)="openUrl('comercial')"
                  icon="playlist-plus"
                  type="danger"
                ></dx-button>
                <dx-tooltip
                  target="#comercial-btn"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "exportFakir.goToList" | translate }}
                  </div>
                </dx-tooltip>
              </div>
              <div class="box-row">
                <label style="width: 120px">{{
                  "contactsAdd.warehouse" | translate
                }}</label>
                <dx-text-box
                  class="textbox-with-btns"
                  [readOnly]="true"
                  [value]="selections?.Warehouse + choosen"
                >
                  <dxi-button
                    name="warehouseClear"
                    location="after"
                    [options]="onClearButtonWarehouse"
                  >
                  </dxi-button>
                </dx-text-box>
                <dx-button
                  id="warehouse-btn"
                  [height]="28"
                  (onClick)="openUrl('warehouse')"
                  icon="playlist-plus"
                  type="danger"
                ></dx-button>
                <dx-tooltip
                  target="#warehouse-btn"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "exportFakir.goToList" | translate }}
                  </div>
                </dx-tooltip>
              </div>
              <div class="box-row">
                <label style="width: 120px">{{
                  "contactsAdd.financial" | translate
                }}</label>
                <dx-text-box
                  class="textbox-with-btns"
                  [readOnly]="true"
                  [value]="selections?.Financial + choosen"
                >
                  <dxi-button
                    name="financialClear"
                    location="after"
                    [options]="onClearButtonFinancial"
                  >
                  </dxi-button>
                </dx-text-box>
                <dx-button
                  id="financial-btn"
                  [height]="28"
                  (onClick)="openUrl('financial')"
                  icon="playlist-plus"
                  type="danger"
                ></dx-button>
                <dx-tooltip
                  target="#financial-btn"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "exportFakir.goToList" | translate }}
                  </div>
                </dx-tooltip>
              </div>

              <div class="box-row">
                <label style="width: 120px">RK/WB</label>
                <dx-text-box
                  class="textbox-with-btns"
                  [readOnly]="true"
                  [value]="selections?.RKWB + choosen"
                >
                  <dxi-button
                    name="rkwbClear"
                    location="after"
                    [options]="onClearButtonRkwb"
                  >
                  </dxi-button>
                </dx-text-box>
                <dx-button
                  id="rkwb-btn"
                  [height]="28"
                  (onClick)="openUrl('rkwb')"
                  icon="playlist-plus"
                  type="danger"
                ></dx-button>
                <dx-tooltip
                  target="#rkwb-btn"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "exportFakir.goToList" | translate }}
                  </div>
                </dx-tooltip>
              </div>
              <div class="box-row">
                <label style="width: 120px">Sad</label>
                <dx-text-box
                  class="textbox-with-btns"
                  [readOnly]="true"
                  [value]="selections?.Sad + choosen"
                >
                  <dxi-button
                    name="sadClear"
                    location="after"
                    [options]="onClearButtonSad"
                  >
                  </dxi-button>
                </dx-text-box>
                <dx-button
                  id="sad-btn"
                  [height]="28"
                  (onClick)="openUrl('sad')"
                  icon="playlist-plus"
                  type="danger"
                ></dx-button>
                <dx-tooltip
                  target="#sad-btn"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "exportFakir.goToList" | translate }}
                  </div>
                </dx-tooltip>
              </div>
            </div>
          </div>
          <div class="form-box">
            <div class="box-title">
              <div class="c-frame-row">
                <h5 style="margin-top: 5px; text-align: left; width: 100%">
                  {{ "exportFakir.exportOptions" | translate }}
                </h5>
              </div>
            </div>
            <div class="flex-box-column-content">
              <div class="box-row">
                <label style="width: 120px"
                  >{{ "inventory.nameOfFile" | translate }} XML</label
                >
                <dx-text-box
                  [(value)]="fileName"
                  class="textbox-with-btns"
                ></dx-text-box>
              </div>
              <div class="box-row">
                <label style="width: 120px"> </label>
                <dx-check-box
                  text="{{ 'exportFakir.accountDocuments' | translate }}"
                  formControlName="SetAccountingTag"
                ></dx-check-box>
              </div>
              <div class="box-row">
                <label style="width: 120px"> </label>
                <dx-check-box
                  text="{{ 'exportFakir.skipAccounted' | translate }}"
                  formControlName="SkipAccounted"
                ></dx-check-box>
              </div>
              <div class="box-row">
                <label style="width: 120px"> </label>
                <dx-check-box
                  text="{{ 'exportFakir.compressFile' | translate }}"
                  [value]="false"
                ></dx-check-box>
                <!-- 
            brakuje pola w formularzu
            formControlName="SkipAccounted" -->
              </div>
            </div>
          </div>
          <dx-accordion
            [dataSource]="['buttons.advanced' | translate]"
            [collapsible]="true"
            [selectedItems]="[1]"
            [multiple]="false"
            [animationDuration]="300"
            class="internalAccordion no-padding"
            style="margin-top: 0"
          >
            <div *dxTemplate="let company of 'item'">
              <div
                class="d-flex justify-content-center flex-wrap flex-box-wrapper"
              >
                <div
                  class="form-group-inline-right flex-box-column"
                  style="width: 600px"
                >
                  <div class="form-box">
                    <div class="box-title"></div>
                    <div class="flex-box-column-content">
                      <div class="box-row">
                        <label style="width: 120px">{{
                          "exportFakir.fileContent" | translate
                        }}</label>
                        <dx-select-box
                          class="textbox-with-btns"
                          [dataSource]="contentDataSource"
                          formControlName="ExportedType"
                          displayExpr="label"
                          valueExpr="value"
                        ></dx-select-box>
                      </div>
                      <div class="box-row">
                        <label style="width: 120px">{{
                          "exportFakir.skip" | translate
                        }}</label>
                        <dx-check-box
                          text="{{
                            'form-commercial-operation.customer' | translate
                          }}"
                          width="220"
                          formControlName="SkipCustomers"
                        ></dx-check-box>
                        <dx-check-box
                          text="{{
                            'exportFakir.externalFieldsHeader' | translate
                          }}"
                          width="220"
                          formControlName="SkipDocumentAdditionalField"
                        ></dx-check-box>
                      </div>
                      <div class="box-row">
                        <label style="width: 120px"> </label>
                        <dx-check-box
                          text="{{
                            'exportFakir.documentsPositions' | translate
                          }}"
                          width="220"
                          formControlName="SkipLines"
                        ></dx-check-box>
                        <dx-check-box
                          text="{{
                            'exportFakir.externalFieldsPositions' | translate
                          }}"
                          width="220"
                          formControlName="SkipLinesAdditionaField"
                        ></dx-check-box>
                      </div>
                      <div class="box-row">
                        <label style="width: 120px"> </label>
                        <dx-check-box
                          text="{{
                            'exportFakir.documentsCostsPositions' | translate
                          }}"
                          width="220"
                          formControlName="SkipCostLines"
                        ></dx-check-box>
                        <dx-check-box
                          text="{{
                            'exportFakir.externalFieldsCustomer' | translate
                          }}"
                          width="220"
                          formControlName="SkipCustomerAdditionalField"
                        ></dx-check-box>
                      </div>

                      <div class="box-row">
                        <label style="width: 120px"> </label>
                        <dx-check-box
                          text="{{ 'exportFakir.settlements' | translate }}"
                          width="220"
                          formControlName="SkipSettlement"
                        ></dx-check-box>

                        <dx-check-box
                          text="{{
                            'exportFakir.externalFieldsProducts' | translate
                          }}"
                          width="220"
                          formControlName="SkipProductAdditionalField"
                        ></dx-check-box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dx-accordion>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
