import { Injectable } from '@angular/core';
import { EventService } from './event.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {
  recognition: SpeechRecognition;
  constructor(
    private event: EventService,
    private translate: TranslateService
  ) {}

  public async init(): Promise<ISpeechRecognitionResult> {
    // check if browser supports speech recognition
    if (
      !('webkitSpeechRecognition' in window) &&
      !('SpeechRecognition' in window)
    ) {
      this.event.showNotification(
        'error',
        this.translate.instant('speechRecognition.noBrowserSupport')
      );
      return <ISpeechRecognitionResult>{
        errorTranslationSrc: 'speechRecognition.noBrowserSupport',
        transcript: null,
      };
    }

    // check if user has given permission to use microphone
    const permissions = await this.getPermissions();
    if (permissions !== 'granted') {
      try {
        // ask for permission to use microphone
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        return this.startSpeechRecognition();
      } catch (error) {
        // if user denies permission
        return <ISpeechRecognitionResult>{
          errorTranslationSrc: 'waproAssistant.noAccessToMic',
          transcript: null,
        };
      }
    } else {
      return this.startSpeechRecognition();
    }
  }

  async getPermissions() {
    if (!!navigator?.permissions) {
      return (
        navigator.permissions
          // @ts-ignore - ignore because microphone is not in the enum of name for all browsers
          ?.query({ name: 'microphone' })
          .then((result) => result.state)
          .catch(() => {
            return 'prompt';
          })
      );
    }
    return 'prompt';
  }

  async startSpeechRecognition(): Promise<ISpeechRecognitionResult> {
    return new Promise((resolve) => {
      let isResolved = false;
      this.recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();

      this.recognition.lang = navigator.language;
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 1;
      this.recognition.continuous = false;

      this.recognition.onresult = (_event) => {
        if (_event.results.length > 0) {
          isResolved = true;
          resolve(<ISpeechRecognitionResult>{
            transcript: _event.results[0][0].transcript,
            confidence: _event.results[0][0].confidence,
          });
        }
        return;
      };
      this.recognition.onerror = (_event) => {
        isResolved = true;
        resolve(<ISpeechRecognitionResult>{
          errorTranslationSrc: `speechRecognition.${_event.error}`,
          transcript: null,
        });
        this.event.showNotification(
          'error',
          this.translate.instant(`speechRecognition.${_event.error}`)
        );
        return;
      };
      this.recognition.onend = (_event) => {
        if (!isResolved) {
          resolve(<ISpeechRecognitionResult>{
            transcript: null,
          });
          this.event.showNotification(
            'warning',
            this.translate.instant('speechRecognition.noResult')
          );
        }
        this.recognition.stop();
        return;
      };
      this.recognition.start();
    });
  }

  stop(): void {
    if (this.recognition) {
      this.recognition.stop();
    }
  }
}

export interface ISpeechRecognitionResult {
  transcript: string;
  confidence?: number;
  errorTranslationSrc?: string;
}
