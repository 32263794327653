<div class="chat-message-avatar" *ngIf="message.type === 'incoming'">
  <div class="chat-message-avatar-bg"></div>
</div>
<div class="chat-message-content">
  <div class="chat-message-date">
    <span class="chat-message-sender" *ngIf="message.type === 'incoming'">
      Asystent
    </span>
    {{ message.time | date: 'HH:mm' }}
  </div>
  <div class="chat-message-text" *ngIf="!message.error; else errorTemplate">
    <span [innerHTML]="message.text"></span>
    <div class="chat-message-buttons" *ngIf="message?.buttons?.length">
      <button
        *ngFor="let button of message.buttons"
        class="chat-message-button"
        (click)="onButtonClick(button)"
        [disabled]="!isLast"
        [ngClass]="{ chosen: button.chosen }"
      >
        {{ button.text }}
      </button>
    </div>
  </div>
</div>

<ng-template #errorTemplate>
  <div class="chat-message-text has-error">
    <p>Wystąpił błąd podczas komunikacji z czatem:</p>
    <div class="error-details">
      <p *ngIf="message?.error?.name">
        <b><span [innerHTML]="message?.error?.name"></span></b>
      </p>
      <p *ngIf="message?.error?.status !== undefined">
        Kod błędu: <b><span [innerHTML]="message.error!.status!"></span></b>
      </p>
      <p *ngIf="message?.error?.message">
        <span [innerHTML]="message.error!.message!"></span>
      </p>
    </div>

    <div class="chat-message-buttons">
      <button
        class="chat-message-button"
        (click)="reload($event)"
        [disabled]="!isLast"
      >
        Spróbuj ponownie
      </button>
    </div>
  </div>
</ng-template>
