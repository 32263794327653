<div
  class="box-license-information"
  [ngClass]="{
    'box-license-warning': msgType() === 'warning',
    'box-license-error': msgType() === 'error',
    hideLicenseInfo: !isHideLicenseInfo()
  }"
>
  <div class="box-license-icon">
    @if (msgType().includes('info')){
    <i class="icon absui-icon--light-bulb-shine"></i>
    } @else if(msgType().includes('warning')){
    <i class="icon absui-icon--warning2"></i>
    } @else if(msgType().includes('error')){
    <i class="icon absui-icon--error-outline"></i>
    }
  </div>
  <div class="box-license-text">
    @if (msgType().includes('info')){
    <h2>Rozpoczynasz darmowy okres testowy!</h2>
    <p>
      Usługa działa w trybie testowym z pełną funkcjonalnością
      <strong>przez 30 dni</strong>
      . Po tym okresie, aby kontynuować pracę na swoich danych, konieczne będzie
      zakupienie usługi.
      <br />
      Możesz to zrobić, korzystając z
      <a href="https://sklep.assecobs.pl/">Moje konto Wapro</a>
      lub kontaktując się z działem handlowym albo swoim Partnerem.
      <br />

      W przypadku braku kontynuacji abonamentu na usługę, Twoje dane mogą być
      usunięte z usługi po okresie 30 dni.
    </p>
    } @if(msgType().includes('warning')){ @if(event.footerInfo.IsTesting){
    <h2>Okres testowy kończy się za {{ daysDifference() }} dni!</h2>
    <p>
      Usługa działa w trybie testowym jeszcze tylko przez
      {{ daysDifference() }} dni. Aby uniknąć przerwy w dostępie do pełnej
      funkcjonalności, opłać abonament już teraz.
      <br />
      Możesz to zrobić, korzystając z
      <a href="https://sklep.assecobs.pl/">Moje konto Wapro</a>
      lub kontaktując się z działem handlowym albo swoim Partnerem.
      <br />

      W przypadku braku kontynuacji abonamentu na usługę, Twoje dane mogą być
      usunięte z usługi po okresie 30 dni.
    </p>
    } @else if(isDemo) {
    <h2>Korzystasz z wersji DEMO!</h2>
    <p>
      Masz dostęp do przykładowych danych bez limitu czasowego. Jeśli chcesz
      pracować na własnych danych i korzystać z pełnej funkcjonalności -
      zarejestruj się już dziś i skorzystaj z
      <a
        class="try-for-free-link"
        [attr.href]="registrationLink()"
        target="_blank"
      >
        darmowej 30-dniowej wersji testowej
      </a>
      .
    </p>
    } @else {
    <h2>Okres licencji kończy się za {{ daysDifference() }} dni!</h2>
    <p>
      Usługa działa jeszcze tylko przez
      {{ daysDifference() }} dni. Aby uniknąć przerwy w dostępie do pełnej
      funkcjonalności, opłać abonament już teraz.
      <br />
      Możesz to zrobić, korzystając z
      <a href="https://sklep.assecobs.pl/">Moje konto Wapro</a>
      lub kontaktując się z działem handlowym albo swoim Partnerem.
      <br />

      W przypadku braku kontynuacji abonamentu na usługę, Twoje dane mogą być
      usunięte z usługi po okresie 30 dni.
    </p>
    } } @if(msgType().includes('error') && event.footerInfo.IsTesting){
    <h2>Okres testowy dobiegł końca!</h2>
    <p>
      Aby kontynuować korzystanie z pełnej funkcjonalności i mieć dostęp do
      swoich danych, musisz zakupić usługę.
      <br />
      Możesz to zrobić, korzystając z
      <a href="https://sklep.assecobs.pl/">Moje konto Wapro</a>
      lub kontaktując się z działem handlowym albo swoim Partnerem. Zapoznaj sie
      z
      <a
        href="https://wapro.pl/dokumentacja-erp/anywhere/docs/portal-anywhere-cloud/aplikacje/"
        target="_blank"
      >
        instrukcja
      </a>
      jak zarządzać abonamentem w Wapro Anywhere Cloud.
      <br />

      W przypadku braku kontynuacji abonamentu na usługę, Twoje dane mogą być
      usunięte z usługi po okresie 30 dni.
    </p>
    } @if(msgType().includes('error') && !event.footerInfo.IsTesting){
    <h2>Okres licencji dobiegł końca!</h2>
    <p>
      Aby kontynuować korzystanie z pełnej funkcjonalności i mieć dostęp do
      swoich danych, musisz zakupić usługę.
      <br />
      Możesz to zrobić, korzystając z
      <a href="https://sklep.assecobs.pl/">Moje konto Wapro</a>
      lub kontaktując się z działem handlowym albo swoim Partnerem. Zapoznaj sie
      z
      <a
        href="https://wapro.pl/dokumentacja-erp/anywhere/docs/portal-anywhere-cloud/aplikacje/"
        target="_blank"
      >
        instrukcja
      </a>
      jak zarządzać abonamentem w Wapro Anywhere Cloud.
      <br />

      W przypadku braku kontynuacji abonamentu na usługę, Twoje dane mogą być
      usunięte z usługi po okresie 30 dni.
    </p>
    }
  </div>
  <div class="box-action">
    @if(msgType().includes('warning') || msgType().includes('info')){
    @if(event.footerInfo.IsTesting){
    <dx-button
      icon="icon absui-icon--arrow-forward"
      [text]="'endTrialPeriod' | translate"
      (onClick)="goToPortal()"
      stylingMode="contained"
      [type]="msgType()"
      class="license-box-action-btn"
      [ngClass]="getBtnClass()"
    ></dx-button>
    } @else if(isDemo) {
    <dx-button
      icon="icon absui-icon--arrow-forward"
      [text]="'tryForFree.tryForFree' | translate"
      (onClick)="tryForTreeLinkClick()"
      stylingMode="contained"
      [type]="msgType()"
      class="license-box-action-btn"
      [ngClass]="getBtnClass()"
    ></dx-button>
    } @else{
    <dx-button
      icon="icon absui-icon--arrow-forward"
      [text]="'goToPortal' | translate"
      (onClick)="goToPortal()"
      stylingMode="contained"
      [type]="msgType()"
      class="license-box-action-btn"
      [ngClass]="getBtnClass()"
    ></dx-button>
    }
    <dx-button
      icon="icon absui-icon--close"
      [text]="'remindMeLater' | translate"
      stylingMode="contained"
      [type]="msgType()"
      (onClick)="hideLicenseInfo()"
      class="license-box-action-btn"
      [ngClass]="getBtnClass()"
    ></dx-button>

    } @else {

    <dx-button
      icon="icon absui-icon--arrow-forward"
      text="Przejdź do sklepu"
      type="success"
      (onClick)="goToMyAccount()"
      style="margin-left: 130px;"
    ></dx-button>
    }
  </div>
</div>

@if(isEndTrial()){
<app-confirm-dialog
  kindDialog="question"
  confirmText="youAreCurrentlyUsingTheTrialPeriod"
  [isVisible]="isEndTrial()"
  (onClosing)="isEndTrial.set(false)"
  (onRemoving)="accpetEndTrial()"
></app-confirm-dialog>
}
<dx-popup
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [visible]="isOpenLink()"
  [showCloseButton]="false"
  [width]="390"
  [height]="290"
  [minWidth]="390"
  [showTitle]="false"
  (visibleChange)="onVisibleChangedFinish($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-center">
      <img
        src="../../../assets/img/change-variant-success.svg"
        alt="Asseco BS"
      />

      <p class="h1-succes-chage">
        {{ 'updatedVariant' | translate }}
      </p>

      <p class="description-succes-chage">
        {{ 'yourNewVariant' | translate }}
      </p>

      <dx-button
        text="OK"
        type="success"
        (onClick)="isOpenLink.set(false)"
        [width]="70"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<app-try-for-free
  [isVisible]="isTryForFreeVisible()"
  [registrationLink]="registrationLink()"
  (onClosing)="closeTryForFree()"
></app-try-for-free>

<!--
<app-confirm-dialog
  kindDialog="information"
  [confirmText]="'paymentLinkGenerated'"
  [isVisible]="true"
  (onClosing)="openLink()"
  btnConfig="close"
>
</app-confirm-dialog> -->
