import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, signal, WritableSignal
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {EventService} from '../event.service';

@Component({
    selector: 'app-application-status-message-bar',
    imports: [CommonModule, TranslateModule],
    templateUrl: './application-status-message-bar.component.html',
    styleUrl: './application-status-message-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ApplicationStatusMessageBarComponent {
  textMessage: string = '';
  isVisible: WritableSignal<boolean>;

  inventoryStatusSub: Subscription;

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.isVisible = signal(false);
    this.inventoryStatusSub = this.event.isInventory
      .subscribe((res) => {
        if (res) {
          // this.isVisible = res;
          this.isVisible.set(res);
          // this.cd.detectChanges();
        } else if (!res) {
          // this.isVisible = false;
          this.isVisible.set(false);

          // this.cd.detectChanges();
        }
      });
  }

  deleteFooter() {
    this.isVisible.set(false);
  }

  ngOnDestroy() {
    try {
      this.inventoryStatusSub.unsubscribe();
    } catch {
    }
  }
}
